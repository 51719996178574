import { IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { FC, memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { GridCloseIcon } from "@mui/x-data-grid-premium";
import { BluSelect } from "@bludata/components";

interface ImoTipoUtenteInterface {
  disabled: boolean;
}

export const ImoTipoUtente: FC<ImoTipoUtenteInterface> = memo(
  ({ disabled }) => {
    const imoTipoUtente = useWatch({ name: "imoTipoUtente" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const options = [
      {
        label: "Solo un punto vendita",
        value: 1,
      },
      {
        label: "Tutti i negozi con lo stesso layout",
        value: 2,
      },
      {
        label: "Tutta l'azienda",
        value: 3,
      },
    ];
    const { setValue } = useFormContext();

    const handleOnChange = useCallback(
      (event: any) => {
        setValue("imoTipoUtente", event.target.value);
      },
      [setValue]
    );

    const handleErase = useCallback(() => {
      setValue("imoTipoUtente", 0);
    }, [setValue]);

    return (
      <Stack flex="1">
        <Typography>{"Tipo utente"}</Typography>
        <Stack flex="1" direction="row">
          <BluSelect
            multiple={false}
            disabled={disabled}
            value={imoTipoUtente}
            onChange={handleOnChange}
            renderValue={(selected: any) => {
              if (selected < 1 || selected > 3)
                return <>{"Seleziona un tipo utente"}</>;
              const index = selected - 1;
              return <>{options[index].label}</>;
            }}
          >
            {options.map((option: any) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </BluSelect>
          {!disabled && (
            <IconButton color="primary" size="small" onClick={handleErase}>
              <GridCloseIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    );
  }
);
