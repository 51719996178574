export interface LoginDataInterface {
  pointOfSaleId: string;
  userId: string;
  isAdmin: boolean;
  webSocketChannel: string;
  photo: string;
  username: string;
  isDemo: boolean;

  /**
   * ! software
   *  - 0 f10
   *  - 1 fw
   *  - 2 bb
   */
  software: string;

  /**
   * ! companyColorEnum
   *  Possibili valori:
   *  - 0 Colore appuntamenti in base al servizio
   *  - 1 Colore appuntamenti in base all'utente
   */
  companyColorEnum: number;

  /**
   * ! isManualAppointmentBlock
   *  Se a true il form di creazione appuntamenti è bloccato finchè non si usa il cerca disponibilità
   */
  isManualAppointmentBlock: boolean;

  /**
   * ! isSingleCalendarManagement
   *  - true gestine singolo calendario
   *  - false gestione multi calendario (di lavoro e appuntamenti)
   */
  isSingleCalendarManagement: boolean;

  /**
   * ! visibilityUserEnum
   *  visibilità dell'utente nel calendario
   *  - 0 visibilità per punto vendita, l'utente vede solo il suo punto vendita
   *  - 1 visibilità per azienda, l'utente vede tutti i punti vendita della sua azienda
   *  - 2 visibilità per layout, l'utente vede tutti i punti vendita del suo layout
   *  - 3 visibilità per tutte le aziende, l'utente vede tutti i punti vendita di tutte le aziende
   *  - 4 visibilità per utente, l'utente vede solo il suo calendario e può prendere appunatemnti solo per se stesso
   */
  visibilityUserEnum: number;
}

export enum softwareEnum {
  "f10",
  "fw",
  "bb",
}
