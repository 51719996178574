import { memo } from "react";
import { Avatar, Button, Stack } from "@mui/material";
import { FC, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { BluAlert } from "@bludata/components";
import ImageDefaultPdv from "../../../../../../assets/fotoempty.jpg";

interface CustomLogoInterface {
  disabled: boolean;
}

export const CustomLogo: FC<CustomLogoInterface> = memo(({ disabled }) => {
  const logo = useWatch({ name: "logo" });
  const [error, setError] = useState("");
  const { setValue, trigger } = useFormContext();

  //   const { name, control, toggleEdit, width, height, variant, sx, options,imgDefault } =
  //     props
  return (
    <>
      <BluAlert
        type="warning"
        show={!!error}
        text={error}
        handleClose={() => {
          setError("");
        }}
      />

      <Avatar
        sx={{
          mt: 2,
          width: 256,
          height: 128,
          border: "1px solid var(--divider-color)",
        }}
        variant="rounded"
        src={logo ? logo : ImageDefaultPdv}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Button
          color="primary"
          component="label"
          startIcon={<FileUploadIcon />}
          onClick={() => {}}
          disabled={disabled}
        >
          Carica
          <input
            accept="image/png, image/jpeg"
            type="file"
            hidden
            onChange={(e: any) => {
              const fileReader = new FileReader();

              if (e.target.files[0].size > 5 * 1048576) {
                setError("La dimensione massima è 5 mb");
                return;
              }

              fileReader.readAsDataURL(e.target.files[0]);
              fileReader.onloadend = async (event: any) => {
                //dispatch(setUserPhoto(e.target.files[0]))
                setValue("logo", event.target.result);
              };
            }}
          />
        </Button>

        <Button
          color="error"
          startIcon={<DeleteIcon />}
          disabled={disabled}
          onClick={() => {
            setValue("logo", "");
          }}
        >
          Elimina
        </Button>
      </Stack>
    </>
  );
});
