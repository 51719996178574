import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginDataInterface } from "../../interfaces/loginData";
import { RootState } from "..";

export interface RootSliceState {
  openLoader: boolean;
  loginData?: LoginDataInterface;
}

const initialState: RootSliceState = {
  openLoader: false,
};

const RootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setOpenLoader(state: RootSliceState, action: PayloadAction<boolean>) {
      state.openLoader = action.payload;
    },
    setLoginData(
      state: RootSliceState,
      action: PayloadAction<LoginDataInterface | undefined>
    ) {
      state.loginData = action.payload;
    },
  },
});

export const { setOpenLoader, setLoginData } = RootSlice.actions;

export const bluBookingLoginData = (state: RootState): LoginDataInterface =>
  state.root.loginData ?? {
    pointOfSaleId: "",
    userId: "",
    isAdmin: false,
    webSocketChannel: "",
    photo: "",
    username: "",
    software: "bb",
    isDemo: true,
    companyColorEnum: 0,
    isManualAppointmentBlock: false,
    isSingleCalendarManagement: true,
    visibilityUserEnum: 0,
  };

export default RootSlice.reducer;
