import { useEffect, useState } from "react";

const useCountdown = (value: any) => {
  const [countDown, setCountDown] = useState<number>(value);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) setCountDown(countDown - 1);
      else clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  return countDown;
};

export { useCountdown };
