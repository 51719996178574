import { MenuItem, Stack, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { BluSelect } from "@bludata/components";
import { FC, useCallback, useEffect } from "react";
import { useLaboratorioEsterno } from "../../../../api/laboratorio-esterno";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store";
import { setIsLoadingForm } from "../../../../store/root";

interface CustomSelectInterface {
  disabled?: boolean;
}

export const Laboratorio: FC<CustomSelectInterface> = ({ disabled }) => {
  const focusCodiceLaboratorioEsterno = useWatch({
    name: "focusCodiceLaboratorioEsterno",
  });
  const { setValue, trigger } = useFormContext();

  const { data, isLoading } = useLaboratorioEsterno();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoadingForm({ key: "laboratorioEsterno", value: isLoading }));
  }, [dispatch, isLoading]);

  const handleOnChange = useCallback(
    (event: any) => {
      setValue("focusCodiceLaboratorioEsterno", event.target.value);
      setValue("internalLaboratory", false);
      setValue("focusCodiceLaboratorio", "");

      trigger([
        "focusCodiceLaboratorioEsterno",
        "internalLaboratory",
        "focusCodiceLaboratorio",
      ]);
    },
    [setValue, trigger]
  );

  const getOptions = useCallback(() => {
    if (data) return data;
    return [];
  }, [data]);

  return (
    <Stack flex={1} sx={{ width: "100%" }}>
      <Typography>Laboratorio esterno</Typography>
      <BluSelect
        multiple={false}
        disabled={disabled || (data ?? []).length <= 0}
        value={focusCodiceLaboratorioEsterno}
        onChange={handleOnChange}
        renderValue={(selected: any) => {
          if (data && data.length > 0) {
            const find = data.find((d) => d.codiceFiliale === selected);
            if (find) return find.ragioneSociale;
          }
          return selected;
        }}
      >
        {getOptions().map((item: any) => (
          <MenuItem value={item.codiceFiliale}>{item.ragioneSociale}</MenuItem>
        ))}
      </BluSelect>
    </Stack>
  );
};
