import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../../store";
import { setRotaView } from "../../store/root";

export const ViewType = memo(() => {
  const { view } = useSelector((state: RootState) => state.rotaRoot);
  const dispatch = useDispatch<AppDispatch>();

  const handleTurnoTypeChange = useCallback(
    (event: any) => {
      dispatch(setRotaView(event.target.value));
    },
    [dispatch]
  );

  return (
    <Stack>
      <Typography sx={{ color: "var(--color)" }}>
        Tipo di visualizzazione
      </Typography>
      <Select value={view} onChange={handleTurnoTypeChange} sx={{ width: 150 }}>
        <MenuItem value="month">Mensile</MenuItem>
        <MenuItem value="day">Giornaliero</MenuItem>
      </Select>
    </Stack>
  );
});
