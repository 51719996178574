import { Button, Stack } from "@mui/material";
import { memo, useCallback } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { Else, If, Then } from "react-if";
import { useDispatch } from "react-redux";
import { setOpenForm } from "../../../../store/root";
import { AppDispatch } from "../../../../../../store";
import { BluAsyncDialog } from "@bludata/components";
import SaveIcon from "@mui/icons-material/Save";

interface ButtonsProps {
  initialValues?: any;
  openForm?: string;
  handleDelete: () => void;
  handleSubmit: () => void;
}

export const Buttons = memo(
  ({ openForm, initialValues, handleDelete, handleSubmit }: ButtonsProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { isValid, errors } = useFormState();
    const { reset } = useFormContext();

    const submit = useCallback(() => {
      if (!isValid) {
        const temp = [
          ...new Set(
            Object.values(errors).map(
              ({ message }: any) => `<li>${message}</li>`
            )
          ),
        ];

        // dispatch(setErrorPopup(true));
        BluAsyncDialog({
          title: "Attenzione",
          message: temp.join(""),
          confimButton: "Chiudi",
          hideDeclineButton: true,
          type: "warning",
          // maxWidth: false,
          sx: { "& .MuiDialog-paper": { maxWidth: "400px" } },
        });
        return;
      }
      handleSubmit();
    }, [errors, handleSubmit, isValid]);

    return (
      <Stack flex="1" flexDirection="row" gap="10px">
        <Stack flex="1">
          <If condition={openForm === "modify"}>
            <Then>
              <Button
                variant="contained"
                color="error"
                sx={{ width: "fit-content" }}
                onClick={() => handleDelete()}
              >
                Elimina
              </Button>
            </Then>
          </If>
          <If condition={openForm === "new"}>
            <Then>
              <Button
                variant="contained"
                color="error"
                sx={{ width: "fit-content" }}
                onClick={() => reset(initialValues)}
              >
                Azzera
              </Button>
            </Then>
          </If>
        </Stack>

        <If condition={openForm !== "info"}>
          <Then>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(setOpenForm(undefined));
                // dispatch(setCurrentStudio(undefined));
              }}
            >
              Annulla
            </Button>
            <Button
              variant="contained"
              onClick={submit}
              startIcon={<SaveIcon />}
            >
              Salva
            </Button>
          </Then>
          <Else>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(setOpenForm(undefined));
                // dispatch(setCurrentStudio(undefined));
              }}
            >
              Chiudi
            </Button>
          </Else>
        </If>
      </Stack>
    );
  }
);
