import moment from "moment";

export const checkShiftRanges = (schemaContext: {
  rangeStart: Date | null;
  rangeEnd: Date | null;

  typeShift: "default" | "extra" | "extra-closed" | "closed";
  isSingleCalendarManagement: boolean;
}): boolean => {
  const {
    rangeStart,
    rangeEnd,

    typeShift,
    isSingleCalendarManagement,
  } = schemaContext;

  if (isSingleCalendarManagement) return true;

  if (typeShift === "closed" || typeShift === "extra-closed") return true;
  if (rangeStart && rangeEnd)
    return moment(rangeEnd).isAfter(moment(rangeStart), "minute");
  if (!rangeStart && !rangeEnd) return true;
  return false;
};
