import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { AssistantInterface } from '../interfaces/assistant'

export const getAssistantInfo = async (
  assistantId: string
): Promise<AssistantInterface | null> => {
  try {
    const {
      data: { data }
    } = await axios.get(`/Assistant/${assistantId}`)

    if (data) {
      return { ...data, updatedAt: new Date(data.updatedAt) }
    }
    return null
  } catch (error) {
    console.log(error)
    return null
  }
}

export const useAssistantInfo = (assistantId: string, key: string = '') => {
  return useQuery({
    queryFn: async () => await getAssistantInfo(assistantId),
    queryKey: ['assistant', assistantId, key],
    retry: 2,
    retryDelay: 180000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
    // select: normalize,
  })
}
