interface DefaultRectangle {
  borderRadius: number;
  borderWidth: number;
  borderWidthEnable: number;
}

interface LogoInterface {
  width?: number;
  height?: number;
}

export interface DefaultCustomThemeInterface {
  rectangle: DefaultRectangle;
  logo: LogoInterface;
}

export const defaultCustomTheme: DefaultCustomThemeInterface = {
  rectangle: { borderRadius: 8, borderWidth: 1, borderWidthEnable: 2 },
  logo: {},
};
