import axios from "axios";
import { getUsersPath } from "../lib/white-cache";

export const updateUserGoogleCalendar = async (
  userId: string,
  email: string,
  id: string
): Promise<boolean> => {
  try {
    await axios.put(`${getUsersPath()}/GoogleCalendarUserSync/${id}`, {
      userId: userId,
      email: email,
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
