import moment from "moment";
import {
  TurniDefaultInterface,
  TurniItemInterface,
} from "../interfaces/default-turni-user";

export const checkAllDefaultHours = (
  defaultHours: TurniDefaultInterface
): any => {
  const errorDefaultHours: any = {};
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const turniType = ["calendar", "shift"];
  for (const key in defaultHours) {
    errorDefaultHours[key] = {
      shiftmonday1: false,
      shifttuesday1: false,
      shiftwednesday1: false,
      shiftthursday1: false,
      shiftfriday1: false,
      shiftsaturday1: false,
      shiftsunday1: false,
      shiftmonday2: false,
      shifttuesday2: false,
      shiftwednesday2: false,
      shiftthursday2: false,
      shiftfriday2: false,
      shiftsaturday2: false,
      shiftsunday2: false,

      calendarmonday1: false,
      calendartuesday1: false,
      calendarwednesday1: false,
      calendarthursday1: false,
      calendarfriday1: false,
      calendarsaturday1: false,
      calendarsunday1: false,
      calendarmonday2: false,
      calendartuesday2: false,
      calendarwednesday2: false,
      calendarthursday2: false,
      calendarfriday2: false,
      calendarsaturday2: false,
      calendarsunday2: false,
    };
    for (const turni of turniType) {
      for (const day of days) {
        errorDefaultHours[key][`${turni}${day}1`] = checkDefaultHoursValue(
          turni as any,
          1,
          day,
          defaultHours[key]
        );
        errorDefaultHours[key][`${turni}${day}2`] = checkDefaultHoursValue(
          turni as any,
          2,
          day,
          defaultHours[key]
        );
      }
    }
  }
  return errorDefaultHours;
};

export const checkDefaultHoursValue = (
  turniType: "calendar" | "shift",
  rangeNumber: 1 | 2,
  day: string,
  hours: TurniItemInterface | undefined
): boolean => {
  let defaultHours: any = hours;

  const range = defaultHours[`${turniType}${day}`];

  // ? CHECK RANGE NULL
  if (!range) return false;

  // ? SET RANGESTART AND RANGEEND
  let rangeStart = null;
  let rangeEnd = null;
  if (`startTime${rangeNumber}` in range && range[`startTime${rangeNumber}`])
    rangeStart = moment(range[`startTime${rangeNumber}`].toDate());
  if (`endTime${rangeNumber}` in range && range[`endTime${rangeNumber}`])
    rangeEnd = moment(range[`endTime${rangeNumber}`].toDate());

  // ? CHECK RANGESTART OR RANGEEND NULL
  if (!rangeStart && !rangeEnd) return false;
  if ((rangeStart && !rangeEnd) || (!rangeStart && rangeEnd)) return true;

  // ? CHEK IF RANGE IS VALID
  if (rangeStart!!.isSameOrAfter(rangeEnd)) return true;

  let otherRangeNumber = rangeNumber === 1 ? 2 : 1;
  let otherRangeStart = null;
  let otherRangeEnd = null;
  if (
    `startTime${otherRangeNumber}` in range &&
    range[`startTime${otherRangeNumber}`]
  )
    otherRangeStart = moment(range[`startTime${otherRangeNumber}`].toDate());
  if (
    `endTime${otherRangeNumber}` in range &&
    range[`endTime${otherRangeNumber}`]
  )
    otherRangeEnd = moment(range[`endTime${otherRangeNumber}`].toDate());

  if (otherRangeStart && otherRangeEnd) {
    if (
      otherRangeStart.isBetween(rangeStart, rangeEnd, "minutes", "[)") ||
      otherRangeEnd.isBetween(rangeStart, rangeEnd, "minutes", "(]")
    )
      return true;
  }

  // ? IF SHIFT RETURN
  if (turniType === "shift") return false;

  const rangeShift = defaultHours[`shift${day}`];
  if (!rangeShift) return true;

  let rangeShiftStart1 = null;
  let rangeShiftEnd1 = null;
  let rangeShiftStart2 = null;
  let rangeShiftEnd2 = null;
  if (`startTime1` in rangeShift && rangeShift[`startTime1`])
    rangeShiftStart1 = moment(rangeShift[`startTime1`].toDate());
  if (`startTime2` in rangeShift && rangeShift[`startTime2`])
    rangeShiftStart2 = moment(rangeShift[`startTime2`].toDate());

  if (`endTime1` in rangeShift && rangeShift[`endTime1`])
    rangeShiftEnd1 = moment(rangeShift[`endTime1`].toDate());
  if (`endTime2` in rangeShift && rangeShift[`endTime2`])
    rangeShiftEnd2 = moment(rangeShift[`endTime2`].toDate());

  if (rangeShiftStart1 && rangeShiftEnd1) {
    if (
      rangeStart!!.isBetween(
        rangeShiftStart1,
        rangeShiftEnd1,
        "minutes",
        "[)"
      ) &&
      rangeEnd!!.isBetween(rangeShiftStart1, rangeShiftEnd1, "minutes", "(]")
    )
      return false;
  }
  if (rangeShiftStart2 && rangeShiftEnd2) {
    if (
      rangeStart!!.isBetween(
        rangeShiftStart2,
        rangeShiftEnd2,
        "minutes",
        "[)"
      ) &&
      rangeEnd!!.isBetween(rangeShiftStart2, rangeShiftEnd2, "minutes", "(]")
    )
      return false;
  }

  return true;
};
