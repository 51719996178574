import { styled } from '@mui/material'

interface CustomMessageInterface {
  isUser: boolean
}

export const CustomMessage = styled('div')(
  ({ isUser }: CustomMessageInterface) => {
    return {
      maxWidth: '80%',
      borderRadius: '8px',
      height: 'fit-content',
      backgroundColor: isUser ? 'var(--base-color)' : 'var(--base-light-color)',
      display: 'flex',
      marginLeft: isUser ? 'auto' : '0px',
      marginRight: isUser ? '0px' : 'auto',
      color: isUser ? 'var(--reverse-color)' : 'var(--color)',
      padding: '8px',
      whiteSpace: 'normal',
      overflow: 'hidden',
      wordBreak: 'break-all'
      // textOverflow: 'ellipsis',
      // whiteSpace: 'pre-line'
    }
  }
)
