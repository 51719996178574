import { Card, Stack } from "@mui/material";
import {
  FC,
  memo,
  useCallback,
  useDeferredValue,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  RootState,
  useAppSelector,
} from "../../../../../../store";
import { useTurniDefaultUser } from "../../../../api/turni-default-user";
import { getUsersWhiteCache } from "../../../../lib/white-cache";
import { setDefaultHours } from "../../../../store/default-hours";
import { PoitOfSaleTabs } from "./PointOfSaleTabs";
import { DefaultHours } from "../DefaultHours/DefaultHours";
import { NewExtraHours } from "../ExtraHours/ExtraHours";
import { bluBookingLoginData } from "../../../../../../store/slices/root";

export const UserHours: FC<{
  mode: "modify" | "info" | "new";
}> = memo(({ mode }) => {
  const [pointOfSaleId, setPointOfSaleId] = useState<string>("");
  const { currentUserBluBookingId, pointOfSales } = useSelector(
    (state: RootState) => state.newUsersRoot
  );

  const { isSingleCalendarManagement } = useAppSelector(bluBookingLoginData);

  const dispatch: AppDispatch = useDispatch();
  const { defaultHours } = useSelector(
    (state: RootState) => state.newUserDefaultHours
  );
  const { data, isLoading } = useTurniDefaultUser(
    currentUserBluBookingId,
    isSingleCalendarManagement
  );
  const result = useDeferredValue(data);

  useEffect(() => {
    if (result) {
      const pointOfSalesKeys = Object.keys(result);
      if (pointOfSalesKeys.length > 0) {
        const d = [];
        for (const key of pointOfSalesKeys) {
          if (pointOfSales && key in pointOfSales)
            d.push({
              id: key,
              name: pointOfSales[key].branch,
            });
        }
        d.sort(function (a: any, b: any) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        if (d.length > 0) setPointOfSaleId(d[0].id);
      }
      dispatch(setDefaultHours(result));
    }
  }, [dispatch, isLoading, pointOfSales, result]);

  const getPointOfSales = useCallback(() => {
    if (!result) return [];
    const pointOfSaleKeys = Object.keys(defaultHours);
    const d = [];
    for (const key of pointOfSaleKeys) {
      if (pointOfSales && key in pointOfSales)
        d.push({
          id: key,
          name: pointOfSales[key]?.branch ?? "",
        });
    }
    d.sort(function (a: any, b: any) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return d;
  }, [defaultHours, pointOfSales, result]);

  const getDisabled = useCallback(() => {
    return mode === "info";
  }, [mode]);

  return (
    <Card variant="outlined" sx={{ p: 1, overflow: "visible" }}>
      <Stack flex="1" sx={{ position: "relative", top: 0 }}>
        <PoitOfSaleTabs
          defaultHoursPointOfSales={getPointOfSales()}
          value={pointOfSaleId}
          setValue={setPointOfSaleId}
          disabled={getDisabled()}
        />
        <DefaultHours
          disabled={getDisabled()}
          pointOfSaleId={pointOfSaleId}
          setPointOfSaleId={setPointOfSaleId}
          pointOfSales={Object.keys(defaultHours)}
        />
        <NewExtraHours disabled={getDisabled()} pointOfSaleId={pointOfSaleId} />
      </Stack>
    </Card>
  );
});
