import { TableRow } from "@mui/material";
import moment from "moment";
import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { nanoid } from "nanoid";
import { DoubleCellDay } from "../../components/double-cell-day";

interface CalendarPointOfSaleTableRowInterface {
  holiday: {
    [key: string]: string;
  };
  turni?: any;
  isSingleCalendarManagement: boolean;
}
export const CalendarPointOfSaleTableRow: FC<CalendarPointOfSaleTableRowInterface> =
  memo(({ holiday, turni, isSingleCalendarManagement }) => {
    const { selectedDate } = useSelector((state: RootState) => state.rotaRoot);

    const getCell = useCallback(
      (el: number) => {
        const realDate = moment(selectedDate).set({ hour: el });
        if (!turni)
          return (
            <DoubleCellDay
              hour={el}
              date={realDate.toDate()}
              isInitial={true}
              isSingleCalendarManagement={isSingleCalendarManagement}
            />
          );

        const date = realDate.format("YYYY-MM-DD");

        if (holiday && date in holiday)
          return (
            <DoubleCellDay
              hour={el}
              date={realDate.toDate()}
              isHoliday={true}
              isSingleCalendarManagement={isSingleCalendarManagement}
            />
          );
        const [calendar, shift] = turni;

        return (
          <DoubleCellDay
            hour={el}
            date={realDate.toDate()}
            shift={shift.current}
            calendar={calendar.current}
            isSingleCalendarManagement={isSingleCalendarManagement}
          />
        );
      },
      [holiday, isSingleCalendarManagement, selectedDate, turni]
    );

    const GetTurni = useMemo(() => {
      const calendarHours = Array.from({ length: 19 }, (_, i) => i + 5);
      return (
        <>
          {calendarHours.map((el: number) => {
            return <Fragment key={nanoid()}>{getCell(el)}</Fragment>;
          })}
        </>
      );
    }, [getCell]);

    return (
      <TableRow key={nanoid()} sx={{ height: "40px" }}>
        {GetTurni}
      </TableRow>
    );
  });
