import { FC, memo, useCallback, useDeferredValue, useEffect } from "react";
import { BluTitle } from "@bludata/components";
import { Card, Divider, Stack, Table } from "@mui/material";
import { DefaultBody } from "./DefaultBody";
import { DefaultHead } from "./DefaultHead";
import { getPointOfSaleWhiteCache } from "../../../../lib/white-cache";
import { useWatch } from "react-hook-form";
import { useTurniDefaultPointOfSale } from "../../../../api/turni-default-point-of-sale";
import {
  AppDispatch,
  RootState,
  useAppSelector,
} from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { setDefaultHours } from "../../../../store/default-hours";
import { ImportDefaultHours } from "./ImportDefaultHours";
import { DefaultFooter } from "./DefaultFooter";
import { setIsLoadingForm } from "../../../../store/root";
import { bluBookingLoginData } from "../../../../../../store/slices/root";

export const DefaultHours: FC<{
  mode: "modify" | "info" | "new";
}> = memo(({ mode }): JSX.Element => {
  const { isSingleCalendarManagement } = useAppSelector(bluBookingLoginData);
  const dispatch: AppDispatch = useDispatch();

  const defaultHours = useSelector(
    (state: RootState) => state.newPointOfSaleDefaultHours
  );

  const pointOfSaleId = useWatch({ name: "pointOfSaleId" });

  const { data, isLoading } = useTurniDefaultPointOfSale(
    pointOfSaleId,
    isSingleCalendarManagement
  );
  const result = useDeferredValue(data);

  const getDisabled = useCallback(() => {
    return mode === "info";
  }, [mode]);

  useEffect(() => {
    if (result) dispatch(setDefaultHours(result));
    dispatch(setIsLoadingForm({ key: "defaultHours", value: isLoading }));
  }, [dispatch, isLoading, result]);

  const getValues = useCallback(() => {
    return { ...defaultHours.defaultHours };
  }, [defaultHours]);

  return (
    <Stack flex="1" gap={1}>
      <ImportDefaultHours
        isSingleCalendarManagement={isSingleCalendarManagement}
      />
      <BluTitle
        text={"Orari di apertura"}
        sx={{ mt: "8px!important", mb: "8px!important" }}
      />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="start"
        spacing={2}
        flex="1"
      >
        <Table
          size="small"
          sx={{
            width: "100%",
            // border: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <DefaultHead
            isSingleCalendarManagement={isSingleCalendarManagement}
          />
          {result && (
            <DefaultBody
              disabled={getDisabled()}
              defaultHours={getValues()}
              isSingleCalendarManagement={isSingleCalendarManagement}
            />
          )}
          <DefaultFooter
            disabled={getDisabled()}
            isSingleCalendarManagement={isSingleCalendarManagement}
          />
        </Table>
      </Stack>
    </Stack>
  );
});
