import { FC, useMemo } from "react";
import { getUsersWhiteCache } from "../../../../lib/white-cache";
import { OtherInfoBB } from "./OtherInfoBB";
import { OtherInfoFW } from "./OtherInfoFW";

export const OtherInfo: FC<{
  mode: "modify" | "info" | "new";
}> = ({ mode }): JSX.Element => {
  const { isFocusWeb } = getUsersWhiteCache();

  const GetContent = useMemo(() => {
    if (isFocusWeb) return <OtherInfoFW disabled={mode === "info"} />;
    return <OtherInfoBB disabled={mode === "info"} />;
  }, [isFocusWeb, mode]);

  return <>{GetContent}</>;
};
