import { BluAutoComplete, BluTitle } from "@bludata/components";
import { Box } from "@mui/material";
import { useCallback } from "react";
import { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

export const PointOfSale = memo(() => {
  const pointOfSale = useWatch({
    name: "pointOfSaleId",
  });

  const { openForm, pointOfSales } = useSelector(
    (state: RootState) => state.studiRoot
  );

  const { setValue, trigger } = useFormContext();

  const getValue = useCallback(() => {
    if (pointOfSale !== "" && pointOfSale in pointOfSales)
      return pointOfSales[pointOfSale];
    return null;
  }, [pointOfSale, pointOfSales]);

  const handleChange = useCallback(
    (_: any, newValue: any) => {
      setValue("pointOfSaleId", newValue !== null ? newValue.id : "");
      trigger();
    },
    [setValue, trigger]
  );

  return (
    <div
      style={{
        width: "100%",
        padding: "5px 20px 5px 20px",
        boxSizing: "border-box",
      }}
    >
      <BluTitle text="Punto vendita" sx={{ p: "3px 0px" }} />
      <BluAutoComplete
        size="small"
        // disableClearable
        renderOption={(props: any, option: any) => (
          <Box component="li" {...props}>
            {option.branch}
          </Box>
        )}
        disabled={openForm === "info"}
        getOptionLabel={(option: any) => option.branch}
        data={Object.values(pointOfSales)}
        value={getValue()}
        // defaultValue={CONF.pointOfSale}
        onChange={handleChange}
        isOptionEqualToValue={(option: any, value: any) =>
          option.id === value.id
        }
      />
    </div>
  );
});
