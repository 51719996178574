import axios from "axios";
import PointOfSale_interface from "./interface/PointOfSale_interface";
import PointOfSale_interface_global from "./interface/PointOfSale_interface_global";

const fetchPointOfSales = (software: "bb" | "fw" | "crm" | "f10") => {
  return new Promise<PointOfSale_interface[] | PointOfSale_interface_global[]>(
    (resolve, reject) => {
      const errorHandler = (errorText: string) => {
        reject(errorText);
      };

      const url =
        software === "fw"
          ? "/focusapi/global/PointOfSale"
          : "/api/PointOfSale/UICalendar";

      axios
        .get(url)
        .then((response: any) => {
          if (software === "fw") {
            const newResponse = response.data.map((item: any) => {
              return { ...item, id: item.codiceFiliale };
            });
            resolve(newResponse);
          } else {
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          reject(false);
          errorHandler(error.response.data);
        });
    }
  );
};

export default fetchPointOfSales;
