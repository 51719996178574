import axios from "axios";
import { getUsersPath } from "../lib/white-cache";
import { CalendarUserInterface } from "../interfaces/calendar-user";
import { useQuery } from "@tanstack/react-query";
import { TurniDefaultInterface } from "../interfaces/default-turni-user";
import dayjs from "dayjs";

const getTurniShiftAndCalendarUser = async (
  userId: string,
  isSingleCalendarManagement: boolean
): Promise<[CalendarUserInterface[], CalendarUserInterface[]]> => {
  const getCalendarUser = async (
    userId: string
  ): Promise<CalendarUserInterface[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(`${getUsersPath()}/CalendarUser/User/${userId}`);
      return data;
    } catch (error) {
      return [];
    }
  };

  const getShiftUser = async (
    userId: string
  ): Promise<CalendarUserInterface[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(`${getUsersPath()}/ShiftUser/User/${userId}`);
      return data;
    } catch (error) {
      return [];
    }
  };
  if (!userId) return [[], []];

  try {
    if (isSingleCalendarManagement) {
      const responses = await getCalendarUser(userId);
      return [[], responses] as any;
    }

    const responses = await Promise.all([
      getCalendarUser(userId),
      getShiftUser(userId),
    ]);

    return responses as any;
  } catch (err) {
    console.error(err);
    return [[], []];
  }
};

export const useTurniDefaultUser = (
  userId: string,
  isSingleCalendarManagement: boolean = false,
  key: string = ""
) => {
  return useQuery({
    queryFn: async () =>
      await getTurniShiftAndCalendarUser(userId, isSingleCalendarManagement),
    queryKey: ["turni-default-user", userId, isSingleCalendarManagement, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: any): any => {
  const [calendar, shift] = data;
  const pointOfSalesCalendar: any[] = calendar
    .map((user: CalendarUserInterface) => user.pointOfSaleId)
    .flat();
  const pointOfSalesShift: any[] = shift
    .map((user: CalendarUserInterface) => user.pointOfSaleId)
    .flat();

  let result: TurniDefaultInterface = {};
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const daysTranslate: any = {
    monday: "hasMonday",
    tuesday: "hasTuesday",
    wednesday: "hasWednesday",
    thursday: "hasThursday",
    friday: "hasFriday",
    saturday: "hasSaturday",
    sunday: "hasSunday",
  };

  try {
    const getResult = (
      turno: any,
      turnoType: "calendar" | "shift",
      value: any | undefined
    ) => {
      let temp: any = {
        [`${turnoType}monday`]: null,
        [`${turnoType}tuesday`]: null,
        [`${turnoType}wednesday`]: null,
        [`${turnoType}thursday`]: null,
        [`${turnoType}friday`]: null,
        [`${turnoType}saturday`]: null,
        [`${turnoType}sunday`]: null,
      };
      if (value) {
        temp = { ...temp, ...value };
      }

      for (const day of days) {
        if (turno[daysTranslate[day]]) {
          temp[`${turnoType}${day}`] = {
            id: turno.id,
            pointOfSaleId: turno?.pointOfSaleId,
            startTime1:
              turno && turno?.startTime1
                ? dayjs(turno?.startTime1, "HH:mm:ss")
                : null,
            startTime2:
              turno && turno?.startTime2
                ? dayjs(turno?.startTime2, "HH:mm:ss")
                : null,
            endTime1:
              turno && turno?.endTime1
                ? dayjs(turno?.endTime1, "HH:mm:ss")
                : null,
            endTime2:
              turno && turno?.endTime2
                ? dayjs(turno?.endTime2, "HH:mm:ss")
                : null,
          };
        }
      }

      return temp;
    };

    for (const [index, value] of pointOfSalesCalendar.entries()) {
      const turno = calendar[index];
      result[value] = {
        ...getResult(turno, "calendar", result[value]),
      };
    }
    for (const [index, value] of pointOfSalesShift.entries()) {
      const turno = shift[index];
      result[value] = {
        ...getResult(turno, "shift", result[value]),
      };
    }
  } catch (err) {
    console.error("ERROR:", err);
  }
  return result;
};
