import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TurniDefaultInterface,
  TurniItemInterface,
} from "../interfaces/default-turni-point-of-sale";
import {
  checkAllDefaultHours,
  checkDefaultHoursValue,
} from "../components/PopUp/utils/check-default-hours";
import { RootState } from "../../../store";

export interface TurniDefaultHoursInterface {
  defaultHours: TurniDefaultInterface;
  errorDefaultHours: {
    shiftmonday1: boolean;
    shifttuesday1: boolean;
    shiftwednesday1: boolean;
    shiftthursday1: boolean;
    shiftfriday1: boolean;
    shiftsaturday1: boolean;
    shiftsunday1: boolean;
    shiftmonday2: boolean;
    shifttuesday2: boolean;
    shiftwednesday2: boolean;
    shiftthursday2: boolean;
    shiftfriday2: boolean;
    shiftsaturday2: boolean;
    shiftsunday2: boolean;

    calendarmonday1: boolean;
    calendartuesday1: boolean;
    calendarwednesday1: boolean;
    calendarthursday1: boolean;
    calendarfriday1: boolean;
    calendarsaturday1: boolean;
    calendarsunday1: boolean;
    calendarmonday2: boolean;
    calendartuesday2: boolean;
    calendarwednesday2: boolean;
    calendarthursday2: boolean;
    calendarfriday2: boolean;
    calendarsaturday2: boolean;
    calendarsunday2: boolean;
  };

  importDefaultHoursPopUp: boolean;
}

const initialState: TurniDefaultHoursInterface = {
  defaultHours: {
    shiftmonday: null,
    shifttuesday: null,
    shiftwednesday: null,
    shiftthursday: null,
    shiftfriday: null,
    shiftsaturday: null,
    shiftsunday: null,

    calendarmonday: null,
    calendartuesday: null,
    calendarwednesday: null,
    calendarthursday: null,
    calendarfriday: null,
    calendarsaturday: null,
    calendarsunday: null,
  },
  errorDefaultHours: {
    shiftmonday1: false,
    shifttuesday1: false,
    shiftwednesday1: false,
    shiftthursday1: false,
    shiftfriday1: false,
    shiftsaturday1: false,
    shiftsunday1: false,
    shiftmonday2: false,
    shifttuesday2: false,
    shiftwednesday2: false,
    shiftthursday2: false,
    shiftfriday2: false,
    shiftsaturday2: false,
    shiftsunday2: false,

    calendarmonday1: false,
    calendartuesday1: false,
    calendarwednesday1: false,
    calendarthursday1: false,
    calendarfriday1: false,
    calendarsaturday1: false,
    calendarsunday1: false,
    calendarmonday2: false,
    calendartuesday2: false,
    calendarwednesday2: false,
    calendarthursday2: false,
    calendarfriday2: false,
    calendarsaturday2: false,
    calendarsunday2: false,
  },

  importDefaultHoursPopUp: false,
};

const PointOfSaleDefaultHoursSlice = createSlice({
  name: "PointOfSaleDefaultHours",
  initialState,
  reducers: {
    setDefaultHours(
      state: TurniDefaultHoursInterface,
      action: PayloadAction<TurniDefaultInterface>
    ) {
      state.defaultHours = action.payload;
      const errors = checkAllDefaultHours(action.payload);
      state.errorDefaultHours = errors;
    },

    setDefaultHoursValue(
      state: TurniDefaultHoursInterface,
      action: PayloadAction<{
        defaultHoursValue: TurniItemInterface;
        day: string;
        turniType: "calendar" | "shift";
      }>
    ) {
      (state.defaultHours as any)[
        `${action.payload.turniType}${action.payload.day}`
      ] = action.payload.defaultHoursValue;

      (state.errorDefaultHours as any)[`shift${action.payload.day}1`] =
        checkDefaultHoursValue(
          "shift",
          1,
          action.payload.day,
          state.defaultHours
        );
      (state.errorDefaultHours as any)[`shift${action.payload.day}2`] =
        checkDefaultHoursValue(
          "shift",
          2,
          action.payload.day,
          state.defaultHours
        );
      (state.errorDefaultHours as any)[`calendar${action.payload.day}1`] =
        checkDefaultHoursValue(
          "calendar",
          1,
          action.payload.day,
          state.defaultHours
        );
      (state.errorDefaultHours as any)[`calendar${action.payload.day}2`] =
        checkDefaultHoursValue(
          "calendar",
          2,
          action.payload.day,
          state.defaultHours
        );
    },
    setImportDefaultHoursPopUp(
      state: TurniDefaultHoursInterface,
      action: PayloadAction<boolean>
    ) {
      state.importDefaultHoursPopUp = action.payload;
    },
    resetDefaultHours() {
      return initialState;
    },
  },
});

export const {
  setDefaultHours,
  setImportDefaultHoursPopUp,
  setDefaultHoursValue,
  resetDefaultHours,
} = PointOfSaleDefaultHoursSlice.actions;

export const defaultHourValueError = (
  state: RootState,
  turniType: "calendar" | "shift",
  day: string,
  rangeNumber: 1 | 2
): boolean =>
  (state.newPointOfSaleDefaultHours.errorDefaultHours as any)[
    `${turniType}${day}${rangeNumber}`
  ];

export const defaultHourValue = (
  state: RootState,
  turniType: "calendar" | "shift",
  day: string
): boolean =>
  (state.newPointOfSaleDefaultHours.defaultHours as any)[`${turniType}${day}`];

// Selector to check for any default hour value errors
export const hasErrordefaultHourValue = (state: RootState): boolean =>
  Object.values(state.newPointOfSaleDefaultHours.errorDefaultHours).some(
    (error) => error
  );

export default PointOfSaleDefaultHoursSlice.reducer;
