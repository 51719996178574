import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { FC, memo } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface EmailNotSetPopupProps {
  open: boolean;
  text: string;
  text2?: string;
  countDown?: number;
  action: (event: any) => void;
  cancelAction: (event: any) => void;
}

export const DeletePopup: FC<EmailNotSetPopupProps> = memo(
  ({ open, text, text2, action, cancelAction, countDown = 0 }) => {
    return (
      <Dialog open={open} maxWidth={text2 ? "sm" : "xs"}>
        <DialogTitle
          style={{
            backgroundColor: "var(--background-color)",
            color: "var(--color)",
            cursor: "move",
            textAlign: "center",
          }}
        >
          <Stack flexDirection="column" alignItems="center">
            <WarningAmberIcon
              color="warning"
              style={{
                fontSize: "6.0rem",
              }}
            />
            Attenzione
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div" sx={{ color: "var(--color)" }}>
            {/* <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ paddingBottom: "10px" }}
            >
              Attenzione
            </Typography> */}
            {text2}
            <Typography fontSize="1rem" fontWeight="bold">
              {text}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <div style={{ flex: 1 }}>
              <Button
                variant="outlined"
                sx={{ textAlign: "center" }}
                onClick={cancelAction}
              >
                <div style={{ width: "100%", textAlign: "center" }}>
                  Annulla
                </div>
              </Button>
            </div>

            <Button
              variant="contained"
              color="primary"
              sx={{ textAlign: "center" }}
              onClick={action}
              disabled={countDown > 0}
            >
              <div style={{ width: "100%", textAlign: "center" }}>{`${
                countDown > 0 ? countDown : ""
              } Conferma`}</div>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    );
  }
);
