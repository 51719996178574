import axios from "axios";
import { HolidayInterface } from "../interfaces/holiday";
import { getRotaPath } from "../lib/white-cache";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

const getHoliday = async (range: Date): Promise<HolidayInterface[]> => {
  try {
    const startTime = moment(range).startOf("month").format("YYYY-MM-DD");
    const endTime = moment(range).endOf("month").format("YYYY-MM-DD");
    const {
      data: { data },
    } = await axios.post(`${getRotaPath()}/Holiday/Range`, {
      startDate: startTime,
      endDate: endTime,
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const useHoliday = (range: Date, key: string = "") => {
  return useQuery({
    queryFn: async () => await getHoliday(range),
    queryKey: ["holiday", range, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: (data: any) => normalize(data, range),
  });
};

const normalize = (data: any, range: Date): any => {
  const result: any = {};
  for (const row of data) {
    const date = moment(range).set({ date: row.day }).format("YYYY-MM-DD");
    result[date] = row.id;
  }
  return result;
};
