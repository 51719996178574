import { FC, memo, useEffect } from 'react'

import { Drawer, List } from '@mui/material'
import React from 'react'
import { AssistantsMenu } from './components/assistant/assistant'
import { ThreadsMenu } from './components/thread'
import { ThreadPopup } from './components/thread-popup'
import { useAssistants } from './api/get-assistants'
import { useThread } from './api/get-thread'
import BluLoader from '../../common/Loader'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { getThreadIdCancel } from './utils/white-cache'

interface ChatMenuInterface {
  threadId?: string | null
  setThreadId: (newThreadId?: string | null) => void
}

// let firstLoad: boolean = true

export const ChatMenu: FC<ChatMenuInterface> = memo(
  ({ threadId, setThreadId }) => {
    const { data: assistants, isLoading: isAssistantsLoading } = useAssistants()
    const { data: threads, isLoading: isThreadLoading } = useThread()
    const { isLoading } = useSelector((state: RootState) => state.chatMenu)

    useEffect(() => {
      const width = document.body.clientWidth
      if (width <= 600) return
      if (threads && threads.length && threadId === undefined) {
        if (getThreadIdCancel() !== threads[0].openAiId)
          setThreadId(threads[0].openAiId)
      }
    }, [setThreadId, threadId, threads])

    return (
      <>
        <BluLoader open={isAssistantsLoading || isThreadLoading || isLoading} />
        <ThreadPopup setThreadId={setThreadId} />
        <Drawer
          open
          // onClose={onMenuClose}
          variant="permanent"
          sx={{
            position: 'static',
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            '& .MuiPaper-root': {
              position: 'static',
              backgroundColor: 'var(--base-foreground-color)',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto'
            }
          }}
        >
          <List
            sx={{
              flex: 1,
              overflowY: 'auto',
              pt: 0,
              '& .MuiListItemButton-root': {
                height: '46px',
                paddingLeft: '6px !important'
              },
              '& .MuiListItemButton-root:hover': {
                backgroundColor: 'var(--hovered-background-color) !important',
                borderLeft: '6px solid var(--base-color)',
                paddingLeft: '0px !important'
              },
              '& .MuiListItemButton-root.Mui-selected': {
                backgroundColor: 'var(--hovered-background-color) !important',
                borderLeft: '6px solid var(--base-color)',
                paddingLeft: '0px !important'
              },
              '& .MuiListItem-root.Mui-selected': {
                backgroundColor: 'var(--base-color) !important'
              },
              '& .MuiListItem-root.Mui-selected svg': {
                color: 'var(--reverse-color) !important'
              },
              '& .MuiListItem-root.Mui-selected .MuiListItemButton-root:hover':
                {
                  backgroundColor: 'var(--base-color) !important'
                }
            }}
          >
            <AssistantsMenu assistants={assistants} setThreadId={setThreadId} />

            <ThreadsMenu
              threadId={threadId}
              setThreadId={setThreadId}
              threads={threads}
            />
          </List>
        </Drawer>
      </>
    )
  }
)
