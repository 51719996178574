import { BluTitle } from "@bludata/components";
import { Stack } from "@mui/material";
import { FC } from "react";
import { getUsersWhiteCache } from "../../../../lib/white-cache";
import { CustomTextField } from "../../common/CustomTextField";
import { CustomCheckbox } from "../../common/CustomCheckBox";
import { CustomUserImage } from "../../components/userImage/UserImage";

export const InformazioniGeneraliBB: FC<{
  disabled: boolean;
}> = ({ disabled }): JSX.Element => {
  const { isFocus10 } = getUsersWhiteCache();

  return (
    <Stack flex="1">
      <BluTitle text={"Informazioni generali"} />
      <Stack direction="column">
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="end"
          spacing={0.5}
          width={"100%"}
          mt={1}
        >
          <Stack direction="column" flex="1" justifyItems="start">
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing={2}
              flex="1"
            >
              <CustomTextField
                name="username"
                label="Utente*"
                disabled={disabled || isFocus10}
                sx={{ flex: "1" }}
              />
              <CustomTextField
                name="sign"
                label="Codice"
                disabled
                width="80px"
              />
              <CustomCheckbox
                name="isActive"
                label="Attivo"
                disabled={disabled || isFocus10}
                width="90px"
              />
            </Stack>
            <Stack direction="row" flex={1} gap={1}>
              <CustomTextField
                name="surname"
                label="Cognome"
                disabled={disabled}
              />
              <CustomTextField name="name" label="Nome" disabled={disabled} />
            </Stack>
          </Stack>
          {!isFocus10 && <CustomUserImage disabled={disabled} />}
        </Stack>
      </Stack>
    </Stack>
  );
};
