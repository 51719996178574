import { BluColorPicker, BluNumberField } from "@bludata/components";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../../store";
import { TestPalette } from "./test";
import { setPaletteValue } from "../../store/palette";
import { setCustomThemeValue } from "../../store/custom-theme";

interface PaletteInterface {
  disabled: boolean;
}

export const Palette: FC<PaletteInterface> = memo(({ disabled }) => {
  const { background, footer, primary } = useSelector(
    (state: RootState) => state.layoutPalette
  );
  const { logo } = useSelector((state: RootState) => state.layoutCustomTheme);
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Stack direction={"row"} spacing={2}>
      <Stack gap="10px" flex="1" sx={{ pr: "10px" }}>
        <Stack
          gap="2%"
          flexWrap="wrap"
          sx={{ width: "100%" }}
          flexDirection="unset"
        >
          <Stack flex="1">
            <Typography>Larghezza logo (px)</Typography>
            <BluNumberField
              disabled={disabled}
              value={logo?.width ?? "auto"}
              onChange={(event: any) => {
                dispatch(
                  setCustomThemeValue({
                    logo: {
                      ...logo,
                      width: event === null ? undefined : event,
                    },
                  })
                );
              }}
            />
          </Stack>
          <Stack flex="1">
            <Typography>Altezza logo (px)</Typography>
            <BluNumberField
              disabled={disabled}
              value={logo?.height ?? "auto"}
              onChange={(event: any) =>
                dispatch(
                  setCustomThemeValue({
                    logo: {
                      ...logo,
                      height: event === null ? undefined : event,
                    },
                  })
                )
              }
            />
          </Stack>
        </Stack>
        <Stack
          gap="2%"
          flexWrap="wrap"
          sx={{ width: "100%" }}
          flexDirection="unset"
        >
          <Stack flex="1">
            <Typography>Intestazione</Typography>
            <BluColorPicker
              disabled={disabled}
              value={background?.default ?? ""}
              onChange={(args: any) =>
                dispatch(
                  setPaletteValue({
                    background: {
                      ...background,
                      default: args.currentValue.hex,
                    },
                  })
                )
              }
            />
          </Stack>
          <Stack flex="1">
            <Typography>Pagina</Typography>
            <BluColorPicker
              disabled={disabled}
              value={background?.paper ?? ""}
              onChange={(args: any) =>
                dispatch(
                  setPaletteValue({
                    background: {
                      ...background,
                      paper: args.currentValue.hex,
                    },
                  })
                )
              }
            />
          </Stack>
        </Stack>
        <Stack
          gap="2%"
          flexWrap="wrap"
          sx={{ width: "100%" }}
          flexDirection="unset"
        >
          <Stack flex="1">
            <Typography>Colore primario</Typography>
            <BluColorPicker
              disabled={disabled}
              value={primary?.main ?? ""}
              onChange={(args: any) =>
                dispatch(
                  setPaletteValue({
                    primary: {
                      ...primary,
                      main: args.currentValue.hex,
                    },
                  })
                )
              }
            />
          </Stack>

          <Stack flex="1">
            <Typography>Piè di pagina</Typography>
            <BluColorPicker
              disabled={disabled}
              value={footer?.main ?? ""}
              onChange={(args: any) =>
                dispatch(
                  setPaletteValue({
                    footer: {
                      ...footer,
                      main: args.currentValue.hex,
                    },
                  })
                )
              }
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack flex="1.5">
        <TestPalette />
      </Stack>
    </Stack>
  );
});
