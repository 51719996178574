import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ServiceUserInterface } from "../interfaces/service-details";

const getServiceUser = async (
  serviceId: string
): Promise<ServiceUserInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get(`/api/ServiceUser/Service/${serviceId}`);
    return data;
  } catch (error) {
    return [];
  }
};

export const useServiceUser = (serviceId: string, key: string = "") => {
  return useQuery({
    queryFn: async () => getServiceUser(serviceId),
    queryKey: ["services-user", serviceId, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
