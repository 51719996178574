import axios from "axios";
import { getRotaPath } from "../lib/white-cache";

export const deleteExtraShiftPointOfSale = async (
  id: string
): Promise<boolean> => {
  try {
    await axios.delete(`${getRotaPath()}/ExtraShiftPointOfSale/${id}`);
    return true;
  } catch (error) {
    return false;
  }
};
