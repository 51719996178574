import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Theme } from "../utils/default-theme";

const initialState: any = Theme.palette;

const LayoutPaletteSlice = createSlice({
  name: "LayoutPalette",
  initialState,
  reducers: {
    setPalette(_: any, action: PayloadAction<any>) {
      return action.payload.palette;
    },
    setPaletteValue(state: any, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetPalette() {
      return Theme.palette;
    },
  },
});

export const { setPalette, setPaletteValue, resetPalette } =
  LayoutPaletteSlice.actions;

export default LayoutPaletteSlice.reducer;
