import { FC } from "react";
import { store } from "../../store";
import { Provider } from "react-redux";
import axios from "axios";
import { setPointOfSaleWhiteCache } from "./lib/white-cache";
import { PointOfSalesPage } from "./PointOfSalesPage";
import jwtDecode from "jwt-decode";
import { InitData } from "../../util/init-data";

interface PointOfSaleInterface {
  software: "bb" | "fw" | "crm" | "f10";
  root?: string;
  token?: string;
  url?: string;
  isBluBookingActive?: boolean;
  isConsole?: boolean;
  focusWeb?: boolean;
  isFocus10?: boolean;
  isCrm?: boolean;
  isSingleCalendarManagement?: boolean;
}

export const NewPointOfSales: FC<PointOfSaleInterface> = ({
  software,
  root = "",
  token,
  url,
  isBluBookingActive,
  isConsole,
  focusWeb,
  isFocus10,
  isCrm,
  isSingleCalendarManagement,
}): JSX.Element => {
  if (url !== undefined) axios.defaults.baseURL = url;

  if (token !== undefined) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  let isBBActive: boolean = false;
  if (isBluBookingActive) {
    isBBActive = isBluBookingActive;
  } else {
    const decoded: any = jwtDecode(
      axios.defaults.headers.common.Authorization!.toString()
    );
    isBBActive = decoded["IsBluBookingActive"] === "True";
  }

  setPointOfSaleWhiteCache({
    isBluBookingActive: isBBActive,
    path: focusWeb ? "/diaryapi" : "/api",
    isFocusWeb: focusWeb ?? software === "fw",
    isFocus10: isFocus10 ?? software === "f10",
    isConsole: isConsole ?? software === "bb",
    isCrm: isCrm ?? software === "crm",
  });

  return (
    <Provider store={store}>
      <InitData
        software={software}
        isSingleCalendarManagement={isSingleCalendarManagement}
        isFocusWeb={focusWeb}
      />
      <PointOfSalesPage root={root} />
    </Provider>
  );
};
