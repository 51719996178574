import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StudioDetailsInterface } from "../interfaces/studio-details";
import { PointOfSaleInterface } from "../../../interfaces/point-of-sale";
import { StudioInterface } from "../../../interfaces/studio";
import { ServiceInterface } from "../../../interfaces/service";

export interface RootSliceState {
  pointOfSales: {
    [x: string]: PointOfSaleInterface;
  };
  studi: {
    [x: string]: StudioInterface;
  };
  services: {
    [x: string]: ServiceInterface;
  };
  studiLoad: boolean;
  openForm?: "modify" | "info" | "new";
  currentStudio?: StudioDetailsInterface;
  deleteStudio?: string;
}

const initialState: RootSliceState = {
  pointOfSales: {},
  studi: {},
  services: {},
  studiLoad: true,
};

const RootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setOpenForm(
      state: RootSliceState,
      action: PayloadAction<"modify" | "info" | "new" | undefined>
    ) {
      state.openForm = action.payload;
    },
    setCurrentStudio(
      state: RootSliceState,
      action: PayloadAction<undefined | StudioDetailsInterface>
    ) {
      state.currentStudio = action.payload;
    },
    setStudiLoad(state: RootSliceState, action: PayloadAction<boolean>) {
      state.studiLoad = action.payload;
    },
    setDeleteStudio(
      state: RootSliceState,
      action: PayloadAction<string | undefined>
    ) {
      state.deleteStudio = action.payload;
    },
    setStudiData(
      state: RootSliceState,
      action: PayloadAction<{
        pointOfSales: any;
        studi: any;
        services: any;
        studiLoad: boolean;
      }>
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  setOpenForm,
  setStudiLoad,
  setCurrentStudio,
  setDeleteStudio,
  setStudiData,
} = RootSlice.actions;

export default RootSlice.reducer;
