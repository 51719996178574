export const notificationWarningConstants: { [x: string]: string } = {
  "100": " Campo Email dell'appuntamento mancante",
  "101": "Campo Telefono dell'appuntamento mancante",
  "102": "Campo Nome dell'appuntamento mancante",
  "103": "Campo Cognome dell'appuntamento mancante",
  "104": "Campo Codice Cliente Esterno dell'appuntamento mancante",
  "105": "Campo Nome del servizio mancante",
  "106": "Campo Descrizione del servizio mancante",
  "107": "Campo Nome o Nome Online del punto vendita mancante",
  "108": "Campo Email del punto vendita mancante",
  "109": "Campo Indirizzo del punto vendita mancante",
  "110": "Campo Città del punto vendita mancante",
  "111": "Campo Telefono del punto vendita mancante",
  "112": "Campo Nome dell'utente mancante",
  "113": "Campo Cognome dell'utente mancante",
  "114": "Campo Messaggio di Conferma IMO mancante",
  "115": "Campo Messaggio di Modifica IMO mancante",
  "116": "Campo Messaggio di Promemoria IMO mancante",
  "117": "Campo Messaggio di Annullamento IMO mancante",
  "118": "Campo Messaggio di Conferma SMS mancante",
  "119": "Campo Messaggio di Modifica SMS mancante",
  "120": "Campo Messaggio di Promemoria SMS mancante",
  "121": "Campo Messaggio di Annullamento SMS mancante",
  "122": "Cliente non ha l'app Il Mio Ottico attiva",
};
