import { FC, memo, useMemo } from "react";
import { UserPopUpForm } from "./PopUpForm";
import { useUser } from "../../api/user";

interface UserPopUpInterface {
  userId: string;
  mode: "modify" | "info" | "new";
}

export const UserPopUp: FC<UserPopUpInterface> = memo(({ userId, mode }) => {
  const { data } = useUser(userId, mode);
  const GetContent = useMemo(() => {
    if (data) return <UserPopUpForm user={data} mode={mode} />;
    return <></>;
  }, [data, mode]);
  return <>{GetContent}</>;
});
