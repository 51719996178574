import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BluTitle } from "@bludata/components";
import { Box, Stack, SxProps } from "@mui/material";

interface AccordioProps {
  children: React.ReactNode;
  title: string;
  defaultExpanded?: boolean;
  sx?: SxProps;
}

export default function BluAccordion({
  defaultExpanded,
  title,
  children,
  sx,
}: AccordioProps) {
  return (
    <Box sx={{ flex: 1, ...sx }}>
      <Accordion defaultExpanded={defaultExpanded ?? false} variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack direction={"column"} flex={1}>
            <BluTitle
              sx={{
                flex: 1,
              }}
              text={title}
            />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
  // return (
  //   <Accordion
  //     defaultExpanded={props.defaultExpanded}
  //     sx={{
  //       boxShadow: "none",
  //       padding: "5px 10px",
  //       borderRadius: "5px",
  //       border: "1px solid var(--divider-color)",
  //       ...props.sx,
  //       "&.MuiAccordion-root:before": {
  //         height: "0px!important",
  //       },
  //     }}
  //   >
  //     <AccordionSummary
  //       sx={{
  //         p: 0,
  //         "&.MuiButtonBase-root .MuiAccordionSummary-content": { m: 0 },
  //         "&.MuiButtonBase-root": { minHeight: "40px" },
  //       }}
  //       expandIcon={<ExpandMoreIcon />}
  //     >
  //       <BluTitle text={props.title} />
  //     </AccordionSummary>
  //     <AccordionDetails sx={{ padding: 0, pb: 1 }}>
  //       {props.children}
  //     </AccordionDetails>
  //   </Accordion>
  // );
}
