import { Box, Stack, Tooltip } from "@mui/material";
import { FC, memo, useCallback, useMemo } from "react";
import { useAppSelector } from "../../../../../../store";
import { extraHourValueError } from "../../../../store/extra-hours";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";

interface ExtraRangeCellInterface {
  params: any;
  turniType: "shift" | "calendar";
}

export const ExtraRangeCell: FC<ExtraRangeCellInterface> = memo(
  ({ params: { row }, turniType }) => {
    const { extraKey, pointOfSaleId } = row;
    const val = row[turniType];

    const error1 = useAppSelector((state) =>
      extraHourValueError(state, turniType, extraKey, 1, pointOfSaleId)
    );
    const error2 = useAppSelector((state) =>
      extraHourValueError(state, turniType, extraKey, 2, pointOfSaleId)
    );

    const GetTurno1 = useMemo(() => {
      if (error1 && val.startTime1 !== null && val.endTime1 !== null)
        return (
          <Tooltip title={"Turno non valido"}>
            <Box
              sx={{
                flex: 1,
                textAlign: "center",
                color: "var(--accent-color)",
              }}
            >{`${val.startTime1.format("HH:mm")} - ${val.endTime1.format(
              "HH:mm"
            )}`}</Box>
          </Tooltip>
        );
      return (
        <>
          {val.startTime1 !== null && val.endTime1 !== null && (
            <Box
              sx={{
                flex: 1,
                textAlign: "center",
              }}
            >{`${val.startTime1.format("HH:mm")} - ${val.endTime1.format(
              "HH:mm"
            )}`}</Box>
          )}
        </>
      );
    }, [error1, val.endTime1, val.startTime1]);

    const GetTurno2 = useMemo(() => {
      if (error2 && val.startTime2 !== null && val.endTime2 !== null)
        return (
          <Tooltip title={"Turno non valido"}>
            <Box
              sx={{
                flex: 1,
                textAlign: "center",
                color: "var(--accent-color)",
              }}
            >{`${val.startTime2.format("HH:mm")} - ${val.endTime2.format(
              "HH:mm"
            )}`}</Box>
          </Tooltip>
        );
      return (
        <>
          {val.startTime2 !== null && val.endTime2 !== null && (
            <Box
              sx={{
                flex: 1,
                textAlign: "center",
              }}
            >{`${val.startTime2.format("HH:mm")} - ${val.endTime2.format(
              "HH:mm"
            )}`}</Box>
          )}
        </>
      );
    }, [error2, val.endTime2, val.startTime2]);

    const getError = useCallback(() => {
      return error1 || error2 ? (
        <ErrorTwoToneIcon sx={{ color: "var(--accent-color)" }} />
      ) : null;
    }, [error1, error2]);

    if (val?.isClosed)
      return <Box sx={{ flex: 1, textAlign: "center" }}>{`-`}</Box>;

    return (
      <Stack direction="row" gap={1} alignItems="center">
        {getError() && <Box width="24px" height="100%" />}
        <Stack flex="1" gap={1}>
          {GetTurno1}
          {GetTurno2}
        </Stack>

        {getError() && (
          <Tooltip title={"Turno non valido"}>
            <ErrorTwoToneIcon sx={{ color: "var(--accent-color)" }} />
          </Tooltip>
        )}
      </Stack>
    );
  }
);
