import { useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { DeletePopup } from "../../../../../../UI/DeletePopup";
import { RootState, AppDispatch } from "../../../../../../store";
import { setOpenLoader } from "../../../../../../store/slices/root";
import { memo, useCallback } from "react";
import {
  setCurrentStudio,
  setDeleteStudio,
  setOpenForm,
} from "../../../../store/root";
import { deleteStudio } from "../../../../api/delete-studio";
import { useCountdown } from "../../../../../../util/countdown";

export const StudioDeletePopup = memo((): JSX.Element => {
  const { deleteStudio: dStudio } = useSelector(
    (state: RootState) => state.studiRoot
  );
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const cancel = useCallback(
    () => dispatch(setDeleteStudio(undefined)),
    [dispatch]
  );

  const countDown = useCountdown(10);

  const isOpen = useCallback(() => dStudio !== undefined, [dStudio]);

  const handleDelete = useCallback(async () => {
    dispatch(setOpenLoader(true));
    const result = await deleteStudio(dStudio!);
    dispatch(setOpenLoader(false));
    if (result) {
      dispatch(setDeleteStudio(undefined));
      dispatch(setOpenForm(undefined));
      dispatch(setCurrentStudio(undefined));
      // QUERY INVALIDATION
      queryClient.invalidateQueries({ queryKey: ["details", "studio"] });
      queryClient.invalidateQueries({
        queryKey: ["all-filters", "studi"],
      });
      queryClient.invalidateQueries({
        queryKey: ["ui", "studi"],
      });
      queryClient.invalidateQueries(["calendar"], {
        type: "active",
      });
      queryClient.removeQueries(["calendar"], {
        type: "inactive",
      });
      queryClient.invalidateQueries(["filter"], {
        type: "active",
      });
      queryClient.removeQueries(["filter"], {
        type: "inactive",
      });
    }
  }, [dStudio, dispatch, queryClient]);

  return (
    <DeletePopup
      open={isOpen()}
      action={handleDelete}
      cancelAction={cancel}
      text="Sei sicuro di voler eliminare definitivamente questo studio?"
      text2="Questa operazione è irreversibile e tutti gli appuntamenti associati a questo studio verranno eliminati."
      countDown={countDown}
    />
  );
});
