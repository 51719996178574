import { FC, useMemo } from "react";
import { InformazioniGeneraliBB } from "./ImpostazioniGeneraliBB";
import { getPointOfSaleWhiteCache } from "../../../../lib/white-cache";
import { InformazioniGeneraliFW } from "./ImpostazioniGeneraliFW";

const ImpostazioniGenerali: FC<{
  mode: "modify" | "info" | "new";
}> = ({ mode }): JSX.Element => {
  const { isFocusWeb } = getPointOfSaleWhiteCache();

  const GetContent = useMemo(() => {
    if (isFocusWeb)
      return <InformazioniGeneraliFW mode={mode} disabled={mode === "info"} />;
    return <InformazioniGeneraliBB mode={mode} disabled={mode === "info"} />;
  }, [isFocusWeb, mode]);

  return <>{GetContent}</>;
};

export default ImpostazioniGenerali;
