import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { BluSelect } from "@bludata/components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store";
import { FC, useCallback, useDeferredValue, useEffect } from "react";
import { useServicePointOfSale } from "../../../../api/service-point-of-sale";
import { nanoid } from "nanoid";
import { ServicePointOfSaleInterface } from "../../../../interfaces/ServicePointOfSale_interface";
import { setIsLoadingForm, setServices } from "../../../../store/root";
import { useWatch } from "react-hook-form";

interface CustomSelectInterface {
  disabled?: boolean;
}

export const ServiceSelect: FC<CustomSelectInterface> = ({ disabled }) => {
  const pointOfSaleId = useWatch({ name: "pointOfSaleId" });

  const { services } = useSelector(
    (state: RootState) => state.newPointOfSaleRoot
  );

  const dispatch: AppDispatch = useDispatch();

  const { data, isLoading } = useServicePointOfSale(pointOfSaleId);

  const result = useDeferredValue(data);

  useEffect(() => {
    if (result) {
      dispatch(setServices(result));
    }
    dispatch(setIsLoadingForm({ key: "services", value: isLoading }));
  }, [dispatch, isLoading, result]);

  const handleClick = useCallback(
    (value: ServicePointOfSaleInterface) => {
      dispatch(
        setServices({
          ...services,
          [value.serviceId]: { ...value, enabled: !value.enabled },
        })
      );
    },
    [dispatch, services]
  );

  const getValue = useCallback(() => {
    const temp = Object.values(services ?? {}).filter(
      (service: any) => service?.enabled === true
    );
    return temp;
  }, [services]);

  const getOptions = useCallback(() => {
    if (services) {
      return Object.values(services);
    }
    return [];
  }, [services]);

  return (
    <Stack flex="1" sx={{ width: "100%" }}>
      <Typography>Servizi</Typography>
      <BluSelect
        multiple
        disabled={disabled}
        value={getValue()}
        renderValue={(selected: any) => {
          if (selected && selected.length > 0) {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((service: any) => (
                  <Chip
                    key={service.serviceId}
                    size="small"
                    label={service.name}
                  />
                ))}
              </Box>
            );
          }
          return <></>;
        }}
      >
        {getOptions().map((service: any) => {
          return (
            <MenuItem key={nanoid()} onClick={() => handleClick(service)}>
              <Checkbox checked={service.enabled} style={{ padding: 0 }} />
              <ListItemText primary={service.name} />
            </MenuItem>
          );
        })}
      </BluSelect>
    </Stack>
  );
};
