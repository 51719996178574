import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import { FC, memo } from "react";
import { CustomNumberField } from "../../common/CustomNumberField";
import { useFormContext, useWatch } from "react-hook-form";

interface AppointmentResponseInterface {
  disabled: boolean;
}

export const AppointmentResponse: FC<AppointmentResponseInterface> = memo(
  ({ disabled }) => {
    const isRecallOn = useWatch({ name: "isRecallOn" });
    const { setValue, trigger } = useFormContext();

    return (
      <Stack
        flexDirection="unset"
        flexWrap="wrap"
        gap={2}
        sx={{ width: "100%" }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          <Stack
            flex={1}
            alignItems="flex-end"
            direction="row"
            gap={1}
            sx={{ minWidth: "220px" }}
          >
            <CustomNumberField
              disabled={disabled || !isRecallOn}
              name="recallValue"
              label={"Tempo di invio promemoria di un appuntamento"}
              tooltip="Rappresenta il tempo di invio del promemoria per ricordare ai clienti l'appuntamento prenotato."
            />

            <TextField
              disabled
              value="Ore"
              sx={{
                width: 120,
              }}
            />
          </Stack>
        </div>
        <Stack flex={1} alignItems="flex-end" direction="row" gap={1}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                checked={isRecallOn}
                onChange={(e) => {
                  setValue("isRecallOn", e.target.checked);
                  trigger("isRecallOn");
                }}
              />
            }
            label="Abilita richiamo"
          />
        </Stack>
      </Stack>
    );
  }
);
