import axios from "axios";
import { getPath } from "../lib/white-cache";
import ShopSignReview_interface from "../../nuova-point-of-sales/interfaces/ShopSignReview_interface";

export const fetchShopSignReview = (id: string) => {
  return new Promise<ShopSignReview_interface>((resolve, reject) => {
    axios
      .get(`${getPath()}/ShopSign/Review/${id}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};
