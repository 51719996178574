import { IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { FC, memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { GridCloseIcon } from "@mui/x-data-grid-premium";
import { BluSelect } from "@bludata/components";

interface UserVisibilityInterface {
  disabled: boolean;
}

export const UserVisibility: FC<UserVisibilityInterface> = memo(
  ({ disabled }) => {
    const visibilityUserEnum = useWatch({ name: "visibilityUserEnum" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const options = [
      {
        label: "Default",
        value: -1,
      },
      {
        label: "Utente",
        value: 4,
      },
      {
        label: "Punto vendita",
        value: 0,
      },
      {
        label: "Azienda",
        value: 1,
      },
      {
        label: "Layout",
        value: 2,
      },
      {
        label: "Tutte le aziende",
        value: 3,
      },
    ];
    const { setValue } = useFormContext();

    const handleOnChange = useCallback(
      (event: any) => {
        setValue("visibilityUserEnum", event.target.value);
      },
      [setValue]
    );

    const handleErase = useCallback(() => {
      setValue("visibilityUserEnum", 0);
    }, [setValue]);

    return (
      <Stack sx={{ width: "200px" }}>
        <Typography>{"Visibilità del calendario"}</Typography>
        <Stack flex="1" direction="row">
          <BluSelect
            multiple={false}
            disabled={disabled}
            value={visibilityUserEnum}
            onChange={handleOnChange}
            renderValue={(selected: any) => {
              if (selected < -1 || selected > 4)
                return <>Seleziona la visibilità del calendario</>;
              const find = options.find(
                (option: any) => option.value === selected
              );
              return (
                <>{find?.label ?? "Seleziona la visibilità del calendario"}</>
              );
            }}
          >
            {options.map((option: any) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </BluSelect>
          {!disabled && (
            <IconButton color="primary" size="small" onClick={handleErase}>
              <GridCloseIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    );
  }
);
