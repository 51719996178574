import { BluTitle } from "@bludata/components";
import { FC } from "react";
import { CustomTextField } from "../../common/CustomTextField";
import { Citta } from "../../components/Citta/Citta";
import { Stack } from "@mui/material";

export const Indirizzo: FC<{
  mode: "modify" | "info" | "new";
}> = ({ mode }): JSX.Element => {
  return (
    <Stack flex="1" gap={1}>
      <BluTitle text={"Indirizzo"} />
      <Stack direction="column" gap={1}>
        <Stack direction="row" flex="1">
          <CustomTextField
            name={"address"}
            label="Indirizzo"
            disabled={mode === "info"}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          flex="1"
          gap={1}
        >
          <Citta disabled={mode === "info"} />
          <CustomTextField
            name={"region"}
            label="Provincia"
            disabled={mode === "info"}
            limit={2}
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
            width="80px"
          />
          <CustomTextField
            name={"postalCode"}
            label="CAP"
            limit={5}
            disabled={mode === "info"}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
