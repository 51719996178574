import {
  Autocomplete,
  Checkbox,
  Chip,
  InputAdornment,
  TextField,
} from "@mui/material";
import { memo, useCallback } from "react";

interface Props {
  dataList: any[];
  selectedList: any[];
  getAutocompleteLabelKey: (option: any) => any;
  label: string;
  allLabel?: string;
  loading?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  onSelectionChanged: (selection: any[]) => void;
}

function BluFilterAutocomplete(props: Props) {
  const {
    onSelectionChanged,
    dataList,
    selectedList,
    loading,
    allLabel,
    disabled,
    multiple,
    getAutocompleteLabelKey,
    label,
  } = props;

  const onSelezioneLayoutChange = (e: any, newValue: any[] | null) => {
    if (newValue !== null && newValue !== undefined)
      onSelectionChanged(newValue.length === dataList.length ? [] : newValue);
    else onSelectionChanged([]);
  };

  return (
    <Autocomplete
      options={dataList}
      loading={loading || false}
      value={selectedList}
      limitTags={3}
      disabled={disabled}
      multiple={multiple || true}
      size="small"
      disableCloseOnSelect
      fullWidth
      sx={{
        "& .MuiAutocomplete-tag": {
          maxWidth: "calc(100% - 38px)",
        },
        "& .MuiAutocomplete-inputRoot": {
          paddingRight: "30px !important",
        },
        "& .MuiAutocomplete-clearIndicator": {
          position: "absolute",
          right: "25px",
        },
      }}
      renderInput={(params) => {
        if (selectedList.length === 0) {
          return (
            <TextField
              disabled={disabled}
              InputLabelProps={params.InputLabelProps}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Chip
                      label={allLabel || "Tutti"}
                      size="small"
                      clickable
                      disabled={disabled}
                      onClick={params.inputProps.onMouseDown}
                    />
                  </InputAdornment>
                ),
              }}
              inputProps={params.inputProps}
              fullWidth
              label={label}
            />
          );
        }
        return (
          <TextField
            {...params}
            label={label}
            placeholder={
              selectedList.length === 0 ? allLabel || "Tutti" : undefined
            }
          />
        );
      }}
      getOptionLabel={getAutocompleteLabelKey}
      onChange={onSelezioneLayoutChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {getAutocompleteLabelKey(option)}
        </li>
      )}
    />
  );
}

export default memo(BluFilterAutocomplete);
