import axios from "axios";
import { getUsersPath } from "../lib/white-cache";

export const deleteUserGoogleCalendar = async (
  userId: string
): Promise<boolean> => {
  try {
    await axios.delete(`${getUsersPath()}/GoogleCalendarUserSync/${userId}`);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
