import { FC, useMemo } from "react";
import { InformazioniGeneraliBB } from "./ImpostazioniGeneraliBB";
import { getUsersWhiteCache } from "../../../../lib/white-cache";
import { InformazioniGeneraliFW } from "./ImpostazioniGeneraliFW";

export const ImpostazioniGenerali: FC<{
  mode: "modify" | "info" | "new";
}> = ({ mode }): JSX.Element => {
  const { isFocusWeb } = getUsersWhiteCache();

  const GetContent = useMemo(() => {
    if (isFocusWeb)
      return <InformazioniGeneraliFW disabled={mode === "info"} />;
    return <InformazioniGeneraliBB disabled={mode === "info"} />;
  }, [isFocusWeb, mode]);

  return <>{GetContent}</>;
};
