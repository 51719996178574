import { Divider, Stack, TableCell, Typography } from "@mui/material";
import { FC, memo, useCallback, useMemo } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { setPopUpRota } from "../../store/root";
import { getRotaWhiteCache } from "../../lib/white-cache";
import { nanoid } from "nanoid";

interface CellInterface {
  id: string;
  date: Date;
  userName?: string;
  userId?: string;
  shift: {
    extraId?: string;
    extraCalendarType?: number;
    startTime1?: string;
    startTime2?: string;
    endTime1?: string;
    endTime2?: string;
    isHoliday?: boolean;
    isExtra?: boolean;
    isClosed?: boolean;
    isInitial?: boolean;
  };
  calendar: {
    extraId?: string;
    extraCalendarType?: number;
    startTime1?: string;
    startTime2?: string;
    endTime1?: string;
    endTime2?: string;
    isHoliday?: boolean;
    isExtra?: boolean;
    isClosed?: boolean;
    isInitial?: boolean;
  };
  defaultShift?: {
    startTime1?: string;
    startTime2?: string;
    endTime1?: string;
    endTime2?: string;
  };
  defaultCalendar?: {
    startTime1?: string;
    startTime2?: string;
    endTime1?: string;
    endTime2?: string;
  };

  isSingleCalendarManagement: boolean;
}

export const UserDoubleCell: FC<CellInterface> = memo(
  ({
    userId,
    userName,
    id,
    date,
    shift,
    calendar,
    defaultShift,
    defaultCalendar,
    isSingleCalendarManagement,
  }) => {
    const { turniFilter } = useSelector((state: RootState) => state.rotaRoot);
    const dispatch = useDispatch<AppDispatch>();
    const { turniModifica } = getRotaWhiteCache();

    const GetContent = ({ turno }: any) => {
      const {
        startTime1,
        startTime2,
        endTime1,
        endTime2,
        isHoliday = false,
        isExtra = false,
        isClosed = false,
        isInitial = false,
        extraCalendarType,
      } = turno;
      if (isInitial)
        return (
          <Stack
            gap={1}
            flex="1"
            sx={{
              height: "69px",
            }}
            justifyContent="center"
          ></Stack>
        );
      if (isHoliday)
        return (
          <Stack
            gap={1}
            flex="1"
            sx={{
              background: "var(--divider-color)",
              height: "69px",
              opacity: 0.5,
              zIndex: 0,
            }}
            justifyContent="center"
          >
            <ClearIcon
              sx={{
                width: "15px",
                height: "15px",
                margin: "auto",
              }}
            />
          </Stack>
        );
      if (isExtra) {
        let color = "var(--base-light-color)";
        let border = "var(--closed-color)";

        switch (extraCalendarType) {
          case 1:
            border = "#E77D22";
            color = "#E77D2220";
            break;

          case 2:
            border = "var(--accent-pressed-color)";
            color = "#de5c5c30";
            break;

          case 3:
            border = "#FFd300";
            color = "#FFd30020";
            break;

          default:
            color = "#1375c020";
            border = "#1375c0";
            break;
        }

        if (
          isClosed ||
          !(
            (startTime1 !== null &&
              endTime1 !== null &&
              startTime1 !== "" &&
              endTime1 !== "") ||
            (startTime2 !== null &&
              endTime2 !== null &&
              startTime2 !== "" &&
              endTime2 !== "")
          )
        ) {
          return (
            <Stack
              gap={1}
              flex="1"
              sx={{
                background: color,
                height: "40px",
                // opacity: 0.8,
                border: `1px solid ${border}`,
              }}
              justifyContent="center"
            >
              {/* <ClearIcon
                sx={{
                  // color: "#ffffff!important",
                  color: border,
                  width: "15px",
                  height: "15px",
                  margin: "auto",
                }}
              /> */}
            </Stack>
          );
        }

        return (
          <Stack
            flex="1"
            sx={{
              border: `1px solid ${border}`,
              boxSizing: "border-box",
              background: color,
              height: "69px",
            }}
            justifyContent="center"
          >
            <Typography fontWeight="bold">
              {startTime1 && endTime1 ? `${startTime1} - ${endTime1}` : null}
            </Typography>
            <Typography fontWeight="bold">
              {startTime2 && endTime2 ? `${startTime2} - ${endTime2}` : null}
            </Typography>
          </Stack>
        );
      }
      if (isClosed)
        return (
          <Stack
            flex="1"
            sx={{
              // background:
              //   "repeating-linear-gradient( -45deg, #00000030, #00000030 5px, #00000050 5px, #00000050 10px )",
              background: "var(--closed-color)",
              opacity: 0.5,
              height: "69px",
              zIndex: 0,
            }}
            justifyContent="center"
          >
            <ClearIcon
              sx={{
                width: "15px",
                height: "15px",
                margin: "auto",
              }}
            />
          </Stack>
        );

      return (
        <Stack
          flex="1"
          sx={{
            // backgroundColor: "var(--base-light-color)",
            height: "69px",
          }}
          justifyContent="center"
        >
          <Typography>
            {startTime1 && endTime1 ? `${startTime1} - ${endTime1}` : "-"}
          </Typography>
          <Typography>
            {startTime2 && endTime2 ? `${startTime2} - ${endTime2}` : "-"}
          </Typography>
        </Stack>
      );
    };

    const ShiftComponent = useMemo(() => {
      if (turniFilter === "calendar" || isSingleCalendarManagement)
        return <></>;
      return <GetContent turno={shift} />;
    }, [isSingleCalendarManagement, shift, turniFilter]);

    const CalendarComponent = useMemo(() => {
      if (turniFilter === "shift") return <></>;
      return <GetContent turno={calendar} />;
    }, [calendar, turniFilter]);

    const handleClick = useCallback(() => {
      if (turniModifica)
        dispatch(
          setPopUpRota({
            id: id,
            userId: userId,
            userName: userName,
            date: date,
            calendarType: "user",
            shift: shift as any,
            calendar: calendar as any,
            defaultCalendar,
            defaultShift,
          })
        );
    }, [
      calendar,
      date,
      defaultCalendar,
      defaultShift,
      dispatch,
      id,
      shift,
      turniModifica,
      userId,
      userName,
    ]);

    return (
      <TableCell
        key={nanoid()}
        align="center"
        // rowSpan={
        //   turniFilter !== undefined || isSingleCalendarManagement ? 1 : 2
        // }
        style={{
          padding: 0,
          margin: 0,
          height:
            isSingleCalendarManagement || turniFilter !== undefined
              ? "70px"
              : "140px",
          cursor: turniModifica ? "pointer" : "default",
          boxSizing: "border-box",
          borderBottom: "1px solid var(--divider-color)",
          borderRight: "1px solid var(--divider-color)",
        }}
        onClick={handleClick}
      >
        <Stack
          flex="1"
          sx={{
            boxSizing: "border-box",
            p: 0,
            height:
              isSingleCalendarManagement || turniFilter !== undefined
                ? "70px"
                : "140px",
            "&:hover": {
              border: turniModifica ? "1px solid black" : undefined,
            },
          }}
        >
          {ShiftComponent}
          {turniFilter === undefined && !isSingleCalendarManagement && (
            <Divider sx={{ borderColor: "var(--divider-color)" }} />
          )}
          {CalendarComponent}
        </Stack>
      </TableCell>
    );
  }
);
