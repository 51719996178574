import dayjs from "dayjs";

const colors: string[] = [
  "rgba(153, 215, 209, 0.8)",
  "rgba(182, 205, 220, 1)",
  "rgba(184, 211, 205, 0.8)",
  "rgba(206, 211, 190, 0.8)",
  "rgba(189, 218, 179, 0.8)",
  "rgba(202, 184, 229, 0.8)",
  "rgba(224, 191, 157, 0.8)",
  "rgba(151, 199, 232, 0.8)",
  "rgba(230, 187, 178, 0.8)",
  "rgba(224, 189, 208, 0.8)",
];

const shiftColors: string[] = [
  "rgba(153, 215, 209, 0.5)",
  "rgba(182, 205, 220, 0.5)",
  "rgba(184, 211, 205, 0.5)",
  "rgba(206, 211, 190, 0.5)",
  "rgba(189, 218, 179, 0.5)",
  "rgba(202, 184, 229, 0.5)",
  "rgba(224, 191, 157, 0.5)",
  "rgba(151, 199, 232, 0.5)",
  "rgba(230, 187, 178, 0.5)",
  "rgba(224, 189, 208, 0.5)",
];

export const isOpen = (
  data: Date | undefined,
  type?: string,
  calendarPerUser?: any,
  shiftPerUser?: any
) => {
  if (!calendarPerUser && !shiftPerUser) return ["", "var(--closed-color)", 0];
  const time = dayjs(data).format("HH:mm:ss");
  const date = dayjs(data).format("YYYY-MM-DD");
  let color: string = "var(--closed-color)";
  let posId: string = "";
  let i = 0;
  let temp: any;
  let tempShift: any;
  let code: number = 0;
  var union = [
    ...new Set([
      ...(calendarPerUser?.keys ?? []),
      ...(shiftPerUser?.keys ?? []),
    ]),
  ];

  const check = (calendar: any) =>
    calendar &&
    ((time >= calendar.startTime1 && time < calendar.endTime1) ||
      (time >= calendar.startTime2 && time < calendar.endTime2));

  while (i < union.length && color === "var(--closed-color)") {
    const key = union[i];
    let dates;
    let shiftDates;
    if (
      calendarPerUser &&
      calendarPerUser?.values &&
      key in calendarPerUser.values
    ) {
      dates = calendarPerUser.values[key];
      temp = dates !== undefined && date in dates ? dates[date] : undefined;
    }
    if (shiftPerUser && shiftPerUser?.values && key in shiftPerUser.values) {
      shiftDates = shiftPerUser.values[key];
      tempShift =
        shiftDates !== undefined && date in shiftDates
          ? shiftDates[date]
          : undefined;
    }

    if (type && type === "monthCells") {
      if (temp) {
        color = colors[i];
        code = 2;
      } else if (tempShift) {
        color = shiftColors[i];
        code = 1;
      } else {
        color = "var(--closed-color)";
        code = 0;
      }
    } else {
      const checkCalendar = check(temp);
      const checkShift = check(tempShift);
      if (checkCalendar) {
        posId = key;
        color = colors[i];
        code = 2;
      } else if (checkShift) {
        posId = key;
        color = shiftColors[i];
        code = 1;
      } else {
        posId = "";
        color = "var(--closed-color)";
        code = 0;
      }
    }

    temp = undefined;
    tempShift = undefined;
    i++;
  }

  if (calendarPerUser.multi) return [posId, color, code];
  else if (code === 0) return ["", "var(--closed-color)", code];
  return [
    "",
    code === 1
      ? "var(--alt-background-color)"
      : "e-work-cells e-alternate-cells e-work-hours",
    code,
  ];
};
