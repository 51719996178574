import { BluTitle } from "@bludata/components";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import React from "react";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUsersValue } from "../../../../store/form";
import { GridFooter } from "./footer";
import { AppDispatch, RootState } from "../../../../../../store";
import { Columns } from "./column";
import { Stack } from "@mui/material";

export const Users = memo(() => {
  const dispatch = useDispatch<AppDispatch>();

  const { usersForm } = useSelector((state: RootState) => state.serviceForm);
  const { openForm } = useSelector((state: RootState) => state.serviceRoot);
  const getValues = useCallback(() => {
    return Object.values(usersForm);
  }, [usersForm]);

  return (
    <Stack flex="1">
      <BluTitle text="Utenti" sx={{ p: "3px 0px" }} />

      <DataGridPremium
        getRowId={(row: any) => row.id}
        columns={Columns as any}
        rows={getValues()}
        rowCount={5}
        sx={{
          minHeight: "250px",
          maxHeight: "250px",
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
        onRowClick={(event: any) => {
          if (openForm !== "info") dispatch(setUsersValue(event.id));
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "username", sort: "asc" }],
          },
        }}
        density="compact"
        disableIgnoreModificationsIfProcessingProps
        editMode="cell"
        disableColumnMenu
        hideFooterSelectedRowCount
        onProcessRowUpdateError={(error) => console.log(error)}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Footer: GridFooter,
        }}
      />
    </Stack>
  );
});
