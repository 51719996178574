import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { AssistantInterface } from '../interfaces/assistant'

export const getAssistants = async (): Promise<AssistantInterface[]> => {
  try {
    const {
      data: { data }
    } = await axios.get('/Assistant')

    // return [
    //   {
    //     openAiId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-06T09:50:06.7761897"),
    //     name: "Assistente Blu Booking",
    //     description:
    //       "Questo assistente risponderà a tutte le domande e ai dubbi che hai su Blu Booking",
    //   },
    //   {
    //     openAiId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-06T09:50:06.7761897"),
    //     name: "Assistente Blu Booking",
    //     description:
    //       "Questo assistente risponderà a tutte le domande e ai dubbi che hai su Blu Booking",
    //   },
    //   {
    //     openAiId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-06T09:50:06.7761897"),
    //     name: "Assistente Blu Booking",
    //     description:
    //       "Questo assistente risponderà a tutte le domande e ai dubbi che hai su Blu Booking",
    //   },
    //   {
    //     openAiId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-06T09:50:06.7761897"),
    //     name: "Assistente Blu Booking",
    //     description:
    //       "Questo assistente risponderà a tutte le domande e ai dubbi che hai su Blu Booking",
    //   },
    //   {
    //     openAiId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-06T09:50:06.7761897"),
    //     name: "Assistente Blu Booking",
    //     description:
    //       "Questo assistente risponderà a tutte le domande e ai dubbi che hai su Blu Booking",
    //   },
    //   {
    //     openAiId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-06T09:50:06.7761897"),
    //     name: "Assistente Blu Booking",
    //     description:
    //       "Questo assistente risponderà a tutte le domande e ai dubbi che hai su Blu Booking",
    //   },
    // ];

    return data.map((assistant: any) => {
      return { ...assistant, updatedAt: new Date(assistant.updatedAt) }
    })
  } catch (error) {
    console.log(error)
    return []
  }
}

export const useAssistants = (key: string = '') => {
  return useQuery({
    queryFn: async () => await getAssistants(),
    queryKey: ['assistants', key],
    retry: 2,
    retryDelay: 180000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
    // select: normalize,
  })
}
