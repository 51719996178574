import { useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { memo, useCallback } from "react";
import { setDeleteLayout, setLoadLayout } from "../../store/root";
import { AppDispatch, RootState } from "../../../../store";
import { deleteShopSign } from "../../api/delete-shop-sign";
import { DeletePopup } from "../../../../UI/DeletePopup";

export const LayoutDeletePopup = memo((): JSX.Element => {
  const { deleteLayout } = useSelector((state: RootState) => state.layoutRoot);
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const isOpen = useCallback(() => deleteLayout !== undefined, [deleteLayout]);

  const cancel = useCallback(
    () => dispatch(setDeleteLayout(undefined)),
    [dispatch]
  );

  const handleDelete = useCallback(async () => {
    dispatch(setDeleteLayout(undefined));

    dispatch(setLoadLayout(true));
    const result = await deleteShopSign(deleteLayout!!);
    dispatch(setLoadLayout(false));
    if (result) {
      // dispatch(setDeleteLayout(undefined));

      queryClient.invalidateQueries({ queryKey: ["details", "shop-sign"] });
      queryClient.invalidateQueries({
        queryKey: ["all-filters", "shop-sign"],
      });
      queryClient.invalidateQueries(["calendar"], {
        type: "active",
      });
      queryClient.removeQueries(["calendar"], {
        type: "inactive",
      });
      queryClient.invalidateQueries(["filter"], {
        type: "active",
      });
      queryClient.removeQueries(["filter"], {
        type: "inactive",
      });
    }
  }, [deleteLayout, dispatch, queryClient]);

  return (
    <DeletePopup
      open={isOpen()}
      action={handleDelete}
      cancelAction={cancel}
      text="Sei sicuro di voler eliminare definitivamente questo layout?"
    />
  );
});
