import { BluLoader } from "@bludata/components";
import React from "react";
import { memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const Loader = memo(() => {
  const { openLoader } = useSelector((state: RootState) => state.root);

  return (
    <BluLoader
      open={openLoader}
      zIndex={400}
      sx={{ zIndex: 400 }}
      CircularProps={{
        sx: { zIndex: 400 },
      }}
    />
  );
});
