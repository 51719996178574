import axios from "axios";
import { PointOfSalesInterface } from "../interfaces/PointOfSale_interface";
import { ServicePointOfSaleInterface } from "../interfaces/ServicePointOfSale_interface";
import { TurniDefaultInterface } from "../interfaces/default-turni-point-of-sale";
import { TurniExtraInterface } from "../interfaces/extra-turni-point-of-sale";
import { getPointOfSaleWhiteCache } from "../lib/white-cache";
import { parseExtraHours } from "../components/PopUp/utils/parse-extra-hours";

export const createPointOfSale = async (
  impostazioniGenerali: PointOfSalesInterface | null,
  defaultHours: TurniDefaultInterface | null,
  extraHours: TurniExtraInterface | null,
  service: {
    [x: string]: ServicePointOfSaleInterface;
  },
  isSingleCalendarManagement: boolean
) => {
  try {
    const { isFocusWeb } = getPointOfSaleWhiteCache();
    let link = "/api/PointOfSale/Management";
    if (isFocusWeb) link = "/focusapi/global/PointOfSale";

    const services: { serviceId: string }[] = [];
    for (const key in service) {
      if (
        !service[key].id &&
        service[key].enabled !== service[key].initialEnabled &&
        service[key].enabled
      )
        services.push({ serviceId: service[key].serviceId });
    }

    let defaultHoursShift: any = {};
    let defaultHoursCalendar: any = {};
    const days = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    const daysTranslation: any = {
      monday: "hasMonday",
      tuesday: "hasTuesday",
      wednesday: "hasWednesday",
      thursday: "hasThursday",
      friday: "hasFriday",
      saturday: "hasSaturday",
      sunday: "hasSunday",
    };

    if (defaultHours) {
      for (const day of days) {
        if ((defaultHours as any)[`shift${day}`]) {
          const startTime1 =
            (defaultHours as any)[`shift${day}`].startTime1 &&
            (defaultHours as any)[`shift${day}`].startTime1.isValid()
              ? (defaultHours as any)[`shift${day}`].startTime1.format("HH:mm")
              : null;
          const startTime2 =
            (defaultHours as any)[`shift${day}`].startTime2 &&
            (defaultHours as any)[`shift${day}`].startTime2.isValid()
              ? (defaultHours as any)[`shift${day}`].startTime2.format("HH:mm")
              : null;
          const endTime1 =
            (defaultHours as any)[`shift${day}`].endTime1 &&
            (defaultHours as any)[`shift${day}`].endTime1.isValid()
              ? (defaultHours as any)[`shift${day}`].endTime1.format("HH:mm")
              : null;
          const endTime2 =
            (defaultHours as any)[`shift${day}`].endTime2 &&
            (defaultHours as any)[`shift${day}`].endTime2.isValid()
              ? (defaultHours as any)[`shift${day}`].endTime2.format("HH:mm")
              : null;

          const key: string = `${startTime1 ?? ""}${endTime1 ?? ""}${
            startTime2 ?? ""
          }${endTime2 ?? ""}`;
          if (!(key in defaultHoursShift)) {
            defaultHoursShift[key] = {
              startTime1,
              endTime1,
              startTime2,
              endTime2,
              ...{
                hasMonday: false,
                hasTuesday: false,
                hasWednesday: false,
                hasThursday: false,
                hasFriday: false,
                hasSaturday: false,
                hasSunday: false,
              },
              [daysTranslation[day]]: true,
            };
          } else {
            defaultHoursShift[key][daysTranslation[day]] = true;
          }
        }
        if (!isSingleCalendarManagement) {
          if ((defaultHours as any)[`calendar${day}`]) {
            const startTime1 =
              (defaultHours as any)[`calendar${day}`].startTime1 &&
              (defaultHours as any)[`calendar${day}`].startTime1.isValid()
                ? (defaultHours as any)[`calendar${day}`].startTime1.format(
                    "HH:mm"
                  )
                : null;
            const startTime2 =
              (defaultHours as any)[`calendar${day}`].startTime2 &&
              (defaultHours as any)[`calendar${day}`].startTime2.isValid()
                ? (defaultHours as any)[`calendar${day}`].startTime2.format(
                    "HH:mm"
                  )
                : null;
            const endTime1 =
              (defaultHours as any)[`calendar${day}`].endTime1 &&
              (defaultHours as any)[`calendar${day}`].endTime1.isValid()
                ? (defaultHours as any)[`calendar${day}`].endTime1.format(
                    "HH:mm"
                  )
                : null;
            const endTime2 =
              (defaultHours as any)[`calendar${day}`].endTime2 &&
              (defaultHours as any)[`calendar${day}`].endTime2.isValid()
                ? (defaultHours as any)[`calendar${day}`].endTime2.format(
                    "HH:mm"
                  )
                : null;

            const key: string = `${startTime1 ?? ""}${endTime1 ?? ""}${
              startTime2 ?? ""
            }${endTime2 ?? ""}`;
            if (!(key in defaultHoursCalendar)) {
              defaultHoursCalendar[key] = {
                startTime1,
                endTime1,
                startTime2,
                endTime2,
                ...{
                  hasMonday: false,
                  hasTuesday: false,
                  hasWednesday: false,
                  hasThursday: false,
                  hasFriday: false,
                  hasSaturday: false,
                  hasSunday: false,
                },
                [daysTranslation[day]]: true,
              };
            } else {
              defaultHoursCalendar[key][daysTranslation[day]] = true;
            }
          }
        } else defaultHoursCalendar = defaultHoursShift;
      }
    }

    const [createExtraShiftPointOfSales, createExtraCalendarPointOfSales] =
      parseExtraHours(extraHours, isSingleCalendarManagement);

    await axios.post(link, {
      pointOfSale: {
        ...impostazioniGenerali,
      },
      createServicePointOfSales: services,
      shiftPointOfSales: Object.values(defaultHoursShift),
      calendarPointOfSales: Object.values(defaultHoursCalendar),
      createExtraCalendarPointOfSales,
      createExtraShiftPointOfSales,
    });

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};
