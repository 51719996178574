import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PointOfSalesInterface } from "../interfaces/PointOfSale_interface";
import { ServicePointOfSaleInterface } from "../interfaces/ServicePointOfSale_interface";
import { Dayjs } from "dayjs";
import { RootState } from "../../../store";
import User_interface from "../interfaces/User_interface";

export interface UserRootSliceState {
  pointOfSales: {
    [x: string]: PointOfSalesInterface;
  };
  users: {
    [x: string]: User_interface;
  };
  dataLoads: boolean;
  openPasswordDialog: boolean;
  openForm?: "modify" | "info" | "new";
  currentUser: string;
  currentUserBluBookingId: string;
  services: {
    [x: string]: ServicePointOfSaleInterface;
  };
  copiedDefaultRow?: {
    startTime1: Dayjs | null;
    startTime2: Dayjs | null;
    endTime1: Dayjs | null;
    endTime2: Dayjs | null;
  };
  isLoadingForm: {
    services: boolean;
    defaultHours: boolean;
    extraHours: boolean;

    // ! SOLO PER FW
    codiceAttivazione: boolean;
    listaFiliali: boolean;
    profile: boolean;
    citta: boolean;
    layoutCrm: boolean;
    googleCalendarSync: boolean;
  };
}

const initialState: UserRootSliceState = {
  pointOfSales: {},
  users: {},
  dataLoads: true,
  openPasswordDialog: false,
  currentUser: "",
  currentUserBluBookingId: "",
  services: {},
  isLoadingForm: {
    services: false,
    defaultHours: false,
    extraHours: false,

    codiceAttivazione: false,
    listaFiliali: false,
    profile: false,
    citta: false,
    layoutCrm: false,
    googleCalendarSync: false,
  },
};

const UserRootSliceSlice = createSlice({
  name: "PointOfSaleRoot",
  initialState,
  reducers: {
    setUsersOpenForm(
      state: UserRootSliceState,
      action: PayloadAction<"modify" | "info" | "new" | undefined>
    ) {
      state.openForm = action.payload;
    },
    setCurrentUsers(state: UserRootSliceState, action: PayloadAction<string>) {
      state.currentUser = action.payload;
    },
    setCurrentUserBluBookingId(
      state: UserRootSliceState,
      action: PayloadAction<string>
    ) {
      state.currentUserBluBookingId = action.payload;
    },

    setUsersDatas(
      state: UserRootSliceState,
      action: PayloadAction<{
        pointOfSales: {
          [x: string]: PointOfSalesInterface;
        };
        users: {
          [x: string]: User_interface;
        };
        dataLoads: boolean;
      }>
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setServiceLoad(state: UserRootSliceState, action: PayloadAction<boolean>) {
      state.dataLoads = action.payload;
    },

    setServices(
      state: UserRootSliceState,
      action: PayloadAction<{
        [x: string]: ServicePointOfSaleInterface;
      }>
    ) {
      state.services = action.payload;
    },
    setCopiedDefaultRow(
      state: UserRootSliceState,
      action: PayloadAction<
        | {
            startTime1: Dayjs | null;
            startTime2: Dayjs | null;
            endTime1: Dayjs | null;
            endTime2: Dayjs | null;
          }
        | undefined
      >
    ) {
      state.copiedDefaultRow = action.payload;
    },

    setIsLoadingForm(
      state: UserRootSliceState,
      action: PayloadAction<{
        key: string;
        value: boolean;
      }>
    ) {
      (state.isLoadingForm as any)[action.payload.key] = action.payload.value;
    },

    setOpenPasswordDialog(
      state: UserRootSliceState,
      action: PayloadAction<boolean>
    ) {
      state.openPasswordDialog = action.payload;
    },
  },
});

export const {
  setUsersOpenForm,
  setCurrentUsers,
  setUsersDatas,
  setServiceLoad,
  setServices,
  setCopiedDefaultRow,
  setIsLoadingForm,
  setCurrentUserBluBookingId,
  setOpenPasswordDialog,
} = UserRootSliceSlice.actions;

export const isFormLoading = (state: RootState): boolean => {
  return (
    Object.values(state.newPointOfSaleRoot.isLoadingForm).find(
      (value) => value
    ) !== undefined
  );
};

export default UserRootSliceSlice.reducer;
