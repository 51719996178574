import { Avatar, Badge, Checkbox, Typography } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueSetterParams,
} from "@mui/x-data-grid-premium";
import { nanoid } from "nanoid";
import ImageDefault from "../../../assets/utentebase.png";
import { FC, memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const PointOfSaleName: FC<{ pointOfSaleId: string }> = memo(
  ({ pointOfSaleId }) => {
    let pointOfSaleName = "";
    const { pointOfSales } = useSelector(
      (state: RootState) => state.newUsersRoot
    );
    if (pointOfSaleId && pointOfSales && pointOfSaleId in pointOfSales)
      pointOfSaleName = pointOfSales[pointOfSaleId]?.branch ?? "";
    return <Typography>{pointOfSaleName ?? ""}</Typography>;
  }
);

const columnsFun = (isBluBookingActive: boolean) => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", hide: true },
    { field: "blubookingId", headerName: "Blu Booking ID", hide: true },
    {
      field: "photo",
      headerName: "",
      width: 75,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return !!params.value ? (
          <Avatar
            key={`${params.value}?cachebust=${new Date().getTime()}photo`}
            sx={{
              bgcolor: "trasparent",
            }}
            alt="Remy Sharp"
            src={params.value}
          />
        ) : (
          <Avatar
            key={nanoid()}
            sx={{
              bgcolor: "#" + Math.floor(Math.random() * 16777215).toString(16),
            }}
            src={ImageDefault}
          />
        );
      },
    },
    {
      field: "isActive",
      headerName: "Attivo",
      width: 75,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} disabled />;
      },
    },
    {
      field: "username",
      headerName: "Utente",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      valueSetter: (params: GridValueSetterParams) => {
        return {
          ...params.row,
          name: params.value + params.row["surname"],
        };
      },
    },
    {
      field: "sign",
      headerName: "Codice",
      width: 70,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      hide: false,
    },
    {
      field: "surname",
      headerName: "Cognome",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      hide: false,
    },
    {
      field: "pointOfSaleId",
      headerName: "Filiale",
      width: 180,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params: GridRenderCellParams) => {
        return <PointOfSaleName pointOfSaleId={params?.value ?? ""} />;
      },
    },
    {
      field: "profilo",
      headerName: "Profilo",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "center",
      hide: true,
    },
    {
      field: "ottico",
      headerName: "Ottico",
      width: 75,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      hide: true,
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} />;
      },
    },
    {
      field: "hasAgenda",
      headerName: "Agenda",
      width: 75,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} disabled />;
      },
    },
    {
      field: "color",
      headerName: "Colore",
      width: 70,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return !!params.value ? (
          <Badge
            key={params.value + "color"}
            sx={{
              borderRadius: "50%",
              backgroundColor: params.value,
              width: 40,
              height: 40,
            }}
            overlap="circular"
          />
        ) : (
          <Badge
            key={nanoid()}
            sx={{
              borderRadius: "50%",
              backgroundColor: "white",
              width: 40,
              height: 40,
            }}
            overlap="circular"
          />
        );
      },
    },
    {
      field: "filiale2",
      headerName: "Filiale",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "center",
      hide: true,
    },
    {
      field: "stato",
      headerName: "Stato",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "center",
      hide: true,
    },
  ];
  return columns;
};

export default columnsFun;
