// @ts-nocheck
// require('../../scripts/sgcWebSockets.js')
// import * as sgcws from '../../scripts/socket'
// const sgcws = require('../../scripts/socket')
import { sgcws } from '../../scripts/socket'
// require('../../scripts/esegece.com.js')

let socket: any
let timerKeepAlive: any
let timeout = 200000
const timer = (ms: number) => new Promise((res) => setTimeout(res, ms))

function GUID() {
  var e = function () {
    return Math.floor(65536 * Math.random()).toString(16)
  }
  return e() + e() + e() + e() + e() + e() + e() + e()
}

const keepAlive = () => {
  console.log('on open', timeout)
  if (socket.readyState === 1) {
    socket.send(JSON.stringify({ jsonrpc: '2.0', method: '', params: '' }))
  }
  timerKeepAlive = setTimeout(keepAlive, timeout)
}

const cancelKeepAlive = () => {
  if (timerKeepAlive) {
    clearTimeout(timerKeepAlive)
  }
}

const wsOpenPromise = (url: string) =>
  new Promise<void>((resolve, reject) => {
    socket = new WebSocket(url, 'esegece.com')

    socket.onopen = (_: any): void => {
      keepAlive()
      resolve()
    }
    socket.onclose = (_: any) => {
      cancelKeepAlive()
      reject()
    }
    // socket.on('sgcmessage', function (event: any) {
    //   window.dispatchEvent(
    //     new CustomEvent('socketMessage', { detail: { event, socket } })
    //   )
    // })
    socket.onmessage = (event: any) => {
      const temp = JSON.parse(event?.data)

      if (temp && temp?.result) {
        if (
          temp.result?.method !== 'sgc@event' &&
          temp.result?.method !== 'sgc@message'
        )
          return
        let type = ''
        switch (temp.result?.method) {
          case 'sgc@event':
            type = 'onsgcevent'
            break
          case 'sgc@message':
            type = 'onsgcmessage'
            break
          default:
            type = 'onsgcevent'
        }
        window.dispatchEvent(
          new CustomEvent('socketEvent', {
            detail: {
              event: {
                name: type,
                channel: temp.result?.channel,
                message: temp.result?.message
              },
              socket
            }
          })
        )
      }
    }
  })

export class SocketManager {
  socket: any
  timerKeepAlive: any
  socketReady: boolean = false

  constructor({ url }: { url: string }) {
    wsOpenPromise(url).then(() => {
      this.socket = socket
      this.timerKeepAlive = timerKeepAlive
      this.socketReady = true
      return this
    })
  }

  closeSocket = () => {
    console.log('on close', this.socket)
    if (this.socket) {
      this.socket.close()
      this.socket = null
    }
  }

  waitSocketReady = async () => {
    let i = 0
    while (i < 5 && !this.socketReady) {
      await timer(100)
      i += 1
    }
  }

  subscribeSocketChannel = (channelName: string) => {
    try {
      console.log('subscribeSocketChannel ', this.socket)
      if (!this.socket) {
        this.waitSocketReady().then(() => {
          console.log('SOCKET READY', this.socket)
          if (this.socketReady)
            this.socket.send(
              JSON.stringify({
                jsonrpc: '2.0',
                method: 'sgc@subscribe',
                params: {
                  channel: channelName,
                  message: ''
                },
                id: GUID
              })
            )
        })
        return
      }
      this.socket.send(
        JSON.stringify({
          jsonrpc: '2.0',
          method: 'sgc@subscribe',
          params: {
            channel: channelName,
            message: ''
          },
          id: GUID
        })
      )
    } catch (e) {
      console.error(e)
    }
  }

  unsubscribeSocketChannel = (channelName: string) => {
    try {
      console.log('unsubscribeSocketChannel ', this.socket)
      if (this.socket)
        this.socket.send(
          JSON.stringify({
            jsonrpc: '2.0',
            method: 'sgc@unsubscribe',
            params: {
              channel: channelName,
              message: ''
            },
            id: GUID
          })
        )
    } catch (e) {
      console.error(e)
    }
  }
}
