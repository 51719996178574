import { MenuItem, Stack, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { BluSelect } from "@bludata/components";
import { FC, useCallback, useEffect } from "react";
import { useAzienda } from "../../../../api/azienda";
import { AppDispatch } from "../../../../../../store";
import { useDispatch } from "react-redux";
import { setIsLoadingForm } from "../../../../store/root";

interface AziendaSelectInterface {
  disabled?: boolean;
}

export const AziendaSelect: FC<AziendaSelectInterface> = ({ disabled }) => {
  const focusCodiceAzienda = useWatch({ name: "focusCodiceAzienda" });
  const { setValue, trigger } = useFormContext();
  const { data, isLoading } = useAzienda();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoadingForm({ key: "azienda", value: isLoading }));
  }, [dispatch, isLoading]);

  const getOptions = useCallback(() => {
    if (data) return data;
    return [];
  }, [data]);

  const handleOnChange = useCallback(
    (event: any) => {
      setValue("focusCodiceAzienda", event.target.value);
      trigger("focusCodiceAzienda");
    },
    [setValue, trigger]
  );

  return (
    <Stack flex={1}>
      <Typography>Azienda</Typography>
      <BluSelect
        multiple={false}
        disabled={disabled}
        value={focusCodiceAzienda}
        onChange={handleOnChange}
        renderValue={(selected: any) => {
          if (data && data.length > 0) {
            const find = data.find((d) => d.codiceFiliale === selected);
            if (find) return find.ragioneSociale;
          }
          return selected;
        }}
      >
        {getOptions().map((shopSign: any) => (
          <MenuItem value={shopSign.codiceFiliale}>
            {shopSign.ragioneSociale}
          </MenuItem>
        ))}
        <MenuItem value={""}>Nessuna azienda associata</MenuItem>
      </BluSelect>
    </Stack>
  );
};
