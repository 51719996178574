import { Button, Stack, Tab, Tabs } from "@mui/material";
import { FC, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store";
import { BluAsyncDialog } from "@bludata/components";
import {
  setAddPointOfSalePopUp,
  setDefaultHours,
} from "../../../../store/default-hours";
import { setExtraHours } from "../../../../store/extra-hours";

interface PoitOfSaleTabsInterface {
  defaultHoursPointOfSales: { id: string; name: string }[];
  value: string;
  setValue: (value: string) => void;
  disabled: boolean;
}

export const PoitOfSaleTabs: FC<PoitOfSaleTabsInterface> = memo(
  ({ defaultHoursPointOfSales, value, setValue, disabled }) => {
    const dispatch: AppDispatch = useDispatch();
    const { defaultHours } = useSelector(
      (state: RootState) => state.newUserDefaultHours
    );

    const { extra } = useSelector(
      (state: RootState) => state.newUserExtraHours
    );

    const handleChange = useCallback(
      (_event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      },
      [setValue]
    );

    const handleClickAddPointOfSale = useCallback(() => {
      dispatch(setAddPointOfSalePopUp(true));
    }, [dispatch]);

    const handleDeletePointOfSale = useCallback(() => {
      BluAsyncDialog({
        title: "Attenzione",
        type: "warning",
        message: "Vuoi davvero eliminare gli orari di questo punto vendita? ",
        confimButton: "Sì",
        declineButton: "No",
        onConfirmClick: () => {
          if (value !== null) {
            const temp = { ...defaultHours };
            const extraTemp = { ...extra };
            delete temp[value];
            if (value in extraTemp) delete extraTemp[value];
            const keys = Object.keys(temp);
            dispatch(setDefaultHours(temp));
            dispatch(setExtraHours({ value: extraTemp }));
            if (keys.length > 0) setValue(keys[0]);
            else setValue("");
          }
        },
        sx: { "& .MuiDialog-paper": { maxWidth: "370px" } },
      });
    }, [defaultHours, dispatch, extra, setValue, value]);

    return (
      <Stack
        flex="1"
        gap={1}
        sx={{
          width: "100%",
          position: "sticky",
          top: 0,
          backgroundColor: "var(--base-foreground-color)",
          zIndex: 200,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            "&.MuiTabs-root": {
              overflow: "unset!important",
              width: "100%",
            },
          }}
        >
          {defaultHoursPointOfSales.map((defaultHoursPointOfSales: any) => {
            return (
              <Tab
                key={defaultHoursPointOfSales.id}
                label={defaultHoursPointOfSales.name}
                value={defaultHoursPointOfSales.id}
              />
            );
          })}
        </Tabs>
        <Stack direction="row" gap={1} justifyContent="end" sx={{ pb: "10px" }}>
          {!disabled && (
            <Button color="primary" onClick={handleClickAddPointOfSale}>
              Aggiungi punto vendita
            </Button>
          )}
          {!disabled && value !== "" && (
            <Button color="error" onClick={handleDeletePointOfSale}>
              Elimina
            </Button>
          )}
        </Stack>
      </Stack>
    );
  }
);
