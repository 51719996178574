import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TurniDefaultInterface,
  TurniItemRangeInterface,
} from "../interfaces/default-turni-user";

import { RootState } from "../../../store";
import {
  checkAllDefaultHours,
  checkDefaultHoursValue,
} from "../utils/check-default-hours";
import { UserDefaultError } from "../interfaces/user-default-errors";

export interface TurniDefaultHoursInterface {
  defaultHours: TurniDefaultInterface;
  errorDefaultHours: {
    [x: string]: UserDefaultError;
  };

  importDefaultHoursPopUp: boolean;
  addPointOfSalePopUp: boolean;
}

const initialState: TurniDefaultHoursInterface = {
  defaultHours: {},
  errorDefaultHours: {},

  importDefaultHoursPopUp: false,
  addPointOfSalePopUp: false,
};

const UserDefaultHoursSlice = createSlice({
  name: "UserDefaultHours",
  initialState,
  reducers: {
    setDefaultHours(
      state: TurniDefaultHoursInterface,
      action: PayloadAction<TurniDefaultInterface>
    ) {
      state.defaultHours = action.payload;
      const errors = checkAllDefaultHours(action.payload);
      state.errorDefaultHours = errors;
    },

    setDefaultHoursValue(
      state: TurniDefaultHoursInterface,
      action: PayloadAction<{
        defaultHoursValue: TurniItemRangeInterface;
        day: string;
        turniType: "calendar" | "shift";
        pointOfSaleId: string;
      }>
    ) {
      (state.defaultHours as any)[action.payload.pointOfSaleId][
        `${action.payload.turniType}${action.payload.day}`
      ] = action.payload.defaultHoursValue;

      (state.errorDefaultHours as any)[action.payload.pointOfSaleId][
        `shift${action.payload.day}1`
      ] = checkDefaultHoursValue(
        "shift",
        1,
        action.payload.day,
        state.defaultHours[action.payload.pointOfSaleId]
      );
      (state.errorDefaultHours as any)[action.payload.pointOfSaleId][
        `shift${action.payload.day}2`
      ] = checkDefaultHoursValue(
        "shift",
        2,
        action.payload.day,
        state.defaultHours[action.payload.pointOfSaleId]
      );
      (state.errorDefaultHours as any)[action.payload.pointOfSaleId][
        `calendar${action.payload.day}1`
      ] = checkDefaultHoursValue(
        "calendar",
        1,
        action.payload.day,
        state.defaultHours[action.payload.pointOfSaleId]
      );
      (state.errorDefaultHours as any)[action.payload.pointOfSaleId][
        `calendar${action.payload.day}2`
      ] = checkDefaultHoursValue(
        "calendar",
        2,
        action.payload.day,
        state.defaultHours[action.payload.pointOfSaleId]
      );
    },
    setImportDefaultHoursPopUp(
      state: TurniDefaultHoursInterface,
      action: PayloadAction<boolean>
    ) {
      state.importDefaultHoursPopUp = action.payload;
    },
    setAddPointOfSalePopUp(
      state: TurniDefaultHoursInterface,
      action: PayloadAction<boolean>
    ) {
      state.addPointOfSalePopUp = action.payload;
    },
    resetDefaultHours() {
      return initialState;
    },
  },
});

export const {
  setDefaultHours,
  setImportDefaultHoursPopUp,
  setDefaultHoursValue,
  setAddPointOfSalePopUp,
  resetDefaultHours,
} = UserDefaultHoursSlice.actions;

export const defaultHourValueError = (
  state: RootState,
  turniType: "calendar" | "shift",
  day: string,
  rangeNumber: 1 | 2,
  pointOfSaleId: string
): boolean =>
  (state.newUserDefaultHours.errorDefaultHours as any)[pointOfSaleId][
    `${turniType}${day}${rangeNumber}`
  ];

export const defaultHourValue = (
  state: RootState,
  turniType: "calendar" | "shift",
  day: string,
  pointOfSaleId: string
): boolean =>
  (state.newUserDefaultHours.defaultHours as any)[pointOfSaleId][
    `${turniType}${day}`
  ];

export const hasErrordefaultHourValue = (state: RootState): boolean =>
  Object.values(state.newUserDefaultHours.errorDefaultHours).find(
    (pointOfSale: any) =>
      Object.values(pointOfSale ?? {}).find((key: any) => key === true) !==
      undefined
  ) !== undefined;

export default UserDefaultHoursSlice.reducer;
