import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ChatAiRootSliceState {
  streamMessage?: string
  initStream: boolean
}

const initialState: ChatAiRootSliceState = {
  initStream: false
}

const ChatAiRootSlice = createSlice({
  name: 'ChatAiRoot',
  initialState,
  reducers: {
    setStreamMessage(
      state: ChatAiRootSliceState,
      action: PayloadAction<string | undefined>
    ) {
      state.streamMessage = action.payload
    },
    setinitStream(state: ChatAiRootSliceState, action: PayloadAction<boolean>) {
      state.initStream = action.payload
    }
  }
})

export const { setStreamMessage, setinitStream } = ChatAiRootSlice.actions

export default ChatAiRootSlice.reducer
