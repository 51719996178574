import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Profile_interface from "../interfaces/Profile_interface";

export const getProfile = async (): Promise<Profile_interface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get(`/focusapi/tabella/profili`, {
      params: {
        _dc: new Date().getTime(),
        page: 1,
        start: 0,
        limit: 9999,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const useProfile = (key: string = "") => {
  return useQuery({
    queryFn: async () => await getProfile(),
    queryKey: ["profile", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
