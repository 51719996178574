import { FC, memo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography
} from '@mui/material'
import { GridCloseIcon } from '@mui/x-data-grid-premium'
import { useQueryClient } from '@tanstack/react-query'
import { AppDispatch, RootState } from '../../../../store'
import { setModifiedThreadId } from '../../store'
import BluAsyncDialog from '../../../../common/AsyncDialog'
import BluDialog from '../../../../common/Dialog'
import BluLoader from '../../../../common/Loader'
import React from 'react'
import BluTextField from '../../../../common/TextField'
import { updateThread } from '../../api/put-thread'

interface ThreadPopupInterface {
  setThreadId: (newThreadId?: string) => void
}

export const ThreadPopup: FC<ThreadPopupInterface> = memo(({ setThreadId }) => {
  const { modifiedThreadId } = useSelector((state: RootState) => state.chatMenu)
  const dispatch = useDispatch<AppDispatch>()
  const [descrizione, setDescrizione] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const handleCancel = useCallback(() => {
    dispatch(setModifiedThreadId(undefined))
    setDescrizione('')
  }, [dispatch])

  const handleErase = useCallback(() => {
    setDescrizione('')
  }, [])

  const handleOnChange = useCallback((event: any) => {
    setDescrizione(event.target.value)
  }, [])

  const handleModify = useCallback(async () => {
    console.log('modifiedThreadId', modifiedThreadId)
    setLoading(true)

    const result = await updateThread(modifiedThreadId!!, descrizione)

    setLoading(false)

    if (result) {
      setThreadId(modifiedThreadId)
      queryClient.invalidateQueries(['thread'], {
        type: 'active'
      })
      queryClient.removeQueries(['thread'], {
        type: 'inactive'
      })
      dispatch(setModifiedThreadId(undefined))
      return
    }
    BluAsyncDialog({
      title: 'Attenzione',
      message:
        'Ci scusiamo, per un problema tecnico, non è possibile creare una nuova chat. Riprova più tardi.',
      confimButton: 'Chiudi',
      hideDeclineButton: true,
      type: 'warning',
      // maxWidth: false,
      sx: { '& .MuiDialog-paper': { maxWidth: '400px' } }
    })
  }, [modifiedThreadId, descrizione, setThreadId, queryClient, dispatch])

  const getTitle = useCallback(() => {
    return 'Modifica la chat'
  }, [])

  const getContent = useCallback(() => {
    return 'Cambia il titolo della chat:'
  }, [])

  const handleSaveClick = useCallback(() => {
    handleModify()
  }, [handleModify])

  return (
    <BluDialog
      open={modifiedThreadId !== undefined}
      zIndex={200}
      maxWidth="xs"
      dialogTitle={getTitle()}
      dialogContent={
        <Stack sx={{ height: 'fit-content', p: '10px 0' }}>
          <BluLoader open={loading} />
          <Typography>{getContent()}</Typography>
          <Box>
            <BluTextField
              id="titolo"
              value={descrizione}
              // label="Titolo"
              maxLength={40}
              InputProps={{
                endAdornment: (
                  <>
                    {descrizione !== '' && (
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={handleErase}
                        >
                          <GridCloseIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                )
              }}
              onChange={handleOnChange}
            />
          </Box>
        </Stack>
      }
      sx={{
        '& .MuiDialogContent-root': {
          pb: '0px !important',
          height: 'fit-content',
          overflowY: 'hidden'
        }
      }}
      dialogActions={
        <>
          <Button onClick={handleCancel} variant="outlined">
            Annulla
          </Button>
          <Button onClick={handleSaveClick}>Salva</Button>
        </>
      }
    />
  )
})
