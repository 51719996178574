/* eslint-disable jsx-a11y/alt-text */
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useActivationCodeIMO } from "../../../../api/codice-attivazione";
import { BluAsyncDialog, BluLoader } from "@bludata/components";

export const IMOActivationPopUp: FC<{
  setOpen: (value: boolean) => void;
}> = ({ setOpen }): JSX.Element => {
  const codiceFiliale = useWatch({ name: "codiceFiliale" });
  const { data, isLoading, isError } = useActivationCodeIMO(codiceFiliale);
  let errorAlreadyOpen = false;
  useEffect(() => {
    if (isError && !errorAlreadyOpen) {
      BluAsyncDialog({
        title: "Attenzione",
        type: "error",
        message: "Errore durante l'attivazione dell'utente",
        hideDeclineButton: true,
        confimButton: "Chiudi",
        onConfirmClick: () => {
          errorAlreadyOpen = false;
        },
        sx: { "& .MuiDialog-paper": { maxWidth: "370px" } },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      errorAlreadyOpen = true;
    }
  }, [isError]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Dialog open fullScreen={false} maxWidth={"sm"} fullWidth>
      <Card sx={{ backgroundColor: "#1c232c" }}>
        <DialogTitle
          sx={{ mt: 2, mb: 2, backgroundColor: "#1c232c", color: "white" }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <img
              src="https://www.bludata.com/images/repository/loghi/logo_IMO.png"
              style={{ width: "170px", height: "55px" }}
            />
            <Typography variant="h6" color={"#ffffff"}>
              Attiva l'app IL MIO OTTICO
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <BluLoader open={isLoading} sx={{ zIndex: "1400" }} />
          <DialogContentText component="div">
            <Typography variant="h6" color={"#ffffff"}>
              Codice di attivazione APP:{" "}
              <b>{data && data?.codice ? data?.codice : "-"}</b>
            </Typography>
            <Typography variant="subtitle2" color={"#ffffff"}>
              <i>Attenzione, codice valido per un'ora</i>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mt: 1, mb: 1 }}>
          <Button variant="contained" onClick={handleClose}>
            Chiudi
          </Button>
        </DialogActions>
      </Card>
    </Dialog>
  );
};
