import { PointOfSalesInterface } from "../../../interfaces/PointOfSale_interface";
import { getPointOfSaleWhiteCache } from "../../../lib/white-cache";
import { defaultPointOfSaleBB } from "./default-point-of-sale-bb";
import { defaultPointOfSaleFw } from "./default-point-of-sale-fw";

export const getInitialForm = (pointOfSale: PointOfSalesInterface) => {
  const { isFocusWeb } = getPointOfSaleWhiteCache();
  if (isFocusWeb)
    return {
      ...defaultPointOfSaleFw,
      ...pointOfSale,
    };
  return {
    ...defaultPointOfSaleBB,
    ...pointOfSale,
  };
};
