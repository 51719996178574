import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PointOfSaleInterface } from "../../../interfaces/point-of-sale";
import { ServiceInterface } from "../../../interfaces/service";
import { StudioInterface } from "../../../interfaces/studio";
import { UserInterface } from "../../../interfaces/user";

export interface ServiceRootSliceState {
  pointOfSales: {
    [x: string]: PointOfSaleInterface;
  };
  services: {
    [x: string]: ServiceInterface;
  };
  studi: {
    [x: string]: StudioInterface;
  };
  users: {
    [x: string]: UserInterface;
  };
  serviceLoad: boolean;
  openForm?: "modify" | "info" | "new";
  currentService: string;
  deleteService?: string;
}

const initialState: ServiceRootSliceState = {
  pointOfSales: {},
  services: {},
  studi: {},
  users: {},
  serviceLoad: true,
  currentService: "",
};

const ServiceRootSlice = createSlice({
  name: "ServiceRoot",
  initialState,
  reducers: {
    setOpenForm(
      state: ServiceRootSliceState,
      action: PayloadAction<"modify" | "info" | "new" | undefined>
    ) {
      state.openForm = action.payload;
    },
    setCurrentService(
      state: ServiceRootSliceState,
      action: PayloadAction<string>
    ) {
      state.currentService = action.payload;
    },

    setDeleteService(
      state: ServiceRootSliceState,
      action: PayloadAction<string | undefined>
    ) {
      state.deleteService = action.payload;
    },
    setServiceData(
      state: ServiceRootSliceState,
      action: PayloadAction<{
        services: any;
        pointOfSales: any;
        studi: any;
        users: any;
        serviceLoad: boolean;
      }>
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setServiceLoad(
      state: ServiceRootSliceState,
      action: PayloadAction<boolean>
    ) {
      state.serviceLoad = action.payload;
    },
  },
});

export const {
  setOpenForm,
  setCurrentService,
  setDeleteService,
  setServiceData,
  setServiceLoad,
} = ServiceRootSlice.actions;

export default ServiceRootSlice.reducer;
