import { TableContainer, Table, TableBody } from "@mui/material";
import {
  FC,
  Fragment,
  memo,
  useDeferredValue,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { CalendarUserRow } from "./table-row";
import { setIsLoading } from "../../store/root";
import { UserRotaHeader } from "./header";
import { nanoid } from "nanoid";
import { TableHeaderDay } from "../../components/tableHeader/tableHeaderDay";
import { useTurniUserDay } from "../../api/turni-user-day";

interface CalendarUserDayInterface {
  holiday: {
    [key: string]: string;
  };
  isSingleCalendarManagement: boolean;
}
export const CalendarUserDay: FC<CalendarUserDayInterface> = memo(
  ({ holiday, isSingleCalendarManagement }) => {
    const { pointOfSaleId, selectedDate, fetchPointOfSales } = useSelector(
      (state: RootState) => state.rotaRoot
    );
    const dispatch = useDispatch<AppDispatch>();

    const { data, isLoading } = useTurniUserDay(
      fetchPointOfSales,
      pointOfSaleId!!,
      selectedDate,
      isSingleCalendarManagement
    );
    const turni = useDeferredValue(data);

    useEffect(() => {
      dispatch(setIsLoading({ key: "turniUsers", value: isLoading }));
    }, [dispatch, isLoading]);

    const getTurni = useMemo(() => {
      if (turni) {
        const [calendar, shift] = turni;
        const union = [
          ...new Set([
            ...Object.keys(calendar ?? {}),
            ...Object.keys(shift ?? {}),
          ]),
        ];
        return (
          <>
            {union.map((userId) => (
              <Fragment key={nanoid()}>
                <CalendarUserRow
                  userId={userId}
                  userName={
                    calendar && userId in calendar
                      ? calendar[userId].username
                      : shift[userId].username
                  }
                  calendar={
                    calendar && userId in calendar ? calendar[userId] : {}
                  }
                  shift={shift && userId in shift ? shift[userId] : {}}
                  turniType="shift"
                  selectedDate={selectedDate}
                  holiday={holiday}
                  isSingleCalendarManagement={isSingleCalendarManagement}
                />
              </Fragment>
            ))}
          </>
        );
      }
      return <></>;
    }, [holiday, isSingleCalendarManagement, selectedDate, turni]);
    // return <></>;

    return (
      <>
        <UserRotaHeader
          turni={turni}
          isSingleCalendarManagement={isSingleCalendarManagement}
        />

        <TableContainer sx={{ borderRadius: "0 8px 8px 0" }}>
          <Table
            size="small"
            stickyHeader
            sx={{
              tableLayout: "fixed",
              borderRadius: "0 8px  8px 0",
              "& .MuiTableCell-root": {
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
            }}
          >
            <TableHeaderDay />
            <TableBody>{getTurni}</TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
);
