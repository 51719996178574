import { memo, useDeferredValue, useEffect, useMemo, useState } from "react";
import { useGoogleCalendar } from "../../../../api/google-calendar";
import { BluTitle } from "@bludata/components";
import { Stack, Typography, Button } from "@mui/material";
import { CalendarConfigurationDialog } from "./GoogleConfigurationDialog";
import { CalendarReactivationDialog } from "./GoogleDisabledReactivationDialog";
import { useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store";
import { setIsLoadingForm } from "../../../../store/root";

export const GoogleCalendar = memo(() => {
  const userId = useWatch({ name: "userId" });
  const { data, isLoading } = useGoogleCalendar(userId);
  const result = useDeferredValue(data);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [
    showDialogDisableAndReactivation,
    setShowDialogDisableAndReactivation,
  ] = useState<boolean>(false);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoadingForm({ key: "googleCalendarSync", value: isLoading }));
  }, [dispatch, isLoading]);

  const GetText = useMemo(() => {
    if (!result)
      return (
        <Typography>
          In questa sezione è possibile attivare la sincronizzazione dell'agenda
          personale con i calendari di Google. Clicca il pulsante per
          configurare:
        </Typography>
      );
    if (!result?.googleCalendarId)
      return (
        <Typography>
          È stata inviata una mail all'indirizzo <b>{result.email}</b> ma non è
          stato associato nessun calendario. Procedi con la configurazione per
          completare la procedura di attivazione.
        </Typography>
      );

    return (
      <Typography>
        L'agenda personale è già sincronizzata con Google Calendar. L'account
        associato è: <b>{result.email}</b>
      </Typography>
    );
  }, [result]);

  const GetButton = useMemo(() => {
    if (!result)
      return (
        <Button
          onClick={() => {
            setShowDialog((x) => !x);
          }}
        >
          Configura
        </Button>
      );
    if (!result?.googleCalendarId)
      return (
        <Button
          onClick={() => {
            setShowDialogDisableAndReactivation((x) => !x);
          }}
        >
          Riattiva
        </Button>
      );

    return (
      <Button
        color={"error"}
        onClick={() => {
          setShowDialogDisableAndReactivation((x) => !x);
        }}
      >
        Disabilita
      </Button>
    );
  }, [result]);

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="left"
        flex={1}
        spacing={1}
      >
        <CalendarConfigurationDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
        />
        <CalendarReactivationDialog
          id={result && result.id ? result.id : ""}
          email={result && result?.email ? result.email : ""}
          isConnect={result && result?.googleCalendarId ? true : false}
          showDialogDisableAndReactivation={showDialogDisableAndReactivation}
          setShowDialogDisableAndReactivation={
            setShowDialogDisableAndReactivation
          }
        />
        <BluTitle text={"Google Calendar"} />
        <Stack direction="column" sx={{ width: "100%" }} spacing={1}>
          {GetText}
          <Stack direction="row">{GetButton}</Stack>
        </Stack>
      </Stack>
    </>
  );
});
