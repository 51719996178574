import '../../styles/chat-ai-menu.css'

import { FC, memo, useCallback } from 'react'
import { Provider } from 'react-redux'
import React from 'react'
import { store } from '../../store'
import { ChatMenu } from './chat-menu'
import { Box } from '@mui/material'

interface BluChatMenuInterface {
  url?: string
  token?: string
  threadId?: string | null
  setThreadId: (newThreadId?: string | null) => void
}

export const BluChatMenu: FC<BluChatMenuInterface> = memo(
  ({ threadId, setThreadId }) => {
    const getStyles = useCallback(() => {
      if (!threadId) return 'chat-ai-menu-enabled'
      return 'chat-ai-menu-disabled'
    }, [threadId])
    return (
      <>
        <Provider store={store}>
          <Box className={`chat-ai-menu ${getStyles()}`}>
            <ChatMenu threadId={threadId} setThreadId={setThreadId} />
          </Box>
        </Provider>
      </>
    )
  }
)
