import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const fetchListaFiliali = async (): Promise<any> => {
  try {
    const {
      data: { data },
    } = await axios.get(`/focusapi/magazzino/getlistafiliali`, {
      params: {
        _dc: new Date().getTime(),
      },
    });
    return data.map((filiale: any) => {
      return {
        ...filiale,

        id: filiale.filiale,
      };
    });
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const useListaFiliali = (key: string = "") => {
  return useQuery({
    queryFn: async () => await fetchListaFiliali(),
    queryKey: ["lista-filiali", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
