import { Stack, TextField } from "@mui/material";
import React, { ChangeEvent, FC } from "react";
import { memo } from "react";

interface SearchInterface {
  search: string;
  handleOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Search: FC<SearchInterface> = memo(
  ({ search, handleOnChange }) => {
    return (
      <Stack
        direction={"row"}
        justifyContent="start"
        alignItems="end"
        spacing={1}
        sx={{ mb: "15px!important" }}
      >
        <TextField
          value={search}
          label="Cerca per servizio"
          onChange={handleOnChange}
          sx={{ maxWidth: 300, pb: "10px" }}
        />
      </Stack>
    );
  }
);
