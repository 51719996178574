import { FC, useMemo } from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { nanoid } from "nanoid";

export const DefaultHead: FC<{
  isSingleCalendarManagement: boolean;
}> = ({ isSingleCalendarManagement }): JSX.Element => {
  const GetTurniType = useMemo(() => {
    if (isSingleCalendarManagement) return <></>;
    return (
      <TableCell
        id={nanoid()}
        key={nanoid()}
        rowSpan={2}
        style={{
          borderLeft: "1px solid rgba(224, 224, 224, 1)",
          backgroundColor: "var(--closed-color)",
        }}
      >
        Tipo di turno
      </TableCell>
    );
  }, [isSingleCalendarManagement]);

  return (
    <TableHead>
      <TableRow
        style={{
          backgroundColor: "var(--closed-color)",
        }}
      >
        <TableCell
          id={nanoid()}
          key={nanoid()}
          rowSpan={2}
          style={{
            backgroundColor: "var(--closed-color)",
            minWidth: "70px",
            borderTopLeftRadius: "8px",
          }}
        >
          Giorno
        </TableCell>
        {GetTurniType}
        <TableCell
          id={nanoid()}
          key={nanoid()}
          colSpan={2}
          align="center"
          style={{
            backgroundColor: "var(--closed-color)",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          Primo turno
        </TableCell>
        <TableCell
          id={nanoid()}
          key={nanoid()}
          colSpan={2}
          align="center"
          style={{
            backgroundColor: "var(--closed-color)",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          Secondo turno
        </TableCell>
        <TableCell
          id={nanoid()}
          key={nanoid()}
          align="center"
          rowSpan={2}
          style={{
            backgroundColor: "var(--closed-color)",
            width: "10px",
            borderTopRightRadius: "8px",
          }}
        ></TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          id={nanoid()}
          key={nanoid()}
          align="center"
          style={{
            backgroundColor: "var(--closed-color)",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            width: "70px",
          }}
        >
          Dalle ore
        </TableCell>
        <TableCell
          id={nanoid()}
          key={nanoid()}
          align="center"
          style={{
            backgroundColor: "var(--closed-color)",
            width: "70px",
          }}
        >
          alle ore
        </TableCell>
        <TableCell
          id={nanoid()}
          key={nanoid()}
          align="center"
          style={{
            backgroundColor: "var(--closed-color)",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            width: "70px",
          }}
        >
          Dalle ore
        </TableCell>
        <TableCell
          id={nanoid()}
          key={nanoid()}
          align="center"
          style={{
            backgroundColor: "var(--closed-color)",
            borderRight: "1px solid rgba(224, 224, 224, 1)",

            width: "70px",
          }}
        >
          alle ore
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
