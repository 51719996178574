import { Stack, Box, Typography, useTheme } from "@mui/material";
import { FC, memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import ModeIcon from "@mui/icons-material/Mode";
import { setPopUpRota } from "../../store/root";
import { getRotaWhiteCache } from "../../lib/white-cache";

interface PointOfSaleRotaHeaderInterface {
  isSingleCalendarManagement: boolean;
  turni: any;
}

export const PointOfSaleRotaHeader: FC<PointOfSaleRotaHeaderInterface> = memo(
  ({ turni, isSingleCalendarManagement }) => {
    const { turniFilter, pointOfSaleName, view, selectedDate } = useSelector(
      (state: RootState) => state.rotaRoot
    );
    const dispatch = useDispatch<AppDispatch>();
    const { turniModifica } = getRotaWhiteCache();
    const theme = useTheme();

    const handleClick = useCallback(() => {
      if (turniModifica) {
        const [calendar, shift] = turni;
        dispatch(
          setPopUpRota({
            id: `${selectedDate.getDate()}`,
            date: selectedDate,
            calendarType: "pointOfSale",
            shift: shift.current as any,
            calendar: calendar.current as any,
            defaultCalendar: calendar.default,
            defaultShift: shift.default,
          })
        );
      }
    }, [dispatch, selectedDate, turni, turniModifica]);

    const GetTurni = useMemo(() => {
      return (
        <Box
          sx={{
            height: "40px",
            boxSizing: "border-box",
            borderTop: "1px solid var(--divider-color)",
          }}
        >
          <Typography sx={{ pt: "10px", pl: "10px", color: "var(--color)" }}>
            Turni
          </Typography>
        </Box>
      );
    }, []);

    const GetShift = useMemo(() => {
      if (turniFilter === "calendar") return <></>;
      return (
        <Box
          sx={{
            height: "40px",
            boxSizing: "border-box",
            borderTop: "1px solid var(--divider-color)",
          }}
        >
          <Typography sx={{ pt: "10px", pl: "10px", color: "var(--color)" }}>
            Lavoro
          </Typography>
        </Box>
      );
    }, [turniFilter]);

    const GetCalendar = useMemo(() => {
      if (turniFilter === "shift") return <></>;
      return (
        <Box
          sx={{
            height: "40px",
            boxSizing: "border-box",
            borderTop: "1px solid var(--divider-color)",
          }}
        >
          <Typography sx={{ pt: "10px", pl: "10px", color: "var(--color)" }}>
            Appuntamenti
          </Typography>
        </Box>
      );
    }, [turniFilter]);

    return (
      <Stack
        sx={{
          width: "270px",
          position: "sticky",
          left: 0,
          backgroundColor: "var(--base-foreground-color)",
          zIndex: 1,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "var(--base-foreground-color)",
            boxSizing: "border-box",
            border: "1px solid var(--divider-color)",
            borderRadius: "8px 0 0 8px",
            pb: "1px",
            flex: 1,
          }}
        >
          <Stack
            flex="1"
            direction="row"
            sx={{
              height: "38px",
              backgroundColor:
                theme?.palette?.mode === "dark"
                  ? "var(--background-color) !important"
                  : "#f0f0f0 !important",
              cursor: view === "day" ? "pointer" : "default",
              pr: "10px",
            }}
            onClick={handleClick}
          >
            <Typography
              fontSize="16px"
              fontWeight="500"
              sx={{
                flex: 1,
                height: "fit-content",
                color: "var(--color)",
                margin: "auto",
                pl: 1,
              }}
            >
              {pointOfSaleName}
            </Typography>
            {view === "day" && (
              <ModeIcon
                sx={{
                  height: "20px",
                  width: "20px",
                  margin: "auto",
                  // pr: "10px",
                  color: "var(--base-color)",
                }}
              />
            )}
          </Stack>
          {!isSingleCalendarManagement ? (
            <>
              {GetShift}
              {GetCalendar}
            </>
          ) : (
            <>{GetTurni}</>
          )}
        </Stack>
      </Stack>
    );
  }
);
