import { BluSelect, BluTitle } from "@bludata/components";
import { Box, Checkbox, Chip, ListItemText, MenuItem } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import React from "react";
import { memo, useCallback, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { Else, If, Then } from "react-if";
import { useDispatch, useSelector } from "react-redux";
import { setMultipleServiceValue } from "../../../../store/form";
import { AppDispatch, RootState } from "../../../../../../store";
import { useServiceFilter } from "../../../../api/service-filter";

export const Services = memo(() => {
  const dispatch = useDispatch<AppDispatch>();

  const pointOfSaleId = useWatch({ name: "pointOfSaleId" });
  const { serviceForm } = useSelector((state: RootState) => state.studiForm);
  const { openForm, services } = useSelector(
    (state: RootState) => state.studiRoot
  );

  const { data } = useServiceFilter({ pointOfSaleId });

  useEffect(() => {
    if (data !== undefined && openForm === "new")
      dispatch(setMultipleServiceValue(data));
  }, [data, dispatch, openForm]);

  const getValues = useCallback(() => {
    return serviceForm;
  }, [serviceForm]);

  const getItems = useCallback(() => {
    if (
      // openForm === "new" &&
      pointOfSaleId !== undefined &&
      pointOfSaleId !== "" &&
      data !== undefined
    )
      return data;
    return Object.keys(services);
  }, [data, pointOfSaleId, services]);

  const handleChange = useCallback(
    (event: any) => {
      if (event !== undefined) {
        dispatch(setMultipleServiceValue(event.target.value));
      }
    },
    [dispatch]
  );

  return (
    <div
      style={{
        width: "100%",
        padding: "5px 20px 5px 20px",
        boxSizing: "border-box",
      }}
    >
      <BluTitle text="Servizi" sx={{ p: "3px 0px" }} />

      <BluSelect
        displayEmpty
        multiple
        value={getValues()}
        onChange={handleChange}
        disabled={openForm === "info" || pointOfSaleId === ""}
        renderValue={(selected) => {
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              <If condition={selected.length === 0}>
                <Then>
                  <div style={{ color: "#9a9a9a" }}>Seleziona un servizio</div>
                </Then>
                <Else>
                  <If
                    condition={
                      selected.length === Object.keys(getItems()).length
                    }
                  >
                    <Then>
                      <Chip
                        key={"allServicesSelected"}
                        label="Tutti i servizi"
                        // label="Tutti i servizi del punto vendita"
                        size="small"
                      />
                    </Then>
                    <Else>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          size="small"
                          label={services[value]?.name}
                        />
                      ))}
                    </Else>
                  </If>
                </Else>
              </If>
            </Box>
          );
        }}
      >
        {getItems().map((id) => (
          <MenuItem key={nanoid()} value={id}>
            <Checkbox
              checked={serviceForm.find((index) => index === id) !== undefined}
              style={{ padding: 0 }}
            />
            <ListItemText primary={services[id]?.name} />
          </MenuItem>
        ))}
      </BluSelect>
    </div>
  );
});
