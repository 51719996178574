import { DataGridPremium } from "@mui/x-data-grid-premium";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isSafari } from "react-device-detect";
import { AppDispatch, RootState, useAppSelector } from "../../../../store";
import columnsbb from "../../utils/columnsbb";
import columnsfw from "../../utils/columnsfw";
import columnscrm from "../../utils/columnscrm";
import { getUsersWhiteCache } from "../../lib/white-cache";
import {
  setCurrentUserBluBookingId,
  setCurrentUsers,
  setUsersOpenForm,
} from "../../store/root";
import { bluBookingLoginData } from "../../../../store/slices/root";

interface UsersGridInterface {
  gridSearch: string;

  isActive: boolean;

  hasAgenda: boolean;

  optician: boolean;

  filterPointOfSale: string;
}

export const UsersGrid: FC<UsersGridInterface> = ({
  gridSearch,

  isActive,

  hasAgenda,

  optician,

  filterPointOfSale,
}): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();

  const { users } = useSelector((state: RootState) => state.newUsersRoot);
  const { software } = useAppSelector(bluBookingLoginData);

  const { isBluBookingActive, isFocusWeb } = getUsersWhiteCache();

  const getValues = useCallback(() => {
    if (!users) return [];

    return Object.values(users)
      .filter((item: any) => {
        var search = new RegExp(gridSearch, "i");
        return search.test(item.username);
      })
      .filter((item: any) => {
        if (isActive) {
          return item.isActive === true;
        } else {
          return item;
        }
      })
      .filter((item: any) => {
        if (hasAgenda) {
          return item.hasAgenda === true;
        } else {
          return item;
        }
      })
      .filter((item: any) => {
        if (optician) {
          return item.isOptician === true;
        } else {
          return item;
        }
      })
      .filter((item: any) => {
        if (filterPointOfSale !== "") {
          if (isFocusWeb) return item.filiale === filterPointOfSale;
          return item.pointOfSaleId === filterPointOfSale;
        } else {
          return item;
        }
      });
  }, [
    users,
    gridSearch,
    isActive,
    hasAgenda,
    optician,
    filterPointOfSale,
    isFocusWeb,
  ]);

  const getColumns = useCallback(() => {
    switch (software) {
      case "bb":
        return columnsbb(isBluBookingActive);
      case "fw":
        return columnsfw(isBluBookingActive);
      case "crm":
        return columnscrm(isBluBookingActive);
      default:
        return columnsbb(isBluBookingActive);
    }
  }, [isBluBookingActive, software]);

  return (
    <DataGridPremium
      key={"grigliaPuntiVendita"}
      sx={{
        "& .MuiDataGrid-cell": {
          outline: "none!important",
        },
        zIndex: isSafari ? 9999 : undefined,
      }}
      rows={getValues()}
      columns={getColumns()}
      editMode="cell"
      autoHeight={false}
      getEstimatedRowHeight={() => 300}
      getRowHeight={() => "auto"}
      rowBuffer={50}
      rowThreshold={15}
      density={"compact"}
      experimentalFeatures={{ newEditingApi: true }}
      onRowClick={(event: any) => {
        dispatch(setCurrentUsers(event.id));
        dispatch(
          setCurrentUserBluBookingId(event.row?.blubookingId ?? event.id)
        );
        dispatch(setUsersOpenForm(undefined));
      }}
      onRowDoubleClick={() => dispatch(setUsersOpenForm("info"))}
      components={{
        Footer: () => <></>,
      }}
    />
  );
};
