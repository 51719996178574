import axios from "axios";
import { ServiceMenageInterface } from "../interfaces/service-manage";

export const manageService = async (
  service: ServiceMenageInterface
): Promise<boolean> => {
  try {
    await axios.post("/api/Service/Management", service);

    return true;
  } catch (err) {
    console.trace(err);
    return false;
  }
};
