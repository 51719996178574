import { IconButton, Stack } from '@mui/material'
import { ChangeEvent, FC, memo, useCallback, useState } from 'react'
import SendIcon from '@mui/icons-material/Send'
import { useDispatch } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { AppDispatch } from '../../../../store'
import { setStreamMessage, setinitStream } from '../../store/root'
import { postMessage } from '../../api/post-message'
import React from 'react'
import BluTextField from '../../../../common/TextField'
import { setThreadRunInit } from '../../utils/white-cache'
import { ThreadInterface } from '../../interfaces/thread'
import { Questions } from './questions'

interface ChatTextFieldIterface {
  threadId: string
  isMessageLoading: boolean
  isFirstMessage: boolean
  setThreadId: (newThreadId?: string) => void
  threadInfo?: ThreadInterface | null
}

export const ChatTextField: FC<ChatTextFieldIterface> = memo(
  ({ threadId, isMessageLoading, isFirstMessage, threadInfo }) => {
    const [message, setMessage] = useState<string>('')
    const dispatch = useDispatch<AppDispatch>()
    const queryClient = useQueryClient()

    const handleOnChange = useCallback(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessage(event.target.value)
      },
      []
    )

    const handleOnClick = useCallback(async () => {
      setMessage('')
      setThreadRunInit(true)
      dispatch(setStreamMessage(message))
      const result = await postMessage(threadId, message, isFirstMessage)

      if (result) {
        if (isFirstMessage) {
          queryClient.invalidateQueries(['thread'], {
            type: 'active'
          })
          queryClient.removeQueries(['thread'], {
            type: 'inactive'
          })
        }
        dispatch(setinitStream(true))
        queryClient.invalidateQueries(['thread-run'], {
          type: 'active'
        })
        queryClient.removeQueries(['thread-run'], {
          type: 'inactive'
        })
        // setMessage('')
      } else dispatch(setStreamMessage(undefined))
    }, [dispatch, isFirstMessage, message, queryClient, threadId])

    const handleQuestionClick = useCallback(
      async (question: string) => {
        setMessage('')
        setThreadRunInit(true)
        dispatch(setStreamMessage(question))
        const result = await postMessage(threadId, question, isFirstMessage)

        if (result) {
          if (isFirstMessage) {
            queryClient.invalidateQueries(['thread'], {
              type: 'active'
            })
            queryClient.removeQueries(['thread'], {
              type: 'inactive'
            })
          }
          dispatch(setinitStream(true))
          queryClient.invalidateQueries(['thread-run'], {
            type: 'active'
          })
          queryClient.removeQueries(['thread-run'], {
            type: 'inactive'
          })
        } else dispatch(setStreamMessage(undefined))
      },
      [dispatch, isFirstMessage, queryClient, threadId]
    )

    return (
      <Stack>
        {threadInfo && isFirstMessage && (
          <Questions
            threadInfo={threadInfo}
            handleQuestionClick={handleQuestionClick}
          />
        )}
        <Stack
          direction="row"
          gap={1}
          sx={{
            height: 'fit-content',
            width: '100%',
            p: '10px',
            boxSizing: 'border-box'
          }}
        >
          <Stack direction="row" flex="1" sx={{ pb: '10px' }}>
            <BluTextField
              disabled={isMessageLoading}
              id="descrizione"
              value={message}
              label="Scrivi un messaggio"
              onChange={handleOnChange}
              multiline
              maxRows={10}
              onKeyDown={(e: any) => {
                if (e.keyCode === 13 && e.shiftKey) {
                  e.preventDefault()
                  setMessage(`${message}\n`)
                  e.stopPropagation()
                }
                if (e.keyCode === 13 && !e.shiftKey) {
                  e.preventDefault()

                  handleOnClick()
                  e.stopPropagation()
                }
              }}
            />
          </Stack>
          <Stack direction="row" justifyContent="end">
            <IconButton
              sx={{ width: '50px', height: '50px', mt: '10px' }}
              onClick={handleOnClick}
              disabled={isMessageLoading}
            >
              <SendIcon
                color="primary"
                sx={{ width: '30px', height: '30px' }}
              />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    )
  }
)
