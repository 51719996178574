import { FC, memo, useMemo } from "react";
import { PointOfSalePopUpForm } from "./PopUpForm";
import { usePointOfSale } from "../../api/point-of-sale";

interface PointOfSalePopUpInterface {
  pointOfSaleId: string;
  mode: "modify" | "info" | "new";
}

export const PointOfSalePopUp: FC<PointOfSalePopUpInterface> = memo(
  ({ pointOfSaleId, mode }) => {
    const { data } = usePointOfSale(pointOfSaleId, mode);
    const GetContent = useMemo(() => {
      if (data) return <PointOfSalePopUpForm pointOfSale={data} mode={mode} />;
      return <></>;
    }, [data, mode]);
    return <>{GetContent}</>;
  }
);
