import { object, string, boolean } from "yup";

export const schema = object().shape({
  isActive: boolean(),
  name: string().required("Il nome è obbligatorio").default(""),
  description: string().required("La descrizione è obbligatoria").default(""),
  pointOfSaleId: string().when("creation", {
    is: true,
    then: string().required("Il punto vendita è obbligatorio"),
  }),
});
