import axios from "axios";
import { getUsersPath } from "../lib/white-cache";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import ExtraCalendarUser_interface from "../interfaces/ExtraCalendarUser_interface";
import { TurniExtraInterface } from "../interfaces/extra-turni-user";
import { Dayjs } from "dayjs";

const getTurniExtraShiftAndCalendarUser = async (
  userId: string,
  isSingleCalendarManagement: boolean
): Promise<[ExtraCalendarUser_interface[], ExtraCalendarUser_interface[]]> => {
  const getCalendarPointOfSale = async (
    userId: string
  ): Promise<ExtraCalendarUser_interface[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(`${getUsersPath()}/ExtraCalendarUser/User/${userId}`);
      return data;
    } catch (error) {
      return [];
    }
  };

  const getShiftPointOfSale = async (
    userId: string
  ): Promise<ExtraCalendarUser_interface[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(`${getUsersPath()}/ExtraShiftUser/User/${userId}`);
      return data;
    } catch (error) {
      return [];
    }
  };

  if (!userId) return [[], []];
  try {
    if (isSingleCalendarManagement) {
      const responses = await getCalendarPointOfSale(userId);
      return [[], responses] as any;
    }

    const responses = await Promise.all([
      getCalendarPointOfSale(userId),
      getShiftPointOfSale(userId),
    ]);

    return responses as any;
  } catch (err) {
    console.error(err);
    return [[], []];
  }
};

export const useNewTurniExtraUser = (
  userId: string,
  isSingleCalendarManagement: boolean = false,
  key: string = ""
) => {
  return useQuery({
    queryFn: async () =>
      await getTurniExtraShiftAndCalendarUser(
        userId,
        isSingleCalendarManagement
      ),
    queryKey: ["turni-extra-user", userId, isSingleCalendarManagement, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

/**
 *
 * @param data
 * @returns
 * ? extraHours -> { pointOfSaleId: { id: { id, pointOfSaleId, date, type, startTime1, endTime1, startTime2, endTime2 } } }
 * ? extraHoursController -> { pointOfSaleId: [dates, ...] } }
 */

const normalize = (data: any): any => {
  let result: TurniExtraInterface = {};

  try {
    const [calendar, shift] = data;

    for (const extra of calendar) {
      const date = dayjs(extra.startValidDate).format("YYYY-MM-DD");
      const pointOfSaleId = extra.pointOfSaleId;

      // ? Gestione extra
      if (!(pointOfSaleId in result)) result[pointOfSaleId] = {};
      result[pointOfSaleId] = {
        ...result[pointOfSaleId],
        [date]: {
          pointOfSaleId,
          date: dayjs(extra.startValidDate),
          extraCalendarType: extra.extraCalendarType,
          shift: {
            isClosed: false,
            startTime1: null,
            startTime2: null,
            endTime1: null,
            endTime2: null,
          },
          calendar: {
            id: extra.id,
            isClosed: extra.isClosingDay,
            startTime1:
              extra && extra?.startTime1
                ? dayjs(extra?.startTime1, "HH:mm:ss")
                : null,
            startTime2:
              extra && extra?.startTime2
                ? dayjs(extra?.startTime2, "HH:mm:ss")
                : null,
            endTime1:
              extra && extra?.endTime1
                ? dayjs(extra?.endTime1, "HH:mm:ss")
                : null,
            endTime2:
              extra && extra?.endTime2
                ? dayjs(extra?.endTime2, "HH:mm:ss")
                : null,
          },
        },
      };
    }

    for (const extra of shift) {
      const date = dayjs(extra.startValidDate).format("YYYY-MM-DD");
      const pointOfSaleId = extra.pointOfSaleId;

      // ? Gestione extra
      if (!(pointOfSaleId in result)) result[pointOfSaleId] = {};
      const temp: any = { ...result[pointOfSaleId] };
      if (!(date in temp)) {
        temp[date] = {
          pointOfSaleId,
          date: dayjs(extra.startValidDate),
          extraCalendarType: extra.extraCalendarType,
          shift: {
            isClosed: false,
            startTime1: null,
            startTime2: null,
            endTime1: null,
            endTime2: null,
          },
          calendar: {
            isClosed: true,
            startTime1: null,
            startTime2: null,
            endTime1: null,
            endTime2: null,
          },
        };
      }

      (temp[date] as any).shift = {
        id: extra.id,
        isClosed: extra.isClosingDay,
        startTime1:
          extra && extra?.startTime1
            ? dayjs(extra?.startTime1, "HH:mm:ss")
            : null,
        startTime2:
          extra && extra?.startTime2
            ? dayjs(extra?.startTime2, "HH:mm:ss")
            : null,
        endTime1:
          extra && extra?.endTime1 ? dayjs(extra?.endTime1, "HH:mm:ss") : null,
        endTime2:
          extra && extra?.endTime2 ? dayjs(extra?.endTime2, "HH:mm:ss") : null,
      };
      result[pointOfSaleId] = { ...temp };
    }
  } catch (err) {
    console.error("ERROR:", err);
  }

  const extrahours: any = {};

  for (const pointOfSaleId in result) {
    extrahours[pointOfSaleId] = {};

    const dates: any = Object.values(result[pointOfSaleId]);
    dates.sort((a: any, b: any) => a.date.unix() - b.date.unix());
    let key = nanoid();
    let nextDate: Dayjs | null = null;
    let prevCalendarType: number | null = null;
    let preValues: string | null = null;
    for (const date of dates) {
      if (!nextDate) {
        extrahours[pointOfSaleId][key] = {
          pointOfSaleId,
          startDate: date.date,
          endDate: date.date,
          extraCalendarType: date.extraCalendarType,
          shift: date.shift,
          calendar: date.calendar,
        };

        nextDate = date.date!.add(1, "day");
        prevCalendarType = date.extraCalendarType;
        preValues = `${date.shift.startTime1} - ${date.shift.endTime1} | ${date.shift.startTime2} - ${date.shift.endTime2} // ${date.calendar.startTime1} - ${date.calendar.endTime1} | ${date.calendar.startTime2} - ${date.calendar.endTime2}`;
        continue;
      }

      const values = `${date.shift.startTime1} - ${date.shift.endTime1} | ${date.shift.startTime2} - ${date.shift.endTime2} // ${date.calendar.startTime1} - ${date.calendar.endTime1} | ${date.calendar.startTime2} - ${date.calendar.endTime2}`;

      if (
        nextDate.isSame(date.date!, "day") &&
        date.extraCalendarType === prevCalendarType &&
        values === preValues
      ) {
        extrahours[pointOfSaleId][key].endDate = date.date!;
        nextDate = date.date!.add(1, "day");
        continue;
      }

      key = nanoid();
      extrahours[pointOfSaleId][key] = {
        pointOfSaleId,
        extraCalendarType: date.extraCalendarType,
        startDate: date.date,
        endDate: date.date,
        shift: date.shift,
        calendar: date.calendar,
      };

      nextDate = date.date!.add(1, "day");
      prevCalendarType = date.extraCalendarType;
      preValues = `${date.shift.startTime1} - ${date.shift.endTime1} | ${date.shift.startTime2} - ${date.shift.endTime2} // ${date.calendar.startTime1} - ${date.calendar.endTime1} | ${date.calendar.startTime2} - ${date.calendar.endTime2}`;
    }
  }

  return extrahours;
};
