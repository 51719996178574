import axios from "axios";
import { getPointOfSalePath } from "../lib/white-cache";
import { CalendarPointOfSaleInterface } from "../interfaces/calendar-point-of-sale";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { TurniExtraInterface } from "../interfaces/extra-turni-point-of-sale";
import { nanoid } from "nanoid";
import ExtraCalendarPointOfSale_interface from "../interfaces/ExtraCalendarPointOfSale_interface";
import { Dayjs } from "dayjs";

const getTurniExtraShiftAndCalendarPointOfSale = async (
  pointOfSaleId: string,
  isSingleCalendarManagement: boolean
): Promise<
  [CalendarPointOfSaleInterface[], CalendarPointOfSaleInterface[]]
> => {
  const getCalendarPointOfSale = async (
    pointOfSaleId: string
  ): Promise<ExtraCalendarPointOfSale_interface[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getPointOfSalePath()}/ExtraCalendarPointOfSale/PointOfSale/${pointOfSaleId}`
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  const getShiftPointOfSale = async (
    pointOfSaleId: string
  ): Promise<ExtraCalendarPointOfSale_interface[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getPointOfSalePath()}/ExtraShiftPointOfSale/PointOfSale/${pointOfSaleId}`
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  if (!pointOfSaleId) return [[], []];
  try {
    if (isSingleCalendarManagement) {
      const responses = await getCalendarPointOfSale(pointOfSaleId);
      return [[], responses] as any;
    }

    const responses = await Promise.all([
      getCalendarPointOfSale(pointOfSaleId),
      getShiftPointOfSale(pointOfSaleId),
    ]);

    return responses as any;
  } catch (err) {
    console.error(err);
    return [[], []];
  }
};

export const useTurniExtraPointOfSale = (
  pointOfSaleId: string,
  isSingleCalendarManagement: boolean = false,
  key: string = ""
) => {
  return useQuery({
    queryFn: async () =>
      await getTurniExtraShiftAndCalendarPointOfSale(
        pointOfSaleId,
        isSingleCalendarManagement
      ),
    queryKey: [
      "turni-extra-point-of-sale",
      pointOfSaleId,
      isSingleCalendarManagement,
      key,
    ],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: [any[], any[]]) => {
  let result: TurniExtraInterface = {};

  try {
    const [calendar, shift] = data;

    // Process calendar data
    calendar.forEach((extra) => {
      const date = dayjs(extra.startValidDate).format("YYYY-MM-DD");

      result[date] = {
        date: dayjs(extra.startValidDate),
        extraCalendarType: extra.extraCalendarType,
        shift: {
          isClosed: false,
          startTime1: null,
          startTime2: null,
          endTime1: null,
          endTime2: null,
        },
        calendar: {
          id: extra.id,
          isClosed: extra.isClosingDay,
          startTime1: extra.startTime1
            ? dayjs(extra.startTime1, "HH:mm:ss")
            : null,
          startTime2: extra.startTime2
            ? dayjs(extra.startTime2, "HH:mm:ss")
            : null,
          endTime1: extra.endTime1 ? dayjs(extra.endTime1, "HH:mm:ss") : null,
          endTime2: extra.endTime2 ? dayjs(extra.endTime2, "HH:mm:ss") : null,
        },
      };
    });

    // Process shift data
    shift.forEach((extra) => {
      const date = dayjs(extra.startValidDate).format("YYYY-MM-DD");

      if (!(date in result)) {
        result[date] = {
          date: dayjs(extra.startValidDate),
          extraCalendarType: extra.extraCalendarType,
          shift: {
            id: extra.id,
            isClosed: extra.isClosingDay,
            startTime1: extra.startTime1
              ? dayjs(extra.startTime1, "HH:mm:ss")
              : null,
            startTime2: extra.startTime2
              ? dayjs(extra.startTime2, "HH:mm:ss")
              : null,
            endTime1: extra.endTime1 ? dayjs(extra.endTime1, "HH:mm:ss") : null,
            endTime2: extra.endTime2 ? dayjs(extra.endTime2, "HH:mm:ss") : null,
          },
          calendar: {
            isClosed: true,
            startTime1: null,
            startTime2: null,
            endTime1: null,
            endTime2: null,
          },
        };
      } else {
        result[date].shift = {
          ...result[date].shift,
          id: extra.id,
          isClosed: extra.isClosingDay,
          startTime1: extra.startTime1
            ? dayjs(extra.startTime1, "HH:mm:ss")
            : null,
          startTime2: extra.startTime2
            ? dayjs(extra.startTime2, "HH:mm:ss")
            : null,
          endTime1: extra.endTime1 ? dayjs(extra.endTime1, "HH:mm:ss") : null,
          endTime2: extra.endTime2 ? dayjs(extra.endTime2, "HH:mm:ss") : null,
        };
      }
    });
  } catch (err) {
    console.error("ERROR:", err);
  }

  const extrahours: any = {};

  const dates: any = Object.values(result);
  dates.sort((a: any, b: any) => a.date.unix() - b.date.unix());
  let key = nanoid();
  let nextDate: Dayjs | null = null;
  let prevCalendarType: number | null = null;
  let preValues: string | null = null;
  for (const date of dates) {
    if (!nextDate) {
      extrahours[key] = {
        startDate: date.date,
        endDate: date.date,
        extraCalendarType: date.extraCalendarType,
        shift: date.shift,
        calendar: date.calendar,
      };

      nextDate = date.date!.add(1, "day");
      prevCalendarType = date.extraCalendarType;
      preValues = `${date.shift.startTime1} - ${date.shift.endTime1} | ${date.shift.startTime2} - ${date.shift.endTime2} // ${date.calendar.startTime1} - ${date.calendar.endTime1} | ${date.calendar.startTime2} - ${date.calendar.endTime2}`;
      continue;
    }

    const values = `${date.shift.startTime1} - ${date.shift.endTime1} | ${date.shift.startTime2} - ${date.shift.endTime2} // ${date.calendar.startTime1} - ${date.calendar.endTime1} | ${date.calendar.startTime2} - ${date.calendar.endTime2}`;

    if (
      nextDate.isSame(date.date!, "day") &&
      date.extraCalendarType === prevCalendarType &&
      values === preValues
    ) {
      extrahours[key].endDate = date.date!;
      nextDate = date.date!.add(1, "day");
      continue;
    }

    key = nanoid();
    extrahours[key] = {
      extraCalendarType: date.extraCalendarType,
      startDate: date.date,
      endDate: date.date,
      shift: date.shift,
      calendar: date.calendar,
    };

    nextDate = date.date!.add(1, "day");
    prevCalendarType = date.extraCalendarType;
    preValues = `${date.shift.startTime1} - ${date.shift.endTime1} | ${date.shift.startTime2} - ${date.shift.endTime2} // ${date.calendar.startTime1} - ${date.calendar.endTime1} | ${date.calendar.startTime2} - ${date.calendar.endTime2}`;
  }

  return extrahours;
};
