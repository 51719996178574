import { object, string } from "yup";
import "yup-phone-lite";

export const schema = object().shape({
  serviceId: string(),
  pointOfSaleId: string(),
  userId: string(),
  studioId: string(),

  // name: string(),
  // surname: string(),
  // email: string().email("Email non valida"),
  // telephone: string().phone("IT", "Telefono non valido"),
  description: string().required().max(512, "Il testo risulta troppo lungo"),
});
