import { BluSelect } from "@bludata/components";
import { Stack, InputLabel, Box, MenuItem } from "@mui/material";
import { FC, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../../../../store";
import { setDocumentFormat } from "../../../../store/import";

interface DocumentFormatInterface {}

const documentFormats = [
  {
    label: "Bludata (XLXS)",
    value: "bludata",
  },
  {
    label: "Factorial (CSV)",
    value: "factorial",
  },
];

export const DocumentFormatImport: FC<DocumentFormatInterface> = memo(() => {
  const { documentFormat } = useSelector(
    (state: RootState) => state.rotaImport
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleOnChange = useCallback(
    (event: any) => {
      dispatch(setDocumentFormat(event.target.value));
    },
    [dispatch]
  );

  return (
    <Stack sx={{ pt: 1 }}>
      <InputLabel>Formato documento</InputLabel>

      <BluSelect
        value={documentFormat}
        onChange={handleOnChange}
        renderValue={(selected: any) => {
          const find = documentFormats.find((d: any) => d.value === selected);
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {find?.label ?? ""}
            </Box>
          );
        }}
      >
        {documentFormats.map((format) => (
          <MenuItem key={format.value} value={format.value}>
            {format.label}
          </MenuItem>
        ))}
      </BluSelect>
    </Stack>
  );
});
