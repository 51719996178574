import axios from "axios";
import { CalendarPointOfSaleInterface } from "../interfaces/calendar-point-of-sale";
import { ExtraCalendarPointOfSaleInterface } from "../interfaces/extra-calendar-point-of-sale";
import { HolidayInterface } from "../interfaces/holiday";
import { getRotaPath } from "../lib/white-cache";

export const getShiftPointOfSale = async (
  pointOfSaleId: string,
  startTime: string,
  endTime: string
): Promise<
  [CalendarPointOfSaleInterface[], ExtraCalendarPointOfSaleInterface[]]
> => {
  const fetchCalendarPointOfSale = async (): Promise<
    CalendarPointOfSaleInterface[]
  > => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getRotaPath()}/ShiftPointOfSale/PointOfSale/${pointOfSaleId}`,
        {
          params: {
            startTime,
            endTime,
          },
        }
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  const fetchExtraCalendarPointOfSale = async (): Promise<
    ExtraCalendarPointOfSaleInterface[]
  > => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getRotaPath()}/ExtraShiftPointOfSale/PointOfSale/${pointOfSaleId}`,
        {
          params: {
            startTime,
            endTime,
          },
        }
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  const fetchHoliday = async (): Promise<HolidayInterface[]> => {
    try {
      const {
        data: { data },
      } = await axios.post(`${getRotaPath()}/Holiday/Range`, {
        startDate: startTime,
        endDate: endTime,
      });
      return data;
    } catch (error) {
      return [];
    }
  };

  try {
    const responses = await Promise.all([
      fetchCalendarPointOfSale(),
      fetchExtraCalendarPointOfSale(),
    ]);

    return responses as any;
  } catch (error) {
    return [[], []];
  }
};
