import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ThreadRunsMessage } from '../interfaces/thread-runs'

export const getThreadRun = async (
  openAiThreadId: string
): Promise<ThreadRunsMessage[]> => {
  try {
    const {
      data: { data }
    } = await axios.get(`/Thread/${openAiThreadId}/Runs`)

    return data.map((assistant: any) => {
      return { ...assistant, updatedAt: new Date(assistant.updatedAt) }
    })
  } catch (error) {
    console.log(error)
    return []
  }
}

export const useThreadRun = (
  streamMessage: boolean,
  openAiThreadId: string,
  key: string = ''
) => {
  return useQuery({
    queryFn: async () => await getThreadRun(openAiThreadId),
    queryKey: ['thread-run', openAiThreadId, key],
    retry: 2,

    retryDelay: 180000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: streamMessage,
    cacheTime: 1000,
    staleTime: 1000,
    refetchInterval: 1000
    // select: normalize,
  })
}
