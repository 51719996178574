import { Stack, Box, Typography, useTheme } from "@mui/material";
import { FC, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface PointOfSaleRotaHeaderInterface {
  isSingleCalendarManagement: boolean;
}

export const PointOfSaleRotaHeader: FC<PointOfSaleRotaHeaderInterface> = memo(
  ({ isSingleCalendarManagement }) => {
    const { turniFilter } = useSelector((state: RootState) => state.rotaRoot);
    const theme = useTheme();

    const GetTurni = useMemo(() => {
      return (
        <Box
          sx={{
            height: "70px",
            boxSizing: "border-box",
            borderTop: "1px solid var(--divider-color)",
          }}
        >
          <Typography sx={{ pt: "25px", pl: "10px", color: "var(--color)" }}>
            Turni
          </Typography>
        </Box>
      );
    }, []);

    const GetShift = useMemo(() => {
      if (turniFilter === "calendar") return <></>;
      return (
        <Box
          sx={{
            height: "70px",
            boxSizing: "border-box",
            borderTop: "1px solid var(--divider-color)",
          }}
        >
          <Typography sx={{ pt: "25px", pl: "10px", color: "var(--color)" }}>
            Lavoro
          </Typography>
        </Box>
      );
    }, [turniFilter]);

    const GetCalendar = useMemo(() => {
      if (turniFilter === "shift") return <></>;
      return (
        <Box
          sx={{
            height: "70px",
            boxSizing: "border-box",
            borderTop: "1px solid var(--divider-color)",
          }}
        >
          <Typography sx={{ pt: "25px", pl: "10px", color: "var(--color)" }}>
            Appuntamenti
          </Typography>
        </Box>
      );
    }, [turniFilter]);

    return (
      <Stack
        sx={{
          width: "270px",
          position: "sticky",
          left: 0,
          backgroundColor: "var(--base-foreground-color)",
          zIndex: 1,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "var(--base-foreground-color)",
            boxSizing: "border-box",
            // zIndex: 1,
            border: "1px solid var(--divider-color)",

            borderRadius: "8px 0 0 8px",
            // pb: "1px",
          }}
        >
          <Box
            sx={{
              height: "39px",
              backgroundColor:
                theme?.palette?.mode === "dark"
                  ? "var(--background-color) !important"
                  : "#f0f0f0 !important",
            }}
          ></Box>
          {!isSingleCalendarManagement ? (
            <>
              {GetShift}
              {GetCalendar}
            </>
          ) : (
            <>{GetTurni}</>
          )}
        </Stack>
      </Stack>
    );
  }
);
