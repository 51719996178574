import { CalendarPointOfSaleInterface } from "../interfaces/calendar-point-of-sale";
import { CalendarUserItemInterface } from "../interfaces/calendar-user";
import { TurniItemInterface } from "../interfaces/point-of-sale-turni";

export const checkTurnoAlreadySet = (
  turno: TurniItemInterface | null,
  newValue: CalendarPointOfSaleInterface | CalendarUserItemInterface,
  isClosed: boolean
): {
  startTime1: string | null;
  startTime2: string | null;
  endTime1: string | null;
  endTime2: string | null;
} => {
  if (turno === null || isClosed)
    return {
      startTime1: (newValue?.startTime1 || "").slice(0, -3),
      startTime2: (newValue?.startTime2 || "").slice(0, -3),
      endTime1: (newValue?.endTime1 || "").slice(0, -3),
      endTime2: (newValue?.endTime2 || "").slice(0, -3),
    };
  let result = { ...turno };
  if (
    result.startTime1 === null &&
    result.endTime1 === null &&
    newValue.startTime1 !== null &&
    newValue.endTime1 !== null
  ) {
    result = {
      ...result,

      startTime1: (turno?.startTime1 || "").slice(0, -3),
      endTime1: (turno?.endTime1 || "").slice(0, -3),
    };
  }

  if (
    result.startTime2 === null &&
    result.endTime2 === null &&
    newValue.startTime2 !== null &&
    newValue.endTime2 !== null
  ) {
    result = {
      ...result,
      startTime2: (turno?.startTime2 || "").slice(0, -3),
      endTime2: (turno?.endTime2 || "").slice(0, -3),
    };
  }

  return result;
};
