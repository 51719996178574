import moment from "moment";

import { Dayjs } from "dayjs";
import {
  TurniExtraItemInterface,
  TurniExtraPointOfSaleInterface,
} from "../interfaces/extra-turni-user";

export const checkAllExtraHours = (
  extraHours: TurniExtraPointOfSaleInterface
): any => {
  const errorDefaultHours: {
    [key: string]: {
      shift: {
        range1: boolean;
        range2: boolean;
      };
      calendar: {
        range1: boolean;
        range2: boolean;
      };
    };
  } = {};

  const turniType: any = ["calendar", "shift"];
  for (const extra of Object.keys(extraHours)) {
    errorDefaultHours[extra] = {
      shift: {
        range1: false,
        range2: false,
      },
      calendar: {
        range1: false,
        range2: false,
      },
    };
    for (const turno of turniType) {
      (errorDefaultHours as any)[extra][turno].range1 = checkextraHoursValue(
        1,
        turno,
        extraHours[extra]
      );
      (errorDefaultHours as any)[extra][turno].range2 = checkextraHoursValue(
        2,
        turno,
        extraHours[extra]
      );
    }
  }

  return errorDefaultHours;
};

export const checkextraHoursValue = (
  rangeNumber: 1 | 2,
  turniType: "calendar" | "shift",
  hours: {
    date?: Dayjs;
    shift: TurniExtraItemInterface | null;
    calendar: TurniExtraItemInterface | null;
  }
): boolean => {
  let defaultHours: any = hours;

  const range = defaultHours[turniType];

  // ? CHECK RANGE NULL
  if (!range) return false;

  // ? SET RANGESTART AND RANGEEND
  let rangeStart = null;
  let rangeEnd = null;
  if (`startTime${rangeNumber}` in range && range[`startTime${rangeNumber}`])
    rangeStart = moment(range[`startTime${rangeNumber}`].toDate());
  if (`endTime${rangeNumber}` in range && range[`endTime${rangeNumber}`])
    rangeEnd = moment(range[`endTime${rangeNumber}`].toDate());

  // ? CHECK RANGESTART OR RANGEEND NULL
  if (!rangeStart && !rangeEnd) return false;
  if ((rangeStart && !rangeEnd) || (!rangeStart && rangeEnd)) return true;

  // ? CHEK IF RANGE IS VALID
  if (rangeStart!!.isSameOrAfter(rangeEnd)) return true;

  let otherRangeNumber = rangeNumber === 1 ? 2 : 1;
  let otherRangeStart = null;
  let otherRangeEnd = null;
  if (
    `startTime${otherRangeNumber}` in range &&
    range[`startTime${otherRangeNumber}`]
  )
    otherRangeStart = moment(range[`startTime${otherRangeNumber}`].toDate());
  if (
    `endTime${otherRangeNumber}` in range &&
    range[`endTime${otherRangeNumber}`]
  )
    otherRangeEnd = moment(range[`endTime${otherRangeNumber}`].toDate());

  if (otherRangeStart && otherRangeEnd) {
    if (
      otherRangeStart.isBetween(rangeStart, rangeEnd, "minutes", "[)") ||
      otherRangeEnd.isBetween(rangeStart, rangeEnd, "minutes", "(]")
    )
      return true;
  }

  // ? IF SHIFT RETURN
  if (turniType === "shift") return false;

  const rangeShift = defaultHours["shift"];
  if (!rangeShift) return true;

  let rangeShiftStart1 = null;
  let rangeShiftEnd1 = null;
  let rangeShiftStart2 = null;
  let rangeShiftEnd2 = null;
  if (`startTime1` in rangeShift && rangeShift[`startTime1`])
    rangeShiftStart1 = moment(rangeShift[`startTime1`].toDate());
  if (`startTime2` in rangeShift && rangeShift[`startTime2`])
    rangeShiftStart2 = moment(rangeShift[`startTime2`].toDate());

  if (`endTime1` in rangeShift && rangeShift[`endTime1`])
    rangeShiftEnd1 = moment(rangeShift[`endTime1`].toDate());
  if (`endTime2` in rangeShift && rangeShift[`endTime2`])
    rangeShiftEnd2 = moment(rangeShift[`endTime2`].toDate());
  if (
    rangeShiftStart1 === null &&
    rangeShiftStart2 === null &&
    rangeShiftEnd1 === null &&
    rangeShiftEnd2 === null
  )
    return false;
  if (rangeShiftStart1 && rangeShiftEnd1) {
    if (
      rangeStart!!.isBetween(
        rangeShiftStart1,
        rangeShiftEnd1,
        "minutes",
        "[)"
      ) &&
      rangeEnd!!.isBetween(rangeShiftStart1, rangeShiftEnd1, "minutes", "(]")
    )
      return false;
  }
  if (rangeShiftStart2 && rangeShiftEnd2) {
    if (
      rangeStart!!.isBetween(
        rangeShiftStart2,
        rangeShiftEnd2,
        "minutes",
        "[)"
      ) &&
      rangeEnd!!.isBetween(rangeShiftStart2, rangeShiftEnd2, "minutes", "(]")
    )
      return false;
  }

  return true;
};
