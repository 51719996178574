import { Stack, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

export const UploadError = memo(() => {
  const { importCheck, documentFormat } = useSelector(
    (state: RootState) => state.rotaImport
  );

  const isServerError = useMemo(() => {
    if (importCheck) {
      return (
        <Stack>
          <Typography>
            Abbiamo riscontrato alcuni errori nel file caricato:
          </Typography>
          <Stack sx={{ pl: "10px" }}>
            {importCheck?.pointOfSaleError && (
              <Stack direction="row">
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    clipPath: "circle(10px)",
                    backgroundColor: "var(--color)",
                    borderRadius: "50%",
                    marginRight: "5px",
                    marginTop: "7px",
                  }}
                />

                <Typography>
                  gli orari del punto vendita non sono corretti;
                </Typography>
              </Stack>
            )}
            {(importCheck?.rowsErrors ?? []).length > 0 && (
              <Stack direction="row">
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    clipPath: "circle(10px)",
                    backgroundColor: "var(--color)",
                    borderRadius: "50%",
                    marginRight: "5px",
                    marginTop: "7px",
                  }}
                />
                {(importCheck?.rowsErrors ?? []).length === 1 ? (
                  <Typography>
                    il documento presenta errori di formattazione (nella righa:{" "}
                    {(importCheck?.rowsErrors ?? []).join("")});
                  </Typography>
                ) : (
                  <Typography>
                    il documento presenta errori di formattazione (nelle righe:{" "}
                    {(importCheck?.rowsErrors ?? []).join(", ")});
                  </Typography>
                )}
              </Stack>
            )}
            {(importCheck?.pointOfSaleNotMapped ?? []).length > 0 && (
              <Stack direction="row">
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    clipPath: "circle(10px)",
                    backgroundColor: "var(--color)",
                    borderRadius: "50%",
                    marginRight: "5px",
                    marginTop: "7px",
                  }}
                />
                {(importCheck?.pointOfSaleNotMapped ?? []).length === 1 ? (
                  <Typography>
                    non esiste un punto vendita con la sigla{" "}
                    {(importCheck?.pointOfSaleNotMapped ?? []).join("")};
                  </Typography>
                ) : (
                  <Typography>
                    non esistono punti vendita con queste sigle:{" "}
                    {(importCheck?.pointOfSaleNotMapped ?? []).join(", ")};
                  </Typography>
                )}
              </Stack>
            )}
            {(importCheck?.usersError ?? []).length > 0 && (
              <Stack direction="row">
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    clipPath: "circle(10px)",
                    backgroundColor: "var(--color)",
                    borderRadius: "50%",
                    marginRight: "5px",
                    marginTop: "7px",
                  }}
                />
                {(importCheck?.usersError ?? []).length === 1 ? (
                  <Typography>
                    l'utente {(importCheck?.usersError ?? []).join("")} presenta
                    errori nel calendario;
                  </Typography>
                ) : (
                  <Typography>
                    i seguenti utenti presentano errori nel calendario:{" "}
                    {(importCheck?.usersError ?? []).join(", ")};
                  </Typography>
                )}
              </Stack>
            )}
            {(importCheck?.userNotMapped ?? []).length > 0 && (
              <Stack direction="row">
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    clipPath: "circle(10px)",
                    backgroundColor: "var(--color)",
                    borderRadius: "50%",
                    marginRight: "5px",
                    marginTop: "7px",
                  }}
                />
                {(importCheck?.userNotMapped ?? []).length === 1 ? (
                  <Typography>
                    non esiste un utente con username{" "}
                    {(importCheck?.userNotMapped ?? []).join("")};
                  </Typography>
                ) : (
                  <Typography>
                    non esistono utenti con questi username:{" "}
                    {(importCheck?.userNotMapped ?? []).join(", ")};
                  </Typography>
                )}
              </Stack>
            )}
            {(importCheck?.usersNotMapped ?? []).length > 0 && (
              <Stack direction="row">
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    clipPath: "circle(10px)",
                    backgroundColor: "var(--color)",
                    borderRadius: "50%",
                    marginRight: "5px",
                    marginTop: "7px",
                  }}
                />
                {(importCheck?.usersNotMapped ?? []).length === 1 ? (
                  <Typography>
                    non esiste un utente con username{" "}
                    {(importCheck?.usersNotMapped ?? []).join("")};
                  </Typography>
                ) : (
                  <Typography>
                    non esistono utenti con questi username:{" "}
                    {(importCheck?.usersNotMapped ?? []).join(", ")};
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
          {documentFormat === "factorial" && (
            <Typography>
              È comunque possibile importare i calendari, le righe con errori di
              formattazione verranno ignorate.
            </Typography>
          )}
        </Stack>
      );
    }
    return (
      <Stack>
        <Typography>
          Il file caricato non è valido. Controlla la formattazione interna del
          file.
        </Typography>
        {documentFormat === "factorial" && (
          <Typography>
            È comunque possibile importare i calendari, le righe con errori di
            formattazione verranno ignorate.
          </Typography>
        )}
      </Stack>
    );
  }, [documentFormat, importCheck]);

  return (
    <Stack flex="1">
      <Stack sx={{ pt: "20px" }}>
        <Stack direction="row" justifyContent="center">
          <WarningAmberIcon
            color="warning"
            sx={{ fontSize: "80px", m: "auto", p: "20px 0 10px 0" }}
          />
        </Stack>
        <Typography
          fontWeight="bold"
          align="center"
          fontSize="20px"
          sx={{ p: "0px 0 5px 0" }}
        >
          Attenzione
        </Typography>
      </Stack>

      <>{isServerError}</>
    </Stack>
  );
});
