import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShopSignInterface } from "../../../interfaces/shop-sign";

export interface RootSliceState {
  mode: "edit" | "create" | "view";
  shopSignId?: string;
  prevShopSignId?: string;
  emailValid: boolean;
  pecValid: boolean;
  deleteLayout?: string;
  loadLayout: boolean;
  isSaveLayout?: boolean;
  shopSigns?: ShopSignInterface[];
}

const initialState: RootSliceState = {
  mode: "view",
  emailValid: false,
  pecValid: false,
  isSaveLayout: false,
  loadLayout: true,
};

const LayoutRootSlice = createSlice({
  name: "layoutRoot",
  initialState,
  reducers: {
    setMode(
      state: RootSliceState,
      action: PayloadAction<"edit" | "create" | "view">
    ) {
      state.mode = action.payload;
    },
    setShopSignId(
      state: RootSliceState,
      action: PayloadAction<string | undefined>
    ) {
      state.prevShopSignId = state.shopSignId;
      state.shopSignId = action.payload;
    },

    setEmailValid(state: RootSliceState, action: PayloadAction<boolean>) {
      if (state.emailValid !== action.payload)
        state.emailValid = action.payload;
    },
    setPecValid(state: RootSliceState, action: PayloadAction<boolean>) {
      if (state.pecValid !== action.payload) state.pecValid = action.payload;
    },
    createNew(state: RootSliceState) {
      return {
        ...state,
        mode: "create",
        prevShopSignId: state.shopSignId,
        shopSignId: undefined,
      };
    },
    setDeleteLayout(
      state: RootSliceState,
      action: PayloadAction<string | undefined>
    ) {
      state.deleteLayout = action.payload;
    },
    setLoadLayout(state: RootSliceState, action: PayloadAction<boolean>) {
      if (state.loadLayout !== action.payload)
        state.loadLayout = action.payload;
    },
    setShopSigns(
      state: RootSliceState,
      action: PayloadAction<ShopSignInterface[]>
    ) {
      state.shopSigns = action.payload;
    },
    setIsSaveLayout(state: RootSliceState, action: PayloadAction<boolean>) {
      state.isSaveLayout = action.payload;
    },
  },
});

export const {
  createNew,
  setMode,
  setEmailValid,
  setPecValid,
  setShopSignId,
  setDeleteLayout,
  setLoadLayout,
  setShopSigns,
  setIsSaveLayout,
} = LayoutRootSlice.actions;

export default LayoutRootSlice.reducer;
