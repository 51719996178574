import { BluColorPicker, BluSelect } from "@bludata/components";
import { Checkbox, MenuItem, Stack, Typography } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { FC } from "react";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { TestFont } from "./test";
import { setFontFamily } from "../../store/font";
import { setPaletteValue } from "../../store/palette";

interface GenericTextInterface {
  disabled: boolean;
}

export const GenericText: FC<GenericTextInterface> = memo(({ disabled }) => {
  const font = useSelector((state: RootState) => state.layoutFont);
  const palette = useSelector((state: RootState) => state.layoutPalette);

  const fontFamily = [
    "Roboto",
    "sans-serif",
    "Poppins",
    "Helvetica",
    "Arial",
    "Comfortaa",
    "Montserrat",
    "Noto Sans Georgian",
    "Source Sans Pro",
    "Work Sans",
  ];
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      <Stack direction={"row"} spacing={2}>
        <Stack display="flex" flex="1" sx={{ pr: "10px" }}>
          <Typography>Carattere</Typography>
          <BluSelect
            disabled={disabled}
            multiple
            value={font.fontFamily}
            onChange={(event: any) =>
              dispatch(setFontFamily(event.target.value))
            }
            renderValue={(selected: any) => <>{selected.toString()}</>}
          >
            {fontFamily.map((f) => (
              <MenuItem value={f} key={nanoid()}>
                <Checkbox
                  checked={
                    font.fontFamily.find((fo: string) => fo === f) !== undefined
                  }
                  style={{ padding: 0, paddingRight: "5px" }}
                />
                <Typography>{f}</Typography>
              </MenuItem>
            ))}
            <MenuItem value={undefined} hidden></MenuItem>
          </BluSelect>
          <Stack
            gap="2%"
            flexWrap="wrap"
            sx={{ width: "100%", pt: "10px" }}
            flexDirection="unset"
          >
            <Stack flex="1">
              <Typography>Testo</Typography>
              <BluColorPicker
                disabled={disabled}
                value={palette?.text.primary ?? ""}
                onChange={(args: any) =>
                  dispatch(
                    setPaletteValue({
                      text: {
                        ...palette.text,
                        primary: args.currentValue.hex,
                      },
                    })
                  )
                }
              />
            </Stack>
            <Stack flex="1">
              <Typography>Testo disabilitato</Typography>
              <BluColorPicker
                disabled={disabled}
                value={palette?.text?.disabled ?? ""}
                onChange={(args: any) =>
                  dispatch(
                    setPaletteValue({
                      text: {
                        ...palette?.text,
                        disabled: args.currentValue.hex,
                      },
                    })
                  )
                }
              />
            </Stack>
          </Stack>
          <Stack
            gap="2%"
            flexWrap="wrap"
            sx={{ width: "100%" }}
            flexDirection="unset"
          >
            <Stack flex="1">
              <Typography>Testo piè di pagina</Typography>
              <BluColorPicker
                disabled={disabled}
                value={palette?.footer.text ?? ""}
                onChange={(args: any) =>
                  dispatch(
                    setPaletteValue({
                      footer: {
                        ...palette?.footer,
                        text: args.currentValue.hex,
                      },
                    })
                  )
                }
              />
            </Stack>
            <Stack flex="1"></Stack>
          </Stack>
        </Stack>

        <Stack display="flex" flex="1.5">
          <TestFont />
        </Stack>
      </Stack>
    </>
  );
});
