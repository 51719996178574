import { BluTitle } from "@bludata/components";
import { Stack } from "@mui/material";
import { FC, memo } from "react";
import { CustomCheckbox } from "../../../../../nuova-point-of-sales/components/PopUp/common/CustomCheckBox";
import { ImoTipoUtente } from "../../components/ImoTipoUtente/ImoTipoUtente";
import { ImoCodiceLayout } from "../../components/ImoCodiceLayout/ImoCodiceLayout";

export const AppSelection: FC<{
  mode: "modify" | "info" | "new";
}> = memo(({ mode }) => {
  // const isIMOActive = useWatch({ name: "isIMOActive" });
  return (
    <Stack flex="1" gap={1}>
      <BluTitle text={"Sezione APP"} />
      <CustomCheckbox
        name="isShowChatName"
        label="Visualizza nome in chat"
        disabled={mode === "info"}
        // width="90px"
      />
      <Stack flex="1" direction="row" gap={1}>
        <ImoTipoUtente disabled={mode === "info"} />
        <ImoCodiceLayout disabled={mode === "info"} />
      </Stack>
    </Stack>
  );
});
