import { Stack, Typography } from "@mui/material";
import { memo } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const ExportError = memo(() => {
  return (
    <Stack flex="1">
      <Stack sx={{ pt: "20px" }}>
        <Stack direction="row" justifyContent="center">
          <WarningAmberIcon
            color="warning"
            sx={{ fontSize: "80px", m: "auto", p: "20px 0 10px 0" }}
          />
        </Stack>
        <Typography
          fontWeight="bold"
          align="center"
          fontSize="20px"
          sx={{ p: "0px 0 5px 0" }}
        >
          Attenzione
        </Typography>
      </Stack>

      <Typography>Errore durante l'esportazione del calendario.</Typography>
    </Stack>
  );
});
