import { Stack } from "@mui/material";
import { FC, memo } from "react";
import { Test } from "./test";
import { ConfigButton } from "./button";

interface LayoutButtonInterface {
  disabled: boolean;
}

export const LayoutButton: FC<LayoutButtonInterface> = memo(({ disabled }) => {
  return (
    <Stack direction={"row"} spacing={2}>
      <Stack display="flex" flex="1" sx={{ pr: "10px" }}>
        <Stack
          gap="2%"
          flexWrap="wrap"
          sx={{ width: "100%" }}
          flexDirection="unset"
        >
          <Stack flex="1">
            <ConfigButton disabled={disabled} />
          </Stack>
        </Stack>
      </Stack>
      <Stack display="flex" flex="1.5">
        <Test />
      </Stack>
    </Stack>
  );
});
