import { PointOfSalesInterface } from "../../../interfaces/PointOfSale_interface";

export const defaultPointOfSaleFw: PointOfSalesInterface = {
  id: null,
  pointOfSaleId: null,
  accountingCode: "",
  address: "",
  address2: "",
  blubookingId: "",
  blubookingLayoutId: "",
  branchName: "",
  businessName: "",
  city: "",
  closeDate: null,
  codiceFiliale: "",
  crmActivationType: "",
  crmCodiceLayout: "",
  crmId: null,
  crmLayoutId: null,
  email: "",
  fax: "",
  filiale: "",
  focusCodiceAzienda: "",
  focusCodiceIstitutoCredito: "",
  focusCodiceLaboratorio: "",
  focusCodiceLaboratorioEsterno: "",
  focusId: 0,
  info1: "",
  info2: "",
  internalCode: "",
  internalLaboratory: false,
  isActive: false,
  isBluBookingActive: false,
  isCorporate: null,
  isDoNotDisplay: false,
  isFocusPlusActive: false,
  isSede: false,
  latitude: 0,
  longitude: 0,
  ministryNumber: "",
  nameOnline: "",
  notes: "",
  openDate: null,
  postalCode: "",
  region: "",
  remote: false,
  salesAreaSquareMeters: null,
  sign: "",
  smsSender: "",
  squareMeters: null,
  taxCode: "",
  telephone: "",
  vatNumber: "",
  warehouse: null,
};
