import { BluLoader } from "@bludata/components";
import { memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

export const ServiceFormLoader = memo(() => {
  const { serviceFormLoader } = useSelector(
    (state: RootState) => state.serviceForm
  );

  return (
    <BluLoader
      open={serviceFormLoader}
      zIndex={400}
      sx={{ zIndex: 400 }}
      CircularProps={{
        sx: { zIndex: 400 },
      }}
    />
  );
});
