import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { LoginDataInterface } from "../interfaces/loginData";

export const getUserMe = async (
  isFocusWeb: boolean
): Promise<LoginDataInterface | null> => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${isFocusWeb ? "/diaryapi" : "/api"}/User/Me/LoginData`
    );
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const useUserMe = (isFocusWeb: boolean, key: string = "") => {
  return useQuery({
    queryFn: async () => await getUserMe(isFocusWeb),
    queryKey: ["user", "me", "LoginData", isFocusWeb, key],
    retry: 2,
    retryDelay: 180000,
    staleTime: 86400000,
    cacheTime: 86400000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
