import { EnableCell } from "../../utils/enable-cell";
import { EnableHeader } from "../../utils/enable-header";

export const Columns: any[] = [
  {
    field: "id",
    hide: true,
  },
  {
    field: "enable",
    headerName: "",
    renderCell: EnableCell,
    // renderHeader: EnableHeader,
    renderHeader: () => EnableHeader({ key: "usersForm" }),
    width: 30,
    disableReorder: true,
    align: "center",
    sortable: false,
  },
  {
    field: "username",
    headerName: "Utente",
    flex: 1,
    editable: false,
  },
  {
    field: "name",
    headerName: "Nome",
    flex: 1,
    editable: false,
  },
  {
    field: "surname",
    headerName: "Cognome",
    flex: 1,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 2,
    editable: false,
  },
];
