import { TurniExtraInterface } from "../../../interfaces/extra-turni-point-of-sale";

/**
 *
 * @param userId
 * @param extraHours
 * @param isSingleCalendarManagement
 * @returns [createExtraShiftPointOfSales, createExtraCalendarPointOfSales]
 *
 * ? Nel caso in cui ci sia più volte lo stesso giorno viene preso il primo che ha extraCalendarType = 0
 */
export const parseExtraHours = (
  extraHours: TurniExtraInterface | null,
  isSingleCalendarManagement: boolean,
  pointOfSaleId?: string
) => {
  let createExtraShiftPointOfSales: any = [];
  let createExtraCalendarPointOfSales: any = [];

  if (!extraHours)
    return [createExtraShiftPointOfSales, createExtraCalendarPointOfSales];

  const pointOfSaleExtraHours: any = extraHours;

  const map = new Map();
  for (const pointOfSaleExtraHourKey in pointOfSaleExtraHours) {
    let startDate = pointOfSaleExtraHours[pointOfSaleExtraHourKey].startDate
      ?.clone()
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);
    let endDate = pointOfSaleExtraHours[pointOfSaleExtraHourKey].endDate
      ?.clone()
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);

    while (!startDate?.isAfter(endDate)) {
      const key = startDate?.format("YYYY-MM-DD");

      if ((map.get(key) ?? { isClosed: true })?.isClosed) {
        if (
          !map.has(key) ||
          !pointOfSaleExtraHours[pointOfSaleExtraHourKey]?.isClosed
        ) {
          map.set(key, {
            ...pointOfSaleExtraHours[pointOfSaleExtraHourKey],
            date: startDate,
            startDate: undefined,
            endDate: undefined,
            key: pointOfSaleExtraHourKey,
          });
        }
      }

      startDate = startDate.add(1, "day");
    }
  }

  const pointOfSaleExtraHoursArray = Array.from(map.values());

  for (const extraHour of pointOfSaleExtraHoursArray) {
    const { shift, calendar } = extraHour;
    const formattedDate = extraHour.date.format("YYYY-MM-DD");

    createExtraShiftPointOfSales.push({
      pointOfSaleId,
      isClosingDay: shift?.isClosed ?? false,
      startTime1:
        shift !== null && shift.startTime1 && shift.startTime1.isValid()
          ? shift.startTime1.format("HH:mm")
          : null,
      startTime2:
        shift !== null && shift.startTime2 && shift.startTime2.isValid()
          ? shift.startTime2.format("HH:mm")
          : null,
      endTime1:
        shift !== null && shift.endTime1 && shift.endTime1.isValid()
          ? shift.endTime1.format("HH:mm")
          : null,
      endTime2:
        shift !== null && shift.endTime2 && shift.endTime2.isValid()
          ? shift.endTime2.format("HH:mm")
          : null,
      startValidDate: formattedDate,
      endValidDate: formattedDate,
      extraCalendarType: extraHour.extraCalendarType,
    });
    if (!isSingleCalendarManagement)
      createExtraCalendarPointOfSales.push({
        pointOfSaleId,
        isClosingDay: calendar?.isClosed ?? false,
        startTime1:
          calendar !== null &&
          calendar.startTime1 &&
          calendar.startTime1.isValid()
            ? calendar.startTime1.format("HH:mm")
            : null,
        startTime2:
          calendar !== null &&
          calendar.startTime2 &&
          calendar.startTime2.isValid()
            ? calendar.startTime2.format("HH:mm")
            : null,
        endTime1:
          calendar !== null && calendar.endTime1 && calendar.endTime1.isValid()
            ? calendar.endTime1.format("HH:mm")
            : null,
        endTime2:
          calendar !== null && calendar.endTime2 && calendar.endTime2.isValid()
            ? calendar.endTime2.format("HH:mm")
            : null,
        startValidDate: formattedDate,
        endValidDate: formattedDate,
        extraCalendarType: extraHour.extraCalendarType,
      });
  }

  if (isSingleCalendarManagement)
    return [createExtraCalendarPointOfSales, createExtraShiftPointOfSales];

  return [createExtraShiftPointOfSales, createExtraCalendarPointOfSales];
};
