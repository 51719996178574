import { FC, memo, useCallback, useMemo, useState } from "react";
import {
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
} from "@mui/material";
import { TurniItemInterface } from "../../../../interfaces/default-turni-user";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppDispatch, RootState } from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { setCopiedDefaultRow } from "../../../../store/root";
import { setDefaultHoursValue } from "../../../../store/default-hours";

export const ContextMenu: FC<{
  pointOfSaleId: string;
  disabled: boolean;
  defaultHours: TurniItemInterface;
  turniType: "calendar" | "shift";
  day: string;
}> = memo(
  ({ pointOfSaleId, disabled, defaultHours, turniType, day }): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
    const { copiedDefaultRow } = useSelector(
      (state: RootState) => state.newPointOfSaleRoot
    );
    const dispatch: AppDispatch = useDispatch();
    const open = Boolean(anchorEl);

    const turniData: any = (defaultHours as any)[`${turniType}${day}`];

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event?.currentTarget);
      },
      []
    );

    const handleClose = useCallback((): void => {
      setAnchorEl(null);
    }, []);

    const handleCopyClick = useCallback(() => {
      dispatch(setCopiedDefaultRow(turniData));
      handleClose();
    }, [dispatch, handleClose, turniData]);

    const handlePasteClick = useCallback(() => {
      const temp: any = { ...copiedDefaultRow };
      dispatch(
        setDefaultHoursValue({
          defaultHoursValue: temp,
          day,
          turniType,
          pointOfSaleId,
        })
      );
      handleClose();
    }, [
      copiedDefaultRow,
      day,
      dispatch,
      handleClose,
      pointOfSaleId,
      turniType,
    ]);

    const handleDeleteClick = useCallback(() => {
      const temp: any = {
        ...turniData,
        startTime1: null,
        startTime2: null,
        endTime1: null,
        endTime2: null,
        isClosed: true,
      };
      dispatch(
        setDefaultHoursValue({
          defaultHoursValue: temp,
          day,
          turniType,
          pointOfSaleId,
        })
      );
      handleClose();
    }, [day, dispatch, handleClose, pointOfSaleId, turniData, turniType]);

    const MenuContainer = useMemo(() => {
      return (
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            key="download-ics"
            value="download-ics"
            style={{
              paddingTop: "2px",
              paddingBottom: "2px",
            }}
            onClick={handleCopyClick}
          >
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Copia</ListItemText>
          </MenuItem>
          <MenuItem
            key="google-calendar"
            value="google-calendar"
            style={{
              paddingTop: "2px",
              paddingBottom: "2px",
            }}
            disabled={copiedDefaultRow === undefined}
            onClick={handlePasteClick}
          >
            <ListItemIcon>
              <ContentPasteGoIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Incolla</ListItemText>
          </MenuItem>
          <MenuItem
            key="yahoo"
            value="yahoo"
            style={{
              paddingTop: "2px",
              paddingBottom: "2px",
            }}
            onClick={handleDeleteClick}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Elimina</ListItemText>
          </MenuItem>
        </Menu>
      );
    }, [
      anchorEl,
      open,
      handleClose,
      handleCopyClick,
      copiedDefaultRow,
      handlePasteClick,
      handleDeleteClick,
    ]);

    const GetContent = useMemo(() => {
      return (
        <>
          <TableCell
            id={`moreoptions${turniType}${day}`}
            key={`moreoptions${turniType}${day}`}
            size="small"
            sx={{
              borderTopWidth: "0px",
              borderLeft: "1px solid rgba(224, 224, 224, 1)",
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              p: 0,
            }}
          >
            <IconButton
              id={`${turniType}${day}`}
              key={`${turniType}${day}`}
              onClick={handleClick}
              disabled={disabled}
            >
              <MoreVertIcon />
            </IconButton>
          </TableCell>
          {MenuContainer}
        </>
      );
    }, [MenuContainer, day, disabled, handleClick, turniType]);

    return <>{GetContent}</>;
  }
);
