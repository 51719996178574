import { object, date, string, boolean, number } from "yup";
import { checkShiftRanges } from "../utils/check-shift-ranges";
import { checkCalendarsRanges } from "../utils/check-calendars-ranges";

export const schema = object().shape({
  isSingleCalendarManagement: boolean(),
  firstTurnShift: boolean()
    .nullable()
    .test(
      "first-turn-is-equal-or-after-shift",
      "Primo turno di lavoro non valido", // a message can also be a function
      (_, testContext): boolean => {
        return checkShiftRanges({
          rangeStart: testContext.parent?.startTime1Shift,
          rangeEnd: testContext.parent?.endTime1Shift,
          typeShift: testContext.parent?.typeShift,
          isSingleCalendarManagement:
            testContext.parent?.isSingleCalendarManagement,
        });
      }
    ),
  secondTurnShift: boolean()
    .nullable()
    .test(
      "second-turn-is-equal-or-after-shift",
      "Secondo turno di lavoro non valido", // a message can also be a function
      (_, testContext): boolean =>
        checkShiftRanges({
          rangeStart: testContext.parent?.startTime2Shift,
          rangeEnd: testContext.parent?.endTime2Shift,
          typeShift: testContext.parent?.typeShift,
          isSingleCalendarManagement:
            testContext.parent?.isSingleCalendarManagement,
        })
    ),
  startTime1Shift: date().nullable(),
  startTime2Shift: date().nullable(),
  endTime1Shift: date().nullable(),
  endTime2Shift: date().nullable(),
  defaultstartTime1Shift: date().nullable(),
  defaultstartTime2Shift: date().nullable(),
  defaultendTime1Shift: date().nullable(),
  defaultendTime2Shift: date().nullable(),
  typeShift: string(), // ! default | extra | extra-closed | closed
  initialTypeShift: string(), // ! default | extra | extra-closed | closed
  defaultTypeShift: string(), // ! default | extra | extra-closed | closed
  shiftExtraCalendarType: number().default(0),
  initialShiftExtraCalendarType: number().default(0),

  typeCalendar: string(), // ! default | extra | extra-closed | closed
  initialTypeCalendar: string(), // ! default | extra | extra-closed | closed
  defaultTypeCalendar: string(), // ! default | extra | extra-closed | closed
  calendarExtraCalendarType: number().default(0),
  initialCalendarExtraCalendarType: number().default(0),
  firstTurnCalendar: boolean()
    .nullable()
    .test(
      "first-turn-is-equal-or-after-calendar",
      "Primo turno appuntamento non valido", // a message can also be a function
      (_, testContext): boolean => {
        const {
          startTime1Calendar,
          endTime1Calendar,
          typeCalendar,
          typeShift,
          startTime1Shift,
          endTime1Shift,
          startTime2Shift,
          endTime2Shift,
          defaultstartTime1Shift,
          defaultstartTime2Shift,
          defaultendTime1Shift,
          defaultendTime2Shift,
          isSingleCalendarManagement,
        } = testContext.parent;

        return checkCalendarsRanges({
          rangeStart: startTime1Calendar,
          rangeEnd: endTime1Calendar,

          startTime1Shift:
            typeShift === "default" ? defaultstartTime1Shift : startTime1Shift,
          endTime1Shift:
            typeShift === "default" ? defaultendTime1Shift : endTime1Shift,
          startTime2Shift:
            typeShift === "default" ? defaultstartTime2Shift : startTime2Shift,
          endTime2Shift:
            typeShift === "default" ? defaultendTime2Shift : endTime2Shift,

          typeCalendar,
          typeShift,
          isSingleCalendarManagement,
        });
      }
    ),
  secondTurnCalendar: boolean()
    .nullable()
    .test(
      "second-turn-is-equal-or-after-calendar",
      "Secondo turno appuntamento non valido", // a message can also be a function
      (_, testContext): boolean => {
        const {
          startTime2Calendar,
          endTime2Calendar,
          typeCalendar,
          typeShift,
          startTime1Shift,
          endTime1Shift,
          startTime2Shift,
          endTime2Shift,
          isSingleCalendarManagement,
        } = testContext.parent;

        return checkCalendarsRanges({
          rangeStart: startTime2Calendar,
          rangeEnd: endTime2Calendar,

          startTime1Shift,
          endTime1Shift,
          startTime2Shift,
          endTime2Shift,

          typeCalendar,
          typeShift,
          isSingleCalendarManagement,
        });
      }
    ),
  startTime1Calendar: date().nullable(),
  startTime2Calendar: date().nullable(),
  endTime1Calendar: date().nullable(),
  endTime2Calendar: date().nullable(),
  defaultstartTime1Calendar: date().nullable(),
  defaultstartTime2Calendar: date().nullable(),
  defaultendTime1Calendar: date().nullable(),
  defaultendTime2Calendar: date().nullable(),
});
