import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import OtpInput from "react-otp-input";
import axios from "axios";
import { BluAsyncDialog, BluLoader } from "@bludata/components";

export interface OtpDataInterface {
  otp: string;
  url: string;
}

interface Props {
  onConfirm: () => void;
  onDecline: () => void;
  otpData: OtpDataInterface;
}

function DialogShowQrOtp(props: Props) {
  const { onConfirm, onDecline, otpData } = props;
  const [verificaOtp, setVerificaOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const networkErrorString = "Errore di connessione al server.";

  const errorDialogDefaultFunction = (
    error?: string,
    type?: "error" | "info" | "warning"
  ) =>
    BluAsyncDialog({
      title: "Attenzione",
      message: error || networkErrorString,
      type: type || "error",
      hideDeclineButton: true,
      confimButton: "Chiudi",
    });

  const onContinuaClick = () => {
    if (!verificaOtp.trim()) {
      errorDialogDefaultFunction(
        "Inserire il codice OTP generato dall'app Google Authenticator per continuare."
      );
    } else {
      //? verifico la correttezza dell'otp prima di salvarlo
      setLoading(true);
      axios
        .get<{
          message: string;
          valid: boolean;
        }>("/focusapi/configurazione/otp/validate", {
          params: {
            otp: verificaOtp,
            otpKey: otpData.otp,
          },
        })
        .then((response) => {
          if (response.data.valid) {
            errorDialogDefaultFunction(
              "Codice OTP verificato con successo.",
              "info"
            );
            onConfirm();
          } else {
            errorDialogDefaultFunction(response.data.message);
          }
        })
        .catch((error) => {
          errorDialogDefaultFunction(
            "Errore durante la verifica del codice OTP. Riprovare."
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Dialog open fullWidth maxWidth="sm" fullScreen={false}>
      <DialogTitle>Attivazione autenticazione a 2 fattori (2FA)</DialogTitle>
      <DialogContent>
        <BluLoader open={loading} sx={{ zIndex: "1400" }} />
        <Stack flexDirection="column" spacing={3}>
          <Typography>
            Scaricare dall'App Store o Google Play Store l'app{" "}
            <b>Google Authenticator</b>.<br />
            Una volta aperta, premere il + in alto a destra e inquadrare il QR
            CODE con la fotocamera del proprio smartphone.
          </Typography>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Paper variant="outlined" sx={{ p: 1 }}>
              <QRCode
                value={otpData.url}
                size={250}
                qrStyle="squares"
                eyeColor="#1375c0"
                eyeRadius={10}
              />
            </Paper>
          </Stack>
          <Stack flexDirection="column" spacing={1}>
            <Typography>
              Inserisci qui sotto il codice generato dall'app per verificare la
              corretta configurazione del codice OTP, e premi il tasto{" "}
              <b>Continua</b>.
            </Typography>
            <Stack flexDirection="row" justifyContent="center">
              <OtpInput
                value={verificaOtp}
                onChange={setVerificaOtp}
                numInputs={6}
                renderSeparator={<Box sx={{ width: 5 }} />}
                renderInput={(props) => {
                  props.style.width = 30;
                  return (
                    <TextField
                      {...props}
                      ref={undefined}
                      inputRef={props.ref}
                      sx={{
                        "& input": {
                          textAlign: "center",
                        },
                      }}
                    />
                  );
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onDecline}>
          Annulla
        </Button>
        <Button variant="contained" onClick={onContinuaClick}>
          Continua
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogShowQrOtp;
