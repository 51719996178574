import axios from "axios";
import Azienza_interface from "../interfaces/Azienza_interface";
import { useQuery } from "@tanstack/react-query";

export const fetchAzienda = async (): Promise<Azienza_interface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get(`/focusapi/tabella/azienda`, {
      params: {
        _dc: new Date().getTime(),
        page: 1,
        start: 0,
        limit: 9999,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const useAzienda = (key: string = "") => {
  return useQuery({
    queryFn: async () => await fetchAzienda(),
    queryKey: ["azienda", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: any): Azienza_interface[] => {
  return data
    .map((item: Azienza_interface) => {
      return {
        ...item,
        ragioneSociale:
          item && item?.ragioneSociale
            ? item.ragioneSociale
            : "Ragione sociale non trovata",
      };
    })
    .flat();
};
