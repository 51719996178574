import { BluLoader } from "@bludata/components";
import { memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export const LayoutLoader = memo(() => {
  const { loadLayout } = useSelector((state: RootState) => state.layoutRoot);

  return (
    <BluLoader
      open={loadLayout}
      zIndex={400}
      sx={{ zIndex: 400 }}
      CircularProps={{
        sx: { zIndex: 400 },
      }}
    />
  );
});
