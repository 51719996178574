import { DataGridPremium } from "@mui/x-data-grid-premium";
import { FC, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { EnableCell } from "./enable-cell";
import { setCurrentStudio, setOpenForm } from "../../store/root";

interface ServicesGridInterface {
  search: string;
  active?: boolean;
}

export const StudiGrid: FC<ServicesGridInterface> = memo(
  ({ search, active }) => {
    const { studi, pointOfSales, studiLoad } = useSelector(
      (state: RootState) => state.studiRoot
    );
    const dispatch = useDispatch<AppDispatch>();

    const getValues = useCallback(() => {
      if (studi !== undefined) {
        return Object.values(studi)
          .map((studio: any) => {
            return {
              ...studio,
              pointOfSaleName: pointOfSales[studio.pointOfSaleId].branch,
            };
          })
          .flat()
          .filter((value) => {
            var reg = new RegExp(search, "i");
            if (active)
              return (
                reg.test(value.pointOfSaleName) && value.isActive === active
              );
            return reg.test(value.pointOfSaleName);
          });
      }
      return [];
    }, [active, pointOfSales, search, studi]);

    const Columns: any[] = [
      {
        field: "id",
        hide: true,
      },
      {
        field: "isActive",
        headerName: "Attivo",
        width: 70,
        align: "center",
        type: "boolean",
        editable: true,
        sortable: false,
        renderCell: EnableCell,
      },
      {
        field: "pointOfSaleName",
        headerName: "Filiale",
        flex: 1,
        editable: false,
      },
      {
        field: "name",
        headerName: "Nome",
        flex: 1,
        editable: false,
      },
      {
        field: "description",
        headerName: "Descrizione",
        flex: 1.5,
        editable: false,
      },
    ];

    return (
      <DataGridPremium
        columns={Columns as any}
        rows={getValues()}
        loading={studiLoad}
        sx={{
          "& .MuiDataGrid-cell": {
            outline: "none!important",
          },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
        density="compact"
        disableColumnMenu
        getEstimatedRowHeight={() => 300}
        rowBuffer={50}
        rowThreshold={15}
        getRowHeight={() => "auto"}
        editMode="cell"
        onProcessRowUpdateError={(error) => console.log(error)}
        experimentalFeatures={{ newEditingApi: true }}
        onRowClick={(event: any) => {
          dispatch(setCurrentStudio(event.row));
        }}
        onRowDoubleClick={() => dispatch(setOpenForm("info"))}
        components={{
          Footer: () => <></>,
        }}
      />
    );
  }
);
