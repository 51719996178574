import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { BluTextField } from "@bludata/components";
import { FC, memo, useState } from "react";
import { useWatch } from "react-hook-form";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import WordPressIcon from "../../../../assets/wordpress.png";
import TabPanel from "../../../../UI/tab-panel";

interface PublicationInterface {
  setAlert: (value: string) => void;
}

export const Publication: FC<PublicationInterface> = memo(({ setAlert }) => {
  const token = useWatch({ name: "token" });
  const bluBookingLink = useWatch({ name: "bluBookingLink" }) + "$" + token;

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigatorActive: boolean = navigator?.clipboard !== undefined;

  const embedded = `<iframe
  id="BluBookingIframe"
  src="${bluBookingLink}"
  allowtransparency="true"
  allow="geolocation"
  frameborder="0"
  height="100%"
  width="100%"
  ></iframe>`;

  const embeddedScript = `<script>
  window.addEventListener("message", (event) => {
    if(event?.data && event.data?.source==='Blu Booking' )
      console.log("EVENT:", event.data);
  });
</script>`;

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      spacing={1}
    >
      <Box sx={{ width: "100%" }}>
        <Typography variant="body1">
          La sezione di pubblicazione fornisce un insieme di opzioni versatili
          per l'integrazione del plugin Blu Booking nel proprio sito web.
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Link" />
            <Tab label="iframe" />
            {/*<Tab label="Script" />*/}
            <Tab label="Wordpress" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Stack
            direction={"row"}
            p={4}
            flex={1}
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="start"
              flex={1}
            >
              <Typography variant="subtitle1" fontWeight="500">
                Link diretto
              </Typography>
              <Typography variant="body1">
                Per una semplice implementazione di Blu Booking sul proprio sito
                web, offriamo un'opzione di integrazione via link diretto. È
                sufficiente configurare un pulsante sul proprio sito che, quando
                premuto, apre il link fornito da Blu Booking. Questo metodo
                fornisce un accesso rapido e senza intoppi alle funzionalità di
                Blu Booking.
              </Typography>
            </Stack>
            <BluTextField
              flex={1}
              value={bluBookingLink}
              label=""
              disabled={navigatorActive}
              inputProps={{
                readOnly: true,
              }}
              InputProps={{
                endAdornment: (
                  <>
                    {token && navigatorActive ? (
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          sx={{
                            right: 0,
                            position: "absolute",
                          }}
                          size="small"
                          onClick={(e) => {
                            navigator.clipboard.writeText(bluBookingLink);
                            setAlert("Link copiato");
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <></>
                    )}
                  </>
                ),
              }}
            />
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stack
            p={4}
            flex={1}
            justifyContent="center"
            alignItems="start"
            spacing={1}
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="start"
              spacing={1}
              flex={1}
            >
              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="start"
                flex={1}
              >
                <Typography variant="subtitle1" fontWeight="500">
                  iframe
                </Typography>
                {/* <Typography variant="body1">
                Blubooking offre un'opzione di integrazione tramite iFrame per
                coloro che desiderano una soluzione più incorporata. Per
                attivare questa funzione, gli utenti devono semplicemente
                inserire il codice iFrame fornito da Blubooking nella sezione
                del loro sito in cui desiderano che il plugin sia visibile.
                Abbiamo incorporato uno script per rendere l'iFrame dinamico,
                permettendogli di adattarsi automaticamente e utilizzare lo
                spazio che gli serve. Tuttavia, se si preferisce un controllo
                maggiore sulla dimensione dell'iFrame, è possibile omettere lo
                script per impostare altezza e larghezza fisse. Questo metodo
                assicura una transizione fluida e un accesso diretto alle
                funzionalità di Blubooking all'interno del proprio sito web.
              </Typography> */}

                <Typography variant="body1">
                  Blu Booking offre un'opzione di integrazione tramite iframe
                  per coloro che desiderano una soluzione più incorporata. Per
                  attivare questa funzione, gli utenti devono semplicemente
                  inserire il codice iframe fornito da Blu Booking nella sezione
                  del loro sito in cui desiderano che il plugin sia visibile.
                  Questo metodo assicura una transizione fluida e un accesso
                  diretto alle funzionalità di Blu Booking all'interno del
                  proprio sito web.
                </Typography>
              </Stack>
              <BluTextField
                flex={1}
                value={embedded}
                label=""
                multiline
                maxRows={11}
                disabled={navigatorActive}
                inputProps={{
                  readOnly: true,
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {embedded && navigatorActive ? (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            sx={{
                              right: 0,
                              position: "absolute",
                            }}
                            size="small"
                            onClick={(e) => {
                              navigator.clipboard.writeText(embedded);
                              setAlert("iFrame copiato");
                            }}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <></>
                      )}
                    </>
                  ),
                }}
              />
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="start"
              spacing={1}
              flex={1}
              sx={{ width: "100%" }}
            >
              <Stack justifyContent="center" alignItems="start" flex={1}>
                <Typography variant="body1">
                  Aggiungendo questo script nella pagina dov'è presente l'iframe
                  è possibile tenere traccia degli step realizzati dai clienti.
                  Monitorare il percorso dei clienti aiuta a comprendere meglio
                  gli eventuali problemi che possono incontrare e sapere quando
                  hanno confermato l'appuntamento.
                </Typography>
              </Stack>

              <BluTextField
                flex={1}
                value={embeddedScript}
                label=""
                multiline
                maxRows={11}
                disabled={navigatorActive}
                inputProps={{
                  readOnly: true,
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {navigatorActive ? (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            sx={{
                              right: 0,
                              position: "absolute",
                            }}
                            size="small"
                            onClick={(e) => {
                              navigator.clipboard.writeText(embeddedScript);
                              setAlert("script copiato");
                            }}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <></>
                      )}
                    </>
                  ),
                }}
              />
            </Stack>
          </Stack>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Stack direction={"row"} p={4}>
            <Stack
              direction={"row"}
              flex={1}
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={WordPressIcon}
                style={{
                  width: "200px ",
                  height: "200px ",
                }}
                alt=""
              />
            </Stack>
            <Stack direction={"row"} flex={1}>
              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="start"
                width={"100%"}
              >
                <Typography variant="subtitle1" fontWeight="500">
                  WordPress widget
                </Typography>
                <Typography variant="body1">
                  Per facilitare l'implementazione su piattaforme WordPress, Blu
                  Booking fornisce un plugin dedicato. Gli utenti sono invitati
                  a scaricare e installare il plugin di Blu Booking sul loro
                  sito WordPress, e inserire il token di sicurezza fornito.
                  Questa soluzione garantisce una completa integrazione con Blu
                  Booking direttamente all'interno della piattaforma WordPress,
                  offrendo un'esperienza d'uso continua e omogenea.
                </Typography>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  justifyContent="center"
                  alignItems="start"
                >
                  <Button
                    startIcon={<DownloadIcon />}
                    sx={{ mt: 3 }}
                    onClick={() => {
                      axios.get("/api/Wordpress");
                      axios
                        .get("/api/Wordpress", { responseType: "blob" })
                        .then((response) => {
                          const href = URL.createObjectURL(response.data);
                          const link = document.createElement("a");
                          link.href = href;
                          link.setAttribute("download", "WordPress.zip");
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                          URL.revokeObjectURL(href);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                  >
                    Scarica
                  </Button>
                </Stack>
                <Stack
                  direction={"column"}
                  justifyContent="center"
                  alignItems="start"
                  width={"100%"}
                  sx={{ mt: 2 }}
                >
                  <Typography variant="subtitle1" fontWeight="500">
                    Token
                  </Typography>
                  <BluTextField
                    value={token}
                    label=""
                    disabled={navigatorActive}
                    sx={{ width: "380px" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {token && navigatorActive ? (
                            <InputAdornment position="end">
                              <IconButton
                                color="primary"
                                sx={{
                                  right: 0,
                                  position: "absolute",
                                }}
                                size="small"
                                onClick={(e) => {
                                  navigator.clipboard.writeText(token);
                                  setAlert("Token copiato");
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : (
                            <></>
                          )}
                        </>
                      ),
                    }}
                  />
                </Stack>

                <Stack
                  direction={"column"}
                  justifyContent="center"
                  alignItems="start"
                  width={"100%"}
                  sx={{ mt: 2 }}
                >
                  <Typography variant="subtitle1" fontWeight="500">
                    Monitoraggio
                  </Typography>
                  <Typography>
                    Aggiungendo questo script nella pagina dov'è presente il
                    widget è possibile tenere traccia degli step realizzati dai
                    clienti. Monitorare il percorso dei clienti aiuta a
                    comprendere meglio gli eventuali problemi che possono
                    incontrare e sapere quando hanno confermato l'appuntamento.
                  </Typography>
                  <Stack flex="1" sx={{ width: "100%", pt: 1 }}>
                    <BluTextField
                      value={embeddedScript}
                      label=""
                      multiline
                      maxRows={11}
                      disabled={navigatorActive}
                      inputProps={{
                        readOnly: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {navigatorActive ? (
                              <InputAdornment position="end">
                                <IconButton
                                  color="primary"
                                  sx={{
                                    right: 0,
                                    position: "absolute",
                                  }}
                                  size="small"
                                  onClick={(e) => {
                                    navigator.clipboard.writeText(
                                      embeddedScript
                                    );
                                    setAlert("script copiato");
                                  }}
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : (
                              <></>
                            )}
                          </>
                        ),
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </TabPanel>
      </Box>
    </Stack>
  );
});
