import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { StudioInterface } from "../interfaces/studio";

interface StudiObject {
  [x: string]: StudioInterface;
}

const getStudi = async (): Promise<StudioInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get("/api/Studio");

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useStudi = (key: string = "") => {
  return useQuery({
    queryFn: async () => await getStudi(),
    queryKey: ["all-filters", "studi", "configurazione", key],
    retry: 2,
    retryDelay: 180000,
    select: normalize,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

const normalize = (data: any): StudiObject => {
  const result: any = {};
  for (const studio of data) {
    result[studio.id] = {
      ...studio,
      studioId: studio.id,
    };
  }
  return result;
};
