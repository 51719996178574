import { BluAutoComplete } from "@bludata/components";
import { Typography } from "@mui/material";
import { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

const regex = new RegExp(/^[0-9]*$/);

export const Duration = memo(() => {
  const duration = useWatch({ name: "durationValue" });
  const { setValue, trigger } = useFormContext();
  const { openForm } = useSelector((state: RootState) => state.serviceRoot);

  return (
    <div style={{ width: "38%" }}>
      <Typography variant="subtitle2" sx={{ p: "3px 0px 0 0" }}>
        Durata
      </Typography>
      <BluAutoComplete
        inputValue={duration !== 0 ? duration.toString() : ""}
        data={[
          { label: "15", value: 15 },
          { label: "30", value: 30 },
          { label: "45", value: 45 },
        ]}
        freeSolo
        placeholder="Seleziona una durata"
        onInputChange={(_: any, value: any) => {
          if (value && value.match(regex)) {
            if (parseInt(value) <= 99) {
              // if (duration === parseInt(value)) return;
              setValue("durationValue", value !== "" ? parseInt(value) : 0);
              trigger("durationValue");
            }
          } else if (value === "") {
            setValue("durationValue", 0);
            trigger("durationValue");
          }
        }}
        filterOptions={(options: any) => options}
        disabled={openForm === "info"}
      />
    </div>
  );
});
