import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import LayoutCrm_interface from "../interfaces/LayoutCrm_interface";

const fetchLayout = async (): Promise<LayoutCrm_interface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get(`/imoapi/tabella/layout`, {
      params: {
        _dc: new Date().getTime(),
        page: 1,
        start: 0,
        limit: 9999,
      },
    });
    return data.map((filiale: any) => {
      return {
        ...filiale,
        id: filiale.codiceFiliale,
      };
    });
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const useLayoutCrm = (key: string = "") => {
  return useQuery({
    queryFn: async () => await fetchLayout(),
    queryKey: ["layout-crm", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
