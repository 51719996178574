import { BluTextField } from "@bludata/components";
import { Stack, InputAdornment, IconButton, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid-premium";
import { FC, memo, useCallback, useMemo, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface CustomTextFieldInterface {
  name: string;
  helperText?: string;
  label?: string;
  disabled?: boolean;
  width?: string;
  multiline?: number;
  limit?: number;
  [x: string]: any;
  pos?: (field: string, pos: number) => void;
}

export const CustomTextField: FC<CustomTextFieldInterface> = memo(
  ({
    name,
    helperText = "",
    label = "",
    disabled = false,
    width,
    multiline,
    pos,
    limit,
    ...rest
  }) => {
    const field = useWatch({ name });
    const { setValue, trigger } = useFormContext();

    const inputRef = useRef();

    const handleOnChange = useCallback(
      (e: any) => {
        setValue(name, e.target.value);
        trigger(name);

        if (!!inputRef.current && !!pos) {
          const a: any = inputRef.current;
          pos(name, a.selectionStart);
        }
      },
      [name, pos, setValue, trigger]
    );

    const handleErase = useCallback(() => {
      setValue(name, "");
      trigger(name);
    }, [name, setValue, trigger]);

    const ContainsWarning = useMemo(() => {
      if (!limit || disabled) return <></>;
      const containsTag = /\[[a-zA-Z]+\]/.test(field);
      if (containsTag)
        return (
          <Typography fontSize="12px" component="i">
            Attenzione il messaggio contiene campi unione, quindi potrebbe
            superare i {limit} caratteri.
          </Typography>
        );
      return <></>;
    }, [disabled, field, limit]);

    return (
      <Stack
        sx={{
          minWidth: width ? width : "150px",
          width: width,
          flex: width ? undefined : 1,
        }}
      >
        <BluTextField
          id={name}
          inputRef={inputRef}
          disabled={disabled}
          value={field ?? ""}
          label={label}
          helperText={helperText}
          multiline={!!multiline}
          rows={!!multiline ? multiline : 1}
          onClick={() => {
            if (!!inputRef.current && !!pos) {
              const a: any = inputRef.current;
              pos(name, a.selectionStart);
            }
          }}
          InputProps={{
            endAdornment: (
              <>
                {field && field !== "" && !disabled && (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      // sx={{
                      //   p: 0
                      //   //   right: 5,
                      //   //   position: "absolute",
                      // }}
                      size="small"
                      onClick={handleErase}
                    >
                      <GridCloseIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
          onChange={handleOnChange}
          // onClick={handleOnChange}
          {...rest}
        />
        {ContainsWarning}
      </Stack>
    );
  }
);
