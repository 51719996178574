import { ShopSignHolidayInterface } from "../../../interfaces/shop-sign";
import { CompanySettingsInterface } from "../interfaces/CompanySettings";
import { defaultCompanySettings } from "./default-company-settings";

let initialHolidays: ShopSignHolidayInterface[] = [];

let initialCompanySettings: CompanySettingsInterface = defaultCompanySettings;

export const getInitialHolidays = () => initialHolidays;

export const setInitialHolidays = (value: ShopSignHolidayInterface[]) => {
  initialHolidays = value;
};

export const getInitialCompanySettings = () => initialCompanySettings;

export const setInitialCompanySettings = (value: CompanySettingsInterface) => {
  initialCompanySettings = value;
};
