import { store } from "../../../../../store";
import { StudioDetailsInterface } from "../../../interfaces/studio-details";
import { setService } from "../../../store/form";
import { setInitialValue, setIntialArray } from "./white-cache";

export const getInitialValues = (
  studioDetails: StudioDetailsInterface | null
) => {
  let data = {
    isActive: true,
    creation: true,
    name: "",
    description: "",
    pointOfSaleId: "",
    services: false,
  };

  if (studioDetails === null) return data;

  data = {
    isActive: studioDetails?.isActive,
    creation: false,
    name: studioDetails?.name,
    description: studioDetails?.description,
    pointOfSaleId: studioDetails?.pointOfSaleId,
    services: studioDetails?.serviceStudios.length > 0,
  };

  const {
    studiRoot: { services },
  } = store.getState();

  const serviceKeys = Object.keys(services);
  const initialServices: any = {};
  const servicesForm: string[] = [];

  for (const serviceId of serviceKeys) {
    initialServices[serviceId] = {
      enabled: false,
    };
  }

  if (studioDetails?.serviceStudios !== undefined) {
    for (const service of studioDetails?.serviceStudios) {
      servicesForm.push(service.serviceId);
      initialServices[service.serviceId] = {
        id: service.id,
        enabled: true,
      };
    }
  }
  setInitialValue(initialServices);
  setIntialArray(servicesForm);
  store.dispatch(setService(servicesForm));
  return data;
};
