import axios from "axios";

export const setAnalysis = (
  startDate: string,
  endDate: string,
  language: number,
  software: string,
  pointOfSaleIds: string[] | null,
  shopSignIds: string[] | null
) => {
  return new Promise<any[]>((resolve, reject) => {
    const url = software === "fw" ? "/diaryapi/Analysis" : "/api/Analysis";
    axios
      .post(url, {
        pointOfSaleIds:
          pointOfSaleIds !== null && pointOfSaleIds.length > 0
            ? pointOfSaleIds
            : null,
        shopSignIds:
          shopSignIds !== null && shopSignIds.length > 0 ? shopSignIds : null,
        startDate: startDate,
        endDate: endDate,
        language: language,
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};
