import axios from "axios";
import { HolidayInterface } from "../interfaces/holiday";
import { CalendarUserInterface } from "../interfaces/calendar-user";
import { ExtraCalendarUserInterface } from "../interfaces/extra-calendar-user";
import { getRotaPath } from "../lib/white-cache";

export const getCalendarUser = async (
  pointOfSaleId: string,
  startTime: string,
  endTime: string
): Promise<[CalendarUserInterface[], ExtraCalendarUserInterface[]]> => {
  const fetchCalendarUser = async (): Promise<CalendarUserInterface[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getRotaPath()}/CalendarUser/PointOfSale/${pointOfSaleId}`,
        {
          params: {
            startTime,
            endTime,
          },
        }
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  const fetchExtraCalendarUser = async (): Promise<
    ExtraCalendarUserInterface[]
  > => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getRotaPath()}/ExtraCalendarUser/PointOfSale/${pointOfSaleId}`,
        {
          params: {
            startTime,
            endTime,
          },
        }
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  const fetchHoliday = async (): Promise<HolidayInterface[]> => {
    try {
      const {
        data: { data },
      } = await axios.post(`${getRotaPath()}/Holiday/Range`, {
        startDate: startTime,
        endDate: endTime,
      });
      return data;
    } catch (error) {
      return [];
    }
  };

  try {
    const responses = await Promise.all([
      fetchCalendarUser(),
      fetchExtraCalendarUser(),
    ]);

    return responses as any;
  } catch (error) {
    return [[], []];
  }
};
