export const defaultUserFw: any = {
  address: "",
  blubookingId: "",
  blubookingPointOfSaleId: "",
  cellular: "",
  city: "",
  codiceFiliale: "",
  color: "",
  contractExpirationDate: "",
  eliminatePhoto: false,
  email: "",
  employmentDate: "",
  filiale: "",
  focusCodiceGruppo: "",
  focusCodiceProfilo: "",
  focusCodiceQualifica: "",
  focusCodiceReparto: "",
  focusId: 0,
  focusProfiloCorporate: "",
  hasAgenda: false,
  IMOCode: false,
  imoCodicePdvLayout: "",
  imoId: 0,
  imoTipoUtente: 0,
  isActive: true,
  isIMOActive: false,
  isOptician: false,
  isShowChatName: false,
  name: "",
  photo: "",
  postalCode: "",
  presentation: "",
  region: "",
  sign: "",
  surname: "",
  telephone: "",
  username: "",
  visibilityUserEnum: -1,
};
