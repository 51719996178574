import {
  Select,
  CircularProgress,
  Input,
  ThemeProvider,
  Theme
} from '@mui/material'
import React, { memo, useMemo } from 'react'
import { SelectInterface } from './interface'
import overrideBludataTheme from './theme'

const BluSelect = memo(function BluSelect({
  value,
  children,
  themeMode = 'light',
  renderValue = (value: any) => <>{value}</>,
  onChange = () => {},
  loading = false,
  inputProps,
  ...rest
}: SelectInterface) {
  const Loading = useMemo(
    () =>
      (loading ?? false) && (
        <CircularProgress style={{ width: '25px', height: 'auto' }} />
      ),
    [loading]
  )

  return (
    <ThemeProvider theme={(theme: Theme) => overrideBludataTheme(theme)}>
      <Select
        value={value}
        onChange={onChange}
        renderValue={renderValue}
        input={<Input fullWidth {...inputProps} />}
        {...rest}
        startAdornment={
          <>
            {rest?.startAdornment}
            {Loading}
          </>
        }
        endAdornment={<>{rest?.endAdornment}</>}
      >
        {children}
      </Select>
    </ThemeProvider>
  )
})

export default BluSelect
