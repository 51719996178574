import axios from "axios";

interface deleteServiceInterface {
  serviceId: string;
}

export const serviceDelete = async ({
  serviceId,
}: deleteServiceInterface): Promise<boolean> => {
  try {
    await axios.delete(`/api/Service/${serviceId}`);

    return true;
  } catch (err) {
    console.trace(err);
    return false;
  }
};
