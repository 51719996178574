import { BluNumberField } from "@bludata/components";
import { Stack } from "@mui/material";
import { FC, memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface CustomNumberFieldInterface {
  name: string;
  helperText?: string;
  label?: string;
  tooltip?: string;
  disabled?: boolean;
  width?: string;
  [x: string]: any;
}

export const CustomNumberField: FC<CustomNumberFieldInterface> = memo(
  ({
    name,
    helperText = "",
    tooltip,
    label = "",
    disabled = false,
    width,
    ...rest
  }) => {
    const field = useWatch({ name });
    const { setValue, trigger } = useFormContext();

    const handleOnChange = useCallback(
      (value: any) => {
        setValue(name, value);
        trigger(name);
      },
      [name, setValue, trigger]
    );

    return (
      <Stack
        sx={{
          minWidth: width ? width : "230px",
          width: width,
          flex: width ? undefined : 1,
        }}
      >
        <BluNumberField
          disabled={disabled}
          value={field}
          onChange={handleOnChange}
          sx={{
            width: "100%",
          }}
          label={label}
          tooltip={tooltip}
          {...rest}
        />
      </Stack>
    );
  }
);
