import { configureStore } from "@reduxjs/toolkit";

import Root from "./slices/root";
import { ServiceForm, ServiceRoot } from "../pages/services/store";
import {
  LayoutRoot,
  LayoutPalette,
  LayoutFont,
  LayoutComponents,
  LayoutCustomTheme,
} from "../pages/layout/store";
import { StudiRoot, StudiForm } from "../pages/studi/store";
import { GeneraliHolidays, GeneraliRoot } from "../pages/generali/store";
import { RotaImport, RotaRoot } from "../pages/nuova-rota/store";
import {
  NewPointOfSaleRoot,
  PointOfSaleDefaultHours,
  PointOfSaleExtraHours,
} from "../pages/nuova-point-of-sales/store";
import {
  NewUsersRoot,
  UserDefaultHours,
  UserExtraHours,
} from "../pages/nuovi-users/store";
import { TypedUseSelectorHook, useSelector } from "react-redux";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    root: Root,
    serviceForm: ServiceForm,
    serviceRoot: ServiceRoot,
    layoutRoot: LayoutRoot,
    generaliHolidays: GeneraliHolidays,
    layoutPalette: LayoutPalette,
    layoutFont: LayoutFont,
    layoutComponents: LayoutComponents,
    layoutCustomTheme: LayoutCustomTheme,
    studiRoot: StudiRoot,
    studiForm: StudiForm,
    generaliRoot: GeneraliRoot,
    rotaRoot: RotaRoot,
    rotaImport: RotaImport,
    newPointOfSaleRoot: NewPointOfSaleRoot,
    newPointOfSaleDefaultHours: PointOfSaleDefaultHours,
    newPointOfSaleExtraHours: PointOfSaleExtraHours,
    newUsersRoot: NewUsersRoot,
    newUserDefaultHours: UserDefaultHours,
    newUserExtraHours: UserExtraHours,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
