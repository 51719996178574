import { Checkbox } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";

const columnsFun = (isBluBookingActive: boolean) => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "isActive",
      headerName: "Attivo",
      width: 75,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} disabled />;
      },
    },
    {
      field: "isDoNotDisplay",
      headerName: "Non visualizzare",
      width: 75,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} disabled />;
      },
    },
    {
      field: "name",
      headerName: "Ragione sociale",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "city",
      headerName: "Città",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "internalCode",
      headerName: "Sigla",
      width: 60,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      hide: false,
    },
    {
      field: "layout",
      headerName: "Layout",
      width: 250,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      hide: !isBluBookingActive,
    },

    {
      field: "tipo",
      headerName: "Tipo",
      type: "number",
      width: 110,
      editable: false,
      headerAlign: "center",
    },
  ];
  return columns;
};

export default columnsFun;
