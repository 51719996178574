import axios from "axios";
import { getRotaPath } from "../lib/white-cache";
import { ImportCheckInterface } from "../interfaces/import-check";

export const importCheck = async (
  pointOfSaleId: string,
  File: any,
  isSingleCalendarManagement: boolean,
  documentFormat: "bludata" | "factorial"
): Promise<ImportCheckInterface | null> => {
  try {
    let url = `${getRotaPath()}/DualCalendarImport`;
    if (isSingleCalendarManagement)
      url = `${getRotaPath()}/SharedCalendarImport`;

    if (documentFormat === "factorial") url += `/Factorial`;

    const {
      data: { data: check },
    } = await axios.post(
      `${url}/Import/Check`,
      {
        pointOfSaleId,
        File,
        CleanUserCalendars: false,
        companyId: null,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return check;
  } catch (error) {
    console.error(error);
    return null;
  }
};
