import { Checkbox } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";

const columnsFun = (
  isBluBookingActive: boolean,
  software: "bb" | "fw" | "crm" | "f10"
) => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "isActive",
      headerName: "Attivo",
      width: 75,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} disabled />;
      },
    },
    {
      field: "branch",
      headerName: "Punto vendita",
      width: 130,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      hide: false,
    },
    {
      field: "internalCode",
      headerName: "Sigla",
      width: 60,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      hide: false,
    },
    {
      field: "azienda",
      headerName: "Azienda",
      width: 130,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      hide: false,
    },
    {
      field: "Uff-Sede",
      headerName: "Uff-Sede",
      type: "number",
      width: 110,
      editable: false,
      hide: true,
    },
    {
      field: "sede",
      headerName: "Uff.Sede",
      width: 85,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        if (params.row["branchName"] === undefined) return <></>;
        return (
          <Checkbox checked={!!params.row["branchName"].length} disabled />
        );
      },
      hide: software !== "fw",
    },
    {
      field: "internalLaboratory",
      headerName: "Lab.",
      width: 75,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} disabled />;
      },
      hide: software !== "fw",
    },
    {
      field: "remote",
      headerName: "Remoto",
      width: 75,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} disabled />;
      },
      hide: software !== "fw",
    },
    {
      field: "name",
      headerName: "Ragione sociale",
      width: 190,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      hide: true,
    },
    {
      field: "address",
      headerName: "Indirizzo",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      hide: true,
    },
    {
      field: "city",
      headerName: "Città",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "isDoNotDisplay",
      headerName: "Non visualizzare",
      width: 50,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      hide: software === "fw",
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} disabled />;
      },
    },
    {
      field: "Lab.",
      headerName: "Lab.",
      type: "number",
      width: 110,
      editable: false,
      hide: true,
    },
    {
      field: "vatNumber",
      headerName: "Partita IVA",
      type: "number",
      width: 130,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      editable: false,
      hide: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "layout",
      headerName: isBluBookingActive ? "Layout Blu Booking" : "Layout Agenda",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "layoutApp",
      headerName: "Layout App",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
    },
  ];
  return columns;
};

export default columnsFun;
