import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Theme } from "../utils/default-theme";

const initialState: any = Theme.components;

const LayoutComponentsSlice = createSlice({
  name: "LayoutComponents",
  initialState,
  reducers: {
    setComponents(_: any, action: PayloadAction<any>) {
      return action.payload.components;
    },
    setComponentsValue(state: any, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetComponents() {
      return Theme.components;
    },
  },
});

export const { setComponents, resetComponents, setComponentsValue } =
  LayoutComponentsSlice.actions;

export default LayoutComponentsSlice.reducer;
