import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import React, { ChangeEvent, FC } from "react";
import { memo } from "react";

interface SearchInterface {
  search: string;
  handleOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
  active?: boolean;
  handleOnChangeActive: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Search: FC<SearchInterface> = memo(
  ({ search, handleOnChange, active, handleOnChangeActive }) => {
    return (
      <Stack
        direction="row"
        gap={2}
        justifyContent="start"
        spacing={1}
        sx={{ mb: "15px!important" }}
      >
        <TextField
          value={search}
          label="Cerca per filiale"
          onChange={handleOnChange}
          sx={{ width: 300, pb: "10px" }}
        />
        <FormControlLabel
          sx={{ pt: "5px" }}
          label="Attivo"
          control={
            <Checkbox checked={active} onChange={handleOnChangeActive} />
          }
        />
      </Stack>
    );
  }
);
