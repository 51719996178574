import { PointOfSalesInterface } from "../../../interfaces/PointOfSale_interface";

export const defaultPointOfSaleBB: PointOfSalesInterface = {
  id: null,
  pointOfSaleId: null,
  companyId: "",
  shopSignId: "",
  name: "",
  nameOnline: "",
  email: "",
  telephone: "",
  description: "",
  address: "",
  region: "",
  city: "",
  postalCode: "",
  fax: "",
  branch: "",
  externalCode: "",
  internalCode: "",
  isDoNotDisplay: false,
  isActive: false,
  isBluBookingActive: false,
  isFocusPlusActive: false,
  updatedAt: "",
};
