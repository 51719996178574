import { Stack, Typography } from "@mui/material";
import { FC, memo } from "react";
import { PointOfSaleImport } from "./PointOfSale";
import { UploadComponent } from "./UploadComponent";
import { DocumentFormatImport } from "./DocumentFormat";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

interface ImportInitInterface {
  pointOfSales: any[];
}

export const ImportInit: FC<ImportInitInterface> = memo(({ pointOfSales }) => {
  const { documentFormat } = useSelector(
    (state: RootState) => state.rotaImport
  );
  return (
    <Stack flex="1" gap={1}>
      <Stack flex="1">
        <Typography>
          Seleziona il formato del documento: Bludata (XLSX - scarica il
          modello), che include la selezione del punto vendita desiderato,
          oppure Factorial (CSV - scarica il modello), che consente di importare
          contemporaneamente i dati di più punti vendita. Una volta scelto il
          formato.
        </Typography>
        <Typography>Scegli il punto vendita.</Typography>

        <Typography>Procedi all'importazione del calendario.</Typography>
      </Stack>

      <DocumentFormatImport />
      {documentFormat === "bludata" && (
        <PointOfSaleImport pointOfSales={pointOfSales} />
      )}

      <UploadComponent />
    </Stack>
  );
});
