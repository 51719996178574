import axios from "axios"
import ShopSigns_interface from "./interface/ShopSigns_interface"

export const fetchLayout = (software: any) => {
  return new Promise<ShopSigns_interface[]>((resolve, reject) => {
    const url = software==="fw" ? "/diaryapi/ShopSign/Basic" :"/api/ShopSign/Basic"
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.data)
      })
      .catch((error) => {
        reject(error.response.data)
      })
  })
}

export default fetchLayout
