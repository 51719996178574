import { memo, useEffect } from "react";
import { store } from "../../../store";
import { setLoadLayout, setShopSigns } from "../store/root";
import { useShopSignsBasic } from "../api/shop-sign-basic";

export const InitShopSign = memo(() => {
  const { isLoading: shopSignLoading, data: shopSigns } = useShopSignsBasic();

  useEffect(() => {
    if (!shopSignLoading && shopSigns !== undefined) {
      try {
        store.dispatch(setShopSigns(shopSigns));
        store.dispatch(setLoadLayout(false));
      } catch (e) {
        console.error(e);
        store.dispatch(setLoadLayout(false));
      }
    }
  }, [shopSigns, shopSignLoading]);

  return <></>;
});
