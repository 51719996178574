import { MenuItem, Stack, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { BluSelect } from "@bludata/components";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { FC, useCallback } from "react";

interface CustomSelectInterface {
  disabled?: boolean;
}

export const LaboratorioEsterno: FC<CustomSelectInterface> = ({ disabled }) => {
  const focusCodiceLaboratorio = useWatch({
    name: "focusCodiceLaboratorio",
  });
  const { setValue, trigger } = useFormContext();

  const { pointOfSales } = useSelector(
    (state: RootState) => state.newPointOfSaleRoot
  );

  const handleOnChange = useCallback(
    (event: any) => {
      setValue("focusCodiceLaboratorioEsterno", "");
      setValue("internalLaboratory", false);
      setValue("focusCodiceLaboratorio", event.target.value);
      trigger([
        "focusCodiceLaboratorioEsterno",
        "internalLaboratory",
        "focusCodiceLaboratorio",
      ]);
    },
    [setValue, trigger]
  );

  const getOptions = useCallback(() => {
    if (pointOfSales)
      return Object.values(pointOfSales).filter(
        (item: any) => !item.internalLaboratory
      );

    return [];
  }, [pointOfSales]);

  return (
    <Stack flex={1} sx={{ width: "100%" }}>
      <Typography>Laboratorio della catena</Typography>
      <BluSelect
        multiple={false}
        disabled={disabled || getOptions().length <= 0}
        value={focusCodiceLaboratorio}
        onChange={handleOnChange}
        renderValue={(selected: any) => {
          if (pointOfSales) {
            const find = Object.values(pointOfSales).find(
              (d) => !d.internalLaboratory && d.codiceFiliale === selected
            );
            if (find) return find.businessName;
          }
          return selected;
        }}
      >
        {getOptions().map((item: any) => (
          <MenuItem value={item.codiceFiliale}>{item.businessName}</MenuItem>
        ))}
      </BluSelect>
    </Stack>
  );
};
