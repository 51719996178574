import axios from "axios"
import Analisi_inteface from "./interface/Analisi_inteface"

export const fetchAnalysisRange = (software: string) => {
  const url =
    software === "fw" ? "/diaryapi/Analysis/Range" : "/api/Analysis/Range"

  return new Promise<Analisi_inteface>((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.data)
      })
      .catch((error) => {
        reject(error.response.data)
      })
  })
}
