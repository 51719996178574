import axios from "axios";
import { getPointOfSalePath } from "../lib/white-cache";
import { CalendarPointOfSaleInterface } from "../interfaces/calendar-point-of-sale";
import { useQuery } from "@tanstack/react-query";
import { TurniDefaultInterface } from "../interfaces/default-turni-point-of-sale";
import dayjs from "dayjs";

const getTurniShiftAndCalendarPointOfSale = async (
  pointOfSaleId: string,
  isSingleCalendarManagement: boolean
): Promise<
  [CalendarPointOfSaleInterface[], CalendarPointOfSaleInterface[]]
> => {
  const getCalendarPointOfSale = async (
    pointOfSaleId: string
  ): Promise<CalendarPointOfSaleInterface[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getPointOfSalePath()}/CalendarPointOfSale/PointOfSale/${pointOfSaleId}`
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  const getShiftPointOfSale = async (
    pointOfSaleId: string
  ): Promise<CalendarPointOfSaleInterface[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getPointOfSalePath()}/ShiftPointOfSale/PointOfSale/${pointOfSaleId}`
      );
      return data;
    } catch (error) {
      return [];
    }
  };
  if (!pointOfSaleId) return [[], []];

  try {
    if (isSingleCalendarManagement) {
      const responses = await getCalendarPointOfSale(pointOfSaleId);
      return [[], responses] as any;
    }

    const responses = await Promise.all([
      getCalendarPointOfSale(pointOfSaleId),
      getShiftPointOfSale(pointOfSaleId),
    ]);

    return responses as any;
  } catch (err) {
    console.error(err);
    return [[], []];
  }
};

export const useTurniDefaultPointOfSale = (
  pointOfSaleId: string,
  isSingleCalendarManagement: boolean = false,
  key: string = ""
) => {
  return useQuery({
    queryFn: async () =>
      await getTurniShiftAndCalendarPointOfSale(
        pointOfSaleId,
        isSingleCalendarManagement
      ),
    queryKey: [
      "turni-default-point-of-sale",
      pointOfSaleId,
      isSingleCalendarManagement,
      key,
    ],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: any): any => {
  let result: TurniDefaultInterface = {
    shiftmonday: null,
    shifttuesday: null,
    shiftwednesday: null,
    shiftthursday: null,
    shiftfriday: null,
    shiftsaturday: null,
    shiftsunday: null,

    calendarmonday: null,
    calendartuesday: null,
    calendarwednesday: null,
    calendarthursday: null,
    calendarfriday: null,
    calendarsaturday: null,
    calendarsunday: null,
  };

  try {
    const getResult = (
      turno: CalendarPointOfSaleInterface[],
      type: "shift" | "calendar"
    ): any => {
      const temp: any = {};

      for (const calendar of turno) {
        const days = [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ];

        days.forEach((day) => {
          if (
            calendar[
              `has${
                day.charAt(0).toUpperCase() + day.slice(1)
              }` as keyof CalendarPointOfSaleInterface
            ]
          ) {
            temp[`${type}${day}`] = {
              id: calendar.id,
              isClosed: !(
                (calendar.startTime1 && calendar.endTime1) ||
                (calendar.startTime2 && calendar.endTime2)
              ),
              startTime1: calendar.startTime1
                ? dayjs(calendar.startTime1, "HH:mm:ss")
                : null,
              startTime2: calendar.startTime2
                ? dayjs(calendar.startTime2, "HH:mm:ss")
                : null,
              endTime1: calendar.endTime1
                ? dayjs(calendar.endTime1, "HH:mm:ss")
                : null,
              endTime2: calendar.endTime2
                ? dayjs(calendar.endTime2, "HH:mm:ss")
                : null,
            };
          }
        });
      }

      return temp;
    };
    const [calendar, shift] = data;

    result = {
      ...getResult(calendar, "calendar"),
      ...getResult(shift, "shift"),
    };
  } catch (err) {
    console.error("ERROR:", err);
  }

  return result;
};
