import { object, string, boolean, number, array } from "yup";

export const schema = object().shape({
  name: string()
    .required("Il nome è obbligatorio")
    .min(1, "Il nome è obbligatorio"),
  smallDescription: string()
    .required("La descrizione è obbligatoria")
    .min(1, "La descrizione è obbligatoria"),
  description: string().when("bluBookingActive", {
    is: true,
    then: string().required(
      "La descrizione per modulo di prenotazione online è obbligatoria in questo tipo di appuntamento"
    ),
  }),
  notificationDescription: string().nullable(),
  durationValue: number()
    .required("La durata è obbligatoria")
    .default(0)
    .moreThan(0, "La durata è obbligatoria")
    .max(99, "La durata può essere di massimo 99 ore"),
  hasStudio: boolean(),
  color: string().nullable(),
  isOnlineBooking: boolean(),
  hasAutoConfirmation: boolean(),
  studi: boolean().when("hasStudio", {
    is: true,
    then: boolean().oneOf([true], "Lo studio è obbligatorio"),
  }),
  pointOfSales: boolean().oneOf([true], "Il punto vendita è obbligatorio"),
  bluBookingActive: boolean().default(false),
  icons: array().of(string()).nullable(),
});
