interface WhiteCacheInterface {
  [key: string]: {
    id?: string;
    enabled: boolean;
  };
}

let initialValue: WhiteCacheInterface = {};

let intialArray: string[] = [];

export const getInitialValue = (): WhiteCacheInterface => {
  return initialValue;
};

export const setInitialValue = (value: any) => {
  initialValue = value;
};

export const getIntialArray = (): string[] => {
  return intialArray;
};

export const setIntialArray = (value: string[]) => {
  intialArray = value;
};
