import { Stack, TextField } from "@mui/material";
import { FC, memo, useCallback, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useLayoutCrm } from "../../../../api/layout-crm";
import { AppDispatch } from "../../../../../../store";
import { useDispatch } from "react-redux";
import { setIsLoadingForm } from "../../../../store/root";
import { nanoid } from "nanoid";
import { BluAutoComplete } from "@bludata/components";

interface ImoCodiceLayoutCrmInterface {
  disabled: boolean;
}

export const ImoCodiceLayoutCrm: FC<ImoCodiceLayoutCrmInterface> = memo(
  ({ disabled }) => {
    const imoCodicePdvLayout = useWatch({ name: "imoCodicePdvLayout" });
    const { data, isLoading } = useLayoutCrm();
    const dispatch: AppDispatch = useDispatch();

    const { setValue } = useFormContext();

    useEffect(() => {
      dispatch(setIsLoadingForm({ key: "layoutCrm", value: isLoading }));
    }, [dispatch, isLoading]);

    const handleOnChange = useCallback(
      (_: any, newValue: any) => {
        if (newValue) {
          setValue("imoCodicePdvLayout", newValue.codiceFiliale);
        } else setValue("imoCodicePdvLayout", "");
      },
      [setValue]
    );

    const getValue = useCallback(() => {
      if (data && imoCodicePdvLayout) {
        const layout = data?.find(
          (option: any) => imoCodicePdvLayout === option.codiceFiliale
        );

        if (layout) return layout;
      }
      return null;
    }, [data, imoCodicePdvLayout]);

    return (
      <Stack flex="1">
        <Stack flex="1"></Stack>
        <BluAutoComplete
          value={getValue()}
          onChange={handleOnChange}
          data={data ?? []}
          getOptionLabel={(data: any) => {
            return `${data.layout}`;
          }}
          // disableClearable
          disabled={disabled}
          sx={{ flex: 1 }}
          renderOption={(props: any, option: any) => {
            return (
              <li {...props} key={nanoid()}>
                {`${option.layout}`}
              </li>
            );
          }}
          isOptionEqualToValue={(option: any, value: any) => {
            return (
              option && value && option.codiceFiliale === value.codiceFiliale
            );
          }}
          renderInput={(params: any) => (
            <TextField {...params} label="Layout" disabled={disabled} />
          )}
        />
      </Stack>
    );
  }
);
