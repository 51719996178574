import { ShopSignInterface } from "../../../interfaces/shop-sign";
import { defaultShopSign } from "../utils/default-shop-sign";

let initialShopSign: ShopSignInterface = defaultShopSign;

let logoFile: any = undefined;

let faviconFile: any = undefined;

let firstPath = "/api";

export const setDefaultShopSign = (value: ShopSignInterface) => {
  initialShopSign = value;
};

export const setLogoFile = (logo: any) => {
  logoFile = logo;
};

export const setFaviconFile = (favicon: any) => {
  faviconFile = favicon;
};

export const getDefaultShopSign = () => initialShopSign;

export const getPath = () => firstPath;

export const getLogoFile = () => logoFile;

export const getFaviconFile = () => faviconFile;

export const resetDefaultShopSign = () => {
  initialShopSign = defaultShopSign;
  logoFile = undefined;
};

export const setPath = (value: string) => {
  firstPath = value;
};
