import { LinearProgress, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { FC, memo, useEffect, useState } from "react";
import { getRotaPath } from "../../../../lib/white-cache";
import { AppDispatch, RootState } from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { setImportCheck, setStep } from "../../../../store/import";
import { importCheck } from "../../../../api/import-check";

interface UploadInterface {
  isSingleCalendarManagement: boolean;
  documentFormat: "bludata" | "factorial";
}

export const Upload: FC<UploadInterface> = memo(
  ({ isSingleCalendarManagement, documentFormat }) => {
    const [progress, setProgress] = useState<number>(-1);
    const {
      step,
      pointOfSaleId,
      file,
      importCheck: ic,
    } = useSelector((state: RootState) => state.rotaImport);
    const queryClient = useQueryClient();
    let url = `${getRotaPath()}/DualCalendarImport`;
    if (isSingleCalendarManagement)
      url = `${getRotaPath()}/SharedCalendarImport`;

    if (documentFormat === "factorial") {
      url += "/Factorial";
    }
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
      const check = async () => {
        try {
          if (ic !== null && documentFormat === "factorial") return true;
          const checkImport = await importCheck(
            pointOfSaleId,
            file,
            isSingleCalendarManagement,
            documentFormat
          );
          dispatch(setImportCheck(checkImport));

          if (
            !checkImport ||
            checkImport?.pointOfSaleError ||
            (checkImport?.usersError ?? []).length > 0 ||
            (checkImport?.usersNotMapped ?? []).length > 0 ||
            (checkImport?.pointOfSaleNotMapped ?? []).length > 0 ||
            (checkImport?.rowsErrors ?? []).length > 0
          ) {
            dispatch(setStep("error"));
            return false;
          }

          setProgress(0);
          return true;
        } catch (error) {
          dispatch(setStep("error"));
          return false;
        }
      };
      const save = async () => {
        try {
          axios({
            url: `${url}/Import`,
            data: {
              pointOfSaleId,
              File: file,
              CleanUserCalendars: false,
              companyId: null,
            },
            headers: {
              accept: "*",
              "Content-Type": "multipart/form-data",
            },
            method: "POST",
            onDownloadProgress: (progressEvent) => {
              const xhr = progressEvent.event.target;
              let { responseText } = xhr;

              try {
                responseText = responseText.split("\n");
                const result = responseText[responseText.length - 2];
                const res = JSON.parse(result);
                setProgress(res.Progress);
                if (res.Status === 2 || res.Status === 3) {
                  dispatch(setStep(res.Status === 2 ? "confirm" : "error"));

                  queryClient.invalidateQueries(["turni-point-of-sale"], {
                    type: "active",
                  });
                  queryClient.removeQueries(["turni-point-of-sale"], {
                    type: "inactive",
                  });

                  queryClient.invalidateQueries(["turni-user"], {
                    type: "active",
                  });
                  queryClient.removeQueries(["turni-user"], {
                    type: "inactive",
                  });
                }
              } catch (error) {
                console.error(error);
              }
            },
          });
          return;
        } catch (error) {}
      };

      const importFile = async () => {
        const isOK = await check();
        if (isOK) await save();
      };
      if (step === "import") {
        importFile();
      }
    }, [
      dispatch,
      documentFormat,
      file,
      ic,
      isSingleCalendarManagement,
      pointOfSaleId,
      queryClient,
      step,
      url,
    ]);

    return (
      <Stack flex="1" sx={{ height: "120px" }}>
        <Stack flex="1" sx={{ pb: "40px" }}>
          <Stack sx={{ height: "60px" }}>
            {documentFormat === "bludata" && ic && ic?.usersNumber ? (
              <>
                <Typography>
                  Stiamo importando il calendario con le seguenti informazioni:
                </Typography>
                {ic && ic?.usersNumber && (
                  <Typography component="li">
                    {`il numero di utenti presenti nel punto vendita è: ${ic.usersNumber}; `}
                  </Typography>
                )}
              </>
            ) : (
              <Typography sx={{ pt: "40px", height: "100%" }}>
                Caricamento in corso...
              </Typography>
            )}
          </Stack>
          <Stack sx={{ height: "fit-content", width: "100%", margin: "auto" }}>
            <LinearProgress
              variant={progress === -1 ? undefined : "determinate"}
              value={progress === -1 ? undefined : progress}
              sx={{ height: 10 }}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  }
);
