import { Autocomplete, Stack, TextField } from "@mui/material";
import { FC, memo, useCallback, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { nanoid } from "nanoid";

interface FilialeSelectionInterface {
  disabled: boolean;
}

export const FilialeSelection: FC<FilialeSelectionInterface> = memo(
  ({ disabled }) => {
    const filiale = useWatch({ name: "filiale" });
    const { pointOfSales } = useSelector(
      (state: RootState) => state.newUsersRoot
    );
    const { setValue } = useFormContext();

    useEffect(() => {
      if (!disabled && filiale && filiale === "") {
        const keys = Object.keys(pointOfSales);
        if (keys.length > 0) {
          setValue(
            "blubookingPointOfSaleId",
            pointOfSales[keys[0]].blubookingId
          );
          setValue("filiale", pointOfSales[keys[0]].filiale);
        }
      }
    }, [disabled, filiale, pointOfSales, setValue]);

    const getValue = useCallback(() => {
      if (pointOfSales && filiale) {
        const find = Object.values(pointOfSales).find(
          (pos: any) => pos.filiale === filiale
        );
        if (find) return find;
      }
      return undefined;
    }, [filiale, pointOfSales]);

    const getOptions = useCallback(() => {
      if (pointOfSales) return Object.values(pointOfSales);
      return [];
    }, [pointOfSales]);

    const handleOnChange = useCallback(
      (_: any, newValue: any) => {
        setValue("blubookingPointOfSaleId", newValue.blubookingId);
        setValue("filiale", newValue.filiale);
      },
      [setValue]
    );

    return (
      <>
        <Stack
          sx={{
            flex: 1,
          }}
        >
          <Autocomplete
            value={getValue()}
            onChange={handleOnChange}
            options={getOptions()}
            getOptionLabel={(data: any) => {
              return `${data.branchName} - ${data.internalCode}`;
            }}
            freeSolo
            disabled={disabled}
            disableClearable
            sx={{ flex: 1 }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={nanoid()}>
                  {`${option.branchName} - ${option.internalCode}`}
                </li>
              );
            }}
            renderInput={(params: any) => (
              <TextField {...params} label="Filiale" disabled={disabled} />
            )}
          />
        </Stack>
      </>
    );
  }
);
