import { FC, memo, useCallback } from "react";
import { IconButton } from "@mui/material";
import {
  AppDispatch,
  RootState,
  useAppSelector,
} from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGridPremium,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridEventListener,
  GridRowParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { EditRow } from "./EditRow";
import { ExtraRangeCell } from "./ExtraRangeCell";
import { ExtraFooter } from "./DataGridFooter";
import { setDeleteExtraHour } from "../../../../store/extra-hours";
import { getUsersWhiteCache } from "../../../../lib/white-cache";
import { bluBookingLoginData } from "../../../../../../store/slices/root";

interface ExtraHoursInterface {
  pointOfSaleId: string;
  disabled: boolean;
}

export const ExtraHoursDataGrid: FC<ExtraHoursInterface> = memo(
  ({ pointOfSaleId, disabled }) => {
    const { isSingleCalendarManagement } = useAppSelector(bluBookingLoginData);

    const apiRef = useGridApiRef();

    const { extra } = useSelector(
      (state: RootState) => state.newUserExtraHours
    );
    const dispatch: AppDispatch = useDispatch();

    const onRowClick = useCallback<GridEventListener<"rowClick">>(
      (params) => {
        apiRef.current.toggleDetailPanel(params.id);
      },
      [apiRef]
    );

    const getValue = useCallback(() => {
      if (extra && pointOfSaleId in extra) {
        const dates = Object.keys(extra[pointOfSaleId])
          .map((key) => {
            return {
              extraKey: key,
              ...extra[pointOfSaleId][key],
            };
          })
          .flat();
        return dates;
      }
      return [];
    }, [pointOfSaleId, extra]);

    const handleDelete = useCallback(
      (row: any) => {
        const temp = { ...row };
        dispatch(
          setDeleteExtraHour({
            key: row.extraKey,
            idShift: temp.shift?.id,
            idCalendar: temp.calendar?.id,
            pointOfSaleId,
          })
        );
      },
      [dispatch, pointOfSaleId]
    );

    return (
      <div style={{ width: "100%", padding: "0 5px", boxSizing: "border-box" }}>
        <DataGridPremium
          apiRef={apiRef}
          getRowId={(row: any) => `${row.extraKey}`}
          autoHeight
          sx={{
            "& .MuiDataGrid-cell": {
              outline: "none!important",
            },
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
          disableColumnMenu
          density="compact"
          hideFooterSelectedRowCount
          getRowHeight={() => 70}
          editMode="row"
          onProcessRowUpdateError={(error) => console.log(error)}
          experimentalFeatures={{ newEditingApi: true }}
          columns={[
            { field: "extraKey", headerName: "ID", hide: true },
            {
              field: "pointOfSaleId",
              headerName: "pointOfSaleId",
              hide: true,
            },
            {
              field: "startDate",
              headerName: "Giorno inizio",
              width: 150,
              valueFormatter: (params: any) =>
                params?.value.format("DD/MM/YYYY"),
            },
            {
              field: "endDate",
              headerName: "Giorno fine",
              width: 150,
              valueFormatter: (params: any) =>
                params?.value.format("DD/MM/YYYY"),
            },
            {
              field: "shift",
              headerName: isSingleCalendarManagement
                ? "Orari"
                : "Orari di lavoro",
              align: "center",
              headerAlign: "center",
              flex: 1,
              renderCell: (params: any) => (
                <ExtraRangeCell params={params} turniType="shift" />
              ),
            },
            {
              field: "calendar",
              headerName: "Orari appuntamento",
              flex: 1,
              hide: isSingleCalendarManagement,
              align: "center",
              headerAlign: "center",
              renderCell: (params: any) => (
                <ExtraRangeCell params={params} turniType="calendar" />
              ),
            },
            {
              field: "extraCalendarType",
              headerName: "Motivazione",
              width: 100,
              align: "center",
              headerAlign: "center",
              valueFormatter: (params: any) => {
                switch (params?.value) {
                  case 0:
                    return "Altro";
                  case 1:
                    return "Permesso";
                  case 2:
                    return "Malattia";
                  case 3:
                    return "Ferie";
                  default:
                    return "-";
                }
              },
            },
            {
              field: "delete",
              headerName: "",
              sortable: false,
              width: 30,
              hide: disabled,
              renderCell: (params: any) => {
                return (
                  <IconButton onClick={() => handleDelete(params.row)}>
                    <DeleteIcon />
                  </IconButton>
                );
              },
            },
            {
              ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
              hide: disabled,
              renderCell: (params) => {
                return (
                  <IconButton
                    size="small"
                    tabIndex={-1}
                    aria-label={params.value ? "Close" : "Open"}
                  >
                    {params.value ? (
                      <CloseIcon />
                    ) : (
                      <EditIcon fontSize="inherit" />
                    )}
                  </IconButton>
                );
              },
            },
          ]}
          rows={getValue()}
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={({ row }: GridRowParams<any>) => (
            <EditRow row={row} />
          )}
          onRowClick={onRowClick}
          components={{
            Footer: () => (
              <ExtraFooter
                disabled={disabled}
                isSingleCalendarManagement={false}
                pointOfSaleId={pointOfSaleId}
              />
            ),
          }}
        />
      </div>
    );
  }
);
