import {
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
  InputLabel,
  Box,
} from "@mui/material";
import {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { getUsersWhiteCache } from "../../lib/white-cache";
import { BluAutoComplete } from "@bludata/components";
import { nanoid } from "nanoid";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";

interface UsersGridFiltersInterface {
  gridSearch: string;
  setGridSearch: (value: string) => void;

  isActive: boolean;
  setIsActive: (value: boolean) => void;

  hasAgenda: boolean;
  setHasAgenda: (value: boolean) => void;

  optician: boolean;
  setOptician: (value: boolean) => void;

  filterPointOfSale: string;
  setFilterPointOfSale: (value: string) => void;
}

export const UsersGridFilters: FC<UsersGridFiltersInterface> = ({
  gridSearch,
  setGridSearch,

  isActive,
  setIsActive,

  hasAgenda,
  setHasAgenda,

  optician,
  setOptician,

  filterPointOfSale,
  setFilterPointOfSale,
}): JSX.Element => {
  const [searchValue, setSearchValue] = useState("");
  const { isFocusWeb } = getUsersWhiteCache();
  const { pointOfSales } = useSelector(
    (state: RootState) => state.newUsersRoot
  );
  useEffect(() => {
    const delayFn = setTimeout(() => {
      if (searchValue !== gridSearch) setGridSearch(searchValue);
    }, 250);

    return () => {
      clearTimeout(delayFn);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const getPointOfSalesValue = useCallback(() => {
    if (
      filterPointOfSale &&
      pointOfSales &&
      filterPointOfSale in pointOfSales
    ) {
      return pointOfSales[filterPointOfSale];
    }
    return null;
  }, [filterPointOfSale, pointOfSales]);

  const setHasAgendaCheckBox = useCallback(
    (_: SyntheticEvent<Element, Event>, checked: boolean) => {
      setHasAgenda(checked);
    },
    [setHasAgenda]
  );

  const setIsActiveCheckBox = useCallback(
    (_: SyntheticEvent<Element, Event>, checked: boolean) => {
      setIsActive(checked);
    },
    [setIsActive]
  );

  const setOpticianCheckBox = useCallback(
    (_: SyntheticEvent<Element, Event>, checked: boolean) => {
      setOptician(checked);
    },
    [setOptician]
  );

  return (
    <Stack
      direction={"row"}
      justifyContent="start"
      alignItems="end"
      spacing={1}
      sx={{ mt: "10px!important", mb: "15px!important" }}
    >
      <TextField
        label="Cerca per utente"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchValue(e.target.value);
        }}
        fullWidth
        sx={{ maxWidth: 300 }}
        value={searchValue}
        InputProps={{
          endAdornment: searchValue.length ? (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                sx={{
                  p: 0,
                  top: 5,
                  right: 5,
                  position: "absolute",
                }}
                size="small"
                onClick={() => {
                  setSearchValue("");
                }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          ),
        }}
      />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="start"
        spacing={2}
      >
        <InputLabel>Filiale</InputLabel>
        <BluAutoComplete
          size="small"
          sx={{ width: 200 }}
          renderOption={(props: any, option: any) => {
            if (isFocusWeb)
              return (
                <Box component="li" {...props} key={nanoid()}>
                  {`${option.branchName} - ${option.internalCode}`}
                </Box>
              );
            return (
              <Box component="li" {...props} key={nanoid()}>
                {option.branch ||
                  option.branchName + " - " + option.internalCode}
              </Box>
            );
          }}
          getOptionLabel={(option: any) => {
            return option.branch || option.branchName;
          }}
          data={Object.values(pointOfSales ?? {})}
          value={getPointOfSalesValue()}
          onChange={(_: any, newValue: any) => {
            if (newValue === null || newValue === "") {
              setFilterPointOfSale("");
              return;
            }
            if (isFocusWeb) setFilterPointOfSale(newValue.filiale);
            else setFilterPointOfSale(newValue.id);
          }}
          isOptionEqualToValue={(option: any, value: any) => {
            return option.id === value.id;
          }}
        />
      </Stack>
      <FormControlLabel
        control={<Checkbox checked={isActive} />}
        onChange={setIsActiveCheckBox}
        label="Attivo"
      />
      <FormControlLabel
        control={<Checkbox checked={hasAgenda} />}
        onChange={setHasAgendaCheckBox}
        label="Agenda"
      />
      {isFocusWeb && (
        <FormControlLabel
          control={<Checkbox checked={optician} />}
          onChange={setOpticianCheckBox}
          label="Ottico"
        />
      )}
    </Stack>
  );
};
