import React, { FC, useEffect, useState } from 'react'

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  TooltipItem,
  ChartType
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import moment from 'moment'

import 'moment/locale/it'
import { Typography } from '@mui/material'
ChartJS.register(ArcElement, Tooltip, Legend, Title)

const defColor = [
  [
    '#1984c5',
    '#22a7f0',
    '#63bff0',
    '#a7d5ed',
    '#e2e2e2',
    '#e1a692',
    '#de6e56',
    '#e14b31',
    '#c23728'
  ],
  [
    '#ffb400',
    '#d2980d',
    '#a57c1b',
    '#786028',
    '#363445',
    '#48446e',
    '#5e569b',
    '#776bcd',
    '#9080ff'
  ],
  [
    '#54bebe',
    '#76c8c8',
    '#98d1d1',
    '#badbdb',
    '#dedad2',
    '#e4bcad',
    '#df979e',
    '#d7658b',
    '#c80064'
  ],
  [
    '#e27c7c',
    '#a86464',
    '#6d4b4b',
    '#503f3f',
    '#333333',
    '#3c4e4b',
    '#466964',
    '#599e94',
    '#6cd4c5'
  ]
]

const randomNumber = (max: number, min: number) => {
  return Math.floor(Math.random() * max) + min
}

interface dataInterface {
  label: string
  data: number[]
  backgroundColor?: string
  borderColor?: string
  borderWitdh?:number | null
}

interface BarProps {
  dataObj: {
    labels: string[]
    datasets: dataInterface[]
  }
  legend?: {
    position?: 'top' | 'left' | 'bottom' | 'right'
    align?: 'start' | 'center' | 'end'
  }
  responsive?: boolean
  title?: {
    text: string
    fontSize?: number
  }
  onClickCallback?: (event: any) => void
  padding?: {
    left?: number
    right?: number
    top?: number
    bottom?: number
    p?: number
  }
  tooltip?: {
    head: string[]
    footer: string[]
  }
}

const BluDoughnut: FC<BarProps> = React.memo(
  (props) => {
    //!Setto la lingua per le date

    moment.locale('it')

    const {
      dataObj,
      legend,
      responsive,
      title,
      onClickCallback,
      padding,
      tooltip
    } = props

    const { left, right, bottom, top } = padding || {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }

    const { p } = padding || { p: 0 }

    const [data, setData] = useState<dataInterface[]>([])

    useEffect(() => {
      let newData = dataObj.datasets.map((item) => {
        if (
          item.backgroundColor === undefined ||
          item.borderColor === undefined
        ) {
          return {
            ...item,
            backgroundColor: defColor[randomNumber(3, 0)][randomNumber(9, 0)],
            borderColor: defColor[randomNumber(3, 0)][randomNumber(9, 0)]
          }
        } else {
          return { ...item }
        }
      })
      setData(newData)
    }, [])

    const footer = (value: TooltipItem<ChartType>[]) => {
      return tooltip!.footer + ' ' + `${value[0]['raw']}`
    }

    const header = (value: TooltipItem<ChartType>[]) => {
      return tooltip!.head + ' ' + `${value[0]['raw']}`
    }

    let checkData = false
    let found = false

    data.forEach((item) => {
      if (item.data.length) {
        item.data.forEach((value: number) => {
          if (value > 0 || found) {
            checkData = false
            found = true
          } else {
            checkData = true
          }
        })
      }
    })

    return (
      <>
        {checkData && (
          <Typography
            variant="h5"
            fontWeight={'bold'}
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '50%',
              margin: 'auto',
              left: 0,
              right: 0,
              textAlign: ' center'
            }}
          >
            Dati non presenti
          </Typography>
        )}
        <Doughnut
          onClick={!!onClickCallback ? onClickCallback : () => {}}
          options={{
            responsive: !!responsive ? responsive : true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                align: !!legend?.align ? legend.align : 'center',
                position: !!legend?.position
                  ? legend.position
                  : ('top' as const),
                fullSize: true,
                display: true,
                labels: {
                  padding: 35,
                  textAlign: 'center',
                  boxWidth: 15,
                  boxHeight: 15,
                  font: {
                    size: 12
                  }
                }
              },
              title: {
                display: !!title ? true : false,
                text: !!title?.text ? title.text : '',
                font: {
                  size: !!title?.fontSize ? title.fontSize : 12
                }
              },
              tooltip: {
                callbacks: {
                  footer: !!tooltip ? footer : undefined,
                  title: !!tooltip ? header : undefined
                }
              }
            },
            layout: {
              padding: {
                left: p || left,
                right: p || right,
                top: p || top,
                bottom: p || bottom
              }
            }
          }}
          data={{
            labels: dataObj.labels,
            datasets: data as any
          }}
        />
      </>
    )
  },
  (oldProps, newProps) => {
    return oldProps.dataObj === newProps.dataObj
  }
)
export default BluDoughnut
