import axios from "axios";
import { StudioManagementInterface } from "../interfaces/studio-management";

export const manageStudio = async (
  studio: StudioManagementInterface
): Promise<boolean> => {
  try {
    await axios.post("/api/Studio/Management", studio);

    return true;
  } catch (err) {
    console.trace(err);
    return false;
  }
};
