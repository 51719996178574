import { Stack, Typography } from "@mui/material";
import { memo } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const ExportSuccess = memo(() => {
  return (
    <Stack flex="1">
      <Stack sx={{ pt: "20px" }}>
        <Stack direction="row" justifyContent="center">
          <CheckCircleIcon
            color="success"
            sx={{ fontSize: "80px", m: "auto", p: "20px 0 10px 0" }}
          />
        </Stack>
        <Typography
          fontWeight="bold"
          align="center"
          fontSize="20px"
          sx={{ p: "0px 0 5px 0" }}
        >
          Esportazione calendario completata
        </Typography>
      </Stack>
      <Typography
      // fontWeight={"bold"}
      >
        Esportazione del calendario avvenuta con successo.
      </Typography>
    </Stack>
  );
});
