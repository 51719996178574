import { store } from "../../../../../store";
import { manageStudio } from "../../../api/manage-studio";
import { getInitialValue } from "./white-cache";

export const updateStudio = async (
  data: any,
  studioId?: string,
  externalCode?: string
) => {
  try {
    const {
      studiForm: { serviceForm },
    } = store.getState();
    const initialServices = getInitialValue();
    let activeServices: any = {};
    let createServiceStudios = [];
    const deleteServiceStudios = [];

    if (studioId !== undefined) {
      for (const id of serviceForm) activeServices[id] = true;
      for (const id of Object.keys(initialServices)) {
        const value = activeServices !== undefined && id in activeServices;
        if (value !== initialServices[id].enabled)
          if (value) createServiceStudios.push({ serviceId: id, studioId });
          else deleteServiceStudios.push(initialServices[id]?.id ?? "");
      }
    } else
      createServiceStudios = serviceForm
        .map((id) => {
          return { serviceId: id };
        })
        .flat();
    const result = await manageStudio({
      studio: {
        isActive: data.isActive,
        name: data.name,
        description: data.description,
        pointOfSaleId: data.pointOfSaleId,
        color: "",
        studioId,
        externalCode,
      },
      createServiceStudios,
      deleteServiceStudios,
    });
    if (!result) return false;
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};
