import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ThreadInterface } from '../interfaces/thread'

export const getThreadInfo = async (
  TreadId: string
): Promise<ThreadInterface | null> => {
  try {
    const {
      data: { data }
    } = await axios.get(`/Thread/${TreadId}`)

    if (data) {
      return { ...data, updatedAt: new Date(data.updatedAt) }
    }
    return null
  } catch (error) {
    console.log(error)
    return null
  }
}

export const useThreadInfo = (threadId: string, key: string = '') => {
  return useQuery({
    queryFn: async () => await getThreadInfo(threadId),
    queryKey: ['thread', threadId, key],
    retry: 2,
    retryDelay: 180000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
    // select: normalize,
  })
}
