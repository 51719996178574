import axios from "axios";
import { CalendarPointOfSaleInterface } from "../interfaces/calendar-point-of-sale";
import { ExtraCalendarPointOfSaleInterface } from "../interfaces/extra-calendar-point-of-sale";
import { getRotaPath } from "../lib/white-cache";

export const getCalendarPointOfSale = async (
  pointOfSaleId: string,
  startTime: string,
  endTime: string
): Promise<
  [CalendarPointOfSaleInterface[], ExtraCalendarPointOfSaleInterface[]]
> => {
  const fetchCalendarPointOfSale = async (): Promise<
    CalendarPointOfSaleInterface[]
  > => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getRotaPath()}/CalendarPointOfSale/PointOfSale/${pointOfSaleId}`,
        {
          params: {
            startTime,
            endTime,
          },
        }
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  const fetchExtraCalendarPointOfSale = async (): Promise<
    ExtraCalendarPointOfSaleInterface[]
  > => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getRotaPath()}/ExtraCalendarPointOfSale/PointOfSale/${pointOfSaleId}`,
        {
          params: {
            startTime,
            endTime,
          },
        }
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  try {
    const responses = await Promise.all([
      fetchCalendarPointOfSale(),
      fetchExtraCalendarPointOfSale(),
    ]);

    return responses as any;
  } catch (error) {
    return [[], []];
  }
};
