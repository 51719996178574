import { FC, memo, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { ImoCodiceLayoutFiliale } from "./ImoCodiceLayoutFiliale";
import { ImoCodiceLayoutCrm } from "./ImoCodiceLayoutCrm";

interface ImoCodiceLayoutInterface {
  disabled: boolean;
}

export const ImoCodiceLayout: FC<ImoCodiceLayoutInterface> = memo(
  ({ disabled }) => {
    const imoTipoUtente = useWatch({ name: "imoTipoUtente" });
    const GetContent = useMemo(() => {
      if (imoTipoUtente === 1)
        return <ImoCodiceLayoutFiliale disabled={disabled} />;
      if (imoTipoUtente === 2)
        return <ImoCodiceLayoutCrm disabled={disabled} />;
      return <></>;
    }, [disabled, imoTipoUtente]);

    return <>{GetContent}</>;
  }
);
