import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ThreadInterface } from '../interfaces/thread'

export const getThread = async (): Promise<ThreadInterface[]> => {
  try {
    const {
      data: { data }
    } = await axios.get('/Thread')

    // return [
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-08T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti",
    //   },
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-05T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti1",
    //   },
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-04T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti2",
    //   },
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2023-12-30T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti3",
    //   },
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2023-12-28T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti4",
    //   },
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2023-11-11T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti5",
    //   },
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-08T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti",
    //   },
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-05T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti1",
    //   },
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2024-01-04T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti2",
    //   },
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2023-12-30T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti3",
    //   },
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2023-12-28T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti4",
    //   },
    //   {
    //     openAiId: "thread_CoVCc3oVZuDj4gkVQ8L7asXH",
    //     openAiAssistantId: "asst_aApiD0ivBqomkM3ezR7GI20x",
    //     updatedAt: new Date("2023-11-11T07:41:37.273Z"),
    //     description: "Richiesta Blu Booking come si configurano gli utenti5",
    //   },
    // ];

    // const result= data.map((assistant: any) => {
    //   return { ...assistant, updatedAt: new Date(assistant.updatedAt) }
    // })

    // if (result.length) {

    // }

    return data.map((assistant: any) => {
      return { ...assistant, updatedAt: new Date(assistant.updatedAt) }
    })
  } catch (error) {
    console.log(error)
    return []
  }
}

export const useThread = (key: string = '') => {
  return useQuery({
    queryFn: async () => await getThread(),
    queryKey: ['thread', key],
    retry: 2,
    retryDelay: 180000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
    // select: normalize,
  })
}
