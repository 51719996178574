import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import React from "react";
import { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

export const Active = memo(() => {
  const [isActive] = useWatch({
    name: ["isActive"],
  });
  const { setValue, trigger } = useFormContext();
  const { openForm } = useSelector((state: RootState) => state.studiRoot);

  return (
    <Stack display="flex" sx={{ pt: "20px", pl: "20px" }}>
      <FormControlLabel
        label="Attivo"
        disabled={openForm === "info"}
        control={
          <Checkbox
            checked={isActive}
            onChange={() => {
              setValue("isActive", !isActive);
              trigger();
            }}
          />
        }
      />
    </Stack>
  );
});
