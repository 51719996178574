import { memo } from "react";
import { Avatar, Button, Stack } from "@mui/material";
import { FC, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { BluAlert } from "@bludata/components";
import ImageDefault from "../../../../../../assets/utentebase.png";
import { getUsersWhiteCache } from "../../../../lib/white-cache";

interface CustomUserImageInterface {
  disabled: boolean;
}

export const CustomUserImage: FC<CustomUserImageInterface> = memo(
  ({ disabled }) => {
    const userPhoto = useWatch({ name: "userPhoto" });
    const [error, setError] = useState("");
    const { setValue } = useFormContext();
    const { isFocusWeb } = getUsersWhiteCache();

    return (
      <Stack direction="column" spacing={1} sx={{ minWidth: "300px" }}>
        <BluAlert
          type="warning"
          show={!!error}
          text={error}
          handleClose={() => {
            setError("");
          }}
        />

        <Avatar
          sx={{
            mt: 2,
            width: 96,
            height: 96,
            border: "1px solid var(--divider-color)",
            margin: "auto !important",
          }}
          src={userPhoto ? userPhoto : ImageDefault}
        />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Button
            color="primary"
            component="label"
            startIcon={<FileUploadIcon />}
            onClick={() => {}}
            disabled={disabled}
          >
            Carica
            <input
              accept="image/png, image/jpeg"
              type="file"
              hidden
              onChange={(e: any) => {
                const fileReader = new FileReader();

                if (e.target.files[0].size > 5 * 1048576) {
                  setError("La dimensione massima è 5 mb");
                  return;
                }

                fileReader.readAsDataURL(e.target.files[0]);
                fileReader.onloadend = async (event: any) => {
                  setValue("userPhoto", event.target.result);

                  if (!isFocusWeb) setValue("photo", e.target.files[0]);
                  else setValue("photo", event.target.result);
                };
              }}
            />
          </Button>

          <Button
            color="primary"
            variant="outlined"
            disabled={disabled}
            onClick={() => {
              setValue("userPhoto", ImageDefault);
              if (!isFocusWeb) setValue("photo", null);
              else setValue("photo", ImageDefault);
            }}
          >
            Default
          </Button>
        </Stack>
      </Stack>
    );
  }
);
