import { BluTitle } from "@bludata/components";
import { Button, Chip, Stack } from "@mui/material";
import { FC, memo, useState } from "react";
import { useWatch } from "react-hook-form";
import { IMOActivationPopUp } from "./IMOActivationPopup";

export const IMOActivation: FC<{
  mode: "modify" | "info" | "new";
}> = memo(({ mode }) => {
  const isIMOActive = useWatch({ name: "isIMOActive" });
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Stack flex="1" gap={1}>
      {open && <IMOActivationPopUp setOpen={setOpen} />}
      <Stack direction="row" gap={1} justifyContent="start" alignItems="center">
        <BluTitle
          sx={{ mt: "18px !important" }}
          text={"Attivazione Il Mio Ottico"}
        />
        {isIMOActive && (
          <Chip
            label="APP attiva"
            color="success"
            sx={{ width: 100, mt: "15px" }}
          />
        )}
      </Stack>
      <Button
        sx={{ width: isIMOActive ? 87 : 75 }}
        onClick={() => setOpen(true)}
      >
        {isIMOActive ? "Riattiva" : "Attiva"}
      </Button>
    </Stack>
  );
});
