import "yup-phone-lite";
import { date, object, string } from "yup";
import moment from "moment";

const phoneRegExp = /^[+]?\d*([\s.-]?\d+)*$/;
// const phoneRegExp =/^[+]?\d*([.-]?\d+)*$/

const regexNumeber = /^\d*$/;

export const schema = object()
  .shape({
    username: string()
      .max(60, "Campo troppo lungo in Informazioni generali: Utente")
      .matches(/.+/, "Il campo Utente è obbligatorio"),
    sign: string()
      .max(6, "Campo troppo lungo in Informazioni generali: Sigla")
      .nullable(),
    name: string()
      //.required("Campo mancante in Informazioni generali: Nome")
      .max(50, "Campo troppo lungo in Informazioni generali: nome")
      .nullable(),
    surname: string()
      //.required("Campo mancante in Informazioni generali: Cognome")
      .max(60, "Campo troppo lungo in Informazioni generali: Cognome")
      .nullable(),
    email: string()
      .email("Campo errato in Informazioni generali: Email")
      .max(80, "Campo troppo lungo in Informazioni generali: Email")
      .nullable(),
    /*color: string()
      .max(20, "Campo troppo lungo in Informazioni generali: Colore"),*/
    telephone: string()
      .matches(phoneRegExp, "Numero di telefono non valido")
      .max(20, "Campo troppo lungo in Informazioni generali: Telefono")
      .nullable(true),
    postalCode: string()
      .matches(regexNumeber, "CAP non valido")
      .max(5, "Campo troppo lungo in Informazioni generali: CAP")
      .nullable(true),
    cellular: string()
      .matches(phoneRegExp, "Numero di cellulare non valido")
      .max(20, "Campo troppo lungo in Informazioni generali: Cellulare")
      .nullable(true),
    employmentDate: date().nullable(true),
    contractExpirationDate: date()
      .nullable(true)
      .test(
        "check-contractExpirationDate",
        "Data di scadenza contratto non valida", // a message can also be a function
        (_, testContext): boolean => {
          if (testContext.parent?.contractExpirationDate) {
            const start = testContext.parent?.employmentDate;
            if (start) {
              return moment(testContext.parent?.contractExpirationDate).isAfter(
                moment(start)
              );
            }
            return false;
          }
          return true;
        }
      ),
  })
  .required();
