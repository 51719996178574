import { Checkbox, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { store } from "../../../../../store";

const columnsFun = (isBluBookingActive: boolean) => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "isActive",
      headerName: "Attivo",
      width: 75,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} disabled />;
      },
    },
    {
      field: "company",
      headerName: "Azienda",
      width: 75,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} />;
      },
      hide: true,
    },
    {
      field: "branch",
      headerName: "Filiale",
      width: 160,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "left",
      hide: false,
    },
    {
      field: "internalCode",
      headerName: "Codice",
      width: 80,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      hide: false,
    },
    {
      field: "nameOnline",
      headerName: "Nome visualizzato",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      hide: !isBluBookingActive,
    },
    {
      field: "name",
      headerName: "Ragione sociale",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "address",
      headerName: "Indirizzo",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "city",
      headerName: "Città",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "shopSignId",
      headerName: "Layout",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      hide: !isBluBookingActive,
      renderCell: (params: GridRenderCellParams) => {
        const { shopSigns } = store.getState().newPointOfSaleRoot;
        if (shopSigns && params.value && params.value in shopSigns) {
          return <Typography>{shopSigns[params.value].name}</Typography>;
        }
        return <></>;
      },
    },

    {
      field: "isDoNotDisplay",
      headerName: "Non visualizzare",
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return <Checkbox checked={!!params.value} disabled />;
      },
    },
    {
      field: "Uff-Sede",
      headerName: "Uff-Sede",
      type: "number",
      width: 110,
      editable: false,
      hide: true,
    },
    {
      field: "Remoto",
      headerName: "Remoto",
      type: "number",
      width: 110,
      editable: false,
      hide: true,
    },
    {
      field: "Lab.",
      headerName: "Lab.",
      type: "number",
      width: 110,
      editable: false,
      hide: true,
    },
    {
      field: "Partita IVA",
      headerName: "Partita IVA",
      type: "number",
      width: 110,
      editable: false,
      hide: true,
    },
  ];
  return columns;
};

export default columnsFun;
