import { createTheme, Theme } from '@mui/material/styles'
import { itIT } from '@mui/material/locale'
import { getBludataTheme } from '../../layout/theme/theme'

export default function overrideBludataTheme(outerTheme?: Theme): Theme {
  if (outerTheme !== undefined) {
    return createTheme(
      {
        ...outerTheme,
        components: {
          ...outerTheme.components
        }
      },
      itIT
    )
  }
  return getBludataTheme('light')
}
