import { BluTitle } from "@bludata/components";
import { Stack } from "@mui/material";
import { FC } from "react";
import { getUsersWhiteCache } from "../../../../lib/white-cache";
import { CustomTextField } from "../../common/CustomTextField";

export const Contact: FC<{
  mode: "modify" | "info" | "new";
}> = ({ mode }): JSX.Element => {
  const { isFocus10 } = getUsersWhiteCache();

  return (
    <Stack direction="column" gap={1} flex="1">
      <BluTitle text={"Contatti"} />
      <Stack direction="column" gap={1}>
        <Stack gap={1} flex="1">
          <Stack direction="row" gap={1} flex="1">
            <CustomTextField
              name="telephone"
              label="Telefono"
              disabled={mode === "info" || isFocus10}
              sx={{ flex: "1" }}
            />
            <CustomTextField
              name="cellular"
              label="Cellulare"
              disabled={mode === "info" || isFocus10}
              sx={{ flex: "1" }}
            />
          </Stack>

          <CustomTextField
            name="email"
            label="Email"
            disabled={mode === "info" || isFocus10}
            sx={{ flex: "1" }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
