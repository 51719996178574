import axios from 'axios'

export const createThread = async (
  assistantId: string,
  description: string
): Promise<string> => {
  try {
    const {
      data: { data: result }
    } = await axios.post('/Thread', {
      openAiAssistantId: assistantId,
      description: description
    })
    return result
  } catch (err) {
    console.error(err)
    return ''
  }
}
