import { FC, memo, useCallback } from "react";
import { Button, Stack, TableCell, TableFooter } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  setAddPointOfSalePopUp,
  setDefaultHours,
  setImportDefaultHoursPopUp,
} from "../../../../store/default-hours";
import { BluAsyncDialog } from "@bludata/components";

interface DefaultFooterInterface {
  disabled: boolean;
  isSingleCalendarManagement: boolean;
  pointOfSaleId: string;
  setPointOfSaleId: (value: string) => void;
}

export const DefaultFooter: FC<DefaultFooterInterface> = memo(
  ({
    pointOfSaleId,
    isSingleCalendarManagement,
    disabled,
    setPointOfSaleId,
  }): JSX.Element => {
    const dispatch: AppDispatch = useDispatch();
    const { defaultHours } = useSelector(
      (state: RootState) => state.newUserDefaultHours
    );

    const handleClickImport = useCallback(() => {
      dispatch(setImportDefaultHoursPopUp(true));
    }, [dispatch]);

    const handleClickAddPointOfSale = useCallback(() => {
      dispatch(setAddPointOfSalePopUp(true));
    }, [dispatch]);

    const handleDeletePointOfSale = useCallback(() => {
      BluAsyncDialog({
        title: "Attenzione",
        type: "warning",
        message: "Vuoi davvero eliminare gli orari di questo punto vendita? ",
        confimButton: "Sì",
        declineButton: "No",
        onConfirmClick: () => {
          if (pointOfSaleId !== null) {
            const temp = { ...defaultHours };
            delete temp[pointOfSaleId];
            const keys = Object.keys(temp);
            dispatch(setDefaultHours(temp));
            if (keys.length > 0) setPointOfSaleId(keys[0]);
            else setPointOfSaleId("");
          }
        },
        sx: { "& .MuiDialog-paper": { maxWidth: "370px" } },
      });
    }, [defaultHours, dispatch, pointOfSaleId, setPointOfSaleId]);

    return (
      <TableFooter
        style={{
          border: "1px solid rgba(224, 224, 224, 1)",
          borderTopWidth: 0,
        }}
      >
        <TableCell
          colSpan={isSingleCalendarManagement ? 7 : 8}
          style={{ padding: 8 }}
        >
          <Stack flex="1" direction="row" gap={1}>
            <Stack flex="1" gap={1}>
              {!disabled && pointOfSaleId !== "" && (
                <Button
                  color="primary"
                  startIcon={<ScheduleIcon />}
                  variant="outlined"
                  sx={{ width: "fit-content" }}
                  onClick={handleClickImport}
                >
                  Importa
                </Button>
              )}
            </Stack>
            {/* <Stack direction="row" gap={1}>
              {!disabled && (
                <Button color="primary" onClick={handleClickAddPointOfSale}>
                  Aggiungi punto vendita
                </Button>
              )}
              {!disabled && pointOfSaleId !== "" && (
                <Button color="error" onClick={handleDeletePointOfSale}>
                  Elimina
                </Button>
              )}
            </Stack> */}
          </Stack>
        </TableCell>
      </TableFooter>
    );
  }
);
