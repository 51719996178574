import { Checkbox } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

export const EnableCell = (params: GridRenderCellParams<any>) => {
  const { openForm } = useSelector((state: RootState) => state.serviceRoot);

  return (
    <Checkbox checked={params?.value ?? false} disabled={openForm === "info"} />
  );
};
