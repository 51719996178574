import { TableHead, TableRow, TableCell, useTheme, Stack } from "@mui/material";
import moment from "moment";
import { FC, memo, useMemo } from "react";
import { nanoid } from "nanoid";

interface TableHeaderInterface {}
export const TableHeaderDay: FC<TableHeaderInterface> = memo(() => {
  const theme = useTheme();

  const GetHeader = useMemo(() => {
    const hours = Array.from({ length: 19 }, (_, i) => i + 5);
    return (
      <>
        {hours.map((el: number) => {
          return (
            <TableCell
              id={`calendar-point-of-sale-header-${el}`}
              key={nanoid()}
              align="center"
              style={{
                width: "50px ",
                height: "40px",
                padding: 0,
                margin: 0,
                fontWeight: moment().isSame(hours, "hour") ? "bold" : undefined,
                zIndex: 0,

                borderTop: "1px solid",
                borderColor: "var(--divider-color)",
                boxSizing: "border-box",
              }}
            >
              <Stack
                flex="1"
                sx={{
                  p: 0,
                  backgroundColor:
                    theme?.palette?.mode === "dark"
                      ? "var(--background-color) !important"
                      : "#f0f0f0 !important",
                  height: "38px",
                  textAlign: "center",
                }}
                justifyContent={"center"}
              >
                {`${el > 9 ? el : `0${el}`}:00`}
              </Stack>
            </TableCell>
          );
        })}
      </>
    );
  }, [theme?.palette?.mode]);

  return (
    <TableHead>
      <TableRow
        style={{
          backgroundColor: "var(--closed-color)",
        }}
      >
        {GetHeader}
      </TableRow>
    </TableHead>
  );
});
