import axios from "axios";
import { getRotaPath } from "../lib/white-cache";

export const updateExtraCalendarPointOfSale = async (
  pointOfSaleId: string,
  startTime1Param: string | null,
  endTime1Param: string | null,
  startTime2Param: string | null,
  endTime2Param: string | null,
  startValidDate: string,
  isClosingDay: boolean,
  id: string
): Promise<boolean> => {
  try {
    let [startTime1, startTime2, endTime1, endTime2] = [
      startTime1Param,
      startTime2Param,
      endTime1Param,
      endTime2Param,
    ];
    if (isClosingDay) {
      [startTime1, startTime2, endTime1, endTime2] = [null, null, null, null];
    }
    await axios.put(`${getRotaPath()}/ExtraCalendarPointOfSale/${id}`, {
      pointOfSaleId,
      startTime1,
      endTime1,
      startTime2,
      endTime2,
      startValidDate,
      endValidDate: startValidDate,
      isClosingDay,
    });
    return true;
  } catch (error) {
    return false;
  }
};
