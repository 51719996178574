import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FC, memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface UnitInterface {
  name: string;
  disabled?: boolean;
}

export const Unit: FC<UnitInterface> = memo(({ name, disabled = false }) => {
  const field = useWatch({ name });
  const { setValue, trigger } = useFormContext();

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      setValue(name, Number(event.target.value));
      trigger(name);
    },
    [name, setValue, trigger]
  );
  return (
    <FormControl
      size="small"
      sx={{ minWidth: "120px", verticalAlign: "bottom" }}
    >
      <Select
        disabled={disabled}
        fullWidth
        value={field}
        onChange={handleChange}
      >
        <MenuItem key={0} value={0}>
          Minuti
        </MenuItem>
        <MenuItem key={1} value={1}>
          Ore
        </MenuItem>
        <MenuItem key={2} value={2}>
          Giorni
        </MenuItem>
        <MenuItem key={3} value={3}>
          Settimane
        </MenuItem>
      </Select>
    </FormControl>
  );
});
