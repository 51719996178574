import '../../styles/color-picker.css'

import { Button, Fade, Menu, Stack, Typography } from '@mui/material'
import React, { useMemo, useRef, useState } from 'react'
import { memo } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { ColorPickerInterface } from './interface'
import {
  ColorPickerComponent,
  ColorPickerMode
} from '@syncfusion/ej2-react-inputs'
import hexToRgba from 'hex-to-rgba'

export const ColorPicker = memo(
  ({ value, onChange, ...rest }: ColorPickerInterface) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [mode, setMode] = useState<ColorPickerMode>('Palette')
    const open = Boolean(anchorEl)
    const ref = useRef<HTMLButtonElement>(null)
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
      setAnchorEl(event?.currentTarget)
    }
    const handleClose = (): void => {
      setAnchorEl(null)
    }

    const MenuContainer = useMemo(() => {
      return (
        <Menu
          MenuListProps={{
            'aria-labelledby': 'fade-button'
          }}
          className="blu-color-picker"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          sx={{
            '& .MuiList-root': {
              p: 0
            },
            '& .MuiPaper-root': {
              borderRadius: 0
            }
          }}
        >
          <Stack flexDirection="column">
            <ColorPickerComponent
              mode={mode}
              inline={true}
              change={onChange}
              showButtons={false}
              modeSwitcher={false}
              value={value}
              {...rest}
            />
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              sx={{ p: ' 10px 5px' }}
            >
              {mode === 'Palette' ? (
                <div
                  onClick={() => setMode('Picker')}
                  style={{
                    width: '30px',
                    height: '30px',
                    background: `linear-gradient(90deg, ${hexToRgba(
                      value,
                      1
                    )} 0%, ${hexToRgba(value, 0.2)} 100%)`,
                    cursor: 'pointer',
                    border: '0.5px solid lightgray'
                  }}
                />
              ) : (
                <div
                  style={{
                    width: '30px',
                    height: '30px',
                    cursor: 'pointer',
                    border: '0.5px solid lightgray'
                  }}
                  onClick={() => setMode('Palette')}
                >
                  <Stack flexDirection="row">
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: '#22c1c3'
                      }}
                    />
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: '#fdbb2d'
                      }}
                    />
                  </Stack>
                  <Stack flexDirection="row">
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: '#d82923'
                      }}
                    />{' '}
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: '#33c633'
                      }}
                    />
                  </Stack>
                </div>
              )}

              <Button
                onClick={() => setAnchorEl(null)}
                sx={{ width: 'fit-content' }}
              >
                <Typography>Chiudi</Typography>
              </Button>
            </Stack>
          </Stack>
        </Menu>
      )
    }, [ref, open, handleClose, anchorEl])

    return (
      <>
        <Button
          {...rest}
          ref={ref}
          onClick={handleClick}
          sx={{
            pl: 0,
            pr: 0,
            width: '100%',
            ...rest.sx
          }}
          variant="outlined"
        >
          <Stack flexDirection="row" flex="1">
            <Stack
              style={{
                flex: 1,
                backgroundColor: value,
                border: '0.5px solid lightgrey',
                margin: '0 3px'
              }}
            />
            {open ? (
              <ArrowDropUpIcon sx={{ color: 'var(--color)' }} />
            ) : (
              <ArrowDropDownIcon sx={{ color: 'var(--color)' }} />
            )}
          </Stack>
        </Button>

        {MenuContainer}
      </>
    )
  }
)
