import React, { FC, useMemo } from 'react'
import { memo } from 'react'
import { ThreadInterface } from '../../interfaces/thread'
import { Chip, Stack } from '@mui/material'
import { useAssistantInfo } from '../../api/get-assistant-info'
import { nanoid } from '@reduxjs/toolkit'

interface ChatTextFieldIterface {
  threadInfo: ThreadInterface
  handleQuestionClick: (message: string) => void
}

export const Questions: FC<ChatTextFieldIterface> = memo(
  ({ threadInfo, handleQuestionClick }) => {
    const { data } = useAssistantInfo(threadInfo.openAiAssistantId)

    const GetQuestions = useMemo(() => {
      if (data)
        return (
          <>
            {(data?.questions ?? []).map((question: string) => (
              <Chip
                key={nanoid()}
                label={question}
                onClick={() => handleQuestionClick(question)}
                sx={{ width: 'fit-content', cursor: 'pointer' }}
              />
            ))}
          </>
        )

      return <></>
    }, [data, handleQuestionClick])
    return (
      <Stack
        gap={1}
        flexWrap="wrap"
        sx={{ width: '100%', padding: '10px' }}
        flexDirection="unset"
        justifyContent="center"
      >
        {GetQuestions}
      </Stack>
    )
  }
)
