import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShopSignInterface } from "../../../interfaces/shop-sign";
import { PointOfSalesInterface } from "../interfaces/PointOfSale_interface";
import { ServicePointOfSaleInterface } from "../interfaces/ServicePointOfSale_interface";
import { Dayjs } from "dayjs";
import { RootState } from "../../../store";

export interface PointOfSaleRootSliceState {
  pointOfSales: {
    [x: string]: PointOfSalesInterface;
  };
  shopSigns: {
    [x: string]: ShopSignInterface;
  };
  dataLoads: boolean;
  openForm?: "modify" | "info" | "new";
  currentPointOfSale: string;
  deletePointOfSale?: string;
  services: {
    [x: string]: ServicePointOfSaleInterface;
  };
  copiedDefaultRow?: {
    startTime1: Dayjs | null;
    startTime2: Dayjs | null;
    endTime1: Dayjs | null;
    endTime2: Dayjs | null;
  };
  isLoadingForm: {
    services: boolean;
    defaultHours: boolean;
    extraHours: boolean;

    // ! SOLO PER FW
    azienda: boolean;
    citta: boolean;
    layoutCrm: boolean;
    laboratorioEsterno: boolean;
    istitutoDiCredito: boolean;
  };
}

const initialState: PointOfSaleRootSliceState = {
  pointOfSales: {},
  shopSigns: {},
  dataLoads: true,
  currentPointOfSale: "",
  services: {},
  isLoadingForm: {
    services: false,
    defaultHours: false,
    extraHours: false,

    azienda: false,
    citta: false,
    layoutCrm: false,
    istitutoDiCredito: false,
    laboratorioEsterno: false,
  },
};

const PointOfSaleRootSlice = createSlice({
  name: "PointOfSaleRoot",
  initialState,
  reducers: {
    setPointOfSaleOpenForm(
      state: PointOfSaleRootSliceState,
      action: PayloadAction<"modify" | "info" | "new" | undefined>
    ) {
      state.openForm = action.payload;
    },
    setCurrentPointOfSale(
      state: PointOfSaleRootSliceState,
      action: PayloadAction<string>
    ) {
      state.currentPointOfSale = action.payload;
    },

    setDeletePointOfSale(
      state: PointOfSaleRootSliceState,
      action: PayloadAction<string | undefined>
    ) {
      state.deletePointOfSale = action.payload;
    },
    setPointOfSalesDatas(
      state: PointOfSaleRootSliceState,
      action: PayloadAction<{
        pointOfSales: {
          [x: string]: PointOfSalesInterface;
        };
        shopSigns: {
          [x: string]: ShopSignInterface;
        };
        dataLoads: boolean;
      }>
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setServiceLoad(
      state: PointOfSaleRootSliceState,
      action: PayloadAction<boolean>
    ) {
      state.dataLoads = action.payload;
    },

    setServices(
      state: PointOfSaleRootSliceState,
      action: PayloadAction<{
        [x: string]: ServicePointOfSaleInterface;
      }>
    ) {
      state.services = action.payload;
    },
    setCopiedDefaultRow(
      state: PointOfSaleRootSliceState,
      action: PayloadAction<
        | {
            startTime1: Dayjs | null;
            startTime2: Dayjs | null;
            endTime1: Dayjs | null;
            endTime2: Dayjs | null;
          }
        | undefined
      >
    ) {
      state.copiedDefaultRow = action.payload;
    },

    setIsLoadingForm(
      state: PointOfSaleRootSliceState,
      action: PayloadAction<{
        key: string;
        value: boolean;
      }>
    ) {
      (state.isLoadingForm as any)[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  setPointOfSaleOpenForm,
  setCurrentPointOfSale,
  setDeletePointOfSale,
  setPointOfSalesDatas,
  setServiceLoad,
  setServices,
  setCopiedDefaultRow,
  setIsLoadingForm,
} = PointOfSaleRootSlice.actions;

export const isFormLoading = (state: RootState): boolean => {
  return (
    Object.values(state.newPointOfSaleRoot.isLoadingForm).find(
      (value) => value
    ) !== undefined
  );
};

export default PointOfSaleRootSlice.reducer;
