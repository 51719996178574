import moment, { Moment } from "moment";

const checkRange = (
  startTime: Moment,
  endTime: Moment,
  rangeStartShift: Date | null,
  rangeEndShift: Date | null
) => {
  if (rangeStartShift && rangeEndShift) {
    const startTimeShift = moment(rangeStartShift);
    const endTimeShift = moment(rangeEndShift);
    if (
      startTime.isBetween(startTimeShift, endTimeShift, "minutes", "[)") &&
      endTime.isBetween(startTimeShift, endTimeShift, "minutes", "(]")
    )
      return true;
  }
  return false;
};

export const checkCalendarsRanges = (schemaContext: {
  rangeStart: Date | null;
  rangeEnd: Date | null;

  startTime1Shift: Date | null;
  endTime1Shift: Date | null;
  startTime2Shift: Date | null;
  endTime2Shift: Date | null;

  typeCalendar: "default" | "extra" | "extra-closed" | "closed";
  typeShift: "default" | "extra" | "extra-closed" | "closed";
  isSingleCalendarManagement: boolean;
}): boolean => {
  const {
    rangeStart,
    rangeEnd,

    startTime1Shift,
    endTime1Shift,
    startTime2Shift,
    endTime2Shift,

    typeCalendar,
    typeShift,
    isSingleCalendarManagement,
  } = schemaContext;

  if (isSingleCalendarManagement) {
    if (typeCalendar === "closed" || typeCalendar === "extra-closed")
      return true;
    if (!rangeStart && !rangeEnd) return true;
    if (rangeStart && rangeEnd)
      return moment(rangeEnd).isAfter(moment(rangeStart));
    return false;
  }
  if (typeCalendar === "closed" || typeCalendar === "extra-closed") return true;
  if (!rangeStart && !rangeEnd) return true;
  if (typeShift === "closed" || typeShift === "extra-closed") return false;
  if (rangeStart && rangeEnd) {
    const startTime = moment(rangeStart);
    const endTime = moment(rangeEnd);
    if (
      checkRange(startTime, endTime, startTime1Shift, endTime1Shift) ||
      checkRange(startTime, endTime, startTime2Shift, endTime2Shift)
    )
      return endTime.isAfter(startTime, "minute");
  }

  return false;
};
