import { BluAutoComplete } from "@bludata/components";
import { Stack, InputLabel, Box, Typography } from "@mui/material";
import { nanoid } from "nanoid";
import { FC, memo, useCallback } from "react";
import PointOfSale_interface from "../../../nuova-point-of-sales/interfaces/PointOfSale_interface";

interface PointOfSaleInterface {
  pointOfSaleId: string;
  setPointOfSaleId: (value: string) => void;
  setLoading: (value: boolean) => void;
  pointOfSales?: PointOfSale_interface[];
}

export const PointOfSaleImport: FC<PointOfSaleInterface> = memo(
  ({ pointOfSaleId, setPointOfSaleId, pointOfSales }) => {
    const getValueOfPointOfSales = useCallback(() => {
      if (pointOfSaleId && pointOfSales) {
        const pointOfSale = pointOfSales.find(
          (d: any) => d.id === pointOfSaleId
        );
        if (pointOfSale) {
          return pointOfSale;
        }
        if (pointOfSales.length > 0) return pointOfSales[0];
      }
      return null;
    }, [pointOfSales, pointOfSaleId]);

    return (
      <Stack sx={{ pt: 1 }}>
        <InputLabel>Filiale</InputLabel>

        <BluAutoComplete
          // nullable
          size="small"
          disableClearable
          renderOption={(props: any, option: any) => {
            return (
              <Box component="li" {...props} key={nanoid()}>
                {option.branch}
              </Box>
            );
          }}
          getOptionLabel={(option: any) => {
            return option.branch;
          }}
          data={pointOfSales ?? []}
          value={getValueOfPointOfSales()}
          onChange={(_: any, newValue: any) => {
            setPointOfSaleId(newValue.id);
          }}
          loading={false}
          isOptionEqualToValue={(option: any, value: any) => {
            return option && value && option.id === value.id;
          }}
        />
      </Stack>
    );
  }
);
