import { store } from "../../../../../store";
import { manageService } from "../../../api/manage-service";
import { ServiceInfoInterface } from "../../../interfaces/service-info";
import { getServiceWhiteCache } from "../../../lib/white-cache";

export const updateService = async (
  data: ServiceInfoInterface,
  serviceId?: string
): Promise<boolean> => {
  try {
    const sID =
      serviceId !== undefined && serviceId !== "" ? serviceId : undefined;
    const {
      serviceForm: { pointOfSalesForm, usersForm },
    } = store.getState();

    const { initialPointOfSales, initialUsers, initialStudi } =
      getServiceWhiteCache();

    const createServicePointOfSales = [];
    const deleteServicePointOfSales = [];
    const createServiceStudios = [];
    const deleteServiceStudios = [];
    const createServiceUsers = [];
    const deleteServiceUsers = [];

    if (sID !== undefined) {
      for (const pointOfSaleId in initialPointOfSales) {
        if (pointOfSaleId in pointOfSalesForm) {
          const initialValue = initialPointOfSales[pointOfSaleId];
          const { enable, studi, associationId } =
            pointOfSalesForm[pointOfSaleId];
          if (initialValue !== enable)
            if (enable)
              createServicePointOfSales.push({ serviceId: sID, pointOfSaleId });
            else if (associationId)
              deleteServicePointOfSales.push(associationId);

          for (const studio of Object.values(studi))
            if (studio.enable !== initialStudi[studio.id])
              if (studio.enable)
                createServiceStudios.push({
                  serviceId: sID,
                  studioId: studio.id,
                });
              else if (studio?.associationId)
                deleteServiceStudios.push(studio.associationId);
        }
      }

      for (const userId in initialUsers) {
        if (userId in usersForm) {
          const initialValue = initialUsers[userId];
          if (usersForm[userId].enable !== initialValue)
            if (usersForm[userId].enable)
              createServiceUsers.push({ serviceId: sID, userId });
            else if (usersForm[userId]?.associationId)
              deleteServiceUsers.push(usersForm[userId].associationId ?? "");
        }
      }
    } else {
      for (const pointOfSaleId in pointOfSalesForm) {
        const { enable, studi } = pointOfSalesForm[pointOfSaleId];
        if (enable) createServicePointOfSales.push({ pointOfSaleId });
        for (const studioId in studi)
          if (studi[studioId].enable) createServiceStudios.push({ studioId });
      }
      for (const userId in usersForm)
        if (usersForm[userId].enable) createServiceUsers.push({ userId });
    }
    const result = await manageService({
      service: {
        name: data.name,
        smallDescription: data.smallDescription,
        notificationDescription: data.notificationDescription,
        description: data.description,
        durationValue: data.durationValue,
        hasStudio: data.hasStudio,
        isOnlineBooking: data.isOnlineBooking,
        hasAutoConfirmation: data.hasAutoConfirmation,
        color: data?.color,
        serviceId: sID,
        icons: data?.icons ?? [],
      },
      createServicePointOfSales,
      deleteServicePointOfSales,
      createServiceStudios,
      deleteServiceStudios,
      createServiceUsers,
      deleteServiceUsers,
    });
    if (!result) return false;
    return true;
  } catch (err) {
    console.error(err);

    return false;
  }
};
