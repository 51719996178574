import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getUsersWhiteCache } from "../lib/white-cache";
import User_interface from "../interfaces/User_interface";
import { nanoid } from "nanoid";

interface UsersObject {
  [x: string]: User_interface;
}

export const getUsers = async () => {
  const { isFocusWeb } = getUsersWhiteCache();
  const url = isFocusWeb ? "/focusapi/global/User" : "/api/User";

  try {
    const { data } = await axios.get(url);

    if (!isFocusWeb) return data.data;
    return data
      .map((item: any) => {
        return { ...item, id: item.codiceFiliale };
      })
      .flat();
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const useUsers = (key: string = "") => {
  return useQuery({
    queryFn: async () => await getUsers(),
    queryKey: ["users", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: any): UsersObject => {
  const { isFocusWeb } = getUsersWhiteCache();
  const result: any = {};
  for (const pos of data) {
    let photo = pos?.photo;
    if (!isFocusWeb && photo) photo = `${photo}?cacheTime=${nanoid()}`;

    result[pos.id] = {
      ...pos,
      photo,
    };
  }
  return result;
};
