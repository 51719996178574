import { TableRow } from "@mui/material";
import moment, { Moment } from "moment";
import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { UserDoubleCell } from "../../components/double-cell/DoubleCellUser";
import { nanoid } from "nanoid";

interface CalendarUserRowInterface {
  calendar: any;
  shift: any;
  selectedDate: Date;
  turniType: string;
  userId: string;
  userName: string;
  holiday: {
    [key: string]: string;
  };
  isSingleCalendarManagement: boolean;
}
export const CalendarUserRow: FC<CalendarUserRowInterface> = memo(
  ({
    userId,
    userName,
    calendar,
    shift,
    selectedDate,
    holiday,
    isSingleCalendarManagement,
  }) => {
    const getTurniData = useCallback((realDate: Moment, turno: any) => {
      const date = realDate.format("YYYY-MM-DD");
      let data: any = {};
      if (turno && turno?.extra && date in turno.extra) {
        data = {
          extraId: turno.extra[date]?.id,
          isExtra: true,
          extraCalendarType: turno.extra[date].extraCalendarType,
          startTime1: turno.extra[date]?.startTime1,
          startTime2: turno.extra[date]?.startTime2,
          endTime1: turno.extra[date]?.endTime1,
          endTime2: turno.extra[date]?.endTime2,
        };
      }
      const daysOfWeek = realDate.locale("en").format("dddd").toLowerCase();
      if (!data?.isExtra) {
        if (turno && turno?.default && daysOfWeek in turno.default) {
          data = {
            isClosed:
              !turno.default[daysOfWeek]?.startTime1 &&
              !turno.default[daysOfWeek]?.startTime2 &&
              !turno.default[daysOfWeek]?.endTime1 &&
              !turno.default[daysOfWeek]?.endTime2,
            startTime1: turno.default[daysOfWeek]?.startTime1,
            startTime2: turno.default[daysOfWeek]?.startTime2,
            endTime1: turno.default[daysOfWeek]?.endTime1,
            endTime2: turno.default[daysOfWeek]?.endTime2,
          };
        } else {
          data = {
            isClosed: true,
          };
        }
      }
      return data;
    }, []);

    const getDefaultData = useCallback((realDate: Moment, turno: any) => {
      const daysOfWeek = realDate.locale("en").format("dddd").toLowerCase();

      if (turno && turno?.default && daysOfWeek in turno.default) {
        return {
          isClosed:
            !turno.default[daysOfWeek]?.startTime1 &&
            !turno.default[daysOfWeek]?.startTime2 &&
            !turno.default[daysOfWeek]?.endTime1 &&
            !turno.default[daysOfWeek]?.endTime2,
          startTime1: turno.default[daysOfWeek]?.startTime1,
          startTime2: turno.default[daysOfWeek]?.startTime2,
          endTime1: turno.default[daysOfWeek]?.endTime1,
          endTime2: turno.default[daysOfWeek]?.endTime2,
        };
      }
      return undefined;
    }, []);

    const getCell = useCallback(
      (el: number) => {
        const day = el.toString();
        const realDate = moment(selectedDate).set({ date: el });

        if (!calendar && !shift)
          return (
            <UserDoubleCell
              id={`${userId} _${day}`}
              userId={userId}
              userName={userName}
              date={realDate.toDate()}
              shift={{ isInitial: true }}
              calendar={{ isInitial: true }}
              isSingleCalendarManagement={isSingleCalendarManagement}
            />
          );

        const date = realDate.format("YYYY-MM-DD");
        if (holiday && date in holiday)
          return (
            <UserDoubleCell
              id={`${userId} _${day}`}
              userId={userId}
              userName={userName}
              date={realDate.toDate()}
              shift={{ isHoliday: true }}
              calendar={{ isHoliday: true }}
              isSingleCalendarManagement={isSingleCalendarManagement}
            />
          );
        let shiftData: any = getTurniData(realDate, shift),
          calendarData: any = getTurniData(realDate, calendar);
        return (
          <UserDoubleCell
            id={`${userId} _${day}`}
            userId={userId}
            userName={userName}
            date={realDate.toDate()}
            shift={shiftData}
            calendar={calendarData}
            defaultShift={getDefaultData(realDate, shift)}
            defaultCalendar={getDefaultData(realDate, calendar)}
            isSingleCalendarManagement={isSingleCalendarManagement}
          />
        );
      },
      [
        calendar,
        getDefaultData,
        getTurniData,
        holiday,
        isSingleCalendarManagement,
        selectedDate,
        shift,
        userId,
        userName,
      ]
    );

    const GetTurni = useMemo(() => {
      const endOfMonth = moment(selectedDate).endOf("month");
      const calendarDays = Array.from(
        { length: endOfMonth.get("date") },
        (_, i) => i + 1
      );
      return (
        <>
          {calendarDays.map((el: number) => {
            return <Fragment key={nanoid()}>{getCell(el)}</Fragment>;
          })}
        </>
      );
    }, [getCell, selectedDate]);

    return (
      <Fragment key={nanoid()}>
        <TableRow sx={{ height: "70px" }}>{GetTurni}</TableRow>
        {/* {turniFilter === undefined && !isSingleCalendarManagement && (
          <TableRow sx={{ height: "70px" }}></TableRow>
        )} */}
      </Fragment>
    );
  }
);
