import { FC, memo, useCallback, useMemo, useState } from 'react'
import { AssistantInterface } from '../../interfaces/assistant'
import {
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  Typography
} from '@mui/material'
import { useDispatch } from 'react-redux'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { AppDispatch } from '../../../../store'
import { setIsLoading } from '../../store'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import BluAsyncDialog from '../../../../common/AsyncDialog'
import { createThread } from '../../api/post-thread'
import { useQueryClient } from '@tanstack/react-query'

interface AssistantsMenuInterface {
  assistants?: AssistantInterface[]
  setThreadId: (threadId?: string) => void
}

export const AssistantsMenu: FC<AssistantsMenuInterface> = memo(
  ({ assistants, setThreadId }) => {
    const dispatch = useDispatch<AppDispatch>()
    const [open, setOpen] = useState<boolean>(false)
    const queryClient = useQueryClient()

    const rangeLength: number = 1000
    // const rangeLength: number = 3

    // const handleClick = useCallback(
    //   (openAiId: string) => {
    //     dispatch(setAssistantId(openAiId))
    //   },
    //   [dispatch]
    // )

    const handleSave = useCallback(
      async (openAiId: string) => {
        dispatch(setIsLoading(true))
        const result = await createThread(openAiId, 'Nuova chat')
        dispatch(setIsLoading(false))

        if (result !== '') {
          setThreadId(result)
          queryClient.invalidateQueries(['thread'], {
            type: 'active'
          })
          queryClient.removeQueries(['thread'], {
            type: 'inactive'
          })

          return
        }
        BluAsyncDialog({
          title: 'Attenzione',
          message:
            'Ci scusiamo, per un problema tecnico, non è possibile creare una nuova chat. Riprova più tardi.',
          confimButton: 'Chiudi',
          hideDeclineButton: true,
          type: 'warning',
          // maxWidth: false,
          sx: { '& .MuiDialog-paper': { maxWidth: '400px' } }
        })
      },
      [dispatch, queryClient, setThreadId]
    )

    const handleIconClick = useCallback(() => {
      setOpen(!open)
    }, [open])

    const getData = useCallback(() => {
      if (!assistants) return []
      if (assistants.length <= rangeLength) return assistants
      if (open) return assistants
      return assistants.slice(0, rangeLength)
    }, [assistants, open])

    const getIcon = useMemo(() => {
      if (!assistants) return
      if (assistants.length <= rangeLength) return
      if (open)
        return (
          <IconButton onClick={handleIconClick}>
            <ArrowDropUpIcon />
          </IconButton>
        )
      return (
        <IconButton onClick={handleIconClick}>
          <ArrowDropDownIcon />
        </IconButton>
      )
    }, [assistants, handleIconClick, open])

    return (
      <>
        {getData().map((element) => (
          <AssistantItem {...element} handleClick={handleSave} />
        ))}
        <Divider sx={{ mt: '1px', mb: '10px' }}>{getIcon}</Divider>
      </>
    )
  }
)

interface AssistantItemInterface extends AssistantInterface {
  handleClick: (openAiId: string) => void
}

const AssistantItem: FC<AssistantItemInterface> = memo(
  ({ openAiId, name, handleClick }) => {
    const [onHover, setOnHover] = useState<boolean>(false)
    return (
      <ListItem
        disablePadding
        onClick={() => handleClick(openAiId)}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <ListItemButton
          style={{
            padding: '7px 15px',

            height: '55px'
          }}
          // disabled={isDisabled()}
        >
          <Typography
            fontSize="16px"
            fontWeight="bold"
            sx={{ display: 'flex', flex: '1', pl: 1 }}
          >
            {name.toUpperCase()}
          </Typography>
          {onHover && <AddIcon />}
        </ListItemButton>
      </ListItem>
    )
  }
)
