import { BluAutoComplete } from "@bludata/components";
import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { servicesIconsList } from "../../../../../../util/service-icons-list";
import { DynamicIconConverter } from "../../../../../../util/dynamic-icon-converter";
import { GridCloseIcon } from "@mui/x-data-grid-premium";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

export const ServiceIcons = memo(() => {
  const watch = useWatch({ name: "icons" });
  const { setValue } = useFormContext();
  const { openForm } = useSelector((state: RootState) => state.serviceRoot);

  const getValue = useCallback(() => {
    return (watch ?? [])
      .map((item: any) => (servicesIconsList as any)[item] ?? null)
      .filter((item: any) => item !== null);
  }, [watch]);

  const handleChange = useCallback(
    (_: any, newValue: any) => {
      setValue(
        "icons",
        (newValue ?? []).map((item: any) => item.value)
      );
    },
    [setValue]
  );

  const handleErase = useCallback(
    (value: any) => {
      const i = watch.filter((item: any) => item !== value);
      setValue("icons", i ?? []);
    },
    [setValue, watch]
  );

  return (
    <Stack flex="1">
      <Typography variant="subtitle2" sx={{ p: "3px 0px 0 0" }}>
        Icone
      </Typography>
      <BluAutoComplete
        size="small"
        multiple
        disabled={openForm === "info" || (watch ?? []).length === 3}
        renderOption={(props: any, option: any) => (
          <Box component="li" {...props}>
            <Stack flex="1" direction="row" gap={1}>
              <Stack direction="row">
                <DynamicIconConverter name={option.value} />
              </Stack>
              {option.label}
            </Stack>
          </Box>
        )}
        // getOptionLabel={(option: any) => `${option.username}`}
        data={Object.values(servicesIconsList).sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })}
        value={getValue()}
        onChange={handleChange}
        isOptionEqualToValue={(option: any, value: any) => {
          return option && value && option?.value === value?.value;
        }}
        renderTags={(value: any, getTagProps: any) =>
          value.map((option: any, index: any) => {
            return (
              <Chip
                variant="outlined"
                sx={{
                  height: "25px",
                  "& .MuiChip-label": {
                    pl: 1,
                    pr: 0,
                  },
                }}
                label={
                  <Stack direction="row" sx={{ height: "25px" }}>
                    <DynamicIconConverter name={option.value} />
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => handleErase(option.value)}
                    >
                      <GridCloseIcon />
                    </IconButton>
                  </Stack>
                }
              />
            );
          })
        }
      />
    </Stack>
  );
});
