import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CompanySettingsInterface } from "../interfaces/CompanySettings";
import { defaultCompanySettings } from "../utils/default-company-settings";

const getCompanySettings = async (): Promise<CompanySettingsInterface> => {
  try {
    const {
      data: { data },
    } = await axios.get(`/api/Company/CompanySettings`);

    return data;
  } catch (error) {
    console.log(error);
    return defaultCompanySettings;
  }
};

export const useCompanySettings = (key: string = "") => {
  return useQuery({
    queryFn: async () => await getCompanySettings(),
    queryKey: ["company-settings", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
