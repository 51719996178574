import { BluDatePicker } from "@bludata/components";
import { Stack, TextField } from "@mui/material";
import { FC, memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface CustomDatePickerInterface {
  name: string;
  helperText?: string;
  label?: string;
  disabled?: boolean;
  width?: string;
  error: boolean;
  [x: string]: any;
  pos?: (field: string, pos: number) => void;
}

export const CustomDatePicker: FC<CustomDatePickerInterface> = memo(
  ({
    error,
    name,
    helperText = "",
    label = "",
    disabled = false,
    width,
    ...rest
  }) => {
    const field = useWatch({ name });
    const { setValue, trigger } = useFormContext();

    const handleOnChange = useCallback(
      (e: any) => {
        setValue(name, e);
        trigger(name);
      },
      [name, setValue, trigger]
    );

    return (
      <Stack
        sx={{
          minWidth: width ? width : "150px",
          width: width,
          flex: width ? undefined : 1,
        }}
      >
        <BluDatePicker
          id={name}
          disabled={disabled}
          value={field ?? ""}
          label={label}
          helperText={helperText}
          onChange={handleOnChange}
          {...rest}
          renderInput={(params: any) => <TextField {...params} error={error} />}
        />
      </Stack>
    );
  }
);
