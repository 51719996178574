import { FC, memo, useMemo } from 'react';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DateSelection } from './UI/Date';
import {
  CercaDisponibilita,
  CercaDisponibilitaButton,
} from './UI/CercaDisponibilita/CercaDisponibilita';
import { IsImportant } from './UI/IsImportant';
import { IsRecallOn } from './UI/IsRecallOn';
import { PointOfSale } from './UI/PointOfSale';
import { Service } from './UI/Service';
import { Status } from './UI/Status';
import { User } from './UI/User';
import { Email } from './UI/Email';
import { InternalDescription } from './UI/InternalDescription';
import { Name } from './UI/Name';
import { Note } from './UI/Note';
import { Surname } from './UI/Surname';
import { Telephone } from './UI/Telephone';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'packages/blu-booking/src/store';
import { useWatch, useFormContext } from 'react-hook-form';
import { getConfig } from '../../utility_objects/WhiteCache';
import { setSearchPopupForm } from '../../store/AppointmentForm';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { Studi } from './UI/Studi';
import dayjs from 'dayjs';

interface AppointmentFormInnerInterface {
  isModify: boolean;
  isTabClienti: boolean;
}

export const AppointmentFormInner: FC<AppointmentFormInnerInterface> = memo(
  ({ isModify, isTabClienti }) => {
    const dispatch = useDispatch<AppDispatch>();

    const { getClient, focusWeb } = getConfig();
    const { reset, getValues, trigger, watch } = useFormContext();

    const client = async () => {
      const { name, surname } = watch();
      const c = await getClient(name, surname);
      if (c === null) return;
      const data = watch();
      let email = c.email || '';
      let telephone = c.telephone || '';
      if (
        (data.name ?? '').toUpperCase() === (c?.name ?? '').toUpperCase() &&
        (data.surname ?? '').toUpperCase() === (c?.surname ?? '').toUpperCase()
      ) {
        if (data.email && data.email !== '') email = data.email || '';

        if (data.telephone && data.telephone !== '')
          telephone = data.telephone || '';
      }

      reset({
        ...getValues(),
        ...c,
        name: c.name || '',
        surname: c.surname || '',
        telephone,
        email,
      });
      trigger();
    };

    const Row = ({ children }: { children: JSX.Element[] | JSX.Element }) => (
      <Stack
        gap={1}
        flexWrap="wrap"
        sx={{ width: '100%' }}
        flexDirection="unset"
      >
        {children}
      </Stack>
    );

    const [
      updatedById,
      updatedByUsername,
      createdById,
      createdByUsername,
      updatedAt,
      createdAt,
    ] = useWatch({
      name: [
        'updatedById',
        'updatedByUsername',
        'createdById',
        'createdByUsername',
        'updatedAt',
        'createdAt',
      ],
    });

    const getInfo = useMemo(() => {
      if (createdAt === undefined) return null;

      let createdUsername;
      if (
        createdById &&
        createdById === '00000000-0000-0000-0000-000000000000'
      ) {
        switch (createdByUsername) {
          case 'Console':
            createdUsername = " dall'import di attivazione dell'agenda";
            break;
          case 'IMO':
            createdUsername = " dal cliente tramite l'app il mio ottico";
            break;
          case 'BluBooking':
            createdUsername = ' dal cliente tramite il sito di prenotazione';
            break;
        }
      } else if (createdByUsername && createdByUsername !== '')
        createdUsername = ` da ${createdByUsername}`;

      if ((updatedAt && dayjs(updatedAt).isSame(createdAt)) || !updatedAt)
        return (
          <Stack sx={{ width: '100%' }}>
            <Typography sx={{ fontSize: '13px', color: 'grey' }}>{`Creato${
              createdUsername ?? ''
            } il ${dayjs(createdAt).format('DD/MM/YYYY')} alle ${dayjs(
              createdAt
            ).format('HH:mm')}`}</Typography>
          </Stack>
        );

      let updatedUsername;
      if (
        updatedById &&
        updatedById === '00000000-0000-0000-0000-000000000000'
      ) {
        switch (updatedByUsername) {
          case 'Console':
            updatedUsername = " dall'import di attivazione dell'agenda";
            break;
          case 'IMO':
            updatedUsername = " dal cliente tramite l'app il mio ottico";
            break;
          case 'BluBooking':
            updatedUsername = ' dal cliente tramite il sito di prenotazione';
            break;
        }
      } else if (updatedByUsername && updatedByUsername !== '')
        updatedUsername = ` da ${updatedByUsername}`;

      return (
        <Stack sx={{ width: '100%', color: 'var(--disabled-color)' }}>
          <Typography sx={{ fontSize: '13px', color: 'grey' }}>{`Aggiornato${
            updatedUsername ?? ''
          } il ${dayjs(updatedAt).format('DD/MM/YYYY')} alle ${dayjs(
            updatedAt
          ).format('HH:mm')}`}</Typography>
          <Typography sx={{ fontSize: '13px', color: 'grey' }}>{`Creato${
            createdUsername ?? ''
          } il ${dayjs(createdAt).format('DD/MM/YYYY')} alle ${dayjs(
            createdAt
          ).format('HH:mm')}`}</Typography>
        </Stack>
      );
    }, [
      createdAt,
      createdById,
      createdByUsername,
      updatedAt,
      updatedById,
      updatedByUsername,
    ]);

    return (
      <>
        <Stack flex="1" flexDirection="column">
          <Row>
            <Status />
            <Stack
              flexDirection="column"
              sx={{ pl: '20px', justifyContent: 'end', pt: 1 }}
            >
              <IsImportant />
              <IsRecallOn />
            </Stack>
          </Row>
        </Stack>
        <Stack
          flex="1"
          flexDirection="column"
          sx={{
            border: '1px solid rgba(187, 187, 187, 1)',
            borderRadius: '4px',
            p: '10px',
          }}
        >
          <Row>
            <User />
            <Service />
          </Row>
          <Row>
            <PointOfSale />
            <Studi />
          </Row>
          <Row>
            <CercaDisponibilitaButton />
          </Row>
          <CercaDisponibilita />
        </Stack>
        <Stack
          gap={1}
          flexWrap="wrap"
          sx={{
            width: '100%',
            boxSizing: 'border-box',
          }}
          flexDirection="unset"
        >
          <DateSelection />
        </Stack>
        <Stack flex="1" flexDirection="column">
          <Stack flexDirection="row" gap={1}>
            <Surname isTabClienti={isTabClienti} />
            <Stack flex="1" direction="row" gap={1} justifyItems="end">
              <Name isTabClienti={isTabClienti} />
              {!isTabClienti && (
                <IconButton
                  sx={{ width: '40px', height: '40px', marginTop: 'auto' }}
                  onClick={() =>
                    focusWeb && getClient !== undefined
                      ? client()
                      : dispatch(setSearchPopupForm(true))
                  }
                  // onClick={() => dispatch(setSearchPopupForm(true))}
                >
                  <SearchTwoToneIcon />
                </IconButton>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" flex="1" gap={1}>
            <Telephone isTabClienti={isTabClienti} />
            <Email isTabClienti={isTabClienti} />
          </Stack>
          <Stack direction="row" flex="1" gap={1}>
            <Note />
            <InternalDescription isModify={isModify} />
          </Stack>
          <Stack flex="1" direction="row" gap={1} sx={{ pt: 1 }}>
            {getInfo}
          </Stack>
        </Stack>
      </>
    );
  }
);
