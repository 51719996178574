import { EnableCell } from "../../utils/enable-cell";
import { EnableHeader } from "../../utils/enable-header";

export const Columns: any[] = [
  {
    field: "id",
    hide: true,
  },
  {
    field: "enable",
    headerName: "",
    renderCell: EnableCell,
    renderHeader: () => EnableHeader({ key: "pointOfSalesForm" }),
    width: 30,
    disableReorder: true,
    align: "center",
    sortable: false,
  },
  {
    field: "name",
    headerName: "Punto vendita",
    flex: 2,
    editable: false,
  },
  {
    field: "address",
    headerName: "Indirizzo",
    flex: 2,
    editable: false,
  },
  {
    field: "city",
    headerName: "Città",
    flex: 1,
    editable: false,
  },
];
