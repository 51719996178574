import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ChatMenuSliceState {
  menuOpened: boolean
  modifiedThreadId?: string
  isLoading: boolean
}

const initialState: ChatMenuSliceState = {
  menuOpened: true,
  isLoading: false
}

const ChatMenuSlice = createSlice({
  name: 'ChatMenuSlice',
  initialState,
  reducers: {
    setMenuOpened(state: ChatMenuSliceState, action: PayloadAction<boolean>) {
      state.menuOpened = action.payload
    },

    setModifiedThreadId(
      state: ChatMenuSliceState,
      action: PayloadAction<string | undefined>
    ) {
      state.modifiedThreadId = action.payload
    },

    setIsLoading(state: ChatMenuSliceState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    }
  }
})

export const { setMenuOpened, setModifiedThreadId, setIsLoading } =
  ChatMenuSlice.actions

export default ChatMenuSlice.reducer
