import { Button, Stack, Typography } from "@mui/material";
import {
  BluAsyncDialog,
  BluLoader,
  BluTitle,
  BluTooltip,
} from "@bludata/components";
import { FC, useCallback, useMemo, useState } from "react";
import axios from "axios";
import { useFormContext, useWatch } from "react-hook-form";
import { OtpDataInterface } from "../../../../interfaces/otp-data";
import DialogShowQrOtp from "./DialogShowQrOtp";
import { editTableItem } from "../../../../utils/update-table-fw";
import { useQueryClient } from "@tanstack/react-query";
import { getUsersWhiteCache } from "../../../../lib/white-cache";
import { useAppSelector } from "../../../../../../store";
import { bluBookingLoginData } from "../../../../../../store/slices/root";

interface TwoFactorAutheticationInterface {
  disabled: boolean;
}

export const TwoFactorAuthetication: FC<TwoFactorAutheticationInterface> = ({
  disabled,
}) => {
  const [id, userId, username, otp, focusId] = useWatch({
    name: ["id", "userId", "username", "otp", "focusId"],
  });

  const [loading, setLoading] = useState(false);
  const [otpData, setOtpData] = useState<OtpDataInterface>();
  const { setValue, watch } = useFormContext();
  const queryClient = useQueryClient();

  //? solo se sto modificando il mio utente oppure se sono admin.
  // const showSecurity = true;
  const { isAdmin } = useAppSelector(bluBookingLoginData);

  const showSecurity = isAdmin;
  const networkErrorString = "Errore di connessione al server.";

  const errorDialogDefaultFunction = (
    error?: string,
    type?: "error" | "info" | "warning"
  ) =>
    BluAsyncDialog({
      title: "Attenzione",
      message: error || networkErrorString,
      type: type || "error",
      hideDeclineButton: true,
      confimButton: "Chiudi",
    });

  const configura2FAClick = useCallback(() => {
    setLoading(true);

    axios
      .get<OtpDataInterface>("/focusapi/configurazione/otp/utente", {
        params: {
          codiceFiliale: userId,
          nomeUtente: username,
        },
      })
      .then((response) => {
        setOtpData(response.data);
      })
      .catch((error) => {
        errorDialogDefaultFunction();
        // ! errore da gestire
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId, username]);

  const rimuovi2FAClick = useCallback(async () => {
    if (
      await BluAsyncDialog({
        title: "Attenzione",
        message: "Sei sicuro di voler rimuovere il codice 2FA?",
        type: "warning",
      })
    ) {
      setLoading(true);
      editTableItem("utente", {
        id: focusId,
        otp: "",
      })
        .then(() => {
          setValue("otp", "");
          queryClient.invalidateQueries(["user"], {
            type: "active",
          });
          queryClient.removeQueries(["user"], {
            type: "inactive",
          });
          queryClient.invalidateQueries(["users"], {
            type: "active",
          });
          queryClient.removeQueries(["users"], {
            type: "inactive",
          });
          queryClient.invalidateQueries(["codice-attivazione"], {
            type: "active",
          });
          queryClient.removeQueries(["codice-attivazione"], {
            type: "inactive",
          });
          queryClient.invalidateQueries(["lista-filiali"], {
            type: "active",
          });
          queryClient.removeQueries(["lista-filiali"], {
            type: "inactive",
          });
          queryClient.invalidateQueries(["profile"], {
            type: "active",
          });
          queryClient.removeQueries(["profile"], {
            type: "inactive",
          });
          queryClient.invalidateQueries(["layout-crm"], {
            type: "active",
          });
          queryClient.removeQueries(["layout-crm"], {
            type: "inactive",
          });
          queryClient.invalidateQueries(["google-calendar-sync"], {
            type: "active",
          });
          queryClient.removeQueries(["google-calendar-sync"], {
            type: "inactive",
          });
          queryClient.invalidateQueries(["service", "user"], {
            type: "active",
          });
          queryClient.removeQueries(["service", "user"], {
            type: "inactive",
          });
          queryClient.invalidateQueries(["turni-default-user"], {
            type: "active",
          });
          queryClient.removeQueries(["turni-default-user"], {
            type: "inactive",
          });
          queryClient.invalidateQueries(["turni-extra-user"], {
            type: "active",
          });
          queryClient.removeQueries(["turni-extra-user"], {
            type: "inactive",
          });
          return;
        })
        .catch(() => {
          errorDialogDefaultFunction();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [focusId, queryClient, setValue]);

  const onCloseDialogOtp = () => {
    setOtpData(undefined);
  };

  const onConfirmDialogOtp = () => {
    const otpUtente = otpData?.otp;
    setLoading(true);
    setOtpData(undefined);
    axios.post("/focusapi/configurazione/init", {
      value: true,
    });

    editTableItem("utente", {
      id: focusId,
      otp: otpUtente,
    })
      .then(() => {
        setValue("otp", otpUtente);
        queryClient.invalidateQueries(["user"], {
          type: "active",
        });
        queryClient.removeQueries(["user"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["users"], {
          type: "active",
        });
        queryClient.removeQueries(["users"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["codice-attivazione"], {
          type: "active",
        });
        queryClient.removeQueries(["codice-attivazione"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["lista-filiali"], {
          type: "active",
        });
        queryClient.removeQueries(["lista-filiali"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["profile"], {
          type: "active",
        });
        queryClient.removeQueries(["profile"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["layout-crm"], {
          type: "active",
        });
        queryClient.removeQueries(["layout-crm"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["google-calendar-sync"], {
          type: "active",
        });
        queryClient.removeQueries(["google-calendar-sync"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["service", "user"], {
          type: "active",
        });
        queryClient.removeQueries(["service", "user"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["turni-default-user"], {
          type: "active",
        });
        queryClient.removeQueries(["turni-default-user"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["turni-extra-user"], {
          type: "active",
        });
        queryClient.removeQueries(["turni-extra-user"], {
          type: "inactive",
        });
        return;
      })
      .catch(() => {
        errorDialogDefaultFunction();
      })
      .finally(() => {
        axios.post("/focusapi/configurazione/init", {
          value: false,
        });
        setLoading(false);
      });
  };

  const GetContent = useMemo(() => {
    if (!showSecurity) return <></>;
    if (otp && otp !== "")
      return (
        <Stack flex="1" gap={1}>
          <BluTitle sx={{ mt: "18px !important" }} text={"Sicurezza"} />
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Typography flex={1}>
              Rimuovi il codice 2FA (Google Authenticator).
              <br />
              Attenzione! Questo utente dovrà usare il codice OTP generico.
            </Typography>
            <BluTooltip
              title="Informazioni 2FA"
              text="Disattiva l'autenticazione a due fattori (2FA) per questo utente.<br/>
        Disattivando questa funzionalità, per accedere all'applicazione l'utente dovrà inserire il codice OTP generico dato al titolare in fase di attivazione di FOCUS WEB."
              sx={{
                p: "2px",
                ml: "20px",
                backgroundColor: "#f5f5f5", // Colore di sfondo predefinito
                ".dark-mode-bludata &": {
                  backgroundColor: "#404040", // Colore di sfondo in modalità dark
                },
                "& svg": { width: "15px!important", height: "15px!important" },
              }}
            />
          </Stack>
          <Button
            sx={{ maxWidth: 320 }}
            color="error"
            variant="outlined"
            onClick={rimuovi2FAClick}
            disabled={disabled}
          >
            Rimuovi autenticazione a 2 fattori (2FA)
          </Button>
        </Stack>
      );
    return (
      <Stack flex="1" gap={1}>
        <BluTitle sx={{ mt: "18px !important" }} text={"Sicurezza"} />
        <Stack flexDirection="row" gap={1} alignItems="center">
          <Typography flex={1}>
            Crea un codice 2FA (Google Authenticator)
            <br /> da usare con questo utente.
          </Typography>
          <BluTooltip
            title="Informazioni 2FA"
            text={`Attiva l'autenticazione a due fattori (2FA) per questo utente.\n
        Con questa funzione attivata, per accedere all'applicazione questo utente potrà inserire un codice OTP generato dall'app gratuita Google Authenticator oppure codice OTP fornito al titolare durante l'attivazione di FOCUS WEB.`}
            sx={{
              p: "2px",
              ml: "20px",
              backgroundColor: "#f5f5f5", // Colore di sfondo predefinito
              ".dark-mode-bludata &": {
                backgroundColor: "#404040", // Colore di sfondo in modalità dark
              },
              "& svg": { width: "15px!important", height: "15px!important" },
            }}
          />
        </Stack>
        <Button
          variant="outlined"
          sx={{ maxWidth: 310 }}
          onClick={configura2FAClick}
          disabled={disabled}
        >
          Attiva autenticazione a 2 fattori (2FA)
        </Button>
      </Stack>
    );
  }, [configura2FAClick, disabled, otp, rimuovi2FAClick, showSecurity]);

  return (
    <>
      <BluLoader open={loading} sx={{ zIndex: "1400" }} />
      {!!otpData && (
        <DialogShowQrOtp
          onConfirm={onConfirmDialogOtp}
          onDecline={onCloseDialogOtp}
          otpData={otpData}
        />
      )}
      {GetContent}
    </>
  );
};
