import { BluTitle } from "@bludata/components";
import { Stack } from "@mui/material";
import { FC } from "react";
import { getUsersWhiteCache } from "../../../../lib/white-cache";
import { PointOfSaleSelection } from "../../components/PointOfSale/PointOfsale";
import { UserRoles } from "../../components/UserRoles/UserRoles";
import { UserVisibility } from "../../components/UserVisibility/UserRoles";
import { ServiceSelect } from "../../components/ServiceSelect/ServiceSelect";
import { CustomInputHtmlArea } from "../../common/CustomHtmlArea";

export const OtherInfoBB: FC<{
  disabled: boolean;
}> = ({ disabled }): JSX.Element => {
  const { isFocus10 } = getUsersWhiteCache();

  return (
    <Stack direction="column" gap={1} flex="1">
      <BluTitle text={"Altre informazioni"} />
      <Stack direction="column" gap={1}>
        <Stack gap={1} flex="1">
          <Stack direction="row" gap={1} flex="1">
            <PointOfSaleSelection disabled={disabled || isFocus10} />
            <UserRoles disabled={disabled || isFocus10} />
            <UserVisibility disabled={disabled} />
          </Stack>

          <ServiceSelect disabled={disabled} />
          <CustomInputHtmlArea
            name={"presentation"}
            label="Note"
            disabled={disabled || isFocus10}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
