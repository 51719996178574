import { FC, memo, useCallback } from "react";
import { Button, Stack, TableCell, TableFooter } from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { setImportDefaultHoursPopUp } from "../../../../store/default-hours";

interface DefaultFooterInterface {
  disabled: boolean;
  isSingleCalendarManagement: boolean;
}

export const DefaultFooter: FC<DefaultFooterInterface> = memo(
  ({ isSingleCalendarManagement, disabled }): JSX.Element => {
    const dispatch: AppDispatch = useDispatch();

    const handleClick = useCallback(() => {
      dispatch(setImportDefaultHoursPopUp(true));
    }, [dispatch]);

    return (
      <TableFooter
        style={{
          border: "1px solid rgba(224, 224, 224, 1)",
          borderTopWidth: 0,
        }}
      >
        <TableCell
          colSpan={isSingleCalendarManagement ? 7 : 8}
          style={{ padding: 8 }}
        >
          <Stack flex="1" direction="row" gap={1}>
            {!disabled && (
              <Button
                color="primary"
                startIcon={<ScheduleIcon />}
                variant="outlined"
                onClick={handleClick}
              >
                Importa
              </Button>
            )}
          </Stack>
        </TableCell>
      </TableFooter>
    );
  }
);
