import { Box, Button } from "@mui/material";
import { GridFooterContainer } from "@mui/x-data-grid-premium";
import { memo, useMemo, useCallback, FC } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { nanoid } from "@reduxjs/toolkit";
import { changeHolidays } from "../../store/holidays";

interface FooterInterface {}

export const FooterNoForm: FC<FooterInterface> = memo(() => {
  const dispatch = useDispatch<AppDispatch>();
  const { mode } = useSelector((state: RootState) => state.layoutRoot);
  const { holidays } = useSelector(
    (state: RootState) => state.generaliHolidays
  );

  const handleAddDays = useCallback(() => {
    const newRows = {
      shopSignId: undefined,
      companyId: undefined,
      internalId: nanoid(),
      name: "",
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      edit: "edit",
    };
    dispatch(changeHolidays([...holidays, newRows]));
  }, [dispatch, holidays]);

  const disableButton = useCallback(() => {
    if (holidays.length === 0) return false;

    const lastAdded = holidays[holidays.length - 1]!;
    return lastAdded.name === "";
  }, [holidays]);

  const GetFooter = useMemo(() => {
    if (mode === "create" || mode === "edit")
      return (
        <Box flex={1} sx={{ pl: "10px" }}>
          <Button
            variant="outlined"
            onClick={handleAddDays}
            disabled={disableButton()}
          >
            <AddIcon /> {" Aggiungi giorno"}
          </Button>
        </Box>
      );
    return null;
  }, [disableButton, handleAddDays, mode]);
  return <GridFooterContainer>{GetFooter}</GridFooterContainer>;
});
