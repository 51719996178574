import React, { FC } from "react";
import { memo, useEffect } from "react";
import { useUserMe } from "../api/user-me";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import { setLoginData } from "../store/slices/root";
import { setPointOfSaleId } from "../pages/nuova-rota/store/root";
import { softwareEnum } from "../interfaces/loginData";

interface InitDataInterface {
  pointOfSaleId?: string;
  userId?: string;
  companyColorEnum?: number;
  software?: string | "bb" | "fw" | "crm" | "f10";
  isSingleCalendarManagement?: boolean;
  isAdmin?: boolean;
  isManualAppointmentBlock?: boolean;
  visibilityUserEnum?: number;
  isFocusWeb?: boolean;
}

export const InitData: FC<InitDataInterface> = memo(
  ({
    pointOfSaleId,
    userId,
    software,
    isSingleCalendarManagement,
    isAdmin,
    companyColorEnum,
    isManualAppointmentBlock,
    visibilityUserEnum,
    isFocusWeb = false,
  }) => {
    const { data } = useUserMe(isFocusWeb);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
      if (data) {
        try {
          dispatch(
            setLoginData({
              ...data,
              pointOfSaleId: pointOfSaleId ?? data?.pointOfSaleId,
              userId: userId ?? data?.userId,
              software:
                software !== undefined
                  ? software
                  : softwareEnum[(data?.software as any) ?? 0],
              isSingleCalendarManagement:
                isSingleCalendarManagement ??
                data?.isSingleCalendarManagement ??
                true,
              isAdmin: isAdmin ?? data?.isAdmin ?? false,
              companyColorEnum: companyColorEnum ?? data?.companyColorEnum ?? 0,
              isManualAppointmentBlock:
                isManualAppointmentBlock ??
                data?.isManualAppointmentBlock ??
                false,
              visibilityUserEnum:
                visibilityUserEnum ?? data?.visibilityUserEnum ?? 0,
            })
          );
          dispatch(setPointOfSaleId(pointOfSaleId ?? data?.pointOfSaleId));
        } catch (e) {
          console.error(e);
        }
      }
    }, [
      companyColorEnum,
      data,
      dispatch,
      isAdmin,
      isManualAppointmentBlock,
      isSingleCalendarManagement,
      pointOfSaleId,
      software,
      userId,
      visibilityUserEnum,
    ]);

    return <></>;
  }
);
