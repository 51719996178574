import { memo } from "react";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { BluAlert } from "@bludata/components";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../../../../store";
import { setFile } from "../../../../store/import";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { faFileCsv, faFileXls } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface UploadComponentInterface {}

export const UploadComponent: FC<UploadComponentInterface> = memo(() => {
  const [error, setError] = useState("");
  const { file, fileName, documentFormat } = useSelector(
    (state: RootState) => state.rotaImport
  );
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Stack
      direction="column"
      spacing={1}
      justifyContent="center"
      sx={{ pt: "10px" }}
    >
      {fileName !== "" ? (
        <Stack>
          <Badge
            overlap="circular"
            badgeContent={
              <IconButton
                sx={{ width: "20px", height: "20px" }}
                onClick={() => {
                  dispatch(
                    setFile({
                      file: null,
                      fileName: "",
                    })
                  );
                }}
              >
                <CloseIcon sx={{ width: "18px", height: "18px" }} />
              </IconButton>
            }
            // color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{ height: "50px", width: "50px", margin: "auto", pb: "5px" }}
          >
            {/* <InsertDriveFileOutlinedIcon
              sx={{ height: "50px", width: "50px", margin: "auto" }}
            /> */}
            <FontAwesomeIcon
              color={"var(--icon-color)"}
              icon={documentFormat === "bludata" ? faFileXls : faFileCsv}
              style={{ height: "50px", width: "50px", margin: "auto" }}
            />
          </Badge>
          <Typography align="center">{`${fileName}`}</Typography>
        </Stack>
      ) : (
        <Box sx={{ height: "20px" }} />
      )}
      {/* <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Button
          color="primary"
          component="label"
          startIcon={<FileUploadIcon />}
          onClick={() => {}}
          variant={file === null ? "contained" : "outlined"}
        >
          Importa
          <input
            accept={documentFormat === "bludata" ? ".xlsx" : ".csv"}
            type="file"
            hidden
            onChange={(e: any) => {
              const fileReader = new FileReader();

              if (e.target.files[0].size > 5 * 1048576) {
                setError("La dimensione massima è 5 mb");
                return;
              }

              fileReader.readAsDataURL(e.target.files[0]);
              fileReader.onloadend = async (event: any) => {
                dispatch(
                  setFile({
                    file: e.target.files[0],
                    fileName: e.target.files[0].name,
                  })
                );
              };
            }}
          />
        </Button>
      </Stack> */}
    </Stack>
  );
});
