interface WhiteCacheInterface {
  // pointOfSaleId?: string;
  isBluBookingActive: boolean;
  path: string;
  isFocusWeb: boolean;
  isFocus10: boolean;
  turniInserimento: boolean;
  turniModifica: boolean;
  turniCancellazione: boolean;
}

let rotaWhiteCache: WhiteCacheInterface = {
  isBluBookingActive: true,
  path: "/api",
  isFocusWeb: false,
  isFocus10: false,
  turniInserimento: false,
  turniModifica: false,
  turniCancellazione: false,
};

export const getRotaWhiteCache = () => rotaWhiteCache;
export const setRotaWhiteCache = (value: WhiteCacheInterface) => {
  rotaWhiteCache = value;
};

export const setKeyRotaWhiteCache = (
  key: keyof WhiteCacheInterface,
  value: any
) => {
  (rotaWhiteCache[key] as any) = value;
};

export const getRotaPath = () => rotaWhiteCache.path;
