import { TableRow } from "@mui/material";
import moment from "moment";
import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { nanoid } from "nanoid";
import { DoubleCellUserDay } from "../../components/double-cell-day/DoubleCellUserDay";

interface CalendarUserRowInterface {
  calendar: any;
  shift: any;
  selectedDate: Date;
  turniType: string;
  userId: string;
  userName: string;
  holiday: {
    [key: string]: string;
  };
  isSingleCalendarManagement: boolean;
}
export const CalendarUserRow: FC<CalendarUserRowInterface> = memo(
  ({
    userId,
    userName,
    calendar,
    shift,
    selectedDate,
    holiday,
    isSingleCalendarManagement,
  }) => {
    const getCell = useCallback(
      (el: number) => {
        const realDate = moment(selectedDate).set({ hour: el });
        if (!calendar && !shift)
          return (
            <DoubleCellUserDay
              hour={el}
              date={realDate.toDate()}
              isInitial
              isSingleCalendarManagement={isSingleCalendarManagement}
            />
          );

        const date = realDate.format("YYYY-MM-DD");

        if (holiday && date in holiday)
          return (
            <DoubleCellUserDay
              hour={el}
              date={realDate.toDate()}
              isHoliday
              isSingleCalendarManagement={isSingleCalendarManagement}
            />
          );

        return (
          <DoubleCellUserDay
            hour={el}
            date={realDate.toDate()}
            shift={shift.current}
            calendar={calendar.current}
            isSingleCalendarManagement={isSingleCalendarManagement}
          />
        );
      },
      [calendar, holiday, isSingleCalendarManagement, selectedDate, shift]
    );

    const GetTurni = useMemo(() => {
      const calendarHours = Array.from({ length: 19 }, (_, i) => i + 5);
      return (
        <>
          {calendarHours.map((el: number) => {
            return <Fragment key={nanoid()}>{getCell(el)}</Fragment>;
          })}
        </>
      );
    }, [getCell]);
    return (
      <Fragment key={nanoid()}>
        <TableRow sx={{ height: "40px" }}>{GetTurni}</TableRow>
      </Fragment>
    );
  }
);
