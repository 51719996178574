import { TableContainer, Table, TableBody } from "@mui/material";
import moment from "moment";
import { FC, memo, useDeferredValue, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { useTurniPointOfSale } from "../../api/turni-point-of-sale";
import { setIsLoading } from "../../store/root";
import { PointOfSaleRotaHeader } from "./header";
import { CalendarPointOfSaleTableRow } from "./table-row";
import { TableHeader } from "../../components/tableHeader/tableHeader";

interface CalendarPointOfSaleInterface {
  holiday: {
    [key: string]: string;
  };
  isSingleCalendarManagement: boolean;
}
export const CalendarPointOfSale: FC<CalendarPointOfSaleInterface> = memo(
  ({ holiday, isSingleCalendarManagement }) => {
    const { pointOfSaleId, selectedDate, fetchPointOfSales } = useSelector(
      (state: RootState) => state.rotaRoot
    );
    const dispatch = useDispatch<AppDispatch>();

    const { data, isLoading } = useTurniPointOfSale(
      fetchPointOfSales,
      pointOfSaleId!!,
      selectedDate,
      isSingleCalendarManagement
    );

    const turni = useDeferredValue(data);

    useEffect(() => {
      if (turni && moment().isSame(selectedDate, "month")) {
        const element = document.getElementById(
          `calendar-point-of-sale-header-${moment().get("date")}`
        );
        if (element)
          element.scrollIntoView({
            inline: "center",
            behavior: "smooth",
          });
      }
    }, [selectedDate, turni]);

    useEffect(() => {
      dispatch(setIsLoading({ key: "turniPointOfSale", value: isLoading }));
    }, [dispatch, isLoading]);

    return (
      <>
        <PointOfSaleRotaHeader
          isSingleCalendarManagement={isSingleCalendarManagement}
        />

        <TableContainer sx={{ borderRadius: "0 8px 8px 0" }}>
          <Table
            size="small"
            stickyHeader
            sx={{
              tableLayout: "fixed",
              borderRadius: "0 8px  8px 0",
              "& .MuiTableCell-root": {
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
            }}
          >
            <TableHeader />
            <TableBody>
              <CalendarPointOfSaleTableRow
                turni={turni}
                holiday={holiday}
                isSingleCalendarManagement={isSingleCalendarManagement}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
);
