import { Stack, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { FC, memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface CustomCheckboxInterface {
  name: string;
  helperText?: string;
  label?: string;
  disabled?: boolean;
  width?: string;
  addedChange?: (value?: any) => void;
  pos?: (field: string, pos: number) => void;

  [x: string]: any;
}

export const CustomCheckbox: FC<CustomCheckboxInterface> = memo(
  ({
    name,
    label = "",
    disabled = false,
    width,
    multiline,
    pos,
    addedChange,
    ...rest
  }) => {
    const field = useWatch({ name });
    const { setValue, trigger } = useFormContext();

    const handleOnChange = useCallback(
      (e: any) => {
        setValue(name, e.target.checked);
        trigger(name);
        if (addedChange) addedChange();
      },
      [addedChange, name, setValue, trigger]
    );

    return (
      <Stack
        sx={{
          minWidth: width ? width : "150px",
          width: width,
          flex: width ? undefined : 1,
        }}
      >
        <FormGroup>
          <FormControlLabel
            onChange={handleOnChange}
            control={<Checkbox disabled={disabled} checked={field} />}
            label={label}
          />
        </FormGroup>
      </Stack>
    );
  }
);
