import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { UserInterface } from "../interfaces/user";

interface UsersObject {
  [x: string]: UserInterface;
}

const getUsers = async (): Promise<UserInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get("/api/User/UI");

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useUsersUI = (key: string = "") => {
  return useQuery({
    queryFn: async () => await getUsers(),
    queryKey: ["ui", "users", "configurazione", key],
    retry: 2,
    retryDelay: 180000,
    select: normalize,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

const normalize = (data: any): UsersObject => {
  const result: any = {};
  for (const user of data) {
    result[user.id] = {
      ...user,
      userId: user.id,
    };
  }
  return result;
};
