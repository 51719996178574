import { Stack } from "@mui/material";
import { FC, memo } from "react";
import { ConfigBox } from "./box";
import { TestBox } from "./test";

interface LayoutButtonInterface {
  disabled: boolean;
}

export const LayoutBox: FC<LayoutButtonInterface> = memo(({ disabled }) => {
  return (
    <Stack direction={"row"} spacing={2}>
      <Stack display="flex" flex="1" sx={{ pr: "10px" }}>
        <Stack
          gap="2%"
          flexWrap="wrap"
          sx={{ width: "100%" }}
          flexDirection="unset"
        >
          <Stack flex="1">
            <ConfigBox disabled={disabled} />
          </Stack>
        </Stack>
      </Stack>
      <Stack display="flex" flex="1.5">
        <TestBox />
      </Stack>
    </Stack>
  );
});
