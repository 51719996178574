import { FC, memo, useDeferredValue, useEffect } from "react";
import { Divider, Stack } from "@mui/material";
import {
  AppDispatch,
  RootState,
  useAppSelector,
} from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { setExtraHours } from "../../../../store/extra-hours";
import { setIsLoadingForm } from "../../../../store/root";
import { getUsersWhiteCache } from "../../../../lib/white-cache";
import { BluTitle } from "@bludata/components";
import { ExtraHoursDataGrid } from "./ExtraHoursDataGrid";
import { useNewTurniExtraUser } from "../../../../api/new-turni-extra-user";
import { AddPeriod } from "./AddPeriod";
import { bluBookingLoginData } from "../../../../../../store/slices/root";

interface ExtraHoursInterface {
  pointOfSaleId: string;
  disabled: boolean;
}

export const NewExtraHours: FC<ExtraHoursInterface> = memo(
  ({ pointOfSaleId, disabled }) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSingleCalendarManagement } = useAppSelector(bluBookingLoginData);

    const { currentUserBluBookingId } = useSelector(
      (state: RootState) => state.newUsersRoot
    );

    const { data, isLoading } = useNewTurniExtraUser(
      currentUserBluBookingId,
      isSingleCalendarManagement
    );

    const result = useDeferredValue(data);

    useEffect(() => {
      if (result) {
        dispatch(setExtraHours({ value: result }));
      }

      dispatch(setIsLoadingForm({ key: "extraHours", value: isLoading }));
    }, [dispatch, isLoading, result]);

    return (
      <Stack flex="1" gap={1}>
        <Divider sx={{ opacity: 0, mb: "24px!important" }} />

        <BluTitle text={"Orari straordinari"} />
        <AddPeriod pointOfSaleId={pointOfSaleId} />
        <ExtraHoursDataGrid pointOfSaleId={pointOfSaleId} disabled={disabled} />
      </Stack>
    );
  }
);
