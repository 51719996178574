export const it = {
  it: {
    schedule: {
      day: 'Giorno',
      week: 'Settimana',
      workWeek: 'Settimana di lavoro',
      month: 'Mese',
      year: 'Anno',
      agenda: 'ordine del giorno',
      weekAgenda: 'Agenda settimanale',
      workWeekAgenda: 'Agenda della settimana lavorativa',
      monthAgenda: 'Agenda mensile',
      today: 'Oggi',
      noEvents: 'Nessun evento',
      emptyContainer: 'Non ci sono eventi in programma questo giorno.',
      allDay: 'Tutto il giorno',
      start: 'Inizio',
      end: 'Fine',
      more: 'Di Più',
      close: 'Vicino',
      cancel: 'Annulla',
      noTitle: '(Senza titolo)',
      delete: 'Elimina',
      deleteEvent: "Quest'evento",
      deleteMultipleEvent: 'Elimina più eventi',
      selectedItems: 'Articoli selezionati',
      deleteSeries: 'Tutta la serie',
      edit: 'modificare',
      editSeries: 'Tutta la serie',
      editEvent: "Quest'evento",
      createEvent: 'Creare',
      subject: 'Soggetto',
      addTitle: 'Aggiungi titolo',
      moreDetails: 'Più dettagli',
      save: 'Salva',
      editContent: "Come vorresti cambiare l'appuntamento nella serie?",
      deleteContent: 'sei sicuro di voler cancellare questo evento?',
      deleteMultipleContent:
        'Sei sicuro di voler eliminare gli eventi selezionati?',
      newEvent: 'Nuovo evento',
      title: 'Titolo',
      location: 'Posizione',
      description: 'Descrizione',
      timezone: 'Fuso orario',
      startTimezone: 'Avvia Timezone',
      endTimezone: 'Fuso orario finale',
      repeat: 'Ripetere',
      saveButton: 'Salva',
      cancelButton: 'Annulla',
      deleteButton: 'Elimina',
      recurrence: 'ricorrenza',
      wrongPattern: 'Il modello di ricorrenza non è valido.',
      seriesChangeAlert:
        "Vuoi annullare le modifiche apportate a istanze specifiche di questa serie e abbinarle nuovamente all'intera serie?",
      createError:
        "La durata dell'evento deve essere inferiore alla frequenza con cui si verifica. Ridurre la durata o modificare il modello di ricorrenza nell'editor degli eventi di ricorrenza.",
      sameDayAlert:
        'Due ricorrenze dello stesso evento non possono verificarsi nello stesso giorno.',
      occurenceAlert:
        "Impossibile ripianificare un'occorrenza dell'appuntamento ricorrente se salta un'occorrenza successiva dello stesso appuntamento.",
      editRecurrence: 'Modifica ricorrenza',
      repeats: 'ripetizioni',
      alert: 'Mettere in guardia',
      startEndError:
        'La data di fine selezionata si verifica prima della data di inizio.',
      invalidDateError: 'Il valore della data immesso non è valido.',
      blockAlert:
        "Gli eventi non possono essere programmati entro l'intervallo di tempo bloccato.",
      ok: 'Ok',
      yes: 'sì',
      no: 'No',
      occurrence: 'avvenimento',
      series: 'Serie',
      previous: 'Precedente',
      next: 'Il prossimo',
      timelineDay: 'Timeline Day',
      timelineWeek: 'Settimana della cronologia',
      timelineWorkWeek: 'Settimana lavorativa della cronologia',
      timelineMonth: 'Mese della linea temporale',
      timelineYear: 'Anno temporale',
      editFollowingEvent: 'A seguito di eventi',
      deleteTitle: 'Elimina evento',
      editTitle: 'Modifica evento',
      beginFrom: 'Inizia da',
      endAt: 'End At',
      expandAllDaySection: 'Espandi la sezione tutto il giorno',
      collapseAllDaySection: 'Comprimi la sezione tutto il giorno',
      searchTimezone: 'Cerca fuso orario',
      noRecords: 'Nessun record trovato'
    },
    recurrenceeditor: {
      none: 'Nessuna',
      daily: 'Quotidiana',
      weekly: 'Settimanale',
      monthly: 'Mensile',
      month: 'Mese',
      yearly: 'Annuale',
      never: 'Mai',
      until: 'Fino a',
      count: 'Contare',
      first: 'Primo',
      second: 'Secondo',
      third: 'Terzo',
      fourth: 'Quarto',
      last: 'Ultimo',
      repeat: 'Frequenza',
      repeatEvery: 'Ripeti ogni',
      on: 'Ripeti il',
      end: 'Fine',
      onDay: 'Il giorno',
      days: 'Giorni',
      weeks: 'Settimane',
      months: 'Mesi',
      years: 'Anni',
      every: 'ogni',
      summaryTimes: 'volte',
      summaryOn: 'il',
      summaryUntil: 'fino a',
      summaryRepeat: 'ripetizioni',
      summaryDay: 'giorni',
      summaryWeek: 'settimane',
      summaryMonth: 'mesi',
      summaryYear: 'anni',
      monthWeek: 'Mese Settimana',
      monthPosition: 'Posizione mensile',
      monthExpander: 'Espansore del mese',
      yearExpander: 'Expander Anno',
      repeatInterval: 'Ripeti intervallo'
    },
    calendar: {
      today: 'Oggi'
    },
    datepicker: {
      today: 'Oggi',
      placeholder: 'Scegli una data'
    },
    daterangepicker: {
      placeholder: 'Scegli un intervallo di date',
      startLabel: "Data d'inizio",
      endLabel: 'Data di fine',
      applyText: 'Applicare',
      cancelText: 'Annulla',
      selectedDays: 'Giorni selezionati',
      days: 'giorni',
      customRange: 'Gamma personalizzata'
    },
    timepicker: {
      placeholder: 'Scegli un orario'
    },
    datetimepicker: {
      today: 'Oggi',
      placeholder: 'Scegli una data e ora'
    }
  }
}
