import { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { Stack, ThemeProvider, Typography, createTheme } from "@mui/material";

interface TestInterface {
  fontTap: any;
}

export const Test = memo(({ fontTap }: TestInterface) => {
  const palette = useSelector((state: RootState) => state.layoutPalette);
  const font = useSelector((state: RootState) => state.layoutFont);

  const getTheme = useCallback(() => {
    return createTheme({ palette, typography: font });
  }, [font, palette]);

  const GetText = useMemo(() => {
    if (font[fontTap]?.textTransform === "uppercase")
      return <>ABCDEFGHIJKLMNOPQRSTUVWXYZ 1234567890</>;
    if (font[fontTap]?.textTransform === "lowercase")
      return <>abcdefghijklmnopqrstuvwxyz 1234567890</>;
    return (
      <>ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 1234567890</>
    );
  }, [font, fontTap]);

  return (
    <Stack
      alignContent="center"
      sx={{
        width: "100%",
        minHeight: "140px",
        height: "100%",
        textAlign: "center",
      }}
    >
      <ThemeProvider theme={getTheme()}>
        <div style={{ margin: "auto" }}>
          <Typography
            variant={fontTap}
            sx={{
              fontFamily:
                font[fontTap]?.fontFamily !== undefined
                  ? font[fontTap]?.fontFamily
                  : font.fontFamily,
              height: "fit-content",
            }}
          >
            {GetText}
          </Typography>
        </div>
      </ThemeProvider>
    </Stack>
  );
});
