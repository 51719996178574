import { getWhiteCache } from "../../../../../lib/white-cache";
import { ServiceDetailsInterface } from "../../../interfaces/service-details";

export const getInitialValues = (
  serviceDetails: ServiceDetailsInterface | null
) => {
  const { isBluBookingActive } = getWhiteCache();
  let data: any = {
    name: "",
    smallDescription: "",
    notificationDescription: "",
    description: "",
    durationValue: 0,
    hasStudio: true,
    bluBookingActive: isBluBookingActive,
    isOnlineBooking: isBluBookingActive,
    hasAutoConfirmation: isBluBookingActive,
    studi: false,
    pointOfSales: false,
    icons: [],
  };

  if (serviceDetails === null) return data;
  return {
    ...data,
    name: serviceDetails?.name ?? "",
    smallDescription: serviceDetails?.smallDescription ?? "",
    notificationDescription: serviceDetails?.notificationDescription ?? "",
    description: serviceDetails?.description ?? "",
    durationValue: serviceDetails?.durationValue,
    hasStudio: serviceDetails?.hasStudio,
    isOnlineBooking: serviceDetails?.isOnlineBooking,
    bluBookingActive: isBluBookingActive,
    hasAutoConfirmation: serviceDetails?.hasAutoConfirmation,
    color: serviceDetails?.color,
    icons: serviceDetails?.icons ?? [],
  };
};
