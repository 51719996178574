import { BluHtmlEditor } from "@bludata/components";
import { Stack, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import "../../../../../../styles/form-input-html-area.css";

import {
  ToolbarSettingsModel,
  ToolbarType,
} from "@syncfusion/ej2-react-richtexteditor";

var checkHTML = function (html: any) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

function sanitizeValue(value: string | null | undefined): string {
  if (value === null || value === undefined || value === "") {
    return "<p></p>";
  }

  if (checkHTML(value)) {
    return value;
  } else {
    return value;
  }
}

export const CustomInputHtmlArea: FC<{
  name: string;
  label: string;
  disabled: boolean;
  [x: string]: any;
}> = ({ name, label, disabled, ...rest }) => {
  const field = useWatch({ name });
  const { setValue, trigger } = useFormContext();

  const quickToolbarSettings = {
    image: [
      "Align",
      "Caption",
      "Remove",
      "InsertLink",
      "OpenImageLink",
      "-",
      "Display",
      "AltText",
      "Dimension",
    ],
  };

  const toolbarSettings = () => {
    return {
      items: [
        "Undo",
        "Redo",
        "|",
        "Bold",
        "Italic",
        "Underline",
        "StrikeThrough",
        "|",
        "Alignments",
        "|",
      ],
      type: "MultiRow" as ToolbarType,
    } as ToolbarSettingsModel;
  };

  const handleOnChange = useCallback(
    (e: any) => {
      setValue(name, e.value);
      trigger(name);
    },
    [name, setValue, trigger]
  );

  const getValue = useCallback(() => {
    return sanitizeValue(field);
  }, [field]);

  return (
    <>
      <Stack direction="column" flex={1} gap={2}>
        <Stack flex={1}>
          <Typography>{label}</Typography>
          {disabled ? (
            <>
              <div
                style={{
                  width: "100%",
                  border: "1px solid var(--divider-color)",
                  height: 250,
                  overflowY: "auto",
                  padding: "10px 20px",
                  boxSizing: "border-box",
                  backgroundColor: "white",
                }}
                dangerouslySetInnerHTML={{ __html: getValue() }}
              />
            </>
          ) : (
            <BluHtmlEditor
              value={getValue()}
              valueTemplate={getValue()}
              toolbarSettings={toolbarSettings()}
              quickToolbarSettings={quickToolbarSettings}
              change={handleOnChange}
              height={250}
              saveInterval={500}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};
