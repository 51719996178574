import { memo } from 'react'
import React from 'react'
import { ColorPickerInterface } from './interface'
import { ColorPicker } from './newPicker'

const BluColorPicker = memo((props: ColorPickerInterface) => {
  // return <ColorPickerComponent value={value} {...rest} />
  return <ColorPicker {...props} />
})

export default BluColorPicker
