import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PointOfSaleObject } from "../interfaces/point-of-sale-object";
import { UserObject } from "../interfaces/user-object";
import { getServiceWhiteCache } from "../lib/white-cache";

export interface EnableAllInterface {
  usersForm: boolean;
  pointOfSalesForm: boolean;
}

export interface FormSliceState {
  pointOfSalesForm: PointOfSaleObject;
  usersForm: UserObject;
  enableAll: EnableAllInterface;
  serviceFormLoader: boolean;
}

const initialState: FormSliceState = {
  pointOfSalesForm: {},
  usersForm: {},
  enableAll: {
    usersForm: false,
    pointOfSalesForm: false,
  },
  serviceFormLoader: false,
};

const ServiceFormSlice = createSlice({
  name: "ServiceForm",
  initialState,
  reducers: {
    resetFormValues() {
      const { pointOfSalesForm, usersForm } = getServiceWhiteCache();
      return {
        pointOfSalesForm: pointOfSalesForm,
        usersForm: usersForm,
        enableAll: {
          usersForm: false,
          pointOfSalesForm: false,
        },
        serviceFormLoader: false,
      };
    },
    setFormValues(
      state: FormSliceState,
      action: PayloadAction<{
        pointOfSalesForm: PointOfSaleObject;
        usersForm: UserObject;
      }>
    ) {
      return {
        pointOfSalesForm: action.payload.pointOfSalesForm,
        usersForm: action.payload.usersForm,
        enableAll: {
          usersForm: false,
          pointOfSalesForm: false,
        },
        serviceFormLoader: false,
      };
    },
    setPointOfSales(
      state: FormSliceState,
      action: PayloadAction<PointOfSaleObject>
    ) {
      state.pointOfSalesForm = action.payload;
    },
    setUsers(state: FormSliceState, action: PayloadAction<UserObject>) {
      state.usersForm = action.payload;
    },
    setPointOfSaleValue(state: FormSliceState, action: PayloadAction<string>) {
      const value = !state.pointOfSalesForm[action.payload].enable;
      state.pointOfSalesForm[action.payload].enable = value;
      const studi = Object.keys(state.pointOfSalesForm[action.payload].studi);
      if (studi.length > 0)
        if (value)
          state.pointOfSalesForm[action.payload].studi[studi[0]].enable = true;
        else
          for (const studioId of studi)
            state.pointOfSalesForm[action.payload].studi[studioId].enable =
              false;
    },
    setUsersValue(state: FormSliceState, action: PayloadAction<string>) {
      const value = !state.usersForm[action.payload].enable;
      state.usersForm[action.payload].enable = value;
    },
    setStudiValue(
      state: FormSliceState,
      action: PayloadAction<{
        pointOfSaleId: string;
        studioId: string;
      }>
    ) {
      state.pointOfSalesForm[action.payload.pointOfSaleId].studi[
        action.payload.studioId
      ].enable =
        !state.pointOfSalesForm[action.payload.pointOfSaleId].studi[
          action.payload.studioId
        ].enable;
      const studi = state.pointOfSalesForm[action.payload.pointOfSaleId].studi;
      const allDisabled =
        Object.values(studi).find((studio: any) => studio.enable === true) ===
        undefined;
      if (allDisabled)
        state.pointOfSalesForm[action.payload.pointOfSaleId].enable = false;
    },
    setEnableAll(
      state: FormSliceState,
      action: PayloadAction<{ key: keyof EnableAllInterface; value: boolean }>
    ) {
      const value = action.payload.value;
      const temp = state[action.payload.key];
      if (action.payload.key === "usersForm") {
        state.enableAll.usersForm = value;
        for (const userId in temp) state.usersForm[userId].enable = value;
      } else {
        state.enableAll.pointOfSalesForm = value;

        for (const pointOfSaleId in temp) {
          temp[pointOfSaleId].enable = value;
          const studi = Object.keys(
            state.pointOfSalesForm[pointOfSaleId].studi
          );
          if (studi.length > 0)
            if (value)
              state.pointOfSalesForm[pointOfSaleId].studi[studi[0]].enable =
                true;
            else
              for (const studioId of studi)
                state.pointOfSalesForm[pointOfSaleId].studi[studioId].enable =
                  false;
        }
      }

      // return {
      //   ...state,
      //   enableAll: {
      //     ...state.enableAll,
      //     [action.payload.key]: action.payload.value,
      //   },
      //   [action.payload.key]: temp,
      // };
    },
    setServiceFormLoader(
      state: FormSliceState,
      action: PayloadAction<boolean>
    ) {
      state.serviceFormLoader = action.payload;
    },
  },
});

export const {
  resetFormValues,
  setFormValues,
  setPointOfSales,
  setStudiValue,
  setUsers,
  setPointOfSaleValue,
  setUsersValue,
  setEnableAll,
  setServiceFormLoader,
} = ServiceFormSlice.actions;

export default ServiceFormSlice.reducer;
