export const dayHours: {
  [key: string]:
    | {
        id?: string;
        isClosed: boolean;
        hour: string;
        type: "default" | "extra";
        open: boolean;
        isHoliday?: boolean;
      }
    | any;
} = {
  current: {},
  default: {},
  "05:00": {
    isClosed: true,
    type: "default",
    hour: "05:00",
    open: false,
  },
  "06:00": {
    isClosed: true,
    type: "default",
    hour: "06:00",
    open: false,
  },
  "07:00": {
    isClosed: true,
    type: "default",
    hour: "07:00",
    open: false,
  },
  "08:00": {
    isClosed: true,
    type: "default",
    hour: "08:00",
    open: false,
  },
  "09:00": {
    isClosed: true,
    type: "default",
    hour: "09:00",
    open: false,
  },
  "10:00": {
    isClosed: true,
    type: "default",
    hour: "10:00",
    open: false,
  },
  "11:00": {
    isClosed: true,
    type: "default",
    hour: "11:00",
    open: false,
  },
  "12:00": {
    isClosed: true,
    type: "default",
    hour: "12:00",
    open: false,
  },
  "13:00": {
    isClosed: true,
    type: "default",
    hour: "13:00",
    open: false,
  },
  "14:00": {
    isClosed: true,
    type: "default",
    hour: "14:00",
    open: false,
  },
  "15:00": {
    isClosed: true,
    type: "default",
    hour: "15:00",
    open: false,
  },
  "16:00": {
    isClosed: true,
    type: "default",
    hour: "16:00",
    open: false,
  },
  "17:00": {
    isClosed: true,
    type: "default",
    hour: "17:00",
    open: false,
  },
  "18:00": {
    isClosed: true,
    type: "default",
    hour: "18:00",
    open: false,
  },
  "19:00": {
    isClosed: true,
    type: "default",
    hour: "19:00",
    open: false,
  },
  "20:00": {
    isClosed: true,
    type: "default",
    hour: "20:00",
    open: false,
  },
  "21:00": {
    isClosed: true,
    type: "default",
    hour: "21:00",
    open: false,
  },
  "22:00": {
    isClosed: true,
    type: "default",
    hour: "22:00",
    open: false,
  },
  "23:00": {
    isClosed: true,
    type: "default",
    hour: "23:00",
    open: false,
  },
};
