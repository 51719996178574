import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../../store";
import { setTurniFilter } from "../../store/root";

export const TurnoType = memo(() => {
  const { turniFilter } = useSelector((state: RootState) => state.rotaRoot);
  const dispatch = useDispatch<AppDispatch>();

  const handleTurnoTypeChange = useCallback(
    (event: any) => {
      dispatch(
        setTurniFilter(
          event.target.value === "tutti" ? undefined : event.target.value
        )
      );
    },
    [dispatch]
  );

  return (
    <Stack>
      <Typography sx={{ color: "var(--color)" }}>Tipo di turno</Typography>
      <Select
        value={turniFilter === undefined ? "tutti" : turniFilter}
        onChange={handleTurnoTypeChange}
        sx={{ width: 150 }}
      >
        <MenuItem value="shift">Di lavoro</MenuItem>
        <MenuItem value="calendar">Appuntamenti</MenuItem>
        <MenuItem value="tutti">Tutti</MenuItem>
      </Select>
    </Stack>
  );
});
