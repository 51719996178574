import { Stack } from "@mui/material";
import React, { FC } from "react";
import { memo, useState } from "react";

import { Test } from "./test";
import { FontsList } from "./font-list";

interface TitlesInterface {
  disabled: boolean;
}

export const Titles: FC<TitlesInterface> = memo(({ disabled }) => {
  const [fontTap, setFontTap] = useState<string>("pageTitle");
  return (
    <Stack direction={"row"} spacing={2}>
      <Stack display="flex" flex="1" sx={{ pr: "10px" }}>
        <Stack
          gap="2%"
          flexWrap="wrap"
          sx={{ width: "100%" }}
          flexDirection="unset"
        >
          <Stack flex="1">
            <FontsList
              font={fontTap}
              disabled={disabled}
              setFont={(v: any) => setFontTap(v)}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack display="flex" flex="1.5">
        <Test fontTap={fontTap} />
      </Stack>
    </Stack>
  );
});
