import { BluTitle } from "@bludata/components";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import React from "react";
import { memo, useCallback, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GridFooter } from "./footer";
import { AppDispatch, RootState } from "../../../../../../store";
import { setPointOfSaleValue } from "../../../../store/form";
import { Columns } from "./column";
import { Stack } from "@mui/material";

export const PointOfSales = memo(() => {
  const dispatch = useDispatch<AppDispatch>();
  const [pointOfSale, hasStudio] = useWatch({
    name: ["pointOfSales", "hasStudio"],
  });
  const { setValue, trigger } = useFormContext();
  const { pointOfSalesForm } = useSelector(
    (state: RootState) => state.serviceForm
  );
  const { openForm } = useSelector((state: RootState) => state.serviceRoot);

  const getValues = useCallback(() => {
    if (hasStudio)
      return Object.values(pointOfSalesForm).filter(
        (value: any) => value?.studi && Object.keys(value.studi).length > 0
      );
    return Object.values(pointOfSalesForm);
  }, [hasStudio, pointOfSalesForm]);

  useEffect(() => {
    const value =
      Object.values(pointOfSalesForm).find(
        (value: any) => value.enable === true
      ) !== undefined;
    if (value !== pointOfSale) {
      setValue("pointOfSales", value);
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointOfSalesForm]);

  return (
    <Stack flex="1">
      <BluTitle text="Punti vendita" sx={{ p: "3px 0px" }} />
      <DataGridPremium
        getRowId={(row: any) => row.id}
        columns={Columns as any}
        rows={getValues()}
        rowCount={5}
        sx={{
          minHeight: "150px",
          maxHeight: "250px",
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
        onRowClick={(event: any) => {
          if (openForm !== "info") dispatch(setPointOfSaleValue(event.id));
        }}
        disableColumnMenu
        density="compact"
        hideFooterSelectedRowCount
        components={{
          Footer: GridFooter,
        }}
      />
    </Stack>
  );
});
