import moment from "moment";
import { updateExtraCalendarPointOfSale } from "../../../api/update-extra-calendar-user";
import { updateExtraShiftPointOfSale } from "../../../api/update-extra-shift-point-of-sale";
import { createExtraCalendarPointOfSale } from "../../../api/create-extra-calendar-point-of-sale";
import { createExtraSharedPointOfSale } from "../../../api/create-extra-shared-point-of-sale";
import { updateExtraSharedPointOfSale } from "../../../api/update-extra-shared-point-of-sale";
import { deleteExtraCalendarPointOfSale } from "../../../api/delete-extra-calendar-user";
import { deleteExtraSharedPointOfSale } from "../../../api/delete-extra-shared-point-of-sale";
import { createExtraShiftPointOfSale } from "../../../api/create-extra-shift-point-of-sale";
import { deleteExtraShiftPointOfSale } from "../../../api/delete-extra-shift-point-of-sale";

const isDifferentExtra = ({
  initialData,
  data,
}: {
  initialData: {
    startTime1: Date | null;
    startTime2: Date | null;
    endTime1: Date | null;
    endTime2: Date | null;
  };

  data: {
    startTime1: Date | null;
    startTime2: Date | null;
    endTime1: Date | null;
    endTime2: Date | null;
  };
}) => {
  return (
    ["startTime1", "startTime2", "endTime1", "endTime2"].find((key: string) => {
      if (
        initialData &&
        data &&
        key in initialData &&
        (initialData as any)[key] &&
        key in data &&
        (data as any)[key] &&
        (data as any)[key].getTime() === (initialData as any)[key].getTime()
      )
        return false;
      return true;
    }) !== undefined
  );
};

const manageChangeExtra = async ({
  id,
  initialData,
  startTime1,
  startTime2,
  endTime1,
  endTime2,
  date,
  pointOfSaleId,
  apiType,
  type,
  initialType,
}: {
  id?: string;
  pointOfSaleId: string;
  initialData: {
    startTime1: Date | null;
    startTime2: Date | null;
    endTime1: Date | null;
    endTime2: Date | null;
  };
  date: Date;

  startTime1: Date | null;
  startTime2: Date | null;
  endTime1: Date | null;
  endTime2: Date | null;
  apiType: "shift" | "calendar" | "shared";
  type: "closed" | "extra-closed" | "default" | "extra";
  initialType: "closed" | "extra-closed" | "default" | "extra";
}): Promise<boolean> => {
  let result = true;

  let createApi, updateApi, deleteApi;
  switch (apiType) {
    case "shift":
      createApi = createExtraShiftPointOfSale;
      updateApi = updateExtraShiftPointOfSale;
      deleteApi = deleteExtraShiftPointOfSale;
      break;

    case "calendar":
      createApi = createExtraCalendarPointOfSale;
      updateApi = updateExtraCalendarPointOfSale;
      deleteApi = deleteExtraCalendarPointOfSale;
      break;

    default:
      createApi = createExtraSharedPointOfSale;
      updateApi = updateExtraSharedPointOfSale;
      deleteApi = deleteExtraSharedPointOfSale;
      break;
  }

  // ! creazione extra row
  if (
    (initialType === "default" || initialType === "closed") &&
    (type === "extra" || type === "extra-closed")
  )
    result = await createApi(
      pointOfSaleId,
      startTime1 ? moment(startTime1).format("HH:mm") : null,
      endTime1 ? moment(endTime1).format("HH:mm") : null,
      startTime2 ? moment(startTime2).format("HH:mm") : null,
      endTime2 ? moment(endTime2).format("HH:mm") : null,
      moment(date).format("YYYY-MM-DD"),
      type === "extra-closed" ? true : false
    );

  if (
    (initialType === "extra" || initialType === "extra-closed") &&
    (type === "extra" || type === "extra-closed")
  ) {
    // ! update extra row
    // ! Aggiungere check
    if (
      type !== initialType ||
      isDifferentExtra({
        initialData,
        data: { startTime1, startTime2, endTime1, endTime2 },
      })
    )
      result = await updateApi(
        pointOfSaleId,
        startTime1 ? moment(startTime1).format("HH:mm") : null,
        endTime1 ? moment(endTime1).format("HH:mm") : null,
        startTime2 ? moment(startTime2).format("HH:mm") : null,
        endTime2 ? moment(endTime2).format("HH:mm") : null,
        moment(date).format("YYYY-MM-DD"),
        type === "extra-closed" ? true : false,
        id!!
      );
  }

  // ! delete extra row
  if (
    (initialType === "extra" || initialType === "extra-closed") &&
    (type === "default" || type === "closed")
  ) {
    result = await deleteApi(id!!);
  }
  return result;
};

export const manageTurniPointOfSale = async ({
  pointOfSaleId,
  isSingleCalendarManagement,
  initialDataCalendar,
  initialDataShift,
  date,

  extraShiftId,
  startTime1Shift,
  startTime2Shift,
  endTime1Shift,
  endTime2Shift,
  typeShift,
  initialTypeShift,

  extraCalendarId,
  startTime1Calendar,
  startTime2Calendar,
  endTime1Calendar,
  endTime2Calendar,
  typeCalendar,
  initialTypeCalendar,
}: {
  pointOfSaleId: string;
  isSingleCalendarManagement: boolean;
  initialDataShift: any;
  initialDataCalendar: any;
  date: Date;

  extraShiftId?: string;
  startTime1Shift: Date | null;
  startTime2Shift: Date | null;
  endTime1Shift: Date | null;
  endTime2Shift: Date | null;
  typeShift: "closed" | "extra-closed" | "default" | "extra";
  initialTypeShift: "closed" | "extra-closed" | "default" | "extra";

  extraCalendarId?: string;
  startTime1Calendar: Date | null;
  startTime2Calendar: Date | null;
  endTime1Calendar: Date | null;
  endTime2Calendar: Date | null;
  typeCalendar: "closed" | "extra-closed" | "default" | "extra";
  initialTypeCalendar: "closed" | "extra-closed" | "default" | "extra";
}) => {
  if (isSingleCalendarManagement) {
    const result = await manageChangeExtra({
      id: extraCalendarId,
      pointOfSaleId,
      date,
      startTime1: startTime1Calendar,
      startTime2: startTime2Calendar,
      endTime1: endTime1Calendar,
      endTime2: endTime2Calendar,
      initialData: initialDataCalendar,
      apiType: "shared",
      type: typeCalendar,
      initialType: initialTypeCalendar,
    });
    return result;
  }

  let resultCalendar = true;
  let resultShift = true;

  resultCalendar = await manageChangeExtra({
    id: extraCalendarId,
    pointOfSaleId,
    date,
    startTime1: startTime1Calendar,
    startTime2: startTime2Calendar,
    endTime1: endTime1Calendar,
    endTime2: endTime2Calendar,
    initialData: initialDataCalendar,
    apiType: "calendar",
    type: typeCalendar,
    initialType: initialTypeCalendar,
  });

  resultShift = await manageChangeExtra({
    id: extraShiftId,
    pointOfSaleId,
    date,
    startTime1: startTime1Shift,
    startTime2: startTime2Shift,
    endTime1: endTime1Shift,
    endTime2: endTime2Shift,
    initialData: initialDataShift,
    apiType: "shift",
    type: typeShift,
    initialType: initialTypeShift,
  });

  return resultCalendar && resultShift;
};
