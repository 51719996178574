import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Theme } from "../utils/default-theme";

const initialState: any = Theme.typography;

const LayoutFontSlice = createSlice({
  name: "LayoutFont",
  initialState,
  reducers: {
    setFont(_: any, action: PayloadAction<any>) {
      return action.payload.typography;
    },
    setFontValue(state: any, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setFontFamily(state: any, action: PayloadAction<any>) {
      state.fontFamily = action.payload;
    },
    resetFont() {
      return Theme.typography;
    },
  },
});

export const { setFont, setFontValue, setFontFamily, resetFont } =
  LayoutFontSlice.actions;

export default LayoutFontSlice.reducer;
