import moment from "moment";
import dayjs from "dayjs";

const getType = (turni: any) => {
  if (turni) {
    if (turni?.isHoliday) return "closed";
    if (turni?.isExtra) {
      if (turni?.isClosed) return "extra-closed";
      else return "extra";
    }
    if (turni?.isClosed) return "closed";
  }
  return "default";
};

const getDefaultType = (turni: any) => {
  if (turni) {
    if (
      turni?.startTime1 ||
      turni?.endTime1 ||
      turni?.startTime2 ||
      turni?.endTime2
    )
      return "default";
  }
  return "closed";
};

export const getInitialValues = ({
  date,
  calendar,
  shift,
  defaultCalendar,
  defaultShift,
  isSingleCalendarManagement,
}: {
  date: Date;
  calendar: {
    isClosed: boolean;
    isExtra: boolean;
    isHoliday: boolean;
    extraCalendarType?: number;
    startTime1: string | null;
    startTime2: string | null;
    endTime1: string | null;
    endTime2: string | null;
  };
  shift: {
    isClosed: boolean;
    isExtra: boolean;
    isHoliday: boolean;
    extraCalendarType?: number;
    startTime1: string | null;
    startTime2: string | null;
    endTime1: string | null;
    endTime2: string | null;
  };
  defaultShift?: {
    startTime1?: string;
    startTime2?: string;
    endTime1?: string;
    endTime2?: string;
  };
  defaultCalendar?: {
    startTime1?: string;
    startTime2?: string;
    endTime1?: string;
    endTime2?: string;
  };
  isSingleCalendarManagement: boolean;
}) => {
  let data = {
    isSingleCalendarManagement: isSingleCalendarManagement,
    startTime1Shift: null,
    startTime2Shift: null,
    endTime1Shift: null,
    endTime2Shift: null,
    defaultstartTime1Shift: null,
    defaultstartTime2Shift: null,
    defaultendTime1Shift: null,
    defaultendTime2Shift: null,
    typeShift: "closed",
    initialTypeShift: "closed",
    shiftExtraCalendarType: 0,

    startTime1Calendar: null,
    startTime2Calendar: null,
    endTime1Calendar: null,
    endTime2Calendar: null,
    defaultstartTime1Calendar: null,
    defaultstartTime2Calendar: null,
    defaultendTime1Calendar: null,
    defaultendTime2Calendar: null,
    typeCalendar: "closed",
    initialTypeCalendar: "closed",
    calendarExtraCalendarType: 0,
  };

  const realDate = moment(date).format("YYYY-MM-DD");

  return {
    ...data,
    isSingleCalendarManagement: isSingleCalendarManagement,
    startTime1Shift:
      shift && shift?.startTime1
        ? dayjs(`${realDate} ${shift?.startTime1}`)
        : null,
    startTime2Shift:
      shift && shift?.startTime2
        ? dayjs(`${realDate} ${shift?.startTime2}`)
        : null,
    endTime1Shift:
      shift && shift?.endTime1 ? dayjs(`${realDate} ${shift?.endTime1}`) : null,
    endTime2Shift:
      shift && shift?.endTime2 ? dayjs(`${realDate} ${shift?.endTime2}`) : null,
    defaultstartTime1Shift:
      defaultShift && defaultShift?.startTime1
        ? dayjs(`${realDate} ${defaultShift?.startTime1}`)
        : null,
    defaultstartTime2Shift:
      defaultShift && defaultShift?.startTime2
        ? dayjs(`${realDate} ${defaultShift?.startTime2}`)
        : null,
    defaultendTime1Shift:
      defaultShift && defaultShift?.endTime1
        ? dayjs(`${realDate} ${defaultShift?.endTime1}`)
        : null,
    defaultendTime2Shift:
      defaultShift && defaultShift?.endTime2
        ? dayjs(`${realDate} ${defaultShift?.endTime2}`)
        : null,
    typeShift: getType(shift),
    initialTypeShift: getType(shift),
    defaultTypeShift: getDefaultType(defaultShift),
    shiftExtraCalendarType: shift?.extraCalendarType ?? 0,
    initialShiftExtraCalendarType: shift?.extraCalendarType ?? 0,

    startTime1Calendar:
      calendar && calendar?.startTime1
        ? dayjs(`${realDate} ${calendar?.startTime1}`)
        : null,
    startTime2Calendar:
      calendar && calendar?.startTime2
        ? dayjs(`${realDate} ${calendar?.startTime2}`)
        : null,
    endTime1Calendar:
      calendar && calendar?.endTime1
        ? dayjs(`${realDate} ${calendar?.endTime1}`)
        : null,
    endTime2Calendar:
      calendar && calendar?.endTime2
        ? dayjs(`${realDate} ${calendar?.endTime2}`)
        : null,
    defaultstartTime1Calendar:
      defaultCalendar && defaultCalendar?.startTime1
        ? dayjs(`${realDate} ${defaultCalendar?.startTime1}`)
        : null,
    defaultstartTime2Calendar:
      defaultCalendar && defaultCalendar?.startTime2
        ? dayjs(`${realDate} ${defaultCalendar?.startTime2}`)
        : null,
    defaultendTime1Calendar:
      defaultCalendar && defaultCalendar?.endTime1
        ? dayjs(`${realDate} ${defaultCalendar?.endTime1}`)
        : null,
    defaultendTime2Calendar:
      defaultCalendar && defaultCalendar?.endTime2
        ? dayjs(`${realDate} ${defaultCalendar?.endTime2}`)
        : null,
    typeCalendar: getType(calendar),
    initialTypeCalendar: getType(calendar),
    defaultTypeCalendar: getDefaultType(defaultCalendar),
    calendarExtraCalendarType: calendar?.extraCalendarType ?? 0,
    initialCalendarExtraCalendarType: calendar?.extraCalendarType ?? 0,
  };
};
