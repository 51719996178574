const convertToFormData = function (value: any) {
  let postDataValue: any = {};

  for (const formKey in value) {
    if (!Array.isArray(value[formKey])) {
      const obj = value[formKey];
      for (const objKey in obj) {
        postDataValue[formKey + "." + objKey] = obj[objKey];
      }
    } else {
      const arr = value[formKey];
      arr.forEach((element: any, index: number) => {
        const obj = element;
        for (const objKey in obj) {
          postDataValue[formKey + `[${index}].` + objKey] = obj[objKey];
        }
      });
    }
  }

  return postDataValue;
};

export default convertToFormData;
