import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getPointOfSalePath } from "../lib/white-cache";
import { ServicePointOfSaleInterface } from "../interfaces/ServicePointOfSale_interface";
import Service_interface_Point_of_Sales from "../interfaces/Service_interface_Point_of_Sales";

interface ServicesObjectInterfaces {
  [x: string]: ServicePointOfSaleInterface;
}

export const fetchService = async (
  id: string
): Promise<Service_interface_Point_of_Sales[]> => {
  try {
    let link = `${getPointOfSalePath()}/Service/Creation`;
    if (id) link = `${getPointOfSalePath()}/Service/PointOfSale/${id}`;
    const {
      data: { data },
    } = await axios.get(link);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const useServicePointOfSale = (id: string, key: string = "") => {
  return useQuery({
    queryFn: async () => await fetchService(id),
    queryKey: ["service", "point-of-sale", id, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: any): ServicesObjectInterfaces => {
  const result: ServicesObjectInterfaces = {};
  for (const service of data) {
    result[service.serviceId] = {
      ...service,
      id: service?.id ?? null,
      enabled: service?.id !== undefined && service?.id !== null,
      initialEnabled: service?.id !== undefined && service?.id !== null,
    };
  }
  return result;
};
