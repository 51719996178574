import axios from "axios";
import dayjs from "dayjs";
import { ServicePointOfSaleInterface } from "../interfaces/ServicePointOfSale_interface";
import { TurniDefaultInterface } from "../interfaces/default-turni-user";
import { getUsersWhiteCache } from "../lib/white-cache";
import { TurniExtraInterface } from "../interfaces/extra-turni-user";
import { getCreateDefaultHours } from "../utils/create-default-hours";
import convertToFormData from "../../../util/convert-to-form-data";
import { parseExtraHours } from "../utils/parse-extra-hours";

export const createUser = async (
  password: string,
  isSingleCalendarManagement: boolean,
  impostazioniGenerali: any | null,
  service: {
    [x: string]: ServicePointOfSaleInterface;
  },
  defaultHours: TurniDefaultInterface | null,
  extraHours: TurniExtraInterface | null
) => {
  try {
    const { isFocusWeb } = getUsersWhiteCache();
    let link = "/api/Authenticate/Management";
    let software = "multipart/form-data";
    if (isFocusWeb) {
      link = "/focusapi/global/User";
      software = "application/json";
    }

    const CreateServiceUsers: { serviceId: string }[] = [];
    let photo: any | null | undefined = impostazioniGenerali?.photo;
    if (photo && typeof photo === "string") {
      photo = impostazioniGenerali.photo.split("?cacheTime")[0];
    }
    for (const key in service) {
      if (
        !service[key].id &&
        service[key].enabled !== service[key].initialEnabled &&
        service[key].enabled
      )
        CreateServiceUsers.push({ serviceId: service[key].serviceId });
    }

    const [ShiftUsers, CalendarUsers] = getCreateDefaultHours(
      undefined,
      defaultHours,
      isSingleCalendarManagement
    );

    const [CreateExtraShiftUsers, CreateExtraCalendarUsers] = parseExtraHours(
      undefined,
      extraHours,
      isSingleCalendarManagement
    );
    let data: any = {
      user: {
        ...impostazioniGenerali,
        isAdmin: impostazioniGenerali?.isAdmin
          ? impostazioniGenerali.isAdmin
          : undefined,
        imoTipoUtente: impostazioniGenerali?.imoTipoUtente
          ? Number(impostazioniGenerali.imoTipoUtente)
          : undefined,
        imoId: impostazioniGenerali?.imoId
          ? Number(impostazioniGenerali.imoId)
          : undefined,
        focusId: impostazioniGenerali?.focusId
          ? Number(impostazioniGenerali.focusId)
          : undefined,
        visibilityUserEnum: impostazioniGenerali?.visibilityUserEnum
          ? Number(impostazioniGenerali.visibilityUserEnum)
          : undefined,
        photo,
        userPhoto: undefined,
        password:
          password && password !== "" ? password.toUpperCase() : undefined,
        employmentDate:
          impostazioniGenerali?.employmentDate &&
          dayjs(impostazioniGenerali.employmentDate).isValid()
            ? dayjs(impostazioniGenerali.employmentDate).format("YYYY-MM-DD")
            : undefined,
        contractExpirationDate:
          impostazioniGenerali?.contractExpirationDate &&
          dayjs(impostazioniGenerali.contractExpirationDate).isValid()
            ? dayjs(impostazioniGenerali.contractExpirationDate).format(
                "YYYY-MM-DD"
              )
            : undefined,
      },
      CreateServiceUsers,
      ShiftUsers,
      CalendarUsers,
      CreateExtraCalendarUsers,
      CreateExtraShiftUsers,
    };
    await axios.post(link, convertToFormData(data), {
      headers: {
        "Content-Type": software,
      },
    });

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};
