import { MenuItem, Stack, Typography } from "@mui/material";
import { FC, memo, useCallback, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { BluSelect } from "@bludata/components";
import { useProfile } from "../../../../api/codice-profilo";
import Profile_interface from "../../../../interfaces/Profile_interface";
import { AppDispatch } from "../../../../../../store";
import { useDispatch } from "react-redux";
import { setIsLoadingForm } from "../../../../store/root";

interface FocusCodiceProfiloInterface {
  disabled: boolean;
}

export const FocusCodiceProfilo: FC<FocusCodiceProfiloInterface> = memo(
  ({ disabled }) => {
    const focusCodiceProfilo = useWatch({ name: "focusCodiceProfilo" });
    const dispatch: AppDispatch = useDispatch();

    const { data, isLoading } = useProfile();

    const { setValue } = useFormContext();

    useEffect(() => {
      dispatch(setIsLoadingForm({ key: "profile", value: isLoading }));
    }, [dispatch, isLoading]);

    const handleOnChange = useCallback(
      (event: any) => {
        setValue("focusCodiceProfilo", event.target.value);
      },
      [setValue]
    );

    return (
      <Stack sx={{ width: "200px" }}>
        <>
          {data !== undefined && data.length > 0 && (
            <>
              <Typography>{"Profilo"}</Typography>
              <Stack flex="1" direction="row">
                <BluSelect
                  multiple={false}
                  disabled={disabled}
                  value={focusCodiceProfilo}
                  onChange={handleOnChange}
                  renderValue={(selected: any) => {
                    const profilo = data.find(
                      (p: Profile_interface) => p.codiceFiliale === selected
                    );
                    if (profilo) return <>{profilo.profilo}</>;
                    return <>{"Seleziona un profilo"}</>;
                  }}
                >
                  {data.map((profilo: any) => (
                    <MenuItem value={profilo.codiceFiliale}>
                      {profilo.profilo}
                    </MenuItem>
                  ))}
                </BluSelect>
              </Stack>
            </>
          )}
        </>
      </Stack>
    );
  }
);
