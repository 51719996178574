import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultCustomTheme } from "../utils/default-custom-theme";

const initialState: any = defaultCustomTheme;

const LayoutCustomThemeSlice = createSlice({
  name: "LayoutCustomTheme",
  initialState,
  reducers: {
    setCustomTheme(_: any, action: PayloadAction<any>) {
      return action.payload;
    },
    setCustomThemeValue(state: any, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetCustomTheme() {
      return defaultCustomTheme;
    },
  },
});

export const { setCustomTheme, setCustomThemeValue, resetCustomTheme } =
  LayoutCustomThemeSlice.actions;

export default LayoutCustomThemeSlice.reducer;
