import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { memo, useCallback } from "react";
import { Stack, ThemeProvider, Typography, createTheme } from "@mui/material";

export const TestFont = memo(() => {
  const palette = useSelector((state: RootState) => state.layoutPalette);
  const font = useSelector((state: RootState) => state.layoutFont);

  const getTheme = useCallback(() => {
    return createTheme({ palette, typography: font });
  }, [font, palette]);
  return (
    <Stack
      justifyContent="center"
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "200px",
        textAlign: "center",
      }}
    >
      <ThemeProvider theme={getTheme()}>
        <div>
          <Typography
            color="text.primary"
            sx={{
              fontFamily: font.fontFamily,
              backgroundColor: palette.background.default,
            }}
          >
            ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 1234567890
          </Typography>
        </div>
        <div>
          <Typography
            color="text.disabled"
            sx={{
              fontFamily: font.fontFamily,
              backgroundColor: palette.background.default,
            }}
          >
            ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 1234567890
          </Typography>
        </div>
        <div>
          <Typography
            color="footer.text"
            sx={{
              backgroundColor: palette.footer.main,
              fontFamily: font.fontFamily,
            }}
          >
            ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 1234567890
          </Typography>
        </div>
      </ThemeProvider>
    </Stack>
  );
});
