import { BluSelect } from "@bludata/components";
import { MenuItem, Stack, Typography } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { FontConfiguration } from "./font-configuration";

interface FontsListInterface {
  font: any;
  disabled: boolean;
  setFont: Function;
}

export const FontsList = memo(
  ({ font, disabled, setFont }: FontsListInterface) => {
    const fonts = {
      pageTitle: { id: "pageTitle", title: "Titolo pagina" },
      progressBarTitle: { id: "progressBarTitle", title: "Titolo menù" },
      footerTitle: { id: "footerTitle", title: "Titolo piè di pagina" },
      footerContent: { id: "footerContent", title: "Contenuto piè di pagina" },
      contentTitle1: { id: "contentTitle1", title: "Titolo del contenuto1" },
      contentTitle2: { id: "contentTitle2", title: "Titolo del contenuto2" },
      contentDescription1: { id: "contentDescription1", title: "Descrizione1" },
      contentDescription2: { id: "contentDescription2", title: "Descrizione2" },
      contentDescription3: { id: "contentDescription3", title: "Descrizione3" },
      link: { id: "link", title: "Link" },
      recap: { id: "recap", title: "Contenuto riassunto" },
      // button: { id: "button", title: "Bottoni" },
    };
    const fontList = useSelector((state: RootState) => state.layoutFont);

    return (
      <Stack gap="10px">
        <div>
          <Typography>Tipologia testo</Typography>
          <BluSelect
            disabled={disabled}
            value={font}
            onChange={(event) => setFont(event.target.value)}
            renderValue={(selected: keyof typeof fonts | undefined) =>
              selected !== undefined && selected in fonts ? (
                <>{fonts[selected]?.title}</>
              ) : (
                <>Seleziona un font</>
              )
            }
          >
            {Object.values(fonts).map((f: any) => (
              <MenuItem key={nanoid()} value={f.id}>
                {f.title}
              </MenuItem>
            ))}
          </BluSelect>
        </div>
        <FontConfiguration
          id={font}
          disabled={disabled}
          font={fontList[font]}
        />
      </Stack>
    );
  }
);
