import { FC, memo, useCallback } from "react";
import { TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { nanoid } from "nanoid";
import { DefaultRange } from "./DefaultRange";
import { TurniItemInterface } from "../../../../interfaces/default-turni-user";
import { ContextMenu } from "./ContextMenu";

export const DefaultBody: FC<{
  pointOfSaleId: string;
  disabled: boolean;
  defaultHours: TurniItemInterface;
  isSingleCalendarManagement: boolean;
}> = memo(
  ({
    pointOfSaleId,
    disabled,
    defaultHours,
    isSingleCalendarManagement,
  }): JSX.Element => {
    const days = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    const getDayName = useCallback((day: string) => {
      switch (day) {
        case "monday":
          return "Lunedì";
        case "tuesday":
          return "Martedì";
        case "wednesday":
          return "Mercoledì";
        case "thursday":
          return "Giovedì";
        case "friday":
          return "Venerdì";
        case "saturday":
          return "Sabato";
        default:
          return "Domenica";
      }
    }, []);

    const TurniType = useCallback(
      (text: string) => {
        if (isSingleCalendarManagement) return <></>;
        return (
          <TableCell
            id={nanoid()}
            key={nanoid()}
            size="small"
            sx={{
              borderTopWidth: "0px",
              borderLeft: "1px solid rgba(224, 224, 224, 1)",
              p: 0,
              height: "30px",
            }}
          >
            <Typography sx={{ pl: "10px" }}>{text}</Typography>
          </TableCell>
        );
      },
      [isSingleCalendarManagement]
    );

    const getHours = useCallback(
      (turniType: "shift" | "calendar", day: string) => {
        // let type = turniType;
        // if (isSingleCalendarManagement) type = "calendar";
        return (
          <>
            <DefaultRange
              disabled={disabled}
              day={day}
              turniType={turniType}
              pointOfSaleId={pointOfSaleId}
            />
            <ContextMenu
              defaultHours={defaultHours}
              disabled={disabled}
              day={day}
              turniType={turniType}
              pointOfSaleId={pointOfSaleId}
            />
          </>
        );
      },
      [defaultHours, disabled, pointOfSaleId]
    );

    return (
      <TableBody>
        {days.map((day: string) => {
          const isSunday = day !== "sunday";
          return (
            <>
              <TableRow
                style={{
                  height: "30px",
                  boxSizing: "border-box",
                }}
              >
                <TableCell
                  id={nanoid()}
                  key={nanoid()}
                  size="small"
                  rowSpan={isSingleCalendarManagement ? 1 : 2}
                  sx={{
                    borderTopWidth: "0px",
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                    p: 0,
                    pl: "5px",
                  }}
                >
                  {getDayName(day)}
                </TableCell>
                {TurniType("Turni di lavoro")}
                {getHours("shift", day)}
              </TableRow>
              {!isSingleCalendarManagement && (
                <TableRow
                  style={{
                    height: "30px",
                    borderBottom: isSunday
                      ? "2px solid rgba(224, 224, 224, 1)"
                      : undefined,
                  }}
                >
                  {TurniType("Turni appuntamenti")}
                  {getHours("calendar", day)}
                </TableRow>
              )}
            </>
          );
        })}
      </TableBody>
    );
  }
);
