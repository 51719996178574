import { useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { DeletePopup } from "../../../../../../UI/DeletePopup";
import { RootState, AppDispatch } from "../../../../../../store";
import { setOpenLoader } from "../../../../../../store/slices/root";
import { serviceDelete } from "../../../../api/delete-service";
import { memo, useCallback } from "react";
import {
  setCurrentService,
  setDeleteService,
  setOpenForm,
} from "../../../../store/root";
import {
  getServiceWhiteCache,
  setServiceWhiteCache,
} from "../../../../lib/white-cache";
import { setFormValues } from "../../../../store/form";
import { useCountdown } from "../../../../../../util/countdown";

export const ServiceDeletePopup = memo((): JSX.Element => {
  const { deleteService } = useSelector(
    (state: RootState) => state.serviceRoot
  );
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const countDown = useCountdown(10);

  const isOpen = useCallback(
    () => deleteService !== undefined,
    [deleteService]
  );

  const cancel = useCallback(
    () => dispatch(setDeleteService(undefined)),
    [dispatch]
  );

  const handleDelete = useCallback(async () => {
    dispatch(setOpenLoader(true));
    const result = await serviceDelete({ serviceId: deleteService!! });
    dispatch(setOpenLoader(false));
    if (result) {
      dispatch(setDeleteService(undefined));
      dispatch(setOpenForm(undefined));
      dispatch(setCurrentService(""));
      const {
        pointOfSalesForm: previousPos,
        usersForm: previousUsers,
        defaultPointOfSales,
        defaultStudi,
        defaultUsers,
      } = getServiceWhiteCache();

      dispatch(setOpenForm(undefined));
      dispatch(setCurrentService(""));
      dispatch(
        setFormValues({
          pointOfSalesForm: previousPos,
          usersForm: previousUsers,
        })
      );
      setServiceWhiteCache("initialPointOfSales", defaultPointOfSales);
      setServiceWhiteCache("initialStudi", defaultStudi);
      setServiceWhiteCache("initialUsers", defaultUsers);
      queryClient.invalidateQueries({ queryKey: ["details", "service"] });
      queryClient.invalidateQueries({
        queryKey: ["all-filters", "services"],
      });
      queryClient.invalidateQueries(["calendar"], {
        type: "active",
      });
      queryClient.removeQueries(["calendar"], {
        type: "inactive",
      });
      queryClient.invalidateQueries(["filter"], {
        type: "active",
      });
      queryClient.removeQueries(["filter"], {
        type: "inactive",
      });
    }
  }, [deleteService, dispatch, queryClient]);

  return (
    <DeletePopup
      open={isOpen()}
      action={handleDelete}
      cancelAction={cancel}
      text="Sei sicuro di voler eliminare definitivamente questo servizio?"
      text2="Questa operazione è irreversibile e tutti gli appuntamenti associati a questo studio verranno eliminati."
      countDown={countDown}
    />
  );
});
