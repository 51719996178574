import { BluTitle } from "@bludata/components";
import { nanoid } from "@reduxjs/toolkit";
import { memo, useCallback, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { CheckboxGroup } from "./checkbox-group";
import { RootState } from "../../../../../../store";
import { Stack } from "@mui/material";

export const Studi = memo(() => {
  const hasStudio = useWatch({ name: "hasStudio" });
  const studio = useWatch({ name: "studi" });
  const { setValue, trigger } = useFormContext();
  const { pointOfSalesForm } = useSelector(
    (state: RootState) => state.serviceForm
  );

  useEffect(() => {
    if (hasStudio) {
      const pointOfSaleKeys = Object.keys(pointOfSalesForm);
      let i = 0;
      let value = false;
      while (i < pointOfSaleKeys.length && !value) {
        value =
          Object.values(pointOfSalesForm[pointOfSaleKeys[i]].studi).find(
            (studio: any) => studio.enable === true
          ) !== undefined;
        i++;
      }
      if (value !== studio) {
        setValue("studi", value);
        trigger();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasStudio, pointOfSalesForm]);

  return <>{hasStudio && <StudiInner1 />}</>;
});

export const StudiInner1 = memo(() => {
  const { pointOfSalesForm } = useSelector(
    (state: RootState) => state.serviceForm
  );

  const getEnabledPointOfSale = useCallback(() => {
    return Object.values(pointOfSalesForm).filter(
      (pos) => pos.enable && JSON.stringify(pos.studi) !== "{}"
    );
  }, [pointOfSalesForm]);

  return (
    <Stack flex="1">
      <BluTitle text="Studi" sx={{ p: "3px 0px" }} />
      <div className="studi">
        {getEnabledPointOfSale().map(({ id, name, studi }: any) => {
          return (
            <CheckboxGroup
              key={nanoid()}
              pointOfSaleName={name}
              pointOfSaleId={id}
              studi={studi}
            />
          );
        })}
      </div>
    </Stack>
  );
});
