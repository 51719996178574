import { ShopSignInterface } from "../../../interfaces/shop-sign";
import { defaultCustomTheme } from "./default-custom-theme";
import { defaultTextPrivacyNotice } from "./default-privacy-notice";
import { Theme } from "./default-theme";

export const defaultShopSign: ShopSignInterface = {
  // name: "",
  // email: "",
  // telephone: "",
  // webSiteLink: "",
  isTestingActive: true,
  token: "new",
  address: "",
  region: "",
  fax: "",
  postalCode: "",
  vatCode: "",
  pec: "",
  city: "",
  // logo: "",
  // favicon: "",
  minBookingValue: 60,
  maxBookingValue: 8,
  minBookingUnit: 0,
  maxBookingUnit: 3,
  minCancellationValue: 24,
  minCancellationUnit: 1,
  timeSlot: 30,
  isRecallOn: true,
  recallValue: 24,
  recallUnit: 1,
  maxRequestableAppointmentByDay: 5,
  isUserSelectable: false,
  privacyPolicy: "<p></p>",
  privacyNotice: defaultTextPrivacyNotice,
  configurationJson: JSON.stringify({
    components: Theme,
    palette: Theme,
    typography: Theme,
  }),
  appointmentProcess: 0,
  customConfigurationJson: JSON.stringify(defaultCustomTheme),
  holidays: [],
  CreateHolidays: [],
  DeleteHolidays: [],
  ModifyHolidays: [],
  isSmsActive: false,
  isGoogleMapsActive: false,
  smsPriority: 0,
  imoPriority: 0,
  emailPriority: 1,
  nameOnline: "",
  confirmationMessage:
    "Ti confermiamo l'appuntamento ([tipo appuntamento]) il [data] alle [ora] presso il nostro centro ottico in [indirizzo].",
  modificationMessage:
    "Ti confermiamo la modifica del tuo appuntamento ([tipo appuntamento]) il [data] alle [ora] nel nostro centro ottico in [indirizzo].",
  cancellationMessage:
    "Gentile cliente, l'appuntamento del [data] alle [ora] presso il nostro negozio in [indirizzo] è stato cancellato.",
  reminderMessage:
    "Ti ricordiamo l'appuntamento ([tipo appuntamento]) il [data] alle [ora] presso il nostro centro ottico in [indirizzo].",
  confirmationIMO:
    "Ti confermiamo l'appuntamento ([tipo appuntamento]) il [data] alle [ora] presso il nostro centro ottico in [indirizzo]. Per modificare o cancellare l'appuntamento, visita la sezione 'Prenota'. Per contattarci, scrivi un messaggio qui o chiamaci ai numeri che trovi nella sezione 'Ottico'.",
  modificationIMO:
    "Ti confermiamo la modifica del tuo appuntamento ([tipo appuntamento]) il [data] alle [ora] nel nostro centro ottico in [indirizzo]. Se desideri modificare o cancellare l'appuntamento, visita la sezione 'Prenota'. Per contattarci, scrivi un messaggio qui o chiamaci ai numeri che trovi nella sezione 'Ottico'.",
  cancellationIMO:
    "Gentile cliente, l'appuntamento il [data] alle [ora] presso il nostro negozio in [indirizzo] è stato cancellato. Per contattarci, scrivi un messaggio qui o chiamaci ai numeri che trovi nella sezione 'Ottico'.",
  reminderIMO:
    "Ti ricordiamo l'appuntamento ([tipo appuntamento]) il [data] alle [ora] nel nostro centro ottico in [indirizzo]. Per contattarci, invia un messaggio qui o chiamaci ai numeri disponibili nella sezione 'Ottico'.",
};
