import moment from "moment";
import { CalendarPointOfSaleInterface } from "../interfaces/calendar-point-of-sale";
import { ExtraCalendarPointOfSaleInterface } from "../interfaces/extra-calendar-point-of-sale";
import { useQuery } from "@tanstack/react-query";
import { checkTurnoAlreadySet } from "../utils/check-turno-already-set";
import { PointOfSaleTurniInterface } from "../interfaces/point-of-sale-turni";
import { getShiftPointOfSale } from "./shift-point-of-sale";
import { getCalendarPointOfSale } from "./calendar-point-of-sale";

export const getTurniPointOfSale = async (
  pointOfSaleId: string,
  range: Date,
  isSingleCalendarManagement: boolean
): Promise<
  [
    [CalendarPointOfSaleInterface[], ExtraCalendarPointOfSaleInterface[]],
    [CalendarPointOfSaleInterface[], ExtraCalendarPointOfSaleInterface[]]
  ]
> => {
  const startTime = moment(range).startOf("month").format("YYYY-MM-DD");
  const endTime = moment(range).endOf("month").format("YYYY-MM-DD");

  if (!isSingleCalendarManagement) {
    try {
      const responses = await Promise.all([
        getCalendarPointOfSale(pointOfSaleId, startTime, endTime),
        getShiftPointOfSale(pointOfSaleId, startTime, endTime),
      ]);

      return responses as any;
    } catch (error) {
      return [
        [[], []],
        [[], []],
      ];
    }
  }

  try {
    const responses = await getCalendarPointOfSale(
      pointOfSaleId,
      startTime,
      endTime
    );

    return [responses, [[], []]] as any;
  } catch (error) {
    return [
      [[], []],
      [[], []],
    ];
  }
};

export const useTurniPointOfSale = (
  fetchPointOfSale: boolean,
  pointOfSaleId: string,
  range: Date,
  isSingleCalendarManagement: boolean,
  key: string = ""
) => {
  return useQuery({
    queryFn: async () =>
      await getTurniPointOfSale(
        pointOfSaleId,
        range,
        isSingleCalendarManagement
      ),
    queryKey: ["turni-point-of-sale", pointOfSaleId, range, key],
    retry: 2,
    enabled: fetchPointOfSale,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: any): any => {
  const result: PointOfSaleTurniInterface[] = [];
  for (const key of data) {
    const [calendarDays, extraDays] = key;

    const temp: PointOfSaleTurniInterface = {
      default: {
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
      },
      extra: {},
    };

    try {
      for (const extra of extraDays) {
        const date = moment(extra.startValidDate).format("YYYY-MM-DD");
        if (extra.isClosingDay)
          temp.extra[date] = {
            id: extra.id,
            extraCalendarType: extra.extraCalendarType,
            isClosed: true,
            startTime1: null,
            startTime2: null,
            endTime1: null,
            endTime2: null,
          };
        else
          temp.extra[date] = {
            id: extra.id,
            extraCalendarType: extra.extraCalendarType,
            isClosed: false,
            startTime1: (extra?.startTime1 || "").slice(0, -3),
            startTime2: (extra?.startTime2 || "").slice(0, -3),
            endTime1: (extra?.endTime1 || "").slice(0, -3),
            endTime2: (extra?.endTime2 || "").slice(0, -3),
          };
      }
      for (const calendar of calendarDays) {
        const isClosed = !(
          (calendar.startTime1 !== null && calendar.endTime1 !== null) ||
          (calendar.startTime2 !== null && calendar.endTime2 !== null)
        );
        if (calendar.hasMonday) {
          temp.default.monday = {
            id: calendar.id,
            isClosed: isClosed,
            ...checkTurnoAlreadySet(temp.default.monday, calendar, isClosed),
          };
        }
        if (calendar.hasTuesday) {
          temp.default.tuesday = {
            id: calendar.id,
            isClosed: isClosed,
            ...checkTurnoAlreadySet(temp.default.tuesday, calendar, isClosed),
          };
        }
        if (calendar.hasWednesday) {
          temp.default.wednesday = {
            id: calendar.id,
            isClosed: isClosed,
            ...checkTurnoAlreadySet(temp.default.wednesday, calendar, isClosed),
          };
        }
        if (calendar.hasThursday) {
          temp.default.thursday = {
            id: calendar.id,
            isClosed: isClosed,
            ...checkTurnoAlreadySet(temp.default.thursday, calendar, isClosed),
          };
        }
        if (calendar.hasFriday) {
          temp.default.friday = {
            id: calendar.id,
            isClosed: isClosed,
            ...checkTurnoAlreadySet(temp.default.friday, calendar, isClosed),
          };
        }
        if (calendar.hasSaturday) {
          temp.default.saturday = {
            id: calendar.id,
            isClosed: isClosed,
            ...checkTurnoAlreadySet(temp.default.saturday, calendar, isClosed),
          };
        }
        if (calendar.hasSunday) {
          temp.default.sunday = {
            id: calendar.id,
            isClosed: isClosed,
            ...checkTurnoAlreadySet(temp.default.sunday, calendar, isClosed),
          };
        }
      }
      result.push(temp);
    } catch (err) {
      console.error("ERROR:", err);
    }
  }

  return result;
};
