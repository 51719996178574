import { Autocomplete, Stack, TextField } from "@mui/material";
import { FC, memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { nanoid } from "nanoid";

interface PointOfSaleSelectionInterface {
  disabled: boolean;
}

export const PointOfSaleSelection: FC<PointOfSaleSelectionInterface> = memo(
  ({ disabled }) => {
    const pointOfSaleId = useWatch({ name: "pointOfSaleId" });
    const { pointOfSales } = useSelector(
      (state: RootState) => state.newUsersRoot
    );
    const { setValue } = useFormContext();

    const getValue = useCallback(() => {
      if (pointOfSales && pointOfSaleId && pointOfSaleId in pointOfSales) {
        return pointOfSales[pointOfSaleId];
      }
      return undefined;
    }, [pointOfSaleId, pointOfSales]);

    const getOptions = useCallback(() => {
      if (pointOfSales) return Object.values(pointOfSales);
      return [];
    }, [pointOfSales]);

    const handleOnChange = useCallback(
      (_: any, newValue: any) => {
        setValue("pointOfSaleId", newValue.id);
      },
      [setValue]
    );

    return (
      <>
        <Stack
          sx={{
            flex: 1,
          }}
        >
          <Autocomplete
            value={getValue()}
            onChange={handleOnChange}
            options={getOptions()}
            getOptionLabel={(data: any) => {
              if (typeof data === "string") return data;
              return data.branch;
            }}
            disabled={disabled}
            freeSolo
            disableClearable
            sx={{ flex: 1 }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={nanoid()}>
                  {option.branch}
                </li>
              );
            }}
            renderInput={(params: any) => (
              <TextField {...params} label="Filiale" />
            )}
          />
        </Stack>
      </>
    );
  }
);
