import { Divider, Stack, TableCell } from "@mui/material";
import { FC, memo, useMemo } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { nanoid } from "nanoid";
import { Moment } from "moment";
import moment from "moment";

interface CellDayInterface {
  hour: number;
  date: Date;
  isInitial?: boolean;
  isHoliday?: boolean;
  shift?: any;
  calendar?: any;
  isSingleCalendarManagement: boolean;
}

export const DoubleCellDay: FC<CellDayInterface> = memo(
  ({
    hour,
    shift,
    calendar,
    date,
    isSingleCalendarManagement,
    isInitial = false,
    isHoliday = false,
  }) => {
    const { turniFilter } = useSelector((state: RootState) => state.rotaRoot);

    const GetContent = ({
      isInitial = false,
      isHoliday = false,
      open,
      isClosed = false,
      start,
      end,
      type = "default",
    }: any) => {
      if (isInitial)
        return (
          <Stack
            gap={1}
            flex="1"
            sx={{
              height: "40px",
            }}
            justifyContent="center"
          ></Stack>
        );
      if (isHoliday)
        return (
          <Stack
            gap={1}
            flex="1"
            sx={{
              background: "var(--closed-color)",
              height: "40px",
              zIndex: 0,
              opacity: 0.5,
            }}
            justifyContent="center"
          >
            <ClearIcon
              sx={{
                // color: "#ffffff!important",
                width: "15px",
                height: "15px",
                margin: "auto",
              }}
            />
          </Stack>
        );

      if (!open) {
        if (type === "extra" && isClosed) {
          return (
            <Stack
              gap={1}
              flex="1"
              sx={{
                border: "1px solid var(--accent-pressed-color)",
                boxSizing: "border-box",
                background: "#de5c5c30",

                height: "40px",
                color: "white",
              }}
              justifyContent="center"
            ></Stack>
          );
        }
        return (
          <Stack
            gap={1}
            flex="1"
            sx={{
              background: "var(--closed-color)",
              height: "40px",
              zIndex: 0,
              opacity: 0.5,
            }}
            justifyContent="center"
          >
            <ClearIcon
              sx={{
                // color: "#ffffff!important",
                width: "15px",
                height: "15px",
                margin: "auto",
              }}
            />
          </Stack>
        );
      }

      let openPercent = 0;
      if (start) openPercent = start ? ((60 - start) / 60) * 100 : 0;
      else openPercent = end ? (end / 60) * 100 : 0;

      return (
        <Stack
          flex="1"
          direction={end !== undefined ? "row-reverse" : "row"}
          sx={{
            width: "100%",
          }}
          justifyContent="center"
        >
          <Stack
            gap={1}
            sx={{
              background: "var(--closed-color)",
              height: "40px",
              width: `${!openPercent ? 0 : 100 - openPercent}%`,
              opacity: 0.5,
            }}
            justifyContent="center"
          />
          {type === "extra" ? (
            <Stack
              gap={1}
              sx={{
                border: "1px solid #1375c0",
                boxSizing: "border-box",
                background: "#1375c020",
                height: "40px",
                width: `${!openPercent ? 100 : openPercent}%`,
              }}
              justifyContent="center"
            />
          ) : (
            <Stack
              gap={1}
              flex="1"
              sx={{
                height: "40px",
              }}
              justifyContent="center"
            />
          )}
        </Stack>
      );
    };

    const getOpen = (hours: any, hour: Moment) => {
      let open = false;
      if (hours?.startTime1 && hours?.endTime1) {
        open = hour.isBetween(
          hour.clone().set({
            hour: hours.startTime1.substring(0, 2),
            minutes: hours.startTime1.substring(3, 5),
          }),
          hour.clone().set({
            hour: hours.endTime1.substring(0, 2),
            minutes: hours.endTime1.substring(3, 5),
          }),
          "minutes",
          "[)"
        );
      }
      if (!open && hours?.startTime2 && hours?.endTime2) {
        open = hour.isBetween(
          hour.clone().set({
            hour: hours.startTime2.substring(0, 2),
            minutes: hours.startTime2.substring(3, 5),
          }),
          hour.clone().set({
            hour: hours.endTime2.substring(0, 2),
            minutes: hours.endTime2.substring(3, 5),
          }),
          "minutes",
          "[)"
        );
      }
      return open;
    };

    const ShiftComponent = useMemo(() => {
      if (turniFilter === "calendar" || isSingleCalendarManagement)
        return <></>;
      if (isInitial) return <GetContent isInitial />;
      if (isHoliday) return <GetContent isHoliday />;
      const el = moment(date).clone().set({ hour: hour });
      let find = [0, 15, 30, 45].find((x) =>
        getOpen(shift, el.clone().set({ minutes: x }))
      );
      let find2: any = undefined;

      if (find === 0) {
        find2 = [0, 15, 30, 45].find(
          (x) => !getOpen(shift, el.clone().set({ minutes: x }))
        );
        // if (find2 === 0) find2 = undefined;
      }

      return (
        <Stack flex="1" direction="row">
          <GetContent
            open={find !== undefined || find2 !== undefined}
            start={find}
            end={find2}
            type={shift?.isExtra ? "extra" : "default"}
            isClosed={shift?.isClosed}
          />
        </Stack>
      );
    }, [
      date,
      hour,
      isHoliday,
      isInitial,
      isSingleCalendarManagement,
      shift,
      turniFilter,
    ]);

    const CalendarComponent = useMemo(() => {
      if (turniFilter === "shift") return <></>;
      if (isInitial) return <GetContent isInitial />;
      if (isHoliday) return <GetContent isHoliday />;
      const el = moment(date).clone().set({ hour: hour });
      let find = [0, 15, 30, 45].find((x) =>
        getOpen(calendar, el.clone().set({ minutes: x }))
      );
      let find2: any = undefined;

      if (find === 0) {
        find2 = [0, 15, 30, 45].find(
          (x) => !getOpen(calendar, el.clone().set({ minutes: x }))
        );
        // if (find2 === 0) find2 = undefined;
      }
      return (
        <Stack flex="1" direction="row">
          <GetContent
            open={find !== undefined || find2 !== undefined}
            start={find}
            end={find2}
            type={calendar?.isExtra ? "extra" : "default"}
            isClosed={calendar?.isClosed}
          />
        </Stack>
      );
    }, [turniFilter, isInitial, isHoliday, date, hour, calendar]);

    return (
      <TableCell
        key={nanoid()}
        align="center"
        style={{
          padding: 0,
          margin: 0,
          height:
            isSingleCalendarManagement || turniFilter !== undefined
              ? "40px"
              : "80px",
          cursor: "default",
          boxSizing: "border-box",
          borderBottom: "1px solid var(--divider-color)",
          borderRight: "1px solid var(--divider-color)",
        }}
      >
        <Stack
          flex="1"
          sx={{
            boxSizing: "border-box",
            p: 0,
            height:
              isSingleCalendarManagement || turniFilter !== undefined
                ? "41px"
                : "81px",
          }}
        >
          {ShiftComponent}
          {turniFilter === undefined && !isSingleCalendarManagement && (
            <Divider sx={{ borderColor: "var(--divider-color)" }} />
          )}
          {CalendarComponent}
        </Stack>
      </TableCell>
    );
  }
);
