import moment, { Moment } from "moment";
import { useQuery } from "@tanstack/react-query";
import { CalendarUserInterface } from "../interfaces/calendar-user";
import { ExtraCalendarUserInterface } from "../interfaces/extra-calendar-user";
import { UserTurniInterface } from "../interfaces/user-turni";
import { getCalendarUser } from "./calendar-user";
import { getShiftUser } from "./shift-user";
import { dayHours } from "../utils/day-hours";

export const getTurniUserDay = async (
  pointOfSaleId: string,
  range: Date,
  isSingleCalendarManagement: boolean
): Promise<
  [
    [CalendarUserInterface[], ExtraCalendarUserInterface[]],
    [CalendarUserInterface[], ExtraCalendarUserInterface[]]
  ]
> => {
  const day = moment(range).format("YYYY-MM-DD");

  if (!isSingleCalendarManagement) {
    try {
      const responses = await Promise.all([
        getCalendarUser(pointOfSaleId, day, day),
        getShiftUser(pointOfSaleId, day, day),
      ]);

      return responses as any;
    } catch (error) {
      return [
        [[], []],
        [[], []],
      ];
    }
  }

  try {
    const responses = await getCalendarUser(pointOfSaleId, day, day);
    return [responses, [[], []]] as any;
  } catch (error) {
    return [
      [[], []],
      [[], []],
    ];
  }
};

export const useTurniUserDay = (
  fetchPointOfSale: boolean,
  pointOfSaleId: string,
  range: Date,
  isSingleCalendarManagement: boolean,
  key: string = ""
) => {
  return useQuery({
    queryFn: async () =>
      await getTurniUserDay(pointOfSaleId, range, isSingleCalendarManagement),
    queryKey: [
      "turni-user-day",
      pointOfSaleId,
      range,
      isSingleCalendarManagement,
      key,
    ],
    enabled: fetchPointOfSale,
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: (data: any) => normalize(data, range),
  });
};

const normalize = (data: any, range: Date): any => {
  const result: {
    [x: string]: UserTurniInterface;
  }[] = [];
  const date: Moment = moment(range);
  const daysOfWeekConversion = [
    "hasSunday",
    "hasMonday",
    "hasTuesday",
    "hasWednesday",
    "hasThursday",
    "hasFriday",
    "hasSaturday",
  ];

  for (const turniType of data) {
    let temp: any = {};
    const turniResult: {
      [x: string]: any;
    } = {};
    const [calendarUsers, extraDays] = turniType;

    for (const user of calendarUsers) {
      let isExtra = false;

      temp = {
        userId: user.userId,
        username: user.username,
        photo: user.photo,
        ...dayHours,
      };

      try {
        const userExtra = extraDays.filter(
          (day: any) => day.userId === user.userId
        );

        for (const extra of userExtra) {
          const extraDate = moment(extra.startValidDate).format("YYYY-MM-DD");
          const formattedRange = date.format("YYYY-MM-DD");
          if (extraDate === formattedRange) {
            isExtra = true;

            temp.current = {
              extraId: extra.id,
              id: extra.id,
              isClosed: false,
              isExtra: true,
              isHoliday: false,
              extraCalendarType: extra.extraCalendarType,
              startTime1: !extra.isClosingDay
                ? (extra?.startTime1 || "").slice(0, -3)
                : null,
              startTime2: !extra.isClosingDay
                ? (extra?.startTime2 || "").slice(0, -3)
                : null,
              endTime1: !extra.isClosingDay
                ? (extra?.endTime1 || "").slice(0, -3)
                : null,
              endTime2: !extra.isClosingDay
                ? (extra?.endTime2 || "").slice(0, -3)
                : null,
            };
          }
        }
        for (const calendar of user?.calendars ?? []) {
          const dayOfWeek = date.day();
          const dayOfWeekKey = daysOfWeekConversion[dayOfWeek];

          if (calendar[dayOfWeekKey]) {
            const isClosed = !(
              (calendar.startTime1 !== null && calendar.endTime1 !== null) ||
              (calendar.startTime2 !== null && calendar.endTime2 !== null)
            );
            if (!isExtra) {
              temp.current = {
                id: calendar.id,
                isClosed: isClosed,

                startTime1: !isClosed
                  ? (calendar?.startTime1 || "").slice(0, -3)
                  : null,
                startTime2: !isClosed
                  ? (calendar?.startTime2 || "").slice(0, -3)
                  : null,
                endTime1: !isClosed
                  ? (calendar?.endTime1 || "").slice(0, -3)
                  : null,
                endTime2: !isClosed
                  ? (calendar?.endTime2 || "").slice(0, -3)
                  : null,
              };
            }

            temp.default = {
              startTime1: !isClosed
                ? (calendar?.startTime1 || "").slice(0, -3)
                : null,
              startTime2: !isClosed
                ? (calendar?.startTime2 || "").slice(0, -3)
                : null,
              endTime1: !isClosed
                ? (calendar?.endTime1 || "").slice(0, -3)
                : null,
              endTime2: !isClosed
                ? (calendar?.endTime2 || "").slice(0, -3)
                : null,
            };
          }
        }
        turniResult[user.userId] = temp;
      } catch (err) {
        console.error("ERROR:", err);
      }
    }
    result.push(turniResult);
  }
  return result;
};
