export const content = `
Il campo stato messaggio indica se una notifica è stata inviata correttamente o se ci sono stati problemi durante l'invio. Di seguito sono elencati i possibili stati che questo campo può assumere:
1. **Successo**  
\tDescrizione: La notifica è stata inviata correttamente.  
\tEsempio: Tutti i dati richiesti sono presenti e la notifica è stata consegnata senza problemi.  
\tAzione necessaria: Nessuna azione richiesta, l'invio è avvenuto con successo.
2. **Invio saltato**   
\tDescrizione: Alcuni campi richiesti lato cliente per l'invio della notifica sono mancanti.  
\tEsempio: Se si desidera inviare una notifica via email ma manca l’indirizzo email del cliente nell’appuntamento, non sarà possibile inviare la notifica.  
\tAzione necessaria: Verificare e completare i campi mancanti nell'appuntamento, se sono reperibili, per permettere l'invio della notifica.  
3. **Dati mancanti**   
\tDescrizione: Alcuni campi richiesti la negozio per l'invio della notifica sono mancanti.  
\tEsempio: Non è stato inserito il nome e cognome dell'utente associato all'appuntamento; oppure manca l'email associata al punto vendita.  
\tAzione necessaria: Verificare e completare i campi mancanti nell'appuntamento per permettere l'invio della notifica.  
4. **Errore invio**  
\tDescrizione: Si è verificato un problema durante l'invio della notifica.  
\tEsempio: Il server di posta potrebbe essere non disponibile, oppure potrebbero esserci problemi di connessione.  
\tAzione necessaria: Controllare i dettagli dell'errore e riprovare l'invio della notifica.
5. **In attesa**    
\tDescrizione: La notifica è in attesa di essere inviata.   
\tEsempio: La notifica è stata creata ma non è ancora stata inviata.    
\tAzione necessaria: Nessuna azione richiesta, la notifica verrà inviata automaticamente.   
`;
