import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImportCheckInterface } from "../interfaces/import-check";

export interface RotaImportSliceState {
  open: boolean;
  loading: boolean;
  progress: number;
  status: number;
  step: "init" | "import" | "confirm" | "error";
  file: any | null;
  fileName: string;
  pointOfSaleId: string;
  documentFormat: "bludata" | "factorial";
  importCheck: ImportCheckInterface | null;
}

const initialState: RotaImportSliceState = {
  open: false,
  loading: false,
  progress: 0,
  status: 0,
  pointOfSaleId: "",
  fileName: "",
  file: null,
  step: "init",
  importCheck: null,
  documentFormat: "bludata",
};

const RotaImportSlice = createSlice({
  name: "RotaImport",
  initialState,
  reducers: {
    setImportOpen(state: RotaImportSliceState, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
    setFile(
      state: RotaImportSliceState,
      action: PayloadAction<{
        file: any;
        fileName: string;
      }>
    ) {
      state.file = action.payload.file;
      state.fileName = action.payload.fileName;
    },
    setPointOfSaleId(
      state: RotaImportSliceState,
      action: PayloadAction<string>
    ) {
      state.pointOfSaleId = action.payload;
    },
    setDocumentFormat(
      state: RotaImportSliceState,
      action: PayloadAction<"bludata" | "factorial">
    ) {
      state.documentFormat = action.payload;
      state.file = null;
      state.fileName = "";
    },
    setImportLoading(
      state: RotaImportSliceState,
      action: PayloadAction<boolean>
    ) {
      state.loading = action.payload;
    },
    setStatus(state: RotaImportSliceState, action: PayloadAction<number>) {
      state.status = action.payload;
    },
    setProgress(state: RotaImportSliceState, action: PayloadAction<number>) {
      state.progress = action.payload;
    },
    setStep(
      state: RotaImportSliceState,
      action: PayloadAction<"init" | "import" | "confirm" | "error">
    ) {
      state.step = action.payload;
    },
    setImportCheck(
      state: RotaImportSliceState,
      action: PayloadAction<ImportCheckInterface | null>
    ) {
      state.importCheck = action.payload;
    },
    resetImportData(_: RotaImportSliceState, action: PayloadAction<string>) {
      return {
        ...initialState,
        pointOfSaleId: action.payload,
      };
    },
  },
});

export const {
  setImportOpen,
  setImportLoading,
  setProgress,
  setStatus,
  setStep,
  setImportCheck,
  resetImportData,
  setFile,
  setPointOfSaleId,
  setDocumentFormat,
} = RotaImportSlice.actions;

export default RotaImportSlice.reducer;
