import { FC, memo, useMemo } from "react";
import { BluTitle } from "@bludata/components";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { getUsersWhiteCache } from "../../../../lib/white-cache";
import { useSelector } from "react-redux";
import { RootState, useAppSelector } from "../../../../../../store";
import { DefaultHead } from "./DefaultHead";
import { DefaultBody } from "./DefaultBody";
import { DefaultFooter } from "./DefaultFooter";
import { ImportDefaultHours } from "./ImportDefaultHours";
import { AddPointOfSale } from "./AddPointOfSale";
import { nanoid } from "nanoid";
import { bluBookingLoginData } from "../../../../../../store/slices/root";

export const DefaultHours: FC<{
  disabled: boolean;
  pointOfSaleId: string;
  setPointOfSaleId: (value: string) => void;
  pointOfSales: string[];
}> = memo(
  ({
    disabled,
    pointOfSaleId,
    setPointOfSaleId,
    pointOfSales,
  }): JSX.Element => {
    const { isSingleCalendarManagement } = useAppSelector(bluBookingLoginData);

    const { defaultHours } = useSelector(
      (state: RootState) => state.newUserDefaultHours
    );

    const GetValues = useMemo(() => {
      if (pointOfSaleId !== "" && pointOfSaleId in defaultHours) {
        return (
          <DefaultBody
            disabled={disabled}
            pointOfSaleId={pointOfSaleId}
            defaultHours={{ ...defaultHours[pointOfSaleId] }}
            isSingleCalendarManagement={isSingleCalendarManagement}
          />
        );
      }
      return (
        <TableBody>
          <TableRow
            style={{
              height: "30px",
              // height: "80px",
              boxSizing: "border-box",
            }}
          >
            <TableCell
              id={nanoid()}
              key={nanoid()}
              size="small"
              align="center"
              rowSpan={2}
              colSpan={isSingleCalendarManagement ? 7 : 8}
              sx={{
                borderTopWidth: "0px",
                borderLeft: "1px solid rgba(224, 224, 224, 1)",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                p: 0,
                pr: "1px",
              }}
            >
              <Typography>Nessun dato disponibile</Typography>
            </TableCell>
          </TableRow>
          <TableRow
            style={{
              height: "30px",
              // height: "80px",
              boxSizing: "border-box",
            }}
          ></TableRow>
        </TableBody>
      );
    }, [defaultHours, disabled, isSingleCalendarManagement, pointOfSaleId]);

    return (
      <>
        {pointOfSaleId !== "" && (
          <ImportDefaultHours
            isSingleCalendarManagement={isSingleCalendarManagement}
            pointOfSaleId={pointOfSaleId}
          />
        )}
        <AddPointOfSale setPointOfSaleId={setPointOfSaleId} />

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="start"
          flex="1"
          gap={1}
          sx={{ p: "0 5px" }}
        >
          <BluTitle text={"Orari di apertura"} />
          <Table
            size="small"
            sx={{
              width: "100%",
              // border: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <DefaultHead
              isSingleCalendarManagement={isSingleCalendarManagement}
            />
            {GetValues}
            <DefaultFooter
              disabled={disabled}
              pointOfSaleId={pointOfSaleId}
              isSingleCalendarManagement={isSingleCalendarManagement}
              setPointOfSaleId={setPointOfSaleId}
            />
          </Table>
          {/* <div style={{ height: "1000px" }} /> */}
        </Stack>
      </>
    );
  }
);
