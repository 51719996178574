import axios from "axios";

export const editTableItem = <T>(tablename: string, data: T) => {
  return axios<{ data: T[]; success: boolean }>({
    method: "put",
    url: `/focusapi/tabella/${tablename}`,
    data: {
      data: JSON.stringify([data]),
    },
  });
};
