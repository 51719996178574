import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import React from "react";
import { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getWhiteCache } from "../../../../../../lib/white-cache";
import { AppDispatch, RootState } from "../../../../../../store";
import { setPointOfSales } from "../../../../store/form";

export const CheckBoxes = memo(() => {
  const [isOnlineBooking, hasAutoConfirmation, hasStudio] = useWatch({
    name: ["isOnlineBooking", "hasAutoConfirmation", "hasStudio"],
  });
  const { pointOfSalesForm } = useSelector(
    (state: RootState) => state.serviceForm
  );
  const { setValue, trigger } = useFormContext();
  const { openForm } = useSelector((state: RootState) => state.serviceRoot);
  const { isBluBookingActive } = getWhiteCache();
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Stack
      display="flex"
      flexWrap="wrap"
      flexDirection="unset"
      flex="1"
      columnGap="5px"
      sx={{ pt: "20px", pb: "5px" }}
      // sx={{ m: "0px 20px" }}
    >
      {isBluBookingActive && (
        <FormControlLabel
          label="Prenotazione online"
          control={
            <Checkbox
              checked={isOnlineBooking}
              onChange={() => {
                setValue("isOnlineBooking", !isOnlineBooking);
                trigger();
              }}
              disabled={openForm === "info"}

              // sx={{ p: 0 }}
            />
          }
        />
      )}
      {isBluBookingActive && (
        <FormControlLabel
          label="Auto conferma"
          control={
            <Checkbox
              checked={hasAutoConfirmation}
              onChange={() => {
                setValue("hasAutoConfirmation", !hasAutoConfirmation);
                trigger();
              }}
              disabled={openForm === "info"}

              // sx={{ p: 0 }}
            />
          }
        />
      )}
      <FormControlLabel
        label="Studio necessario"
        control={
          <Checkbox
            checked={hasStudio}
            onChange={() => {
              setValue("hasStudio", !hasStudio);
              trigger();
              if (!hasStudio) {
                const temp = JSON.parse(
                  JSON.stringify({ ...pointOfSalesForm })
                );
                for (const key in temp) {
                  if (
                    temp[key].enable &&
                    temp[key].studi &&
                    Object.keys(temp[key].studi).length === 0
                  ) {
                    temp[key].enable = false;
                  }
                }
                dispatch(setPointOfSales(temp));
              }
            }}
            disabled={openForm === "info"}

            // sx={{ p: 0 }}
          />
        }
      />
    </Stack>
  );
});
