import axios from 'axios'

export const updateThread = async (
  threadId: string,
  description: string
): Promise<boolean> => {
  try {
    await axios.put(`/Thread/${threadId}`, {
      description: description
    })
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}
