export const errorsMail: { [x: string]: string } = {
  "101": "email non valida",
  "102": "contenuto email rilevato come spam",
  "103": "email annulata dal destinatario",
  "104": "email non valida",
  "105": "email non valida",
  "106": "email non valida",
  "107": "l'IP mittente è bloccato",
  "108": "sono presenti alcuni errori di validazione",
  "109": "email non valida",
  "201": "email non valida",
  "202":
    "la mail del destinatario potrebbe essere temporaneamente non disponibile",
  "203": "la mail del destinatario ha raggiunto la capacità massima",
  "204":
    "la mail del destinatario potrebbe avere un accesso ristretto oppure non esistere",
  "205": "la mail del destinatario ha raggiunto la capacità massima",
  "206": "email non inviata, controlla il destinatario",
  "301": "email non inviata, controlla il destinatario",
  "302": "errore temporaneo",
  "303": "mittente non registrato",
  "304": "errore nell'invio",
  "305": "template non trovato",
  "306": "l'email continene dei parametri di tracking invalidi",
  "307": "placeholder troppo grande",
  "308": "intestazioni personalizzate non valide",
  "309": "risposta non valida",
  "310": "mittente non valido",
  "311": "email contiene una pagina non valida",
  "312": "email non valida",
  "313": "email scaduta",
};
