import "../../../../styles/service-popup.css";

import { memo } from "react";
import { useSelector } from "react-redux";
import { BluLoader } from "@bludata/components";
import { RootState } from "../../../../store";
import { useServiceDetails } from "../../api/service-details";
import { ServiceForm } from "./popup";
import { useServiceUser } from "../../api/service-user";

export const ServicePopup = memo((): JSX.Element => {
  const { openForm, currentService } = useSelector(
    (state: RootState) => state.serviceRoot
  );

  return (
    <>
      {openForm !== undefined && (
        <LoadService openForm={openForm} currentService={currentService} />
      )}
    </>
  );
});

interface LoadServiceInterface {
  openForm?: string;
  currentService: string;
}

const LoadService = memo(
  ({ openForm, currentService }: LoadServiceInterface) => {
    const { data, isLoading } = useServiceDetails(currentService);
    const { data: serviceUsers, isLoading: serviceUserLoading } =
      useServiceUser(currentService);

    return (
      <>
        {!isLoading && !serviceUserLoading ? (
          <ServiceForm
            openForm={openForm}
            currentService={currentService}
            data={data}
            serviceUsers={serviceUsers}
          />
        ) : (
          <BluLoader open />
        )}
      </>
    );
  }
);
