import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { getPointOfSaleWhiteCache } from "../lib/white-cache";
import { PointOfSalesInterface } from "../interfaces/PointOfSale_interface";

interface PointOfSalesObject {
  [x: string]: PointOfSalesInterface;
}

const fetchPointOfSales = async (): Promise<
  PointOfSalesInterface[] | string
> => {
  const { isFocusWeb } = getPointOfSaleWhiteCache();
  const url = isFocusWeb ? "/focusapi/global/PointOfSale" : "/api/PointOfSale";
  try {
    const { data } = await axios.get(url);

    if (isFocusWeb)
      return data.map((item: any) => {
        return { ...item, id: item.codiceFiliale };
      });

    return data.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const usePointOfSales = (key: string = "") => {
  return useQuery({
    queryFn: async () => await fetchPointOfSales(),
    queryKey: ["point-of-sales", "point-of-sales", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: any): PointOfSalesObject => {
  const { isFocusWeb } = getPointOfSaleWhiteCache();

  const result: any = {};
  for (const pos of data) {
    result[pos.id] = {
      ...pos,
      pointOfSaleId: isFocusWeb ? pos.blubookingId : pos.id,
      name: pos.businessName || pos.name,
      branch: pos.branchName || pos.branch,
    };
  }
  return result;
};
