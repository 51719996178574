import { FC, memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { Box, Button, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { createNew, setDeleteLayout, setMode } from "../../store/root";
import { BluAsyncDialog } from "@bludata/components";
import { useFormState } from "react-hook-form";
import SaveIcon from "@mui/icons-material/Save";
import { resetComponents } from "../../store/components";
import { resetCustomTheme } from "../../store/custom-theme";
import { resetFont } from "../../store/font";
import { resetPalette } from "../../store/palette";
import AddIcon from "@mui/icons-material/Add";

interface ButtonsInterface {
  shopSignId?: string;
  save: () => void;
  reset: () => void;
}

export const Buttons: FC<ButtonsInterface> = memo(
  ({ shopSignId = "", save, reset }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { mode, shopSigns, emailValid, pecValid } = useSelector(
      (state: RootState) => state.layoutRoot
    );

    const { errors, isValid } = useFormState();

    const handleNew = useCallback(() => {
      dispatch(createNew());
      dispatch(resetCustomTheme());
      dispatch(resetPalette());
      dispatch(resetFont());
      dispatch(resetComponents());
    }, [dispatch]);

    const checkIsValid = useCallback(() => {
      if (isValid && !pecValid && !emailValid) {
        save();
        return;
      }
      const err = Object.values(errors).map(
        ({ message }: any) => `<li>${message}</li>`
      );

      if (!("email" in errors)) {
        if (emailValid) err.push("<li>Mail non valida</li>");
      }

      if (!("pec" in errors)) {
        if (pecValid) err.push("<li>Pec non valida</li>");
      }

      if (err.length > 0) {
        BluAsyncDialog({
          title: "Attenzione",
          message: err.join(""),
          confimButton: "Chiudi",
          hideDeclineButton: true,
          type: "warning",
          // maxWidth: false,
          sx: { "& .MuiDialog-paper": { maxWidth: "400px" } },
        });
        return;
      }
      save();
      return;
    }, [emailValid, errors, isValid, pecValid, save]);

    const GetButtons = useMemo(() => {
      if (mode === "view")
        return (
          <>
            <Box flex={1} />
            <Button
              color="primary"
              startIcon={<AddIcon />}
              variant="contained"
              onClick={handleNew}
            >
              Nuovo
            </Button>
            <Button
              variant="outlined"
              startIcon={<ModeEditIcon />}
              onClick={() => {
                dispatch(setMode("edit"));
                try {
                  (window as any).disableMenuBluBooking(true);
                } catch {}
              }}
              sx={{ width: "fit-content" }}
            >
              Modifica
            </Button>

            <Button
              variant="contained"
              startIcon={<DeleteIcon />}
              color="error"
              onClick={() => {
                dispatch(setDeleteLayout(shopSignId));
              }}
              sx={{ width: "fit-content" }}
            >
              Elimina
            </Button>
          </>
        );
      return (
        <>
          <Box flex={1}></Box>
          <Button
            variant="outlined"
            onClick={reset}
            sx={{ width: "fit-content" }}
            disabled={!(shopSigns !== undefined && shopSigns.length > 0)}
          >
            Annulla
          </Button>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={checkIsValid}
          >
            Salva
          </Button>
        </>
      );
    }, [checkIsValid, dispatch, handleNew, mode, reset, shopSignId, shopSigns]);

    return (
      <Stack direction="row" alignItems="flex-start" p={0.5} spacing={0.5}>
        {GetButtons}
      </Stack>
    );
  }
);
