import { Stack } from "@mui/system";
import { memo } from "react";
import { BluContainerBlock, BluTitle } from "@bludata/components";

interface CalendarHeaderProps {
  title: string;
  children?: JSX.Element;
}

export const Header = memo(function CalendarHeader({
  title,
}: CalendarHeaderProps): JSX.Element {
  return (
    <>
      <BluContainerBlock
        sx={{
          height: "46px",
          display: "flex",
          alignItems: "center",
          m: "4px 4px 0px 4px",
        }}
      >
        <Stack flexDirection="row" alignItems="center" sx={{ width: "100%" }}>
          <BluTitle sx={{ flex: 1 }} text={title} />
        </Stack>
      </BluContainerBlock>
    </>
  );
});
