import { memo, useEffect } from "react";
import { store } from "../../../store";
import { usePointOfSale, useServiceUI, useStudi } from "../../../api";
import { setStudiData, setStudiLoad } from "../store/root";

export const InitServiceData = memo(function InitFilters(): null {
  const { isLoading: posLoading, data: pointOfSales } = usePointOfSale();
  const { isLoading: studioLoading, data: studi } = useStudi();
  const { isLoading: servicesLoading, data: services } = useServiceUI();

  useEffect(() => {
    if (!posLoading && !studioLoading && !servicesLoading) {
      try {
        store.dispatch(
          setStudiData({
            pointOfSales,
            studi,
            services,
            studiLoad: false,
          })
        );
      } catch (e) {
        console.error(e);
        store.dispatch(setStudiLoad(false));
      }
    }
  }, [pointOfSales, posLoading, services, servicesLoading, studi, studioLoading]);

  return null;
});
