import '../../../../styles/loader.css'

import {
  FC,
  Suspense,
  memo,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { CustomMessage } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { useThreadRun } from '../../api/get-thread-run'
import { AppDispatch, RootState } from '../../../../store'
import { setStreamMessage, setinitStream } from '../../store/root'
import React from 'react'
import { nanoid } from '@reduxjs/toolkit'
import Markdown from 'react-markdown'
import { ChatInterface } from '../../interfaces/message'
import { getThreadRunInit, setThreadRunInit } from '../../utils/white-cache'

interface MessageIterface {
  threadId: string
  isMessageLoading: boolean
  messages?: ChatInterface | null
}

export const Messages: FC<MessageIterface> = memo(
  ({ threadId, isMessageLoading, messages }) => {
    const { streamMessage, initStream } = useSelector(
      (state: RootState) => state.chatAiRoot
    )
    const messagesEndRef = useRef<any>(null)

    const queryClient = useQueryClient()
    const dispatch = useDispatch<AppDispatch>()

    const { data } = useThreadRun(initStream, threadId)
    const thread = useDeferredValue(data)

    useEffect(() => {
      if (messagesEndRef !== null) {
        messagesEndRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
      }
    }, [messages, messagesEndRef])

    useEffect(() => {
      const invalidateAfterUpdate = async () => {
        await queryClient.invalidateQueries(['message'], {
          type: 'active'
        })
        dispatch(setStreamMessage(undefined))
        dispatch(setinitStream(false))
        queryClient.invalidateQueries(['thread-run'], {
          type: 'active'
        })
        queryClient.removeQueries(['message'], {
          type: 'inactive'
        })

        queryClient.removeQueries(['thread-run'], {
          type: 'inactive'
        })
      }

      if (thread) {
        if (!getThreadRunInit()) {
          if (thread[0].status === 'completed') {
            invalidateAfterUpdate()
          }
          if (thread[0].status === 'in_progress') {
          }
        } else setThreadRunInit(false)
      }
    }, [dispatch, queryClient, streamMessage, thread, threadId])

    const GetStreamMessage = useMemo(() => {
      return (
        <Suspense>
          {streamMessage !== undefined && (
            <>
              <CustomMessage isUser={false}>
                <span className="loader" />
              </CustomMessage>
              <CustomMessage isUser={true}>
                <Typography>
                  <Markdown>{streamMessage}</Markdown>
                </Typography>
              </CustomMessage>
            </>
          )}
        </Suspense>
      )
    }, [streamMessage])

    const GetContent = useMemo(() => {
      if (isMessageLoading)
        return (
          <Stack
            flex="1"
            justifyContent="center"
            justifyItems="center"
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%'
            }}
          >
            <CircularProgress
              color="primary"
              thickness={1.5}
              size={'120px'}
              sx={{ margin: 'auto' }}
            />
          </Stack>
        )

      return (
        <Stack
          gap="5px"
          direction="column-reverse"
          sx={{ overflowY: 'auto', p: '10px 10px 0px' }}
        >
          <div ref={messagesEndRef} style={{ height: '20px' }} />
          {GetStreamMessage}
          {messages &&
            messages?.data &&
            messages?.data.map((message: any) => (
              <CustomMessage key={nanoid()} isUser={message.role === 'user'}>
                <Typography>
                  <Markdown>{message.content[0].text.value}</Markdown>
                </Typography>
              </CustomMessage>
            ))}
        </Stack>
      )
    }, [GetStreamMessage, isMessageLoading, messages])

    return (
      <Stack flex="1" sx={{ position: 'relative' }}>
        {GetContent}
      </Stack>
    )
  }
)
