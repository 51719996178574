import axios from 'axios'

export const deleteThread = async (threadId: string): Promise<boolean> => {
  try {
    await axios.delete(`/Thread/${threadId}`)
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}
