import axios from "axios";
import { getRotaPath } from "../lib/white-cache";

export const deleteExtraCalendarUser = async (id: string): Promise<boolean> => {
  try {
    await axios.delete(`${getRotaPath()}/ExtraCalendarUser/${id}`);
    return true;
  } catch (error) {
    return false;
  }
};
