import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const getCodiceAttivazione = async (
  codicePersonale: string
): Promise<{
  codice: string;
  link: string;
}> => {
  try {
    const { data } = await axios.post("/imoapi/utenti/generacodiceapp", {
      CodiceUtente: codicePersonale,
    });
    return JSON.parse(data);
  } catch (error) {
    throw new Error("Codice non valido");
  }
};

export const useActivationCodeIMO = (
  codicePersonale: string,
  key: string = ""
) => {
  return useQuery({
    queryFn: async () => await getCodiceAttivazione(codicePersonale),
    queryKey: ["codice-attivazione", codicePersonale, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
