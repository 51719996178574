import { FC, memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, useAppSelector } from "../../store";
import { Stack, Tab, Tabs, Typography } from "@mui/material";
import { createNew, setShopSignId } from "./store/root";
import { ShopSign } from "./shop-sign";
import { LayoutDeletePopup } from "./components/DeletePopup";
import { Header } from "../../UI/Header";
import { bluBookingLoginData } from "../../store/slices/root";

interface LayoutNewInterface {
  root?: string;
  hasAgenda: boolean;
  isConsole: boolean;
  isBBActive: boolean;
}

export const LayoutPage: FC<LayoutNewInterface> = memo(
  ({ root = "", hasAgenda, isConsole, isBBActive }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { shopSigns } = useSelector((state: RootState) => state.layoutRoot);
    const { shopSignId, mode } = useSelector(
      (state: RootState) => state.layoutRoot
    );
    const { software } = useAppSelector(bluBookingLoginData);

    const handleChange = useCallback(
      (_event: React.SyntheticEvent, newValue: string) => {
        dispatch(setShopSignId(newValue));
      },
      [dispatch]
    );

    useEffect(() => {
      if (shopSigns !== undefined) {
        if (shopSigns.length) {
          dispatch(setShopSignId(shopSigns[0].id));
          return;
        }
        dispatch(createNew());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, shopSigns]);

    const isDisabled = useCallback(
      (signId: string) => {
        if (signId === shopSignId) return true;
        if (mode === "view") return false;
        return true;
      },
      [mode, shopSignId]
    );

    const getTabValue = useCallback(() => {
      return shopSignId ?? "new";
    }, [shopSignId]);

    return (
      <>
        <Header
          title={`${root ?? ""}${
            software === "fw" ? "Layout Blu Booking" : "Layout"
          }`}
        />
        <Stack
          direction="column"
          flex="1"
          sx={{
            border: "1px solid var(--divider-color)",
            backgroundColor: "var(--base-foreground-color)",
            padding: "5px 10px",
            borderRadius: "5px",
            overflow: "auto",
            m: "4px!important",
          }}
        >
          <Typography
            sx={{
              padding: "5px 0px 5px 0px",
              color: "var(--color)",
            }}
            variant="subtitle1"
          >
            Impostazioni per la gestione del layout del sito di prenotazione
            online.
          </Typography>
          {shopSigns !== undefined && (
            <>
              <Tabs
                value={getTabValue()}
                onChange={handleChange}
                sx={{
                  "&.MuiTabs-root": { overflow: "unset!important", pb: "5px" },
                }}
              >
                {shopSigns.map((sign) => {
                  return (
                    <Tab
                      key={sign.id}
                      label={sign.name}
                      value={sign.id}
                      disabled={isDisabled(sign?.id ?? "")}
                    />
                  );
                })}
                <Tab
                  key={"new"}
                  label={"Nuovo Layout"}
                  value={"new"}
                  sx={{ display: shopSignId === undefined ? "inline" : "none" }}
                />
              </Tabs>
              <LayoutDeletePopup />
              <ShopSign
                shopSignId={shopSignId}
                hasAgenda={hasAgenda}
                software={software}
                isConsole={isConsole}
                isBBActive={isBBActive}
              />
            </>
          )}
        </Stack>
      </>
    );
  }
);
