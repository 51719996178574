interface WhiteCacheInterface {
  isBluBookingActive: boolean;
  pointOfSalesObject: {
    [x: string]: {
      id: string;
      enable: boolean;
      name: string;
      address: string;
      city: string;
      studi: {
        [x: string]: {
          id: string;
          name: string;
          enabled: boolean;
        };
      };
    };
  };
  usersObject: {
    [x: string]: {
      id: string;
      username: boolean;
      name: string;
      surname: string;
      email: string;
    };
  };
}

const WhiteCache: WhiteCacheInterface = {
  isBluBookingActive: false,
  pointOfSalesObject: {},
  usersObject: {},
};

export const getWhiteCache = () => WhiteCache;
export const setWhiteCache = (key: keyof WhiteCacheInterface, value: any) => {
  WhiteCache[key] = value;
};
