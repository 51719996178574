/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface serviceProps {
  pointOfSaleId?: any;
}

export const serviceFilter = async ({
  pointOfSaleId,
}: serviceProps): Promise<string[]> => {
  try {
    if (pointOfSaleId === "" || pointOfSaleId === undefined) return [];
    const {
      data: { data },
    } = await axios.get(`/api/Service/Filter`, { params: { pointOfSaleId } });

    return data.map((ser: any) => ser.id).flat();
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const useServiceFilter = (
  { pointOfSaleId }: serviceProps,
  key: string = ""
) => {
  return useQuery({
    queryFn: async () => await serviceFilter({ pointOfSaleId }),
    queryKey: ["filter", "service", pointOfSaleId, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
