import { DataGridPremium } from "@mui/x-data-grid-premium";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isSafari } from "react-device-detect";
import { AppDispatch, RootState, useAppSelector } from "../../../../store";
import { getPointOfSaleWhiteCache } from "../../lib/white-cache";
import columnsbb from "./Columns/columnsbb";
import columnsfw from "./Columns/columnsfw";
import columnscrm from "./Columns/columnscrm";
import {
  setCurrentPointOfSale,
  setPointOfSaleOpenForm,
} from "../../store/root";
import { bluBookingLoginData } from "../../../../store/slices/root";

interface PointOfSalesGridInterface {
  gridSearch: string;

  isActive: boolean;

  remoto: boolean;
}

export const PointOfSalesGrid: FC<PointOfSalesGridInterface> = ({
  gridSearch,

  isActive,

  remoto,
}): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();

  const { pointOfSales } = useSelector(
    (state: RootState) => state.newPointOfSaleRoot
  );

  const { software } = useAppSelector(bluBookingLoginData);

  const { isBluBookingActive } = getPointOfSaleWhiteCache();

  const getValues = useCallback(() => {
    if (!pointOfSales) return [];

    return Object.values(pointOfSales)
      .filter((item: any) => {
        var search = new RegExp(gridSearch, "i");
        return search.test(item.branch);
      })
      .filter((item: any) => {
        if (isActive) {
          return item.isActive === true;
        } else {
          return item;
        }
      })
      .filter((item: any) => {
        if (remoto) {
          return item.remote === true;
        } else {
          return item;
        }
      });
  }, [pointOfSales, gridSearch, isActive, remoto]);

  const getColumns = useCallback(() => {
    switch (software) {
      case "bb":
        return columnsbb(isBluBookingActive);
      case "fw":
        return columnsfw(isBluBookingActive, software);
      case "crm":
        return columnscrm(isBluBookingActive);
      default:
        return columnsbb(isBluBookingActive);
    }
  }, [isBluBookingActive, software]);

  return (
    <DataGridPremium
      key={"grigliaPuntiVendita"}
      sx={{
        "& .MuiDataGrid-cell": {
          outline: "none!important",
        },
        zIndex: isSafari ? 9999 : undefined,
      }}
      rows={getValues()}
      columns={getColumns()}
      editMode="cell"
      autoHeight={false}
      getEstimatedRowHeight={() => 300}
      getRowHeight={() => "auto"}
      rowBuffer={50}
      rowThreshold={15}
      density={"compact"}
      experimentalFeatures={{ newEditingApi: true }}
      onRowClick={(event: any) => {
        dispatch(setCurrentPointOfSale(event.id));
        dispatch(setPointOfSaleOpenForm(undefined));
      }}
      onRowDoubleClick={() => dispatch(setPointOfSaleOpenForm("info"))}
      components={{
        Footer: () => <></>,
      }}
    />
  );
};
