import axios from 'axios'

export const postMessage = async (
  threadId: string,
  message: string,
  isFirst?: boolean
): Promise<boolean> => {
  try {
    await axios.post(`/Message/${threadId}`, {
      content: message,
      isFirstMessage: isFirst
    })
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}
