import moment, { Moment } from "moment";
import { CalendarPointOfSaleInterface } from "../interfaces/calendar-point-of-sale";
import { ExtraCalendarPointOfSaleInterface } from "../interfaces/extra-calendar-point-of-sale";
import { useQuery } from "@tanstack/react-query";
import { getShiftPointOfSale } from "./shift-point-of-sale";
import { getCalendarPointOfSale } from "./calendar-point-of-sale";
import { dayHours } from "../utils/day-hours";
import { checkTurnoAlreadySet } from "../utils/check-turno-already-set";

export const getTurniPointOfSaleDay = async (
  pointOfSaleId: string,
  range: Date,
  isSingleCalendarManagement: boolean
): Promise<
  [
    [CalendarPointOfSaleInterface[], ExtraCalendarPointOfSaleInterface[]],
    [CalendarPointOfSaleInterface[], ExtraCalendarPointOfSaleInterface[]]
  ]
> => {
  const day = moment(range).format("YYYY-MM-DD");

  if (!isSingleCalendarManagement) {
    try {
      const responses = await Promise.all([
        getCalendarPointOfSale(pointOfSaleId, day, day),
        getShiftPointOfSale(pointOfSaleId, day, day),
      ]);

      return responses as any;
    } catch (error) {
      return [
        [[], []],
        [[], []],
      ];
    }
  }

  try {
    const responses = await getCalendarPointOfSale(pointOfSaleId, day, day);

    return [responses, [[], []]] as any;
  } catch (error) {
    return [
      [[], []],
      [[], []],
    ];
  }
};

export const useTurniPointOfSaleDay = (
  fetchPointOfSale: boolean,
  pointOfSaleId: string,
  range: Date,
  isSingleCalendarManagement: boolean,
  key: string = ""
) => {
  return useQuery({
    queryFn: async () =>
      await getTurniPointOfSaleDay(
        pointOfSaleId,
        range,
        isSingleCalendarManagement
      ),
    queryKey: ["turni-point-of-sale-day", pointOfSaleId, range, key],
    retry: 2,
    enabled: fetchPointOfSale,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: (data: any) => normalize(data, range),
  });
};

const normalize = (data: any, range: Date): any => {
  const result: any[] = [];
  for (const key of data) {
    const [calendarDays, extraDays] = key;

    const getOpen = (hours: any, hour: Moment) => {
      let open = false;
      if (hours?.startTime1 && hours?.endTime1) {
        open = hour.isBetween(
          hour.clone().set({ hour: hours.startTime1.substring(0, 2) }),
          hour.clone().set({ hour: hours.endTime1.substring(0, 2) }),
          "minutes",
          "[)"
        );
      }
      if (!open && hours?.startTime2 && hours?.endTime2) {
        open = hour.isBetween(
          hour.clone().set({ hour: hours.startTime2.substring(0, 2) }),
          hour.clone().set({ hour: hours.endTime2.substring(0, 2) }),
          "minutes",
          "[)"
        );
      }
      return open;
    };

    const temp: any = {};
    const date: Moment = moment(range);
    const daysOfWeekConversion = [
      "hasSunday",
      "hasMonday",
      "hasTuesday",
      "hasWednesday",
      "hasThursday",
      "hasFriday",
      "hasSaturday",
    ];

    try {
      let isExtra = false;
      for (const extra of extraDays) {
        const extraDate = moment(extra.startValidDate).format("YYYY-MM-DD");
        const formattedRange = date.format("YYYY-MM-DD");
        if (extraDate === formattedRange) {
          isExtra = true;

          temp.current = {
            extraId: extra.id,
            id: extra.id,
            isClosed: extra.isClosingDay,
            isExtra: true,
            isHoliday: false,
            startTime1: !extra.isClosingDay
              ? (extra?.startTime1 || "").slice(0, -3)
              : null,
            startTime2: !extra.isClosingDay
              ? (extra?.startTime2 || "").slice(0, -3)
              : null,
            endTime1: !extra.isClosingDay
              ? (extra?.endTime1 || "").slice(0, -3)
              : null,
            endTime2: !extra.isClosingDay
              ? (extra?.endTime2 || "").slice(0, -3)
              : null,
          };
        }
      }

      for (const calendar of calendarDays) {
        const dayOfWeek = date.day();
        const dayOfWeekKey = daysOfWeekConversion[dayOfWeek];

        if (calendar[dayOfWeekKey]) {
          const isClosed = !(
            (calendar.startTime1 !== null && calendar.endTime1 !== null) ||
            (calendar.startTime2 !== null && calendar.endTime2 !== null)
          );
          if (!isExtra) {
            temp.current = {
              id: calendar.id,
              isClosed: isClosed,
              startTime1: !isClosed
                ? (calendar?.startTime1 || "").slice(0, -3)
                : null,
              startTime2: !isClosed
                ? (calendar?.startTime2 || "").slice(0, -3)
                : null,
              endTime1: !isClosed
                ? (calendar?.endTime1 || "").slice(0, -3)
                : null,
              endTime2: !isClosed
                ? (calendar?.endTime2 || "").slice(0, -3)
                : null,
            };
          }

          temp.default = {
            startTime1: !isClosed
              ? (calendar?.startTime1 || "").slice(0, -3)
              : null,
            startTime2: !isClosed
              ? (calendar?.startTime2 || "").slice(0, -3)
              : null,
            endTime1: !isClosed
              ? (calendar?.endTime1 || "").slice(0, -3)
              : null,
            endTime2: !isClosed
              ? (calendar?.endTime2 || "").slice(0, -3)
              : null,
          };
        }
      }
      result.push(temp);
    } catch (err) {
      console.error("ERROR:", err);
    }
  }

  return result;
};
