import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface EnableAllInterface {
  serviceForm: boolean;
}

export interface FormSliceState {
  serviceForm: string[];
  enableAll: EnableAllInterface;
  studioFormLoader: boolean;
}

const initialState: FormSliceState = {
  serviceForm: [],
  enableAll: {
    serviceForm: false,
  },
  studioFormLoader: false,
};

const StudioFormSlice = createSlice({
  name: "StudioForm",
  initialState,
  reducers: {
    resetFormValues() {
      return {
        serviceForm: [],
        enableAll: {
          serviceForm: false,
        },
        studioFormLoader: false,
      };
    },
    setService(state: FormSliceState, action: PayloadAction<string[]>) {
      state.serviceForm = action.payload;
    },

    setMultipleServiceValue(
      state: FormSliceState,
      action: PayloadAction<string[]>
    ) {
      state.serviceForm = action.payload;
    },
    setEnableAll(
      state: FormSliceState,
      action: PayloadAction<keyof EnableAllInterface>
    ) {
      state.enableAll[action.payload] = !state.enableAll[action.payload];
      const temp = state[action.payload];
      Object.keys(temp).forEach(
        (key: any) =>
          ((temp[key] as any).enable = state.enableAll[action.payload])
      );
      state = {
        ...state,
        [action.payload]: temp,
      };
    },
    setStudioFormLoader(state: FormSliceState, action: PayloadAction<boolean>) {
      state.studioFormLoader = action.payload;
    },
  },
});

export const {
  resetFormValues,
  setService,
  setMultipleServiceValue,
  setEnableAll,
  setStudioFormLoader,
} = StudioFormSlice.actions;

export default StudioFormSlice.reducer;
