import moment from "moment";
import { createExtraShiftUser } from "../../../api/create-extra-shift-user";
import { updateExtraShiftUser } from "../../../api/update-extra-shift-user";
import { deleteExtraShiftUser } from "../../../api/delete-extra-shift-user";
import { createExtraCalendarUser } from "../../../api/create-extra-calendar-user";
import { updateExtraCalendarUser } from "../../../api/update-extra-calendar-point-of-sale";
import { deleteExtraCalendarUser } from "../../../api/delete-extra-calendar-point-of-sale";
import { createExtraSharedUser } from "../../../api/create-extra-shared-user";
import { updateExtraSharedUser } from "../../../api/update-extra-shared-user";
import { deleteExtraSharedUser } from "../../../api/delete-extra-shared-user";

const isDifferentExtra = ({
  initialData,
  data,
}: {
  initialData: {
    startTime1: Date | null;
    startTime2: Date | null;
    endTime1: Date | null;
    endTime2: Date | null;
  };

  data: {
    startTime1: Date | null;
    startTime2: Date | null;
    endTime1: Date | null;
    endTime2: Date | null;
  };
}) => {
  return (
    ["startTime1", "startTime2", "endTime1", "endTime2"].find((key: string) => {
      if (
        initialData &&
        data &&
        key in initialData &&
        (initialData as any)[key] &&
        key in data &&
        (data as any)[key] &&
        (data as any)[key].getTime() === (initialData as any)[key].getTime()
      )
        return false;
      return true;
    }) !== undefined
  );
};

const manageChangeExtra = async ({
  id,
  userId,
  initialData,
  startTime1,
  startTime2,
  endTime1,
  endTime2,
  date,
  pointOfSaleId,
  apiType,
  type,
  initialType,
  extraCalendarType,
  initialExtraCalendarType,
}: {
  id?: string;
  pointOfSaleId: string;
  userId: string;

  initialData: {
    startTime1: Date | null;
    startTime2: Date | null;
    endTime1: Date | null;
    endTime2: Date | null;
  };
  date: Date;

  startTime1: Date | null;
  startTime2: Date | null;
  endTime1: Date | null;
  endTime2: Date | null;
  apiType: "shift" | "calendar" | "shared";
  type: "closed" | "extra-closed" | "default" | "extra";
  initialType: "closed" | "extra-closed" | "default" | "extra";
  extraCalendarType: number;
  initialExtraCalendarType: number;
}): Promise<boolean> => {
  let result = true;

  let createApi, updateApi, deleteApi;
  switch (apiType) {
    case "shift":
      createApi = createExtraShiftUser;
      updateApi = updateExtraShiftUser;
      deleteApi = deleteExtraShiftUser;
      break;

    case "calendar":
      createApi = createExtraCalendarUser;
      updateApi = updateExtraCalendarUser;
      deleteApi = deleteExtraCalendarUser;
      break;

    default:
      createApi = createExtraSharedUser;
      updateApi = updateExtraSharedUser;
      deleteApi = deleteExtraSharedUser;
      break;
  }

  // ! creazione extra row
  if (
    (initialType === "default" || initialType === "closed") &&
    (type === "extra" || type === "extra-closed")
  )
    result = await createApi(
      pointOfSaleId,
      userId,
      startTime1 ? moment(startTime1).format("HH:mm") : null,
      endTime1 ? moment(endTime1).format("HH:mm") : null,
      startTime2 ? moment(startTime2).format("HH:mm") : null,
      endTime2 ? moment(endTime2).format("HH:mm") : null,
      moment(date).format("YYYY-MM-DD"),
      type === "extra-closed" ? true : false,
      extraCalendarType
    );

  if (
    (initialType === "extra" || initialType === "extra-closed") &&
    (type === "extra" || type === "extra-closed")
  ) {
    // ! update extra row
    // ! Aggiungere check
    if (
      isDifferentExtra({
        initialData,
        data: { startTime1, startTime2, endTime1, endTime2 },
      }) ||
      initialExtraCalendarType !== extraCalendarType
    )
      result = await updateApi(
        pointOfSaleId,
        userId,
        startTime1 ? moment(startTime1).format("HH:mm") : null,
        endTime1 ? moment(endTime1).format("HH:mm") : null,
        startTime2 ? moment(startTime2).format("HH:mm") : null,
        endTime2 ? moment(endTime2).format("HH:mm") : null,
        moment(date).format("YYYY-MM-DD"),
        type === "extra-closed" ? true : false,
        id!!,
        extraCalendarType
      );
  }

  // ! delete extra row
  if (
    (initialType === "extra" || initialType === "extra-closed") &&
    (type === "default" || type === "closed")
  ) {
    result = await deleteApi(id!!);
  }
  return result;
};

export const manageTurniUser = async ({
  pointOfSaleId,
  userId,
  isSingleCalendarManagement,
  initialDataCalendar,
  initialDataShift,
  date,

  extraShiftId,
  startTime1Shift,
  startTime2Shift,
  endTime1Shift,
  endTime2Shift,
  typeShift,
  initialTypeShift,
  shiftExtraCalendarType,
  initialShiftExtraCalendarType,

  extraCalendarId,
  startTime1Calendar,
  startTime2Calendar,
  endTime1Calendar,
  endTime2Calendar,
  typeCalendar,
  initialTypeCalendar,
  calendarExtraCalendarType,
  initialCalendarExtraCalendarType,
}: {
  pointOfSaleId: string;
  userId: string;
  isSingleCalendarManagement: boolean;
  initialDataShift: {
    startTime1: Date | null;
    startTime2: Date | null;
    endTime1: Date | null;
    endTime2: Date | null;
  };
  initialDataCalendar: {
    startTime1: Date | null;
    startTime2: Date | null;
    endTime1: Date | null;
    endTime2: Date | null;
  };
  date: Date;

  extraShiftId?: string;
  startTime1Shift: Date | null;
  startTime2Shift: Date | null;
  endTime1Shift: Date | null;
  endTime2Shift: Date | null;
  typeShift: "closed" | "extra-closed" | "default" | "extra";
  initialTypeShift: "closed" | "extra-closed" | "default" | "extra";
  shiftExtraCalendarType: number;
  initialShiftExtraCalendarType: number;

  extraCalendarId?: string;
  startTime1Calendar: Date | null;
  startTime2Calendar: Date | null;
  endTime1Calendar: Date | null;
  endTime2Calendar: Date | null;
  typeCalendar: "closed" | "extra-closed" | "default" | "extra";
  initialTypeCalendar: "closed" | "extra-closed" | "default" | "extra";
  calendarExtraCalendarType: number;
  initialCalendarExtraCalendarType: number;
}) => {
  if (isSingleCalendarManagement) {
    const result = await manageChangeExtra({
      id: extraCalendarId,
      userId,

      pointOfSaleId,
      date,
      startTime1: startTime1Calendar,
      startTime2: startTime2Calendar,
      endTime1: endTime1Calendar,
      endTime2: endTime2Calendar,
      initialData: initialDataCalendar,
      apiType: "shared",
      type: typeCalendar,
      initialType: initialTypeCalendar,
      extraCalendarType: calendarExtraCalendarType,
      initialExtraCalendarType: initialCalendarExtraCalendarType,
    });
    return result;
  }

  let resultCalendar = true;
  let resultShift = true;

  resultCalendar = await manageChangeExtra({
    id: extraCalendarId,
    userId,
    pointOfSaleId,
    date,
    startTime1: startTime1Calendar,
    startTime2: startTime2Calendar,
    endTime1: endTime1Calendar,
    endTime2: endTime2Calendar,
    initialData: initialDataCalendar,
    apiType: "calendar",
    type: typeCalendar,
    initialType: initialTypeCalendar,
    extraCalendarType: calendarExtraCalendarType,
    initialExtraCalendarType: initialCalendarExtraCalendarType,
  });

  resultShift = await manageChangeExtra({
    id: extraShiftId,
    userId,
    pointOfSaleId,
    date,
    startTime1: startTime1Shift,
    startTime2: startTime2Shift,
    endTime1: endTime1Shift,
    endTime2: endTime2Shift,
    initialData: initialDataShift,
    apiType: "shift",
    type: typeShift,
    initialType: initialTypeShift,
    extraCalendarType: shiftExtraCalendarType,
    initialExtraCalendarType: initialShiftExtraCalendarType,
  });

  return resultCalendar && resultShift;
};
