import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getRotaPath } from "../lib/white-cache";
import PointOfSale_interface from "../../nuova-point-of-sales/interfaces/PointOfSale_interface";

const getPointOfSalesCalendar = async (): Promise<PointOfSale_interface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get(`${getRotaPath()}/PointOfSale/UICalendar`);

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const usePointOfSaleCalendar = (key: string = "") => {
  return useQuery({
    queryFn: async () => await getPointOfSalesCalendar(),
    queryKey: ["point-of-sale", "point-of-sales", "ui-calendar", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
