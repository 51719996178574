import React, { FC, useCallback, useEffect, useState } from "react";
import { memo } from "react";
import {
  DataGridPremium,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
} from "@mui/x-data-grid-premium";
import { Box, IconButton, TextField } from "@mui/material";
import { BluLoader } from "@bludata/components";
import { If, Then, Else } from "react-if";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { AppDispatch, RootState } from "../../../../store";
import { useShopSignHolidays } from "../../api/shop-sign-holidays";
import {
  changeHolidays,
  resetAndChangeHolidays,
  setDeleteHolidays,
  setModifyHolidays,
} from "../../store/holidays";
import { FooterNoForm } from "./footerNoForm";
import { setInitialHolidays } from "../../utils/white-cache";

interface HolidayInterface {}

export const Holidays: FC<HolidayInterface> = memo(() => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const { data, isLoading } = useShopSignHolidays();
  const dispatch = useDispatch<AppDispatch>();
  const { mode } = useSelector((state: RootState) => state.layoutRoot);
  const { holidays, DeleteHolidays, ModifyHolidays } = useSelector(
    (state: RootState) => state.generaliHolidays
  );

  useEffect(() => {
    if (data !== undefined) {
      dispatch(resetAndChangeHolidays(data));
      setInitialHolidays(data);
    }
  }, [data, dispatch]);

  const handleDelete = useCallback(
    (row: any) => {
      const temp = holidays.filter(
        (prevRow: any) => prevRow.internalId !== row.internalId
      );
      dispatch(changeHolidays(temp));
      if (row?.id) {
        const dH: any = [...(DeleteHolidays ?? []), { id: row?.id }];

        dispatch(setDeleteHolidays(dH));
      }
    },
    [DeleteHolidays, dispatch, holidays]
  );

  const hiddenValue = useCallback(() => {
    return mode !== "edit" && mode !== "create";
  }, [mode]);

  const getEditable = useCallback((): boolean => {
    return mode !== "view";
  }, [mode]);

  const columns: GridColDef[] = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "internalId",
      hide: true,
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      // editable: getEditable(),
      renderCell: (params: any) => {
        if (getEditable())
          return (
            <TextField
              sx={{
                flex: 1,
                ".MuiInputBase-root input": { fontWeight: 400, fontSize: 14 },
              }}
              value={params.row.name}
              onKeyDown={(event: any) => {
                event.stopPropagation();
              }}
              onChange={(event: any) =>
                processRowUpdate({ ...params.row, name: event.target.value })
              }
            />
          );
        return <Box sx={{ flex: 1 }}>{params.row.name}</Box>;
      },
    },
    {
      field: "day",
      headerName: "Giorno",
      width: 100,
      // editable: getEditable(),
      align: "center",
      headerAlign: "center",
      type: "number",
      renderCell: (params: any) => {
        if (getEditable())
          return (
            <TextField
              sx={{
                flex: 1,
                ".MuiInputBase-root input": { fontWeight: 400, fontSize: 14 },
              }}
              inputProps={{ style: { textAlign: "center" } }}
              type="number"
              value={params.row.day}
              onChange={(event: any) =>
                processRowUpdate({ ...params.row, day: event.target.value })
              }
            />
          );
        return (
          <Box sx={{ flex: 1, textAlign: "center" }}>{params.row.day}</Box>
        );
      },
    },
    {
      field: "month",
      headerName: "Mese",
      width: 100,
      // editable: getEditable(),
      headerAlign: "center",
      align: "center",
      type: "number",
      renderCell: (params: any) => {
        if (getEditable())
          return (
            <TextField
              sx={{
                flex: 1,

                ".MuiInputBase-root input": {
                  fontWeight: 400,
                  fontSize: 14,
                },
              }}
              inputProps={{ style: { textAlign: "center" } }}
              type="number"
              value={params.row.month}
              onChange={(event: any) =>
                processRowUpdate({ ...params.row, month: event.target.value })
              }
            />
          );
        return (
          <Box sx={{ flex: 1, textAlign: "center" }}>{params.row.month}</Box>
        );
      },
    },
    {
      field: "edit",
      //default: "view",
      hide: true,
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      width: 30,
      hide: hiddenValue(),
      renderCell: (params: any) => {
        return (
          <IconButton onClick={() => handleDelete(params.row)}>
            <ClearIcon />
          </IconButton>
        );
      },
    },
  ];

  const processRowUpdate = (newRow: any) => {
    const index = holidays.findIndex(
      (row: any) => row?.internalId === newRow.internalId
    );
    if (index !== -1) {
      const temp = holidays.slice();
      temp[index] = newRow;
      dispatch(changeHolidays(temp));
      if (newRow.id !== undefined) {
        const newModifyHoliday = ModifyHolidays.slice();
        const modifyHolidayIndex = newModifyHoliday.findIndex(
          (row: any) => row?.id === newRow.id
        );
        if (modifyHolidayIndex !== -1)
          newModifyHoliday[modifyHolidayIndex] = {
            ...newRow,
            HolidayId: newRow.id,
          };
        else newModifyHoliday.push({ ...newRow, HolidayId: newRow.id });
        dispatch(setModifyHolidays(newModifyHoliday));
      }
    }
    return newRow;
  };

  const handleCellClick = useCallback((params: GridCellParams) => {
    if (params.field === "actions" || params.isEditable === false) {
      const viewRow: any = {
        [params.id]: { [params.field]: { mode: "view" } },
      };
      setCellModesModel(viewRow);
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({
              ...acc,
              [field]: { mode: GridCellModes.View },
            }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  return (
    <>
      <If condition={isLoading}>
        <Then>
          <div style={{ height: "290.5px" }}>
            <BluLoader open />
          </div>
        </Then>
        <Else>
          <DataGridPremium
            getRowId={(row: any) => `${row.internalId}`}
            columns={columns as any}
            rows={holidays}
            rowCount={5}
            sx={{
              "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                padding: "0px",
              },
              "& .MuiFormControl-root .MuiOutlinedInput-root": {
                border: "none!important",
              },
              "& .MuiDataGrid-cell": {
                outline: "none!important",
              },
              "& .error": {
                borderBottomColor: "var(--accent-color)!important",
              },
              "& .MuiDataGrid-cell .MuiInputBase-root": {
                border: "none!important",
              },
              "& .MuiDataGrid-cell .MuiInputBase-root input": {
                p: 0,
                pt: "4px",
              },
              height: "auto",
              flex: 1,
            }}
            density="compact"
            disableIgnoreModificationsIfProcessingProps
            editMode="cell"
            disableColumnMenu
            hideFooterSelectedRowCount
            onProcessRowUpdateError={(error) => console.log(error)}
            experimentalFeatures={{ newEditingApi: true }}
            cellModesModel={cellModesModel}
            // processRowUpdate={processRowUpdate}
            onCellModesModelChange={useCallback(
              (newModel: GridCellModesModel) => {
                setCellModesModel(newModel);
              },
              []
            )}
            components={{
              Footer: () => <FooterNoForm />,
            }}
            onCellClick={handleCellClick}
          />
        </Else>
      </If>
    </>
  );
});
