import { BluTextField } from '@bludata/components';
import { Stack, Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'packages/blu-booking/src/utility_objects/CapitalizeFirstLetter';
import React, { FC } from 'react';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface SurnameInterface {
  isTabClienti: boolean;
}

export const Surname: FC<SurnameInterface> = memo(({ isTabClienti }) => {
  const surname = useWatch({ name: 'surname' });
  const { setValue, trigger } = useFormContext();
  return (
    <Stack flex="1">
      <Typography variant="subtitle2" sx={{ pt: '3px' }}>
        Cognome
      </Typography>
      <BluTextField
        value={surname}
        name="Cognome"
        autoComplete="off" // Disabilita il completamento automatico
        inputProps={{ readOnly: isTabClienti }}
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          const value = capitalizeFirstLetter(event.target.value);
          setValue('surname', value);
          setValue('externalCustomerCode', undefined);
          trigger(['surname', 'externalCustomerCode']);
        }}
      />
    </Stack>
  );
});
