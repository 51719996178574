import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ChatInterface } from '../interfaces/message'

export const getMessages = async (
  openAiThreadId?: string
): Promise<ChatInterface | null> => {
  if (openAiThreadId === undefined) return null
  try {
    const { data } = await axios.get(`/Message/${openAiThreadId}`)
    // const result = {
    //   ...data,
    //   data: data.data.sort((a: any, b: any) => b.created_At - a.created_At)
    // }
    return data
  } catch (error) {
    console.log(error)
    return null
  }
}

export const useMessages = (openAiThreadId?: string, key: string = '') => {
  return useQuery({
    queryFn: async () => await getMessages(openAiThreadId),
    queryKey: ['message', openAiThreadId, key],
    retry: 2,
    retryDelay: 180000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
    // select: normalize,
  })
}
