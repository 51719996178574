import { IconButton, Stack, Typography } from '@mui/material'
import { FC, memo, useMemo } from 'react'
import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import BluContainerBlock from '../../../../common/ContainerBlock'
import { ThreadInterface } from '../../interfaces/thread'

interface ChatHeaderIterface {
  setThreadId: (newThreadId?: string | null) => void
  threadInfo?: ThreadInterface | null
}

export const ChatHeader: FC<ChatHeaderIterface> = memo(
  ({ setThreadId, threadInfo }) => {
    const GetTreadTitle = useMemo(() => {
      if (threadInfo)
        return (
          <Stack
            alignContent="start"
            sx={{
              width: '100%',
              minHeight: '34px',
              overflow: 'hidden',
              wordBreak: 'break-all',
              textOverflow: 'ellipsis',
              color: 'var(--color)'
            }}
          >
            <Typography
              fontSize="13px"
              sx={{ display: 'flex', flex: '1', pt: 0 }}
            >
              {threadInfo?.assistantName ?? ''}
            </Typography>
            <Typography
              fontSize="18px"
              fontWeight="500"
              sx={{ display: 'flex', flex: '1' }}
            >
              {threadInfo?.description ?? ''}{' '}
            </Typography>
          </Stack>
        )
      return <></>
    }, [threadInfo])

    return (
      <BluContainerBlock
        sx={{
          // height: "38px",
          display: 'flex',
          alignItems: 'center',
          m: '4px 4px 0px 4px',
          minHeight: '50px',
          pl: 0,
          pr: 0
        }}
      >
        <>
          <IconButton
            className="chat-ai-back-icon-button"
            onClick={() => setThreadId(null)}
          >
            <ArrowBackIcon className="chat-ai-back-icon" color="primary" />
          </IconButton>
          {GetTreadTitle}
        </>
      </BluContainerBlock>
    )
  }
)
