import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ShopSignInterface } from "../../../interfaces/shop-sign";
import { getPath } from "../lib/white-cache";

const getShopSignsBasic = async (): Promise<ShopSignInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get(`${getPath()}/ShopSign/Basic`);
    return data;
  } catch (error) {
    return [];
  }
};

export const useShopSignsBasic = (key: string = "") => {
  return useQuery({
    queryFn: async () => await getShopSignsBasic(),
    queryKey: ["all-filters", "shop-sign", "basic", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
