import axios from "axios";
import { getPath } from "../lib/white-cache";

const checkMail = async (email: string = ""): Promise<boolean> => {
  try {
    if (email === undefined || email === "") return true;
    const {
      data: { data },
    } = await axios.get(`${getPath()}/Email/${email}`);
    return data;
  } catch (error) {
    return false;
  }
};

export default checkMail;
