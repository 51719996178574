import { Stack, Box, Typography, useTheme } from "@mui/material";
import { FC, memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { nanoid } from "nanoid";
import ModeIcon from "@mui/icons-material/Mode";
import { setPopUpRota } from "../../store/root";
import { getRotaWhiteCache } from "../../lib/white-cache";

interface UserRotaHeaderInterface {
  turni: any;
  isSingleCalendarManagement: boolean;
}

export const UserRotaHeader: FC<UserRotaHeaderInterface> = memo(
  ({ turni, isSingleCalendarManagement }) => {
    const { turniFilter, view, selectedDate } = useSelector(
      (state: RootState) => state.rotaRoot
    );
    const dispatch = useDispatch<AppDispatch>();
    const { turniModifica } = getRotaWhiteCache();
    const theme = useTheme();

    const handleClick = useCallback(
      (user: any) => {
        if (turniModifica) {
          const [calendar, shift] = turni;
          dispatch(
            setPopUpRota({
              id: `${selectedDate.getDate()}`,
              userId: user.userId,
              userName: user.username,
              date: selectedDate,
              calendarType: "user",
              shift: shift[user.userId]?.current as any,
              calendar: calendar[user.userId].current as any,
              defaultCalendar: calendar[user.userId].default,
              defaultShift: shift[user.userId]?.default,
            })
          );
        }
      },
      [dispatch, selectedDate, turni, turniModifica]
    );

    const getUsers = useCallback(() => {
      if (turni) {
        const [calendar, shift] = turni;
        const union = [
          ...new Set([
            ...Object.keys(calendar ?? {}),
            ...Object.keys(shift ?? {}),
          ]),
        ];
        return union
          .map((user: string) => {
            if (calendar && user in calendar) return calendar[user];
            return shift[user];
          })
          .flat();
      }
      return [];
    }, [turni]);

    const GetShift = useMemo(() => {
      if (turniFilter === "calendar") return <></>;
      return (
        <Stack
          flex="1"
          sx={{
            height: "40px",
            boxSizing: "border-box",
            borderTop: "1px solid var(--divider-color)",
          }}
        >
          <Typography sx={{ pt: "10px", pl: "10px", color: "var(--color)" }}>
            Lavoro
          </Typography>
        </Stack>
      );
    }, [turniFilter]);

    const GetCalendar = useMemo(() => {
      if (turniFilter === "shift") return <></>;
      return (
        <Stack
          flex="1"
          sx={{
            height: "40px",
            boxSizing: "border-box",

            borderTop: "1px solid var(--divider-color)",
          }}
        >
          <Typography sx={{ pt: "10px", pl: "10px", color: "var(--color)" }}>
            Appuntamenti
          </Typography>
        </Stack>
      );
    }, [turniFilter]);

    return (
      <Stack
        sx={{
          width: "270px",
          position: "sticky",
          left: 0,
          backgroundColor: "var(--base-foreground-color)",
          boxSizing: "border-box",
          zIndex: 1,

          border: "1px solid var(--divider-color)",
          borderRadius: "8px 0 0 8px",
        }}
        // direction="row"
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "var(--base-foreground-color)",
            boxSizing: "border-box",
            // borderRight: "1px solid rgba(224, 224, 224, 1)",
            borderRadius: "8px 0 0 8px",
          }}
          direction="row"
        >
          <Box
            sx={{
              height: "38px",
              width: "100%",
              backgroundColor:
                theme?.palette?.mode === "dark"
                  ? "var(--background-color) !important"
                  : "#f0f0f0 !important",
            }}
          ></Box>
        </Stack>
        {getUsers().map((user: any, index: number) => (
          <Stack
            sx={{
              width: "100%",
              backgroundColor: "var(--base-foreground-color)",
              boxSizing: "border-box",
              // borderRight: "1px solid rgba(224, 224, 224, 1)",
              borderRadius: "8px 0 0 8px",
              height:
                turniFilter === undefined && !isSingleCalendarManagement
                  ? "82px"
                  : "42px",
            }}
            key={nanoid()}
            direction="row"
          >
            <Stack flex="1">
              <Box
                sx={{
                  boxSizing: "border-box",
                  borderTop: "1px solid var(--divider-color)",
                  borderRight: !isSingleCalendarManagement
                    ? "1px solid var(--divider-color)"
                    : "",
                  display: "flex",
                  flex: 1,
                  direction: "row",
                  cursor: view === "day" ? "pointer" : "default",
                  overflow: "hidden",
                  pr: view === "day" ? "10px" : "0px",
                }}
                onClick={() => handleClick(user)}
              >
                <Typography
                  sx={{
                    width: "100%",
                    margin: "auto",
                    pl: "10px",
                    whiteSpace: "break-spaces",
                    wordBreak: "break-all",
                    color: "var(--color)",
                  }}
                >
                  {user.username}
                </Typography>
                {view === "day" && (
                  <ModeIcon
                    sx={{
                      height: "20px",
                      width: "20px",
                      margin: "auto",
                      color: "var(--base-color)",
                    }}
                  />
                )}
              </Box>
            </Stack>
            {!isSingleCalendarManagement && (
              <Stack flex="1">
                {GetShift}
                {GetCalendar}
              </Stack>
            )}
          </Stack>
        ))}
      </Stack>
    );
  }
);
