import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShopSignHolidayInterface } from "../../../interfaces/shop-sign";
import { defaultHolidays } from "../utils/default-holidays";

export interface RootSliceState {
  holidays: ShopSignHolidayInterface[];
  CreateHolidays: ShopSignHolidayInterface[];
  DeleteHolidays: ShopSignHolidayInterface[];
  ModifyHolidays: ShopSignHolidayInterface[];
}

const initialState: RootSliceState = {
  holidays: defaultHolidays,
  CreateHolidays: [],
  DeleteHolidays: [],
  ModifyHolidays: [],
};

const GeneraliHolidaysSlice = createSlice({
  name: "GeneraliHolidays",
  initialState,
  reducers: {
    changeHolidays(
      state: RootSliceState,
      action: PayloadAction<ShopSignHolidayInterface[]>
    ) {
      state.holidays = action.payload;
    },
    resetAndChangeHolidays(
      _: RootSliceState,
      action: PayloadAction<ShopSignHolidayInterface[]>
    ) {
      return {
        holidays: action.payload,
        CreateHolidays: [],
        DeleteHolidays: [],
        ModifyHolidays: [],
      };
    },
    setDefaultHolidays() {
      return {
        holidays: defaultHolidays,
        CreateHolidays: [],
        DeleteHolidays: [],
        ModifyHolidays: [],
      };
    },
    setCreateHolidays(
      state: RootSliceState,
      action: PayloadAction<ShopSignHolidayInterface[]>
    ) {
      state.CreateHolidays = action.payload;
    },
    setDeleteHolidays(
      state: RootSliceState,
      action: PayloadAction<ShopSignHolidayInterface[]>
    ) {
      state.DeleteHolidays = action.payload;
    },
    setModifyHolidays(
      state: RootSliceState,
      action: PayloadAction<ShopSignHolidayInterface[]>
    ) {
      state.ModifyHolidays = action.payload;
    },
  },
});

export const {
  changeHolidays,
  resetAndChangeHolidays,
  setDefaultHolidays,
  setCreateHolidays,
  setDeleteHolidays,
  setModifyHolidays,
} = GeneraliHolidaysSlice.actions;

export default GeneraliHolidaysSlice.reducer;
