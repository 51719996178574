import { BluSelect, BluTooltip } from "@bludata/components";
import { Box, FormControl, InputLabel, MenuItem, Stack } from "@mui/material";
import { FC, memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface AppointmentProcessInterface {
  disabled?: boolean;
}

export const AppointmentProcess: FC<AppointmentProcessInterface> = memo(
  ({ disabled = false }) => {
    const appointmentProcess = useWatch({ name: "appointmentProcess" });
    const { setValue, trigger } = useFormContext();

    const setAppointmentProcess = useCallback(
      (event: any) => {
        setValue("appointmentProcess", event.target.value);
        trigger("appointmentProcess");
      },
      [setValue, trigger]
    );

    return (
      <Stack
        sx={{
          minWidth: "150px",
          flex: 1,
        }}
      >
        <FormControl>
          <InputLabel>Primo step di prenotazione online</InputLabel>

          <BluTooltip
            text={`Il processo di prenotazione online dipende dall'opzione selezionata dall'utente.
          Se seleziona "Tipo di appuntamento", il percorso è: selezione dell'appuntamento, scelta del punto vendita, scelta dello slot e inserimento dei dati personali. 
          Se, invece, sceglie "Punto di vendita", il percorso prevede: selezione del punto vendita, scelta del tipo di appuntamento, scelta dello slot e inserimento dei dati personali.`}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              p: "2px",
              backgroundColor: "#f5f5f5", // Colore di sfondo predefinito
              ".dark-mode-bludata &": {
                backgroundColor: "#404040", // Colore di sfondo in modalità dark
              },

              "& svg": { width: "15px!important", height: "15px!important" },
            }}
          />
          <BluSelect
            value={appointmentProcess}
            onChange={setAppointmentProcess}
            disabled={disabled}
            renderValue={(selected: any) => {
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected === 0 ? "Tipo di appuntamento" : "Punto vendita"}
                </Box>
              );
            }}
          >
            <MenuItem key={0} value={0}>
              Tipo di appuntamento
            </MenuItem>
            <MenuItem key={1} value={1}>
              Punto vendita
            </MenuItem>
          </BluSelect>
        </FormControl>
      </Stack>
    );
  }
);
