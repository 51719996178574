import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ServiceInterface } from "../interfaces/service";

interface ServicesObject {
  [x: string]: ServiceInterface;
}

const getServices = async (): Promise<ServiceInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get("/api/Service/UI");
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useServiceUI = (key: string = "") => {
  return useQuery({
    queryFn: async () => getServices(),
    queryKey: ["ui", "services", "configurazione", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: any): ServicesObject => {
  const result: any = {};
  for (const service of data) {
    result[service.id] = service;
  }
  return result;
};
