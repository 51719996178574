import { TableContainer, Table, TableBody, Stack, Box } from "@mui/material";
import {
  FC,
  Fragment,
  memo,
  useDeferredValue,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { useTurniUser } from "../../api/turni-user";
import { CalendarUserRow } from "./table-row";
import { setIsLoading } from "../../store/root";
import { UserRotaHeader } from "./header";
import { TableHeader } from "../../components/tableHeader/tableHeader";
import { nanoid } from "nanoid";

interface CalendarUserInterface {
  holiday: {
    [key: string]: string;
  };
  isSingleCalendarManagement: boolean;
}
export const CalendarUser: FC<CalendarUserInterface> = memo(
  ({ holiday, isSingleCalendarManagement }) => {
    const {
      pointOfSaleId,
      selectedDate,
      fetchPointOfSales,
      turniFilter,
      view,
    } = useSelector((state: RootState) => state.rotaRoot);
    const dispatch = useDispatch<AppDispatch>();

    const { data, isLoading } = useTurniUser(
      fetchPointOfSales,
      pointOfSaleId!!,
      selectedDate,
      isSingleCalendarManagement
    );
    const turni = useDeferredValue(data);

    useEffect(() => {
      dispatch(setIsLoading({ key: "turniUsers", value: isLoading }));
    }, [dispatch, isLoading]);

    const getTurni = useMemo(() => {
      if (turni) {
        const [calendar, shift] = turni;
        const union = [
          ...new Set([
            ...Object.keys(calendar ?? {}),
            ...Object.keys(shift ?? {}),
          ]),
        ];
        return (
          <>
            {union.map((userId) => (
              <Fragment key={nanoid()}>
                <CalendarUserRow
                  userId={userId}
                  userName={
                    calendar && userId in calendar
                      ? calendar[userId].username
                      : shift[userId].username
                  }
                  calendar={
                    calendar && userId in calendar ? calendar[userId] : {}
                  }
                  shift={shift && userId in shift ? shift[userId] : {}}
                  turniType="shift"
                  selectedDate={selectedDate}
                  holiday={holiday}
                  isSingleCalendarManagement={isSingleCalendarManagement}
                />
              </Fragment>
            ))}
          </>
        );
      }
      return <></>;
    }, [holiday, isSingleCalendarManagement, selectedDate, turni]);

    return (
      <>
        <UserRotaHeader
          turni={turni}
          isSingleCalendarManagement={isSingleCalendarManagement}
        />

        {/* <TableContainer sx={{ borderRadius: "0 8px 8px 0" }}> */}
        <Table
          size="small"
          stickyHeader
          sx={{
            tableLayout: "fixed",
            borderRadius: "0 8px  8px 0",
            "& .MuiTableCell-root": {
              borderRight: "1px solid rgba(224, 224, 224, 1)",
            },
          }}
        >
          <TableHeader />
          <TableBody>{getTurni}</TableBody>
        </Table>
        {/* </TableContainer> */}
      </>
    );
  }
);
