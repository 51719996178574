import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ServiceDetailsInterface } from "../interfaces/service-details";

const getServiceDetails = async (
  id: string = ""
): Promise<ServiceDetailsInterface | null> => {
  if (id === "") return null;
  try {
    const {
      data: { data },
    } = await axios.get(`/api/Service/Details/${id}`);

    return data;
  } catch (error) {
    return null;
  }
};

export const useServiceDetails = (id: string = "", key: string = "") => {
  return useQuery({
    queryFn: async () => getServiceDetails(id),
    queryKey: ["details", "service", id, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
