import { PointOfSaleObject } from "../interfaces/point-of-sale-object";
import { UserObject } from "../interfaces/user-object";

interface WhiteCacheInterface {
  pointOfSalesForm: PointOfSaleObject;
  usersForm: UserObject;
  initialPointOfSales: {
    [x: string]: boolean;
  };
  initialStudi: {
    [x: string]: boolean;
  };
  initialUsers: {
    [x: string]: boolean;
  };
  defaultPointOfSales: {
    [x: string]: boolean;
  };
  defaultStudi: {
    [x: string]: boolean;
  };
  defaultUsers: {
    [x: string]: boolean;
  };
}

const ServiceWhiteCache: WhiteCacheInterface = {
  pointOfSalesForm: {},
  usersForm: {},
  initialPointOfSales: {},
  initialStudi: {},
  initialUsers: {},
  defaultPointOfSales: {},
  defaultStudi: {},
  defaultUsers: {},
};

export const getServiceWhiteCache = () => ServiceWhiteCache;
export const setServiceWhiteCache = (
  key: keyof WhiteCacheInterface,
  value: any
) => {
  ServiceWhiteCache[key] = value;
};
