import axios from "axios";
import { getPath } from "../lib/white-cache";

export const deleteShopSign = async (id: string): Promise<boolean> => {
  try {
    await axios.delete(`${getPath()}/ShopSign/${id}`);
    return true;
  } catch (err) {
    console.trace(err);
    return false;
  }
};
