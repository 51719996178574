import { memo, useCallback, useEffect } from "react";
import { Name } from "./components/Name";
import { Description } from "./components/Description";
import { SmallDescription } from "./components/SmallDescription";
import { PointOfSales } from "./components/PointOfSales";
import { Users } from "./components/Users";
import { Divider, Stack } from "@mui/material";
import { Studi } from "./components/Studi";
import { Duration } from "./components/Duration";
import { CheckBoxes } from "./components/Checkboxes";
import { schema } from "./schema";
import { BluDialog, BluTitle } from "@bludata/components";
import { FormProvider, useForm } from "react-hook-form";
import { Buttons } from "./components/Buttons";
import { setDeleteService, setOpenForm } from "../../store/root";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getWhiteCache } from "../../../../lib/white-cache";
import { AppDispatch } from "../../../../store";
import { getInitialValues } from "./utils/initial-values";
import {
  getServiceWhiteCache,
  setServiceWhiteCache,
} from "../../lib/white-cache";
import { setFormValues, setServiceFormLoader } from "../../store/form";
import { ServiceDeletePopup } from "./components/DeletePopup";
import { updateService } from "./utils/manage-service";
import { ServiceFormLoader } from "./components/Loader";
import { ServiceColor } from "./components/Color";
import { ServiceIcons } from "./components/Icons";
import { NotificationDescription } from "./components/NotificationDescription";

interface ServiceFormInterface {
  openForm?: string;
  currentService: string;
  data: any;
  serviceUsers: any;
}

export const ServiceForm = memo(
  ({
    openForm,
    currentService,
    data,
    serviceUsers,
  }: ServiceFormInterface): JSX.Element => {
    const initialValues = getInitialValues(data);
    const { isBluBookingActive } = getWhiteCache();
    const queryClient = useQueryClient();
    const dispatch = useDispatch<AppDispatch>();
    const methods = useForm({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: initialValues,
      resolver: yupResolver(schema),
    });

    useEffect(() => {
      if (data === undefined || data === null) return;
      const {
        pointOfSalesForm,
        usersForm,
        initialPointOfSales,
        initialStudi,
        initialUsers,
      } = JSON.parse(JSON.stringify(getServiceWhiteCache()));

      if (data?.servicePointOfSales !== undefined) {
        for (const pointOfSale of data?.servicePointOfSales) {
          const posId: string = pointOfSale.pointOfSaleId as any;
          if (posId in initialPointOfSales) initialPointOfSales[posId] = true;
          if (posId in pointOfSalesForm) {
            pointOfSalesForm[posId].enable = true;
            pointOfSalesForm[posId].associationId = pointOfSale.id;
          }
        }
      }
      setServiceWhiteCache("initialPointOfSales", initialPointOfSales);

      if (data?.serviceStudios !== undefined) {
        for (const studio of data?.serviceStudios) {
          const posId: string = studio.pointOfSaleId as any;
          if (studio.studioId in initialStudi)
            initialStudi[studio.studioId] = true;
          if (
            posId in pointOfSalesForm &&
            studio.studioId in pointOfSalesForm[posId].studi
          ) {
            pointOfSalesForm[posId].studi[studio.studioId].enable = true;
            pointOfSalesForm[posId].studi[studio.studioId].associationId =
              studio.id;
          }
        }
      }
      setServiceWhiteCache("initialStudi", initialStudi);

      if (serviceUsers !== undefined) {
        for (const user of serviceUsers) {
          const userId: string = user.userId as any;
          if (userId in initialUsers) initialUsers[userId] = true;
          if (userId in usersForm) {
            usersForm[userId].enable = true;
            usersForm[userId].associationId = user.id;
          }
        }
      }

      setServiceWhiteCache("initialUsers", initialUsers);
      dispatch(setFormValues({ pointOfSalesForm, usersForm }));
    }, [data, dispatch, serviceUsers]);

    const DialogTitle = useCallback(() => {
      if (openForm === "new") return "Nuovo servizio";
      if (openForm === "info") return "Servizio";
      return "Modifica servizio";
    }, [openForm]);

    const submit = useCallback(async () => {
      const data = methods.watch();

      dispatch(setServiceFormLoader(true));
      const result = await updateService(data, currentService);
      dispatch(setServiceFormLoader(false));

      if (result) {
        // dispatch(setCurrentService(""));
        dispatch(setOpenForm(undefined));
        queryClient.invalidateQueries({ queryKey: ["details", "service"] });
        queryClient.invalidateQueries({
          queryKey: ["all-filters", "services"],
        });
        queryClient.invalidateQueries({
          queryKey: ["ui", "services"],
        });
        queryClient.invalidateQueries(["calendar"], {
          type: "active",
        });
        queryClient.removeQueries(["calendar"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["filter"], {
          type: "active",
        });
        queryClient.removeQueries(["filter"], {
          type: "inactive",
        });
      }
    }, [currentService, dispatch, methods, queryClient]);

    const openDeletePopup = async () => {
      dispatch(setDeleteService(currentService!));
    };

    const Row = ({ children }: { children: JSX.Element[] | JSX.Element }) => (
      <Stack
        gap={1}
        flexWrap="wrap"
        sx={{ width: "100%" }}
        flexDirection="unset"
      >
        {children}
      </Stack>
    );

    return (
      <FormProvider {...methods}>
        <form id="creationForm" style={{ width: "inherit" }}>
          <BluDialog
            className="service-popup"
            open={true}
            maxWidth="lg"
            zIndex={200}
            draggable
            dialogTitle={DialogTitle()}
            dialogContent={
              <>
                <ServiceFormLoader />
                <ServiceDeletePopup />
                <div
                  style={{
                    padding: "5px 10px 0px 10px",
                    border: "1px solid var(--divider-color)",
                    borderRadius: "4px",
                    margin: "5px 10px 10px 10px",
                  }}
                >
                  <Divider sx={{ opacity: 0, mb: "20px!important" }} />

                  <BluTitle text="Informazioni generali" />

                  <Row>
                    <Name />
                    <ServiceIcons />
                  </Row>
                  <Row>
                    <SmallDescription />
                    <ServiceColor />
                  </Row>
                  <Row>
                    <NotificationDescription />
                  </Row>
                  {isBluBookingActive && (
                    <Row>
                      <Description />
                    </Row>
                  )}
                  <Row>
                    <Duration />
                    <CheckBoxes />
                  </Row>
                </div>
                <Stack flex="1" sx={{ p: "0 20px" }} gap={1}>
                  <Row>
                    <PointOfSales />
                  </Row>

                  <Row>
                    <Studi />
                  </Row>

                  <Row>
                    <Users />
                  </Row>
                </Stack>
              </>
            }
            sx={{
              "& .MuiDialogContent-root": {
                pb: "0px !important",
                p: 0,
              },
              zIndex: 200,
            }}
            dialogActions={
              <Buttons
                initialValues={initialValues}
                handleDelete={openDeletePopup}
                handleSubmit={submit}
                openForm={openForm}
              />
            }
          />
        </form>
      </FormProvider>
    );
  }
);
