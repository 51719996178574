import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import PointOfSale_interface from "../pages/nuova-point-of-sales/interfaces/PointOfSale_interface";

interface PointOfSalesObject {
  [x: string]: PointOfSale_interface;
}

const getPointOfSales = async (): Promise<PointOfSale_interface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get("/api/PointOfSale");

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const usePointOfSale = (key: string = "") => {
  return useQuery({
    queryFn: async () => await getPointOfSales(),
    queryKey: ["all-filters", "point-of-sales", "configurazione", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: any): PointOfSalesObject => {
  const result: any = {};
  for (const pos of data) {
    result[pos.id] = {
      ...pos,
      pointOfSaleId: pos.id,
    };
  }
  return result;
};
