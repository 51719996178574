import { Button, Stack } from "@mui/material";
import { FC, memo, useMemo, useState } from "react";
import { AppDispatch, RootState } from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  resetImportData,
  setFile,
  setImportOpen,
  setStep,
} from "../../../../store/import";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { BluAlert } from "@bludata/components";

interface ButtonsInterface {
  pointOfSaleId?: string;
  documentFormat: "bludata" | "factorial";
}

export const Buttons: FC<ButtonsInterface> = memo(
  ({ pointOfSaleId, documentFormat }) => {
    const { step, file } = useSelector((state: RootState) => state.rotaImport);
    const dispatch = useDispatch<AppDispatch>();
    const [error, setError] = useState("");

    const GetActions = useMemo(() => {
      if (step === "init") {
        return (
          <Stack flex="1" justifyContent="end" direction="row" gap={1}>
            <Stack flex="1">
              <Button
                variant="outlined"
                onClick={() => dispatch(resetImportData(pointOfSaleId ?? ""))}
                sx={{ width: "fit-content" }}
              >
                Annulla
              </Button>
            </Stack>
            {file !== null ? (
              <Button
                disabled={file === null}
                onClick={() => dispatch(setStep("import"))}
              >
                Avanti
              </Button>
            ) : (
              <Button
                color="primary"
                component="label"
                startIcon={<FileUploadIcon />}
                onClick={() => {}}
                variant={file === null ? "contained" : "outlined"}
              >
                Importa
                <input
                  accept={documentFormat === "bludata" ? ".xlsx" : ".csv"}
                  type="file"
                  hidden
                  onChange={(e: any) => {
                    const fileReader = new FileReader();

                    if (e.target.files[0].size > 5 * 1048576) {
                      setError("La dimensione massima è 5 mb");
                      return;
                    }

                    fileReader.readAsDataURL(e.target.files[0]);
                    fileReader.onloadend = async (event: any) => {
                      dispatch(
                        setFile({
                          file: e.target.files[0],
                          fileName: e.target.files[0].name,
                        })
                      );
                    };
                  }}
                />
              </Button>
            )}
          </Stack>
        );
      } else if (step === "import") {
        return (
          <Stack flex="1" justifyContent="end" direction="row" gap={1}></Stack>
        );
      } else if (step === "error" && documentFormat === "factorial")
        return (
          <Stack flex="1" direction="row" gap={1}>
            <Stack flex="1">
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(setImportOpen(false));
                  dispatch(resetImportData(pointOfSaleId ?? ""));
                }}
                sx={{ width: "fit-content" }}
              >
                Chiudi
              </Button>
            </Stack>
            <Button
              disabled={file === null}
              onClick={() => dispatch(setStep("import"))}
            >
              Importa
            </Button>
          </Stack>
        );
      return (
        <Stack flex="1" justifyContent="end" direction="row" gap={1}>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(setImportOpen(false));
              dispatch(resetImportData(pointOfSaleId ?? ""));
            }}
          >
            Chiudi
          </Button>
        </Stack>
      );
    }, [dispatch, documentFormat, file, pointOfSaleId, step]);

    return (
      <>
        <BluAlert
          type="warning"
          show={!!error}
          text={error}
          handleClose={() => {
            setError("");
          }}
        />
        {GetActions}
      </>
    );
  }
);
