import { BluTitle } from "@bludata/components";
import { Stack } from "@mui/material";
import { FC } from "react";
import { UserVisibility } from "../../components/UserVisibility/UserRoles";
import { ServiceSelect } from "../../components/ServiceSelect/ServiceSelect";
import { CustomInputHtmlArea } from "../../common/CustomHtmlArea";
import { FilialeSelection } from "../../components/Filiale/PointOfsale";
import { CustomDatePicker } from "../../common/CustomDatePicker";
import { FocusCodiceProfilo } from "../../components/FocusCodiceProfilo/FocusCodiceProfilo";

export const OtherInfoFW: FC<{
  disabled: boolean;
}> = ({ disabled }): JSX.Element => {
  return (
    <Stack direction="column" gap={1} flex="1">
      <BluTitle text={"Altre informazioni"} />
      <Stack direction="column" gap={1}>
        <Stack gap={1} flex="1">
          <Stack direction="row" gap={1} flex="1">
            <FilialeSelection disabled={disabled} />
            <FocusCodiceProfilo disabled={disabled} />
            <UserVisibility disabled={disabled} />
          </Stack>
          <Stack direction="row" gap={1}>
            <CustomDatePicker
              name="employmentDate"
              label="Data inizio contratto"
              disabled={disabled}
              error={false}
            />
            <CustomDatePicker
              name="contractExpirationDate"
              label="Data scadenza contratto"
              disabled={disabled}
              error={false}
            />
          </Stack>

          <ServiceSelect disabled={disabled} />
          <CustomInputHtmlArea
            name={"presentation"}
            label="Note"
            disabled={disabled}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
