import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { checkTurnoAlreadySet } from "../utils/check-turno-already-set";
import { CalendarUserInterface } from "../interfaces/calendar-user";
import { ExtraCalendarUserInterface } from "../interfaces/extra-calendar-user";
import { UserTurniInterface } from "../interfaces/user-turni";
import { getCalendarUser } from "./calendar-user";
import { getShiftUser } from "./shift-user";

export const getTurniUser = async (
  pointOfSaleId: string,
  range: Date,
  isSingleCalendarManagement: boolean
): Promise<
  [
    [CalendarUserInterface[], ExtraCalendarUserInterface[]],
    [CalendarUserInterface[], ExtraCalendarUserInterface[]]
  ]
> => {
  const startTime = moment(range).startOf("month").format("YYYY-MM-DD");
  const endTime = moment(range).endOf("month").format("YYYY-MM-DD");
  if (!isSingleCalendarManagement) {
    try {
      const responses = await Promise.all([
        getCalendarUser(pointOfSaleId, startTime, endTime),
        getShiftUser(pointOfSaleId, startTime, endTime),
      ]);

      return responses as any;
    } catch (error) {
      return [
        [[], []],
        [[], []],
      ];
    }
  }

  try {
    const responses = await getCalendarUser(pointOfSaleId, startTime, endTime);
    return [responses, [[], []]] as any;
  } catch (error) {
    return [
      [[], []],
      [[], []],
    ];
  }
};

export const useTurniUser = (
  fetchPointOfSale: boolean,
  pointOfSaleId: string,
  range: Date,
  isSingleCalendarManagement: boolean,
  key: string = ""
) => {
  return useQuery({
    queryFn: async () =>
      await getTurniUser(pointOfSaleId, range, isSingleCalendarManagement),
    queryKey: [
      "turni-user",
      pointOfSaleId,
      range,
      isSingleCalendarManagement,
      key,
    ],
    enabled: fetchPointOfSale,
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: any): any => {
  const result: {
    [x: string]: UserTurniInterface;
  }[] = [];

  for (const turniType of data) {
    const turniResult: {
      [x: string]: UserTurniInterface;
    } = {};
    const [calendarUsers, extraDays] = turniType;
    for (const user of calendarUsers) {
      const temp: UserTurniInterface = {
        userId: user.userId,
        username: user.username,
        photo: user.photo,
        default: {
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null,
          sunday: null,
        },
        extra: {},
      };

      try {
        const userExtra = extraDays.filter(
          (day: any) => day.userId === user.userId
        );

        for (const extra of userExtra) {
          const date = moment(extra.startValidDate).format("YYYY-MM-DD");
          if (extra.isClosingDay)
            temp.extra[date] = {
              id: extra.id,
              extraCalendarType: extra.extraCalendarType,
              isClosed: true,
              startTime1: null,
              startTime2: null,
              endTime1: null,
              endTime2: null,
            };
          else
            temp.extra[date] = {
              id: extra.id,
              extraCalendarType: extra.extraCalendarType,
              isClosed: false,
              startTime1: (extra?.startTime1 || "").slice(0, -3),
              startTime2: (extra?.startTime2 || "").slice(0, -3),
              endTime1: (extra?.endTime1 || "").slice(0, -3),
              endTime2: (extra?.endTime2 || "").slice(0, -3),
            };
        }

        for (const calendar of user?.calendars ?? []) {
          const isClosed = !(
            (calendar.startTime1 !== null && calendar.endTime1 !== null) ||
            (calendar.startTime2 !== null && calendar.endTime2 !== null)
          );
          if (calendar.hasMonday) {
            temp.default.monday = {
              id: calendar.id,
              isClosed: isClosed,
              ...checkTurnoAlreadySet(temp.default.monday, calendar, isClosed),
            };
          }
          if (calendar.hasTuesday) {
            temp.default.tuesday = {
              id: calendar.id,
              isClosed: isClosed,
              ...checkTurnoAlreadySet(temp.default.tuesday, calendar, isClosed),
            };
          }
          if (calendar.hasWednesday) {
            temp.default.wednesday = {
              id: calendar.id,
              isClosed: isClosed,
              ...checkTurnoAlreadySet(
                temp.default.wednesday,
                calendar,
                isClosed
              ),
            };
          }
          if (calendar.hasThursday) {
            temp.default.thursday = {
              id: calendar.id,
              isClosed: isClosed,
              ...checkTurnoAlreadySet(
                temp.default.thursday,
                calendar,
                isClosed
              ),
            };
          }
          if (calendar.hasFriday) {
            temp.default.friday = {
              id: calendar.id,
              isClosed: isClosed,
              ...checkTurnoAlreadySet(temp.default.friday, calendar, isClosed),
            };
          }
          if (calendar.hasSaturday) {
            temp.default.saturday = {
              id: calendar.id,
              isClosed: isClosed,
              ...checkTurnoAlreadySet(
                temp.default.saturday,
                calendar,
                isClosed
              ),
            };
          }
          if (calendar.hasSunday) {
            temp.default.sunday = {
              id: calendar.id,
              isClosed: isClosed,
              ...checkTurnoAlreadySet(temp.default.sunday, calendar, isClosed),
            };
          }
        }
        turniResult[user.userId] = temp;
      } catch (err) {
        console.error("ERROR:", err);
      }
    }
    result.push(turniResult);
  }

  return result;
};
