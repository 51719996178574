import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultCompanySettings } from "../utils/default-company-settings";
import { CompanySettingsInterface } from "../interfaces/CompanySettings";

export interface RootSliceState extends CompanySettingsInterface {
  mode: "edit" | "create" | "view";
  popUpCalendarManagement: boolean;
}

const initialState: RootSliceState = {
  mode: "view",
  popUpCalendarManagement: false,
  ...defaultCompanySettings,
};

const GeneraliRootSlice = createSlice({
  name: "layoutRoot",
  initialState,
  reducers: {
    setMode(
      state: RootSliceState,
      action: PayloadAction<"edit" | "create" | "view">
    ) {
      state.mode = action.payload;
    },
    setCompanySettings: (
      state: RootSliceState,
      action: PayloadAction<CompanySettingsInterface>
    ) => {
      return { ...state, ...action.payload };
    },
    setCompanyColorEnum(state: RootSliceState, action: PayloadAction<number>) {
      state.companyColorEnum = action.payload;
    },
    setVisibilityEnum(state: RootSliceState, action: PayloadAction<number>) {
      state.visibilityEnum = action.payload;
    },
    setIsManualAppointmentBlock(
      state: RootSliceState,
      action: PayloadAction<boolean>
    ) {
      state.isManualAppointmentBlock = action.payload;
    },
    setIsSingleCalendarManagement(
      state: RootSliceState,
      action: PayloadAction<boolean>
    ) {
      state.isSingleCalendarManagement = action.payload;
    },
    setPopUpCalendarManagement(
      state: RootSliceState,
      action: PayloadAction<boolean>
    ) {
      state.popUpCalendarManagement = action.payload;
    },
  },
});

export const {
  setMode,
  setCompanySettings,
  setCompanyColorEnum,
  setVisibilityEnum,
  setIsManualAppointmentBlock,
  setIsSingleCalendarManagement,
  setPopUpCalendarManagement,
} = GeneraliRootSlice.actions;

export default GeneraliRootSlice.reducer;
