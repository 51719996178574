export const it = {
  it: {
    richtexteditor: {
      alignments: 'allineamenti',
      justifyLeft: 'Allineare a sinistra',
      justifyCenter: 'Allinea al centro',
      justifyRight: 'Allinea a destra',
      justifyFull: 'Allinea giustificato',
      fontName: 'Nome carattere',
      fontSize: 'Dimensione del font',
      fontColor: 'Colore del carattere',
      backgroundColor: 'Colore di sfondo',
      bold: 'Grassetto',
      italic: 'Corsivo',
      underline: 'Sottolineare',
      strikethrough: 'Barrato',
      clearFormat: 'Cancella formato',
      clearAll: 'Cancella tutto',
      cut: 'Tagliare',
      copy: 'copia',
      paste: 'Incolla',
      unorderedList: 'Elenco puntato',
      orderedList: 'Elenco numerato',
      indent: 'Aumenta rientro',
      outdent: 'Riduci rientro',
      undo: 'Disfare',
      redo: 'Rifare',
      superscript: 'indice',
      subscript: 'deponente',
      createLink: 'Inserisci collegamento ipertestuale',
      openLink: 'Link aperto',
      editLink: 'Modifica collegamento',
      removeLink: 'Rimuovi collegamento',
      image: 'Inserisci immagine',
      replace: 'Sostituire',
      align: 'Allineare',
      caption: 'Didascalia immagine',
      remove: 'Rimuovere',
      insertLink: 'Inserisci collegamento',
      display: 'Schermo',
      altText: 'Testo alternativo',
      dimension: 'Cambia dimensione',
      fullscreen: 'aumentare al massimo',
      maximize: 'aumentare al massimo',
      minimize: 'Minimizzare',
      lowerCase: 'Minuscole',
      upperCase: 'Maiuscolo',
      print: 'Stampa',
      formats: 'formati',
      sourcecode: 'Vista codice',
      preview: 'Anteprima',
      viewside: 'ViewSide',
      insertCode: 'Inserire codice',
      linkText: 'Visualizza testo',
      linkTooltipLabel: 'Titolo',
      linkWebUrl: 'Indirizzo Web',
      linkTitle: 'Inserisci un titolo',
      linkurl: 'http://example.com',
      linkOpenInNewWindow: 'Apri il collegamento in una nuova finestra',
      linkHeader: 'Inserisci collegamento',
      dialogInsert: 'Inserire',
      dialogCancel: 'Annulla',
      dialogUpdate: 'Aggiornare',
      imageHeader: 'Inserisci immagine',
      imageLinkHeader: 'Puoi anche fornire un link dal web',
      mdimageLink: 'Fornisci un URL per la tua immagine',
      imageUploadMessage: "Rilascia l'immagine qui o sfoglia per caricarla",
      imageDeviceUploadMessage: 'Clicca qui per caricare',
      imageAlternateText: 'Testo alternato',
      alternateHeader: 'Testo alternativo',
      browse: 'Navigare',
      imageUrl: 'http://example.com/image.png',
      imageCaption: 'Didascalia',
      imageSizeHeader: "Dimensione dell'immagine",
      imageHeight: 'Altezza',
      imageWidth: 'Larghezza',
      textPlaceholder: 'Inserire il testo',
      inserttablebtn: 'Inserisci tabella',
      tabledialogHeader: 'Inserisci tabella',
      tableWidth: 'Larghezza',
      cellpadding: 'Imbottitura cellulare',
      cellspacing: 'Spaziatura cellulare',
      columns: 'Numero di colonne',
      rows: 'Numero di righe',
      tableRows: 'Righe della tabella',
      tableColumns: 'Colonne della tabella',
      tableCellHorizontalAlign: 'Allinea orizzontale cella cella',
      tableCellVerticalAlign: 'Allinea verticale cella cella',
      createTable: 'Crea tabella',
      removeTable: 'Rimuovi tabella',
      tableHeader: 'Intestazione della tabella',
      tableRemove: 'Tabella Rimuovi',
      tableCellBackground: 'Sfondo delle celle della tabella',
      tableEditProperties: 'Proprietà di modifica tabella',
      styles: 'stili',
      insertColumnLeft: 'Inserisci colonna a sinistra',
      insertColumnRight: 'Inserisci colonna a destra',
      deleteColumn: 'Elimina colonna',
      insertRowBefore: 'Inserisci riga prima',
      insertRowAfter: 'Inserisci riga dopo',
      deleteRow: 'Elimina riga',
      tableEditHeader: 'Modifica tabella',
      TableHeadingText: 'Intestazione',
      TableColText: 'Col',
      imageInsertLinkHeader: 'Inserisci collegamento',
      editImageHeader: 'Modifica immagine',
      alignmentsDropDownLeft: 'Allineare a sinistra',
      alignmentsDropDownCenter: 'Allinea al centro',
      alignmentsDropDownRight: 'Allinea a destra',
      alignmentsDropDownJustify: 'Allinea giustificato',
      imageDisplayDropDownInline: 'In linea',
      imageDisplayDropDownBreak: 'Rompere',
      tableInsertRowDropDownBefore: 'Inserisci la riga prima',
      tableInsertRowDropDownAfter: 'Inserisci riga dopo',
      tableInsertRowDropDownDelete: 'Elimina riga',
      tableInsertColumnDropDownLeft: 'Inserisci colonna a sinistra',
      tableInsertColumnDropDownRight: 'Inserisci colonna a destra',
      tableInsertColumnDropDownDelete: 'Elimina colonna',
      tableVerticalAlignDropDownTop: 'Allinea in alto',
      tableVerticalAlignDropDownMiddle: 'Allinea al centro',
      tableVerticalAlignDropDownBottom: 'Allinea in basso',
      tableStylesDropDownDashedBorder: 'Bordi tratteggiati',
      tableStylesDropDownAlternateRows: 'Righe alternative',
      pasteFormat: 'Incolla formato',
      pasteFormatContent: "Scegli l'azione di formattazione",
      plainText: 'Testo semplice',
      cleanFormat: 'Pulito',
      keepFormat: 'Mantenere',
      pasteDialogOk: 'ok',
      pasteDialogCancel: 'Annulla',
      fileManager: 'File Manager',
      fileDialogHeader: 'Browser di file',
      formatsDropDownParagraph: 'Paragrafo',
      formatsDropDownCode: 'Codice',
      formatsDropDownQuotation: 'Quotazione',
      formatsDropDownHeading1: 'Intestazione 1',
      formatsDropDownHeading2: 'Intestazione 2',
      formatsDropDownHeading3: 'Intestazione 3',
      formatsDropDownHeading4: 'Intestazione 4',
      fontNameSegoeUI: 'SegoeUI',
      fontNameArial: 'Arial',
      fontNameGeorgia: 'Georgia',
      fontNameImpact: 'Impatto',
      fontNameTahoma: 'Tahoma',
      fontNameTimesNewRoman: 'Times New Roman',
      fontNameVerdana: 'Verdana',
      formatsOLListNumber: 'Numero',
      formatsOLListLowerAlpha: 'Alfa inferiore',
      formatsOLListUpperAlpha: 'UpperAlpha',
      formatsOLListLowerRoman: 'LowerRoman',
      formatsOLListUpperRoman: 'UpperRoman',
      formatsOLListLowerGreek: 'greco inferiore',
      formatsULListDisc: 'Disco',
      formatsULListCircle: 'Cerchio',
      formatsULListSquare: 'Piazza',
      formatsOLListNone: 'Nessuna',
      formatsULListNone: 'Nessuna',
      formatPainter: 'Formato pittore'
    }
  }
}
