import { IconButton, Typography } from '@mui/material'
import Fade from '@mui/material/Fade'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ModeIcon from '@mui/icons-material/Mode'
import DeleteIcon from '@mui/icons-material/Delete'
import { deleteThread } from '../../api/delete-thread'
import BluAsyncDialog from '../../../../common/AsyncDialog'
import { useQueryClient } from '@tanstack/react-query'
import { AppDispatch } from '../../../../store'
import { useDispatch } from 'react-redux'
import { setIsLoading, setModifiedThreadId } from '../../store'
import { setThreadIdCancel } from '../../utils/white-cache'

interface ThreadMenuInterface {
  threadId?: string
  selectedThreadId?: string
  setThreadId: (newThreadId?: string) => void
}

export const ThreadMenu: React.FC<ThreadMenuInterface> = ({
  threadId,
  selectedThreadId,
  setThreadId
}): JSX.Element => {
  // const { threadId } = useSelector((state: RootState) => state.chatMenu);
  const dispatch = useDispatch<AppDispatch>()
  const queryClient = useQueryClient()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation()
      setAnchorEl(event?.currentTarget)
    },
    []
  )

  const handleClose = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  const handleCancel = useCallback(async () => {
    handleClose()
    BluAsyncDialog({
      title: 'Attenzione',
      message: 'Sei sicuro di voler cancellare la chat?',
      confimButton: 'Si, cancella la chat',
      declineButton: 'Annulla',
      type: 'warning',
      onConfirmClick: async () => {
        dispatch(setIsLoading(true))
        const result = await deleteThread(threadId!!)
        dispatch(setIsLoading(false))
        setThreadIdCancel(threadId)
        queryClient.removeQueries(['thread', threadId])
        queryClient.invalidateQueries(['thread'], {
          type: 'active'
        })
        queryClient.removeQueries(['thread'], {
          type: 'inactive'
        })
        if (selectedThreadId === threadId) {
          setThreadId(undefined)
          queryClient.removeQueries(['message'])
        }

        if (!result) {
          BluAsyncDialog({
            title: 'Attenzione',
            message:
              'Ci scusiamo, per un problema tecnico, non è possibile creare una nuova chat. Riprova più tardi.',
            confimButton: 'Chiudi',
            hideDeclineButton: true,
            type: 'warning',
            // maxWidth: false,
            sx: { '& .MuiDialog-paper': { maxWidth: '400px' } }
          })
        }
      },
      // maxWidth: false,
      sx: { '& .MuiDialog-paper': { maxWidth: '400px' } }
    })
  }, [
    dispatch,
    handleClose,
    queryClient,
    selectedThreadId,
    setThreadId,
    threadId
  ])

  const handleModify = useCallback(
    (event: any) => {
      event.stopPropagation()
      console.log('threadId', threadId)
      dispatch(setModifiedThreadId(threadId))
      handleClose()
    },
    [dispatch, handleClose, threadId]
  )

  const MenuContainer = useMemo(() => {
    return (
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          key="rename"
          value="delete"
          style={{
            minWidth: '200px'
          }}
          onClick={handleModify}
        >
          <ModeIcon />
          <Typography sx={{ pl: '10px' }}>Rinomina</Typography>
        </MenuItem>
        <MenuItem
          key="delete"
          value="delete"
          style={{
            minWidth: '200px'
          }}
          onClick={handleCancel}
        >
          <DeleteIcon color="error" />
          <Typography color="error" sx={{ pl: '10px' }}>
            Elimina
          </Typography>
        </MenuItem>
      </Menu>
    )
  }, [anchorEl, open, handleClose, handleModify, handleCancel])

  return (
    <div style={{ width: '40px', height: '40px' }}>
      <IconButton sx={{ borderRadius: 0 }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      {MenuContainer}
    </div>
  )
}
