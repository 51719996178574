import axios from "axios";
import Laboratorio_interface from "../interfaces/Laboratorio_interface";
import { useQuery } from "@tanstack/react-query";

const fetchLaboratorioEsterno = async (): Promise<Laboratorio_interface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get(`/focusapi/tabella/laboratorioestlista`, {
      params: {
        _dc: new Date().getTime(),
        page: 1,
        start: 0,
        limit: 9999,
        filter: JSON.stringify([
          { operator: "=", property: "attivo", value: true },
        ]),
        sort: JSON.stringify([
          { property: "ragioneSociale", direction: "ASC" },
        ]),
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const useLaboratorioEsterno = (key: string = "") => {
  return useQuery({
    queryFn: async () => await fetchLaboratorioEsterno(),
    queryKey: ["laboratorio-esterno", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
