import { TurniItemInterface } from "../../../interfaces/default-turni-user";

export const DefaultHoursPointOfSale: any = {
  shiftmonday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  shifttuesday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  shiftwednesday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  shiftthursday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  shiftfriday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  shiftsaturday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  shiftsunday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },

  calendarmonday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  calendartuesday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  calendarwednesday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  calendarthursday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  calendarfriday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  calendarsaturday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
  calendarsunday: {
    isClosed: true,
    startTime1: null,
    startTime2: null,
    endTime1: null,
    endTime2: null,
  },
};

export const getDefaultHoursPointOfSale = (pointOfSaleId: string) => {
  return {
    shiftmonday: {
      isClosed: true,
      pointOfSaleId,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    shifttuesday: {
      isClosed: true,
      pointOfSaleId,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    shiftwednesday: {
      isClosed: true,
      pointOfSaleId,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    shiftthursday: {
      isClosed: true,
      pointOfSaleId,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    shiftfriday: {
      isClosed: true,
      pointOfSaleId,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    shiftsaturday: {
      isClosed: true,
      pointOfSaleId,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    shiftsunday: {
      isClosed: true,
      pointOfSaleId,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },

    calendarmonday: {
      isClosed: true,
      pointOfSaleId,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    calendartuesday: {
      pointOfSaleId,
      isClosed: true,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    calendarwednesday: {
      pointOfSaleId,
      isClosed: true,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    calendarthursday: {
      pointOfSaleId,
      isClosed: true,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    calendarfriday: {
      isClosed: true,
      pointOfSaleId,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    calendarsaturday: {
      pointOfSaleId,
      isClosed: true,

      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
    calendarsunday: {
      isClosed: true,
      pointOfSaleId,
      startTime1: null,
      startTime2: null,
      endTime1: null,
      endTime2: null,
    },
  };
};
