import "../../../../styles/studio-popup.css";

import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BluLoader } from "@bludata/components";
import { Else, If, Then } from "react-if";
import { resetFormValues } from "../../store/form";
import { AppDispatch, RootState } from "../../../../store";
import { useStudioDetails } from "../../api/studio-details";
import { StudioForm } from "./popup";

export const StudiPopup = memo((): JSX.Element => {
  const { openForm, currentStudio } = useSelector(
    (state: RootState) => state.studiRoot
  );
  return (
    <>
      {openForm !== undefined && (
        <LoadStudio openForm={openForm} currentStudio={currentStudio} />
      )}
    </>
  );
});

interface LoadStudioInterface {
  openForm?: string;
  currentStudio: any;
}

const LoadStudio = memo(({ openForm, currentStudio }: LoadStudioInterface) => {
  const { data, isLoading } = useStudioDetails(currentStudio?.studioId);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (openForm === "new") dispatch(resetFormValues());
  }, [dispatch, openForm]);
  return (
    <>
      <If condition={!isLoading && data !== undefined}>
        <Then>
          <StudioForm
            openForm={openForm}
            currentStudio={currentStudio}
            data={data}
          />
        </Then>
        <Else>
          <BluLoader open />
        </Else>
      </If>
    </>
  );
});
