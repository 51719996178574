import { BluColorPicker } from "@bludata/components";
import { Stack, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

export const ServiceColor = memo(() => {
  const color = useWatch({ name: "color" });
  const { setValue, trigger } = useFormContext();
  const { openForm } = useSelector((state: RootState) => state.serviceRoot);

  const handleOnChange = useCallback(
    (args: any) => {
      setValue("color", args.currentValue.hex);
      trigger("color");
    },
    [setValue, trigger]
  );
  return (
    <Stack flex="1">
      <Typography variant="subtitle2" sx={{ p: "3px 0px 0 0" }}>
        Colore
      </Typography>
      <BluColorPicker
        disabled={openForm === "info"}
        value={color ?? "#ffffff"}
        onChange={handleOnChange}
      />
    </Stack>
  );
});
