import {
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { getPointOfSaleWhiteCache } from "../../lib/white-cache";

interface PointOfSalesGridFiltersInterface {
  gridSearch: string;
  setGridSearch: (value: string) => void;

  isActive: boolean;
  setIsActive: (value: boolean) => void;

  remoto: boolean;
  setRemoto: (value: boolean) => void;
}

export const PointOfSalesGridFilters: FC<PointOfSalesGridFiltersInterface> = ({
  gridSearch,
  setGridSearch,

  isActive,
  setIsActive,

  remoto,
  setRemoto,
}): JSX.Element => {
  const [searchValue, setSearchValue] = useState("");
  const { isFocusWeb } = getPointOfSaleWhiteCache();

  useEffect(() => {
    const delayFn = setTimeout(() => {
      if (searchValue !== gridSearch) setGridSearch(searchValue);
    }, 250);

    return () => {
      clearTimeout(delayFn);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const getLabelTextField = useCallback(() => {
    return isFocusWeb ? "Cerca per punto vendita" : "Cerca per filiale";
  }, [isFocusWeb]);

  const setIsActiveCheckBox = useCallback(
    (_: SyntheticEvent<Element, Event>, checked: boolean) => {
      setIsActive(checked);
    },
    [setIsActive]
  );

  const setRemotoCheckBox = useCallback(
    (_: SyntheticEvent<Element, Event>, checked: boolean) => {
      setRemoto(checked);
    },
    [setRemoto]
  );

  return (
    <Stack
      direction={"row"}
      justifyContent="start"
      alignItems="end"
      spacing={1}
      sx={{ mt: "10px!important", mb: "15px!important" }}
    >
      <TextField
        label={getLabelTextField()}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchValue(e.target.value);
        }}
        fullWidth
        sx={{ maxWidth: 300 }}
        value={searchValue}
        InputProps={{
          endAdornment: searchValue.length ? (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                sx={{
                  p: 0,
                  top: 5,
                  right: 5,
                  position: "absolute",
                }}
                size="small"
                onClick={() => {
                  setSearchValue("");
                }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          ),
        }}
      />
      <FormControlLabel
        control={<Checkbox checked={isActive} />}
        onChange={setIsActiveCheckBox}
        label="Attivo"
      />
      <FormControlLabel
        control={<Checkbox checked={remoto} />}
        onChange={setRemotoCheckBox}
        label="Remoto"
      />
    </Stack>
  );
};
