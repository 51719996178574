import { Button, Stack } from "@mui/material"
import { memo, useCallback } from "react"
import { useFormContext, useFormState } from "react-hook-form"
import { Else, If, Then } from "react-if"
import { useDispatch } from "react-redux"
import { setOpenForm } from "../../../../store/root"
import { AppDispatch } from "../../../../../../store"
import {
  getServiceWhiteCache,
  setServiceWhiteCache
} from "../../../../lib/white-cache"
import { setFormValues } from "../../../../store/form"
import { BluAsyncDialog } from "@bludata/components"
import SaveIcon from "@mui/icons-material/Save"

interface ButtonsProps {
  initialValues?: any
  handleDelete: () => void
  handleSubmit: () => void
  openForm?: string
}

export const Buttons = memo(
  ({ initialValues, handleDelete, handleSubmit, openForm }: ButtonsProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const { isValid, errors } = useFormState()
    const { reset } = useFormContext()

    const submit = useCallback(() => {
      if (!isValid) {
        const temp = [
          ...new Set(
            Object.values(errors).map(
              ({ message }: any) => `<li>${message}</li>`
            )
          )
        ]

        // dispatch(setErrorPopup(true));
        BluAsyncDialog({
          title: "Attenzione",
          message: temp.join(""),
          confimButton: "Chiudi",
          hideDeclineButton: true,
          type: "warning",
          // maxWidth: false,
          sx: { "& .MuiDialog-paper": { maxWidth: "400px" } }
        })
        return
      }
      handleSubmit()
    }, [errors, handleSubmit, isValid])

    const handleCancel = useCallback(() => {
      const {
        pointOfSalesForm: previousPos,
        usersForm: previousUsers,
        defaultPointOfSales,
        defaultStudi,
        defaultUsers
      } = getServiceWhiteCache()

      dispatch(setOpenForm(undefined))
      // dispatch(setCurrentService(""));
      dispatch(
        setFormValues({
          pointOfSalesForm: previousPos,
          usersForm: previousUsers
        })
      )
      setServiceWhiteCache("initialPointOfSales", defaultPointOfSales)
      setServiceWhiteCache("initialStudi", defaultStudi)
      setServiceWhiteCache("initialUsers", defaultUsers)
    }, [dispatch])

    const handleErase = useCallback(() => {
      const { pointOfSalesForm: previousPos, usersForm: previousUsers } =
        getServiceWhiteCache()
      reset(initialValues)
      dispatch(
        setFormValues({
          pointOfSalesForm: previousPos,
          usersForm: previousUsers
        })
      )
    }, [dispatch, initialValues, reset])

    return (
      <Stack flex="1" flexDirection="row" gap="10px">
        <Stack flex="1">
          <If condition={openForm === "modify"}>
            <Then>
              <Button
                variant="contained"
                color="error"
                sx={{ width: "fit-content" }}
                onClick={() => handleDelete()}
              >
                Elimina
              </Button>
            </Then>
          </If>

          <If condition={openForm === "new"}>
            <Then>
              <Button
                variant="contained"
                color="error"
                sx={{ width: "fit-content" }}
                onClick={handleErase}
              >
                Azzera
              </Button>
            </Then>
          </If>
        </Stack>

        <If condition={openForm !== "info"}>
          <Then>
            <Button variant="outlined" onClick={handleCancel}>
              Annulla
            </Button>
            <Button
              startIcon={<SaveIcon />}
              variant="contained"
              // disabled={getDisableButton()}
              onClick={submit}
            >
              Salva
            </Button>
          </Then>
          <Else>
            <Button variant="outlined" onClick={handleCancel}>
              Chiudi
            </Button>
          </Else>
        </If>
      </Stack>
    )
  }
)
