import { TextField, Theme, ThemeProvider } from '@mui/material'
import {
  DesktopTimePicker,
  LocalizationProvider
} from '@mui/x-date-pickers-pro'
import dayjs from 'dayjs'
import React, { memo } from 'react'
import overrideBludataTheme from './theme'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePickerInterface } from './interface'

const BluTimePicker = memo(function BluTimePicker({
  value,
  onChange,
  format = 'HH:mm',
  textField,
  locale = 'it',
  ...rest
}: TimePickerInterface) {
  return (
    <ThemeProvider theme={(theme: Theme) => overrideBludataTheme(theme)}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <DesktopTimePicker
          inputFormat={format}
          value={dayjs(value)}
          onChange={onChange}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              {...textField}
              inputProps={{
                ...params.inputProps,
                ...textField?.inputProps
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    {params.InputProps.startAdornment}
                    {textField?.InputProps?.startAdornment}
                  </>
                ),
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    {textField?.InputProps?.endAdornment}
                  </>
                )
              }}
            />
          )}
          {...rest}
        />
      </LocalizationProvider>
    </ThemeProvider>
  )
})

export default BluTimePicker
