import { Stack, Typography } from "@mui/material";
import { FC, memo } from "react";
import { AppDispatch, RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { BluColorPicker, BluNumberField } from "@bludata/components";
import { setPaletteValue } from "../../store/palette";
import { setCustomThemeValue } from "../../store/custom-theme";
import hexToRgba from "hex-to-rgba";

interface ConfigBoxInterface {
  disabled: boolean;
}
export const ConfigBox: FC<ConfigBoxInterface> = memo(({ disabled }) => {
  const { card, primary } = useSelector(
    (state: RootState) => state.layoutPalette
  );
  const { rectangle } = useSelector(
    (state: RootState) => state.layoutCustomTheme
  );
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      <Stack display="flex" flex="1" sx={{ pr: "10px" }} gap="10px">
        <Stack
          gap="2%"
          flexWrap="wrap"
          sx={{ width: "100%" }}
          flexDirection="unset"
        >
          <Stack flex="1">
            <Typography>Sfondo</Typography>
            <BluColorPicker
              disabled={disabled}
              value={card.main}
              onChange={(args: any) =>
                dispatch(
                  setPaletteValue({
                    card: {
                      ...card,
                      main: args.currentValue.hex,
                    },
                  })
                )
              }
            />
          </Stack>
          <Stack flex="1">
            <Typography>Sfondo selezionato</Typography>
            <BluColorPicker
              disabled={disabled}
              value={card?.enable ?? hexToRgba(primary.main, 0.12)}
              onChange={(args: any) =>
                dispatch(
                  setPaletteValue({
                    card: {
                      ...card,
                      enable: args.currentValue.hex,
                    },
                  })
                )
              }
            />
          </Stack>
        </Stack>
        <Stack
          gap="2%"
          flexWrap="wrap"
          sx={{ width: "100%" }}
          flexDirection="unset"
        >
          <Stack flex="1">
            <Typography>Bordo</Typography>
            <BluColorPicker
              disabled={disabled}
              value={card.border}
              onChange={(args: any) =>
                dispatch(
                  setPaletteValue({
                    card: {
                      ...card,
                      border: args.currentValue.hex,
                    },
                  })
                )
              }
            />
          </Stack>
          <Stack flex="1">
            <Typography>Bordo selezionato</Typography>
            <BluColorPicker
              disabled={disabled}
              value={card?.enableBorder ?? primary.main}
              onChange={(args: any) =>
                dispatch(
                  setPaletteValue({
                    card: {
                      ...card,
                      enableBorder: args.currentValue.hex,
                    },
                  })
                )
              }
            />
          </Stack>
        </Stack>

        <Stack
          gap="2%"
          flexWrap="wrap"
          sx={{ width: "100%" }}
          flexDirection="unset"
        >
          <Stack flex="1">
            <Typography>Dimensione bordo (px)</Typography>
            <BluNumberField
              disabled={disabled}
              value={rectangle?.borderWidth}
              onChange={(event: any) =>
                dispatch(
                  setCustomThemeValue({
                    rectangle: {
                      ...rectangle,
                      borderWidth: event,
                    },
                  })
                )
              }
            />
          </Stack>
          <Stack flex="1">
            <Typography>Dimensione bordo selezionato (px)</Typography>
            <BluNumberField
              disabled={disabled}
              value={rectangle?.borderWidthEnable}
              onChange={(event: any) =>
                dispatch(
                  setCustomThemeValue({
                    rectangle: {
                      ...rectangle,
                      borderWidthEnable: event,
                    },
                  })
                )
              }
            />
          </Stack>
        </Stack>
        <Stack
          gap="2%"
          flexWrap="wrap"
          sx={{ width: "100%" }}
          flexDirection="unset"
        >
          <Stack flex="1">
            <Typography>Raggio bordo (px)</Typography>
            <BluNumberField
              disabled={disabled}
              value={rectangle?.borderRadius}
              onChange={(event: any) =>
                dispatch(
                  setCustomThemeValue({
                    rectangle: {
                      ...rectangle,
                      borderRadius: event,
                    },
                  })
                )
              }
            />
          </Stack>
          <Stack flex="1"></Stack>
        </Stack>
      </Stack>
    </>
  );
});
