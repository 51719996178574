export const Theme = {
  palette: {
    mode: "light",
    background: {
      paper: "#F8F8F8",
    },
    footer: {
      main: "#083269",
      text: "#ffffff",
    },
    landingTitle: {
      main: "rgba(25, 118, 210, 0.12)",
      text: "#2F80ED",
    },
    card: {
      main: "#ffffff",
      border: "#A9A9A9",
      // enable: "#A9A9A9",
    },
    primary: {
      main: "#1976D2",
    },
    warning: {
      main: "#ccdcec",
    },
    text: {
      primary: "#000000",
      disabled: "#a1a1a1",
    },
  },
  typography: {
    HtmlFontSize: "16px",
    fontFamily: ["Roboto"],
    titleLanding: {
      fontWeight: 700,
      fontSize: "2.25rem",
      textTransform: "none",
    },
    progressBarTitle: {
      fontWeight: 400,
      fontSize: "1.25rem",
      textTransform: "none",
    },
    pageTitle: {
      fontWeight: 700,
      fontSize: "1.625rem",
      textTransform: "none",
    },
    footerTitle: {
      fontWeight: 700,
      fontSize: "1.375rem",
      textTransform: "none",
    },
    footerContent: {
      fontWeight: 400,
      fontSize: "1rem",
      textTransform: "none",
    },
    contentTitle1: {
      fontWeight: 700,
      fontSize: "1.375rem",
      textTransform: "none",
    },
    contentTitle2: {
      fontWeight: 400,
      fontSize: "1.25rem",
      textTransform: "none",
    },
    contentDescription1: {
      fontWeight: 400,
      fontSize: "1.125rem",
      textTransform: "none",
    },
    contentDescription2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      textTransform: "none",
    },
    contentDescription3: {
      fontWeight: 400,
      fontSize: "0.75rem",
      textTransform: "none",
    },
    link: {
      fontWeight: 500,
      fontSize: "1.25rem",
      textTransform: "none",
    },
    recap: {
      fontWeight: 500,
      fontSize: "1rem",
      textTransform: "none",
    },
    button: {
      textTransform: "none",
      fontSize: "1.125rem",
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // backgroundColor: "#1976D2",
          borderRadius: "4px",
          padding: "0px 40px",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#485293",
            color: "#ffffff",
          },
        },
      },
      defaultProps: {
        disableElevation: false,
      },
    },
  },
};
