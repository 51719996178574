interface WhiteCacheInterface {
  isBluBookingActive: boolean;
  path: string;
  isFocusWeb: boolean;
  isFocus10: boolean;
  isConsole: boolean;
  isCrm: boolean;
}

let pointOfSaleWhiteCache: WhiteCacheInterface = {
  isBluBookingActive: true,
  path: "/api",
  isFocusWeb: false,
  isFocus10: false,
  isConsole: false,
  isCrm: false,
};

export const getPointOfSaleWhiteCache = () => pointOfSaleWhiteCache;
export const setPointOfSaleWhiteCache = (value: WhiteCacheInterface) => {
  pointOfSaleWhiteCache = value;
};

export const setKeyPointOfSaleWhiteCache = (
  key: keyof WhiteCacheInterface,
  value: any
) => {
  (pointOfSaleWhiteCache[key] as any) = value;
};

export const getPointOfSalePath = () => pointOfSaleWhiteCache.path;
