import { memo, useEffect } from "react";
import { setServiceData, setServiceLoad } from "../store/root";
import { store } from "../../../store";
import {
  usePointOfSaleUI,
  useService,
  useStudiUI,
  useUsersUI,
} from "../../../api";

export const InitServiceData = memo(function InitFilters(): null {
  const { isLoading: serviceLoading, data: services } = useService();
  const { isLoading: posLoading, data: pointOfSales } = usePointOfSaleUI();
  const { isLoading: studioLoading, data: studi } = useStudiUI();
  const { isLoading: userLoading, data: users } = useUsersUI();

  useEffect(() => {
    if (!serviceLoading && !userLoading && !posLoading && !studioLoading) {
      try {
        store.dispatch(
          setServiceData({
            services,
            pointOfSales,
            studi,
            users,
            serviceLoad: false,
          })
        );
      } catch (e) {
        console.error(e);
        store.dispatch(setServiceLoad(false));
      }
    }
  }, [pointOfSales, posLoading, serviceLoading, services, studi, studioLoading, userLoading, users]);

  return null;
});
