import { BluAutoComplete } from "@bludata/components";
import { Stack, InputLabel, Box } from "@mui/material";
import { nanoid } from "nanoid";
import { FC, memo, useCallback } from "react";
import { setPointOfSaleId, setPointOfSaleName } from "../../store/root";
import { AppDispatch, RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import PointOfSale_interface from "../../../nuova-point-of-sales/interfaces/PointOfSale_interface";

interface FilialeInterface {
  data?: PointOfSale_interface[];
}

export const Filiale: FC<FilialeInterface> = memo(({ data }) => {
  const { pointOfSaleId } = useSelector((state: RootState) => state.rotaRoot);
  // const { data, isLoading } = usePointOfSaleCalendar();
  const dispatch = useDispatch<AppDispatch>();

  // useEffect(() => {
  //   dispatch(setIsLoading({ key: "pointOfSales", value: isLoading }));
  // }, [dispatch, isLoading]);

  const getValueOfPointOfSales = useCallback(() => {
    if (pointOfSaleId && data) {
      const pointOfSale = data.find((d) => d.id === pointOfSaleId);
      if (pointOfSale) {
        return pointOfSale;
      }
      if (data.length > 0) return data[0];
    }
    return null;
  }, [data, pointOfSaleId]);
  return (
    <Stack sx={{ width: "200px" }}>
      <InputLabel>Punto vendita</InputLabel>

      <BluAutoComplete
        // nullable
        size="small"
        sx={{
          minWidth: 200,
          "& .MuiInput-input": {
            pl: "0px !important",
          },
        }}
        disableClearable
        renderOption={(props: any, option: any) => {
          return (
            <Box component="li" {...props} key={nanoid()}>
              {option.branch}
            </Box>
          );
        }}
        getOptionLabel={(option: any) => {
          return option.branch;
        }}
        data={data ?? []}
        value={getValueOfPointOfSales()}
        onChange={(_: any, newValue: any) => {
          dispatch(setPointOfSaleId(newValue.id));
          dispatch(setPointOfSaleName(newValue.branch));
        }}
        loading={false}
        isOptionEqualToValue={(option: any, value: any) => {
          return option && value && option.id === value.id;
        }}
      />
    </Stack>
  );
});
