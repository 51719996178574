import { MenuItem, Stack, Typography } from "@mui/material";
import { FC, memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { BluSelect } from "@bludata/components";

interface UserRolesInterface {
  disabled: boolean;
}

export const UserRoles: FC<UserRolesInterface> = memo(({ disabled }) => {
  const userRolesEnum = useWatch({ name: "userRolesEnum" });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const profili = [
    {
      label: "Utente",
      value: 0,
    },
    {
      label: "Amministratore",
      value: 2,
    },
  ];
  const { setValue } = useFormContext();

  const handleOnChange = useCallback(
    (event: any) => {
      setValue("userRolesEnum", event.target.value);
    },
    [setValue]
  );

  return (
    <Stack sx={{ width: "200px" }}>
      <Typography>{"Profilo"}</Typography>
      <Stack flex="1" direction="row">
        <BluSelect
          multiple={false}
          disabled={disabled}
          value={userRolesEnum}
          onChange={handleOnChange}
          renderValue={(selected: any) => {
            if (!selected) return <>{profili[0].label}</>;
            if (selected === 2) return <>{profili[1].label}</>;
            return <>{"Seleziona un profilo"}</>;
          }}
        >
          {profili.map((profilo: any) => (
            <MenuItem value={profilo.value}>{profilo.label}</MenuItem>
          ))}
        </BluSelect>
      </Stack>
    </Stack>
  );
});
