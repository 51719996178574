import {
  FC,
  Fragment,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { BluTimePicker } from "@bludata/components";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Fade,
  IconButton,
  Menu,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { useAppSelector } from "../../../../../../store";
import { defaultHourValueError } from "../../../../store/default-hours";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";

export const DefaultTimeComponent: FC<{
  start: Dayjs;
  end: Dayjs;
  disabled: boolean;
  setValue: (startTime: Dayjs | null, endTime: Dayjs | null) => void;
  title: string;
  pointOfSaleId: string;
  turniType: "shift" | "calendar";
  day: string;
  rangeNumber: 1 | 2;
}> = memo(
  ({
    start,
    end,
    disabled,
    setValue,
    title,
    turniType,
    day,
    rangeNumber,
    pointOfSaleId,
  }): JSX.Element => {
    const [startTime, setStartTime] = useState<Dayjs | null>(start);
    const [endTime, setEndTime] = useState<Dayjs | null>(end);
    const [openStart, setOpenStart] = useState<boolean>(false);
    const [openEnd, setOpenEnd] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const error = useAppSelector((state) =>
      defaultHourValueError(state, turniType, day, rangeNumber, pointOfSaleId)
    );

    const openPicker = Boolean(anchorEl);
    const ref = useRef<any>(null);

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLElement>): void => {
        if (disabled) return;
        setAnchorEl(event?.currentTarget);
        event.stopPropagation();
      },
      [disabled]
    );

    const handleClose = useCallback(
      (event: any): void => {
        setAnchorEl(null);
        setValue(startTime, endTime);
        event.stopPropagation();
      },
      [endTime, setValue, startTime]
    );

    const StartTimePicker = useMemo(() => {
      return (
        <BluTimePicker
          value={startTime}
          onChange={(newValue: any) => {
            setStartTime(newValue);
          }}
          open={openStart}
          onClose={() => setOpenStart(false)}
          renderInput={(params: any) => (
            <TextField
              {...params}
              sx={{
                borderWidth: "0px !important",
                width: "92px",
                "& .MuiInput-root": {
                  borderWidth: "0px !important",
                },
              }}
              placeholder="HH:mm"
              inputProps={{
                ...params.inputProps,
                style: { textAlign: "right" },
              }}
              InputProps={{
                startAdornment: (
                  <IconButton
                    sx={{ height: "25px", width: "25px" }}
                    onClick={() => {
                      setOpenStart(!openStart);
                      setOpenEnd(false);
                    }}
                  >
                    <AccessTimeIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      );
    }, [openStart, startTime]);

    const EndTimePicker = useMemo(() => {
      return (
        <BluTimePicker
          value={endTime}
          onChange={(newValue: any) => {
            setEndTime(newValue);
          }}
          open={openEnd}
          onClose={() => setOpenEnd(false)}
          renderInput={(params: any) => (
            <TextField
              {...params}
              sx={{
                borderWidth: "0px !important",
                width: "92px",
                "& .MuiInput-root": {
                  borderWidth: "0px !important",
                },
              }}
              inputProps={{
                ...params.inputProps,
                style: { textAlign: "left" },
              }}
              placeholder="HH:mm"
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ height: "25px", width: "25px" }}
                    onClick={() => {
                      setOpenEnd(!openEnd);
                      setOpenStart(false);
                    }}
                  >
                    <AccessTimeIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      );
    }, [endTime, openEnd]);

    const GetPicker = useMemo(() => {
      return (
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={openPicker}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          elevation={0}
          sx={{ "& .MuiMenu-list": { p: 0 } }}
        >
          <Stack
            flex="1"
            direction="row"
            sx={{ width: ref?.current?.clientWidth ?? "auto" }}
            gap={1}
          >
            {StartTimePicker}
            <Typography sx={{ pt: "3px" }}>{"-"}</Typography>
            {EndTimePicker}
          </Stack>
        </Menu>
      );
    }, [EndTimePicker, StartTimePicker, anchorEl, handleClose, openPicker]);

    const GetContent = useMemo(() => {
      return (
        <Stack
          id={title}
          key={title}
          ref={ref}
          flex="1"
          direction="row"
          gap={1}
          sx={{
            cursor: disabled ? "auto" : "pointer",
            boxSizing: "border-box",
            borderBottom:
              error && anchorEl === null ? "1px solid red" : undefined,
          }}
          onClick={handleClick}
        >
          <Typography
            fontSize="1rem"
            sx={{
              p: "4.5px 0",
              pl: error ? "30px" : undefined,
              width: "100%",
              textAlign: "right",
            }}
          >
            {anchorEl === null ? startTime?.format("HH:mm") ?? "" : ""}
          </Typography>
          <Typography sx={{ pt: "3px" }}>{"-"}</Typography>
          <Typography
            fontSize="1rem"
            sx={{
              p: "4.5px 0",
              width: "100%",
              textAlign: "left",
            }}
          >
            {anchorEl === null ? endTime?.format("HH:mm") ?? "" : ""}
          </Typography>
          {error && (
            <Tooltip title={"Turno non valido"} sx={{ pt: "5px" }}>
              <ErrorTwoToneIcon color="error" />
            </Tooltip>
          )}
          {GetPicker}
        </Stack>
      );
    }, [
      GetPicker,
      anchorEl,
      disabled,
      endTime,
      error,
      handleClick,
      startTime,
      title,
    ]);

    return <>{GetContent}</>;
  }
);
