import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { nanoid } from "@reduxjs/toolkit";
import { ShopSignHolidayInterface } from "../../../interfaces/shop-sign";
import { defaultHolidays, setInitialHoliday } from "../utils/default-holidays";

const getShopSignHolidays = async (
  shopSignId: string
): Promise<ShopSignHolidayInterface[]> => {
  let result = defaultHolidays;
  try {
    const {
      data: { data },
    } = await axios.get(`/api/Holiday`);

    result = data.map((row: any) => {
      return {
        ...row,
        internalId: nanoid(),
      };
    });
    setInitialHoliday(result);
    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useShopSignHolidays = (
  shopSignId: string = "",
  key: string = ""
) => {
  return useQuery({
    queryFn: async () => await getShopSignHolidays(shopSignId),
    queryKey: ["shop-sign", "holiday", shopSignId, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
