import { BluNumberField, BluSelect } from "@bludata/components";
import { MenuItem, Stack, Typography } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setFontValue } from "../../store/font";
import { AppDispatch } from "../../../../store";

interface FontConfigurationInterface {
  id: string;
  disabled: boolean;
  [x: string]: any;
}

export const FontConfiguration = memo(
  ({ id, disabled, font }: FontConfigurationInterface) => {
    const fontFamily = [
      "Roboto",
      "sans-serif",
      "Poppins",
      "Helvetica",
      "Arial",
      "Comfortaa",
      "Montserrat",
      "Noto Sans Georgian",
      "Source Sans Pro",
      "Work Sans",
    ];

    const textTrasforms: any = {
      // capitalize: '',
      lowercase: "Minuscolo",
      uppercase: "Maiuscolo",
      none: "Normale",
    };

    const dispatch = useDispatch<AppDispatch>();

    const handleFontFamilyChange = useCallback(
      (event: any) => {
        if (event.target.value === "globale") {
          dispatch(
            setFontValue({
              [id]: {
                ...font,
                fontFamily: undefined,
              },
            })
          );
          return;
        }
        dispatch(
          setFontValue({
            [id]: {
              ...font,
              fontFamily: event.target.value,
            },
          })
        );
      },
      [dispatch, font, id]
    );

    const handleFontWeightChange = useCallback(
      (event: any) =>
        dispatch(
          setFontValue({
            [id]: {
              ...font,
              fontWeight: event.target.value,
            },
          })
        ),
      [dispatch, font, id]
    );

    const handleFontSizeChange = useCallback(
      (event: any) => {
        let data = "";
        if (event) data = `${event / 16}rem`;
        dispatch(
          setFontValue({
            [id]: {
              ...font,
              fontSize: data,
            },
          })
        );
      },
      [dispatch, font, id]
    );

    const handleTextTraformChange = useCallback(
      (event: any) => {
        dispatch(
          setFontValue({
            [id]: {
              ...font,
              textTransform: event.target.value,
            },
          })
        );
      },
      [dispatch, font, id]
    );

    const Size = useMemo(() => {
      let value = font.fontSize.replace("rem", "");

      try {
        if (value !== "") value = parseFloat(value) * 16;
      } catch (err) {
        console.error(err);
      }
      return (
        <Stack flex="1">
          <Typography>Dimensione (px)</Typography>
          <BluNumberField
            disabled={disabled}
            value={value}
            onChange={handleFontSizeChange}
            decimals={2}
          />
        </Stack>
      );
    }, [disabled, font.fontSize, handleFontSizeChange]);

    const getFontFamily = useCallback(() => {
      return font?.fontFamily ?? "globale";
    }, [font.fontFamily]);

    return (
      <>
        <Stack
          gap="10px"
          // spacing="10px"
          flexWrap="wrap"
          sx={{ width: "100%" }}
          flexDirection="unset"
        >
          <Stack flex="1" sx={{ minWidth: "170px" }}>
            <Typography>Formazione paragrafi</Typography>
            <BluSelect
              disabled={disabled}
              displayEmpty
              value={getFontFamily()}
              renderValue={(selected: any) =>
                selected !== undefined ? <>{selected}</> : <>Globale</>
              }
              onChange={handleFontFamilyChange}
            >
              {fontFamily.map((f) => (
                <MenuItem key={nanoid()} value={f}>
                  {f}
                </MenuItem>
              ))}
              <MenuItem value="globale">Globale</MenuItem>
              <MenuItem value={undefined} hidden />
            </BluSelect>
          </Stack>
          <Stack flex="1" sx={{ minWidth: "100px" }}>
            <Typography>Grassetto</Typography>

            <BluSelect
              disabled={disabled}
              value={font.fontWeight}
              onChange={handleFontWeightChange}
              renderValue={(selected: any) => {
                switch (selected) {
                  case 300:
                    return <>Fino</>;
                  case 400:
                    return <>Leggermente fino</>;
                  case 500:
                    return <>Normale</>;
                  case 700:
                    return <>Grassetto</>;
                  default:
                    return <>Normale</>;
                }
              }}
            >
              <MenuItem key={nanoid()} value={300}>
                Fino
              </MenuItem>
              <MenuItem key={nanoid()} value={400}>
                Leggermente fino
              </MenuItem>
              <MenuItem key={nanoid()} value={500}>
                Normale
              </MenuItem>
              <MenuItem key={nanoid()} value={700}>
                Grassetto
              </MenuItem>
            </BluSelect>
          </Stack>
          {Size}
          <Stack flex="1" sx={{ minWidth: "170px" }}>
            <Typography>Trasformazione testo</Typography>
            <BluSelect
              disabled={disabled}
              displayEmpty
              value={font?.textTransform ?? "none"}
              renderValue={(selected: any) =>
                selected !== undefined ? (
                  <>{textTrasforms[selected]}</>
                ) : (
                  <>Normale</>
                )
              }
              onChange={handleTextTraformChange}
            >
              {Object.keys(textTrasforms).map((key: any) => (
                <MenuItem key={key} value={key}>
                  {textTrasforms[key]}
                </MenuItem>
              ))}
            </BluSelect>
          </Stack>
        </Stack>
      </>
    );
  }
);
