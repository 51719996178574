import { BluAutoComplete } from "@bludata/components";
import { Stack, InputLabel, Box } from "@mui/material";
import { nanoid } from "nanoid";
import { FC, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../../../../store";
import { setPointOfSaleId } from "../../../../store/import";
import PointOfSale_interface from "../../../../../nuova-point-of-sales/interfaces/PointOfSale_interface";

interface PointOfSaleInterface {
  pointOfSales?: PointOfSale_interface[];
}

export const PointOfSaleImport: FC<PointOfSaleInterface> = memo(
  ({ pointOfSales }) => {
    const { pointOfSaleId } = useSelector(
      (state: RootState) => state.rotaImport
    );
    const dispatch = useDispatch<AppDispatch>();

    const getValueOfPointOfSales = useCallback(() => {
      if (pointOfSaleId && pointOfSales) {
        const pointOfSale = pointOfSales.find(
          (d: any) => d.id === pointOfSaleId
        );
        if (pointOfSale) {
          return pointOfSale;
        }
        if (pointOfSales.length > 0) return pointOfSales[0];
      }
      return null;
    }, [pointOfSales, pointOfSaleId]);

    return (
      <Stack sx={{ pt: 1 }}>
        <InputLabel>Punto vendita</InputLabel>

        <BluAutoComplete
          // nullable
          size="small"
          disableClearable
          renderOption={(props: any, option: any) => {
            return (
              <Box component="li" {...props} key={nanoid()}>
                {option.branch}
              </Box>
            );
          }}
          getOptionLabel={(option: any) => {
            return option.branch;
          }}
          data={pointOfSales ?? []}
          value={getValueOfPointOfSales()}
          onChange={(_: any, newValue: any) => {
            dispatch(setPointOfSaleId(newValue.id));
          }}
          loading={false}
          isOptionEqualToValue={(option: any, value: any) => {
            return option && value && option.id === value.id;
          }}
        />
      </Stack>
    );
  }
);
