import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ShopSignInterface } from "../../../interfaces/shop-sign";
import { getPointOfSalePath } from "../lib/white-cache";

interface ShopSignObject {
  [x: string]: ShopSignInterface;
}

export const fetchShopSigns = async (): Promise<ShopSignInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get(`${getPointOfSalePath()}/ShopSign/Basic`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const useShopSignBasic = (key: string = "") => {
  return useQuery({
    queryFn: async () => await fetchShopSigns(),
    queryKey: ["basic", "shop-sign", key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (data: any): ShopSignObject => {
  const result: any = {};
  for (const shopSign of data) {
    result[shopSign.id] = {
      ...shopSign,
    };
  }
  return result;
};
