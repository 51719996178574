import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import React from "react";
import { FC, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store";
import { setPointOfSaleValue, setStudiValue } from "../../../../store/form";

interface CheckboxGroupInterface {
  pointOfSaleId: string;
  pointOfSaleName: string;
  studi: any;
}

export const CheckboxGroup: FC<CheckboxGroupInterface> = memo(
  ({ pointOfSaleId, pointOfSaleName, studi }) => {
    const { pointOfSalesForm } = useSelector(
      (state: RootState) => state.serviceForm
    );

    console.log("CheckboxGroup", studi, pointOfSaleName);

    const dispatch = useDispatch<AppDispatch>();
    const { openForm } = useSelector((state: RootState) => state.serviceRoot);

    const isParentChecked = useCallback(() => {
      return pointOfSalesForm[pointOfSaleId].enable;
    }, [pointOfSaleId, pointOfSalesForm]);

    const handleChildChange = useCallback(
      (studioId: string) => {
        dispatch(setStudiValue({ pointOfSaleId, studioId }));
      },
      [dispatch, pointOfSaleId]
    );

    const handleParentChange = useCallback(() => {
      dispatch(setPointOfSaleValue(pointOfSaleId));
    }, [dispatch, pointOfSaleId]);

    return (
      <div style={{ width: "fit-content", padding: "0 8px 10px 8px" }}>
        <FormControlLabel
          label={pointOfSaleName}
          control={
            <Checkbox
              checked={isParentChecked()}
              disabled={openForm === "info"}
              sx={{ p: 0 }}
              onChange={handleParentChange}
            />
          }
        />
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
          {Object.values(studi).map(({ id, name, enable }: any) => {
            return (
              <FormControlLabel
                key={nanoid()}
                label={name}
                disabled={openForm === "info"}
                control={
                  <Checkbox
                    sx={{ p: "2px" }}
                    checked={enable}
                    onChange={() => handleChildChange(id)}
                  />
                }
              />
            );
          })}
        </Box>
      </div>
    );
  }
);
