import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { getPointOfSaleWhiteCache } from "../lib/white-cache";
import { PointOfSalesInterface } from "../interfaces/PointOfSale_interface";
import { defaultPointOfSaleFw } from "../components/PopUp/utils/default-point-of-sale-fw";
import { defaultPointOfSaleBB } from "../components/PopUp/utils/default-point-of-sale-bb";

const fetchPointOfSales = async (
  pointOfSaleId?: string,
  mode?: "modify" | "info" | "new"
): Promise<PointOfSalesInterface | null> => {
  const { isFocusWeb } = getPointOfSaleWhiteCache();
  const url = isFocusWeb
    ? `/focusapi/global/PointOfSale/${pointOfSaleId}`
    : `/api/PointOfSale/${pointOfSaleId}`;

  if (mode === "new") {
    if (isFocusWeb) return defaultPointOfSaleFw;
    return defaultPointOfSaleBB;
  }
  try {
    const { data } = await axios.get(url);

    if (isFocusWeb)
      return {
        ...data,
        id: data.codiceFiliale,
        pointOfSaleId: data.blubookingId,
        name: data.businessName || data.name,
        branch: data.branchName || data.branch,
      };

    return { ...data.data, pointOfSaleId: data.data.id };
  } catch (error: any) {
    return null;
  }
};

export const usePointOfSale = (
  pointOfSaleId?: string,
  mode?: "modify" | "info" | "new",
  key: string = ""
) => {
  return useQuery({
    queryFn: async () => await fetchPointOfSales(pointOfSaleId, mode),
    queryKey: ["point-of-sales", pointOfSaleId, mode, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    // select: normalize,
  });
};
