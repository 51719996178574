import { BluTextField } from "@bludata/components";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

export const SmallDescription = memo(() => {
  const watch = useWatch({ name: "smallDescription" });
  const { setValue, trigger } = useFormContext();
  const { openForm } = useSelector((state: RootState) => state.serviceRoot);

  return (
    <Stack flex="1">
      <Typography variant="subtitle2" sx={{ p: "3px 0px 0 0" }}>
        Descrizione servizio (uso interno)
      </Typography>
      <BluTextField
        value={watch}
        name="SmallDescription"
        onChange={(event: any) => {
          setValue("smallDescription", event.target.value);
          trigger();
        }}
        disabled={openForm === "info"}
      />
    </Stack>
  );
});
