import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { getInitialForm } from "./utils/initial";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema/schema";
import { AppDispatch, RootState, useAppSelector } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  isFormLoading,
  setOpenPasswordDialog,
  setUsersOpenForm,
} from "../../store/root";
import { BluAsyncDialog, BluDialog, BluLoader } from "@bludata/components";
import { Stack, Button, Card } from "@mui/material";
import { If, Then, Else } from "react-if";
import SaveIcon from "@mui/icons-material/Save";
import { useQueryClient } from "@tanstack/react-query";
import {
  hasErrordefaultHourValue,
  resetDefaultHours,
} from "../../store/default-hours";
import {
  hasErrorExtraHourValue,
  resetExtraHours,
} from "../../store/extra-hours";
import { getUsersWhiteCache } from "../../lib/white-cache";
import User_interface from "../../interfaces/User_interface";
import { ImpostazioniGenerali } from "./features/ImpostazioniGenerali/ImpostazioniGenerali";
import { GoogleCalendar } from "./features/GoogleCalendar/GoogleCalendar";
import { Contact } from "./features/Contact/Contact";
import { Indirizzo } from "./features/Indirizzo/Indirizzo";
import { OtherInfo } from "./features/OtherInfo/OtherInfo";
import { AppSelection } from "./features/AppSection/AppSelection";
import { IMOActivation } from "./features/IMOActivation/IMOActivation";
import { UserHours } from "./features/Hours/Hours";
import PasswordDialog from "./features/PasswordDialog/PasswordDialog";
import LockIcon from "@mui/icons-material/Lock";
import { createUser } from "../../api/create-user";
import { updateUser } from "../../api/update-user";
import { AgendaUser } from "./features/Agenda/Agenda";
import { TwoFactorAuthetication } from "./features/TwoFactorAuthentication/TwoFactorAuthentication";
import { bluBookingLoginData } from "../../../../store/slices/root";

interface UserPopUpFormInterface {
  user: User_interface;
  mode: "modify" | "info" | "new";
}

export const UserPopUpForm: FC<UserPopUpFormInterface> = memo(
  ({ user, mode }) => {
    const initialFormData = getInitialForm(user);
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const queryClient = useQueryClient();
    const errorDefaultHours = useAppSelector(hasErrordefaultHourValue);
    const errorExtraHours = useAppSelector(hasErrorExtraHourValue);
    const isLoadingForm = useAppSelector(isFormLoading);
    const { isFocusWeb } = getUsersWhiteCache();

    const { services, currentUser } = useSelector(
      (state: RootState) => state.newUsersRoot
    );
    const { defaultHours } = useSelector(
      (state: RootState) => state.newUserDefaultHours
    );
    const { isSingleCalendarManagement } = useAppSelector(bluBookingLoginData);
    const { isFocus10 } = getUsersWhiteCache();
    const {
      extra,

      // updateExtraHoursCalendar,
      // updateExtraHoursShift,
    } = useSelector((state: RootState) => state.newUserExtraHours);

    const methods = useForm({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: initialFormData,
      resolver: yupResolver(schema),
    });

    const { errors } = methods.formState;
    const { watch, trigger } = methods;

    useEffect(() => {
      if (errorMessage)
        BluAsyncDialog({
          title: "Attenzione!",
          message: `${Object.values(errors)
            .map(({ message }: any) => `<li>${message}</li>`)
            .join("")}${
            errorDefaultHours ? "<li>Orario di apertura non valido</li>" : ""
          }${
            errorExtraHours ? "<li>Orario straordinario non valido</li>" : ""
          }${
            password === "" && mode === "new"
              ? "<li>La password è obbligatoria</li>"
              : ""
          }`,
          confimButton: "Chiudi",
          hideDeclineButton: true,
          type: "warning",
          sx: { "& .MuiDialog-paper": { maxWidth: "370px" } },
          onConfirmClick: () => setErrorMessage(false),
        });
    }, [
      errorDefaultHours,
      errorExtraHours,
      errorMessage,
      errors,
      mode,
      password,
    ]);

    const cancel = useCallback(() => {
      dispatch(setUsersOpenForm(undefined));
      dispatch(resetDefaultHours());
      dispatch(resetExtraHours());
    }, [dispatch]);

    const save = useCallback(async () => {
      setLoading(true);
      const trig = await trigger();

      if (
        !trig ||
        errorDefaultHours ||
        errorExtraHours ||
        (mode === "new" && (!password || password === ""))
      ) {
        setLoading(false);
        setErrorMessage(true);
        return;
      }
      const data = watch();
      let result: boolean = false;
      if (mode === "new") {
        result = await createUser(
          password,
          isSingleCalendarManagement,
          data,
          services,
          defaultHours,
          extra
        );
      } else
        result = await updateUser(
          currentUser,
          password,
          isSingleCalendarManagement,
          data,
          services,
          defaultHours,
          extra
        );
      setLoading(false);
      if (result) {
        dispatch(setUsersOpenForm(undefined));
        dispatch(resetDefaultHours());
        dispatch(resetExtraHours());
        queryClient.invalidateQueries(["user"], {
          type: "active",
        });
        queryClient.removeQueries(["user"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["users"], {
          type: "active",
        });
        queryClient.removeQueries(["users"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["codice-attivazione"], {
          type: "active",
        });
        queryClient.removeQueries(["codice-attivazione"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["lista-filiali"], {
          type: "active",
        });
        queryClient.removeQueries(["lista-filiali"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["profile"], {
          type: "active",
        });
        queryClient.removeQueries(["profile"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["layout-crm"], {
          type: "active",
        });
        queryClient.removeQueries(["layout-crm"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["google-calendar-sync"], {
          type: "active",
        });
        queryClient.removeQueries(["google-calendar-sync"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["service", "user"], {
          type: "active",
        });
        queryClient.removeQueries(["service", "user"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["turni-default-user"], {
          type: "active",
        });
        queryClient.removeQueries(["turni-default-user"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["turni-extra-user"], {
          type: "active",
        });
        queryClient.removeQueries(["turni-extra-user"], {
          type: "inactive",
        });
        return;
      }
      BluAsyncDialog({
        title: "Attenzione",
        type: "error",
        message:
          mode === "new"
            ? "Errore durante la creazione dell'utente."
            : "Errore durante la modifica dell'utente.",
        hideDeclineButton: true,
        confimButton: "Chiudi",
        sx: { "& .MuiDialog-paper": { maxWidth: "370px" } },
      });
    }, [
      currentUser,
      defaultHours,

      dispatch,
      errorDefaultHours,
      errorExtraHours,
      extra,
      isSingleCalendarManagement,
      mode,
      password,
      queryClient,
      services,
      trigger,
      watch,
    ]);

    const ChangePasswordButton = useMemo(() => {
      if (isFocus10) return <></>;
      return (
        <Button
          color="primary"
          startIcon={<LockIcon />}
          sx={{ width: "fit-content" }}
          onClick={() => {
            dispatch(setOpenPasswordDialog(true));
          }}
        >
          {mode === "new" ? "Imposta password" : "Cambia password"}
        </Button>
      );
    }, [dispatch, isFocus10, mode]);

    return (
      <FormProvider {...methods}>
        <form id="creationForm" style={{ width: "inherit" }}>
          <BluDialog
            className="service-popup"
            open={true}
            maxWidth="lg"
            zIndex={200}
            draggable
            dialogTitle={"Utente"}
            dialogContent={
              <Stack flex="1" sx={{ p: "10px" }} gap={1}>
                <PasswordDialog setPassword={setPassword} />
                <BluLoader
                  open={loading || isLoadingForm}
                  sx={{ zIndex: "1400" }}
                />
                <Card variant="outlined" sx={{ p: 1 }}>
                  <Stack flex="1" gap="40px">
                    <ImpostazioniGenerali mode={mode} />
                    <Stack direction="row" gap={1}>
                      <AgendaUser mode={mode} />
                      <GoogleCalendar />
                    </Stack>
                    <Stack direction="row" gap={1}>
                      {isFocusWeb && <Indirizzo mode={mode} />}
                      <Contact mode={mode} />
                    </Stack>
                    <OtherInfo mode={mode} />
                    {isFocusWeb && <AppSelection mode={mode} />}
                    {isFocusWeb && (
                      <Stack direction="row" gap={1}>
                        <IMOActivation mode={mode} />
                        <TwoFactorAuthetication
                          disabled={mode === "info" || isFocus10}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Card>
                <UserHours mode={mode} />
              </Stack>
            }
            sx={{
              "& .MuiDialogContent-root": {
                p: 0,
              },
              zIndex: 200,
            }}
            dialogActions={
              <Stack flexDirection="row" flex="1" gap={1}>
                <If condition={mode !== "info"}>
                  <Then>
                    <Stack flex="1">{ChangePasswordButton}</Stack>
                    <Button variant="outlined" onClick={cancel}>
                      Annulla
                    </Button>
                    <Button
                      startIcon={<SaveIcon />}
                      variant="contained"
                      onClick={save}
                      // disabled={getDisableButton()}
                    >
                      Salva
                    </Button>
                  </Then>
                  <Else>
                    <Stack flex="1"></Stack>
                    <Button variant="outlined" onClick={cancel}>
                      Chiudi
                    </Button>
                  </Else>
                </If>
              </Stack>
            }
          />
        </form>
      </FormProvider>
    );
  }
);
