import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import React from "react";
import { RootState } from "../../../../../../store";

export const GridFooter = memo(() => {
  const { usersForm } = useSelector((state: RootState) => state.serviceForm);

  const getEnabledCount = useCallback(() => {
    return Object.values(usersForm).reduce((prev: number, val: any) => {
      if (val.enable) return prev + 1;
      return prev;
    }, 0);
  }, [usersForm]);

  const getTotalCount = useCallback(() => {
    return Object.keys(usersForm).length;
  }, [usersForm]);

  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
        borderTop: "1px solid var(--divider-color)",
        p: "1px 25px 1px 15px",
      }}
    >
      <Stack display="flex" flexDirection="row">
        <Stack display="flex" flex={1}></Stack>
        <span style={{ textAlign: "center", padding: "6px 0px" }}>
          Abilitati {getEnabledCount()} su {getTotalCount()}
        </span>
      </Stack>
    </Box>
  );
});
