import { nanoid } from "@reduxjs/toolkit";
import { ShopSignHolidayInterface } from "../../../interfaces/shop-sign";

export const defaultHolidays: ShopSignHolidayInterface[] = [
  {
    name: "Anniversario della liberazione d'Italia",
    day: 25,
    month: 4,
    internalId: nanoid(),
  },
  {
    name: "Festa dei lavoratori",
    day: 1,
    month: 5,
    internalId: nanoid(),
  },
  {
    name: "Natale",
    day: 25,
    month: 12,
    internalId: nanoid(),
  },
];

let initialHoliday = [...defaultHolidays];

export const setInitialHoliday = (value: ShopSignHolidayInterface[]) => {
  initialHoliday = value;
};

export const getInitialHoliday = () => initialHoliday;

export const resetHoliday = () => {
  initialHoliday = [...defaultHolidays];
};
