import { memo, useCallback } from "react";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import {
  Button,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";

export const Test = memo(() => {
  const palette = useSelector((state: RootState) => state.layoutPalette);
  const font = useSelector((state: RootState) => state.layoutFont);
  const components = useSelector((state: RootState) => state.layoutComponents);

  const getTheme = useCallback(() => {
    return createTheme({ palette, typography: font, components });
  }, [components, font, palette]);

  return (
    <Stack
      justifyContent="center"
      sx={{ width: "100%", minHeight: "200px", height: "100%" }}
    >
      <ThemeProvider theme={getTheme()}>
        <div style={{ margin: "auto" }}>
          <Button
            variant="contained"
            sx={{
              width: "fit-content",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            Conferma e...
          </Button>
        </div>
      </ThemeProvider>
    </Stack>
  );
});
