import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TurniExtraInterface,
  TurniExtraItemInterface,
} from "../interfaces/extra-turni-point-of-sale";
import {
  checkAllExtraHours,
  checkextraHoursValue,
} from "../components/PopUp/utils/check-extra-hours";
import { RootState } from "../../../store";
import { Dayjs } from "dayjs";

export interface ExtraHoursInterface {
  extra: TurniExtraInterface;
  extraErrors: {
    [key: string]: {
      shift: {
        range1: boolean;
        range2: boolean;
      };
      calendar: {
        range1: boolean;
        range2: boolean;
      };
    };
  };

  openAddPeriodPopUp: boolean;
}

const initialState: ExtraHoursInterface = {
  extra: {},
  extraErrors: {},
  openAddPeriodPopUp: false,
};

const PointOfSaleExtraHoursSlice = createSlice({
  name: "PointOfSaleExtraHours",
  initialState,
  reducers: {
    setExtraHours(
      state: ExtraHoursInterface,
      action: PayloadAction<{ value: TurniExtraInterface; keyChanged?: string }>
    ) {
      state.extra = action.payload.value;
      if (action.payload.keyChanged) {
        (state.extraErrors as any)[action.payload.keyChanged]["shift"].range1 =
          checkextraHoursValue(
            1,
            "shift",
            action.payload.value[action.payload.keyChanged]
          );
        (state.extraErrors as any)[action.payload.keyChanged]["shift"].range2 =
          checkextraHoursValue(
            2,
            "shift",
            action.payload.value[action.payload.keyChanged]
          );

        (state.extraErrors as any)[action.payload.keyChanged][
          "calendar"
        ].range1 = checkextraHoursValue(
          1,
          "calendar",
          action.payload.value[action.payload.keyChanged]
        );
        (state.extraErrors as any)[action.payload.keyChanged][
          "calendar"
        ].range2 = checkextraHoursValue(
          2,
          "calendar",
          action.payload.value[action.payload.keyChanged]
        );
      } else state.extraErrors = checkAllExtraHours(action.payload.value);
    },
    setExtraHourValue(
      state: ExtraHoursInterface,
      action: PayloadAction<{
        value: {
          date: Dayjs;
          shift: TurniExtraItemInterface | null;
          calendar: TurniExtraItemInterface | null;
        };
        keyChanged: string;
        turniType?: "calendar" | "shift";
      }>
    ) {
      (state.extra as any)[action.payload.keyChanged] = action.payload.value;

      (state.extraErrors as any)[action.payload.keyChanged]["shift"].range1 =
        checkextraHoursValue(1, "shift", action.payload.value);
      (state.extraErrors as any)[action.payload.keyChanged]["shift"].range2 =
        checkextraHoursValue(2, "shift", action.payload.value);

      (state.extraErrors as any)[action.payload.keyChanged]["calendar"].range1 =
        checkextraHoursValue(1, "calendar", action.payload.value);
      (state.extraErrors as any)[action.payload.keyChanged]["calendar"].range2 =
        checkextraHoursValue(2, "calendar", action.payload.value);
    },
    setDeleteExtraHour(
      state: ExtraHoursInterface,
      action: PayloadAction<{
        key: string;
        idShift: string | undefined;
        idCalendar: string | undefined;
      }>
    ) {
      const temp: any = state.extra;
      const tempErrors: any = state.extraErrors;

      delete temp[action.payload.key];
      if (action.payload.key in tempErrors) {
        delete tempErrors[action.payload.key];
        state.extraErrors = tempErrors;
      }
      state.extra = temp;
    },

    setOpenAddPeriodPopUp(
      state: ExtraHoursInterface,
      action: PayloadAction<boolean>
    ) {
      state.openAddPeriodPopUp = action.payload;
    },
    resetExtraHours() {
      return initialState;
    },
  },
});

export const {
  setOpenAddPeriodPopUp,
  setExtraHours,
  setExtraHourValue,
  setDeleteExtraHour,
  resetExtraHours,
} = PointOfSaleExtraHoursSlice.actions;

export const extraHourValueError = (
  state: RootState,
  turniType: "calendar" | "shift",
  key: string,
  rangeNumber: 1 | 2
): boolean =>
  key in (state.newPointOfSaleExtraHours.extraErrors as any)
    ? (state.newPointOfSaleExtraHours.extraErrors as any)[key][turniType][
        `range${rangeNumber}`
      ]
    : false;

// Selector to determine if there are any errors in the extra hours data
export const hasErrorExtraHourValue = (state: RootState): boolean => {
  return Object.values(state.newPointOfSaleExtraHours.extraErrors).some(
    (error) =>
      error.shift.range1 ||
      error.shift.range2 ||
      error.calendar.range1 ||
      error.calendar.range2
  );
};

export default PointOfSaleExtraHoursSlice.reducer;
