import axios from "axios";

export const deleteStudio = async (id: string): Promise<boolean> => {
  try {
    await axios.delete(`/api/studio/${id}`);

    return true;
  } catch (err) {
    console.trace(err);
    return false;
  }
};
