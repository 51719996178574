import { FC, memo, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import TodayIcon from "@mui/icons-material/Today";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { RootState, AppDispatch } from "../../../../../../store";
import {
  setExtraHours,
  setOpenAddPeriodPopUp,
} from "../../../../store/extra-hours";
import { GridFooterContainer } from "@mui/x-data-grid-premium";

interface ExtraFooterInterface {
  disabled: boolean;
  isSingleCalendarManagement: boolean;
}

export const ExtraFooter: FC<ExtraFooterInterface> = memo(
  ({ isSingleCalendarManagement, disabled }): JSX.Element => {
    const { extra } = useSelector(
      (state: RootState) => state.newPointOfSaleExtraHours
    );
    const dispatch: AppDispatch = useDispatch();

    const handleClick = useCallback(() => {
      const temp: any = {
        ...(extra ?? {}),
        [nanoid()]: {
          startDate: dayjs(),
          endDate: dayjs(),
          extraCalendarType: 0,
          shift: {
            isClosed: true,
            startTime1: null,
            startTime2: null,
            endTime1: null,
            endTime2: null,
          },
          calendar: {
            isClosed: true,
            startTime1: null,
            startTime2: null,
            endTime1: null,
            endTime2: null,
          },
        },
      };

      dispatch(setExtraHours({ value: { ...temp } }));
    }, [dispatch, extra]);

    const handlePeriodClick = useCallback(() => {
      dispatch(setOpenAddPeriodPopUp(true));
    }, [dispatch]);

    return (
      <GridFooterContainer>
        <Stack flex="1" direction="row" gap={1} sx={{ p: 1 }}>
          {!disabled && (
            <Button
              variant="outlined"
              onClick={handleClick}
              startIcon={<TodayIcon />}
            >
              Aggiungi giorno
            </Button>
          )}

          {!disabled && (
            <Button
              variant="outlined"
              onClick={handlePeriodClick}
              startIcon={<CalendarMonthIcon />}
            >
              Aggiungi periodo
            </Button>
          )}
        </Stack>
      </GridFooterContainer>
    );
  }
);
