import { useQuery } from "@tanstack/react-query";
import { getUsersPath } from "../lib/white-cache";
import axios from "axios";
import { GoogleCalendarInterface } from "../interfaces/GoogleCalendarInterface";

export const getUserGoogleCalendar = async (
  userId: string
): Promise<GoogleCalendarInterface | null> => {
  try {
    const {
      data: { data },
    } = await axios.get(`${getUsersPath()}/GoogleCalendarUserSync/${userId}`);
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const useGoogleCalendar = (userId: string, key: string = "") => {
  return useQuery({
    queryFn: async () => await getUserGoogleCalendar(userId),
    queryKey: ["google-calendar-sync", userId, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
