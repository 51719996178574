import { BluTextField } from "@bludata/components";
import { Typography } from "@mui/material";
import React from "react";
import { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

export const Name = memo(() => {
  const watch = useWatch({ name: "name" });
  const { setValue, trigger } = useFormContext();
  const { openForm } = useSelector((state: RootState) => state.serviceRoot);

  return (
    <>
      <div style={{ width: "60%" }}>
        <Typography variant="subtitle2" sx={{ p: "3px 0px 0 0" }}>
          Servizio
        </Typography>
        <BluTextField
          value={watch}
          name="Nome"
          onChange={(event: any) => {
            setValue("name", event.target.value);
            trigger();
          }}
          disabled={openForm === "info"}
        />
      </div>
    </>
  );
});
