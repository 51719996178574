import { BluHtmlEditor, BluTooltip } from "@bludata/components";
import { Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import { FC, memo, useCallback } from "react";
import { Typography } from "@mui/material";
import { Else, If, Then } from "react-if";
import { AppDispatch } from "../../../../store";
import { useFormContext } from "react-hook-form";
import "../../../../styles/html-editor.css";

interface PrivacyInterface {
  disabled: boolean;
  privacyNotice: string;
  privacyPolicy: string;
}

export const Privacy: FC<PrivacyInterface> = memo(
  ({ disabled, privacyNotice, privacyPolicy }) => {
    // const [privacyNoticeCompiled, privacyPolicyCompiled] = useWatch({
    //   name: ["privacyNoticeCompiled", "privacyPolicyCompiled"],
    // });
    const { setValue, trigger } = useFormContext();
    const fontFamily = {
      default: "Roboto",
      items: [
        {
          text: "Roboto",
          value: "Roboto",
          command: "Font",
          subCommand: "FontName",
        },

        {
          text: "sans-serif",
          value: "sans-serif",
          command: "Font",
          subCommand: "FontName",
        },
        {
          text: "Poppins",
          value: "Poppins",
          command: "Font",
          subCommand: "FontName",
        },
        {
          text: "Helvetica",
          value: "Helvetica",
          command: "Font",
          subCommand: "FontName",
        },
        {
          text: "Arial",
          value: "Arial",
          command: "Font",
          subCommand: "FontName",
        },
        {
          text: "Comfortaa",
          value: "Comfortaa",
          command: "Font",
          subCommand: "FontName",
        },
        // {
        //   text: "Montserrat",
        //   value: "Montserrat",
        //   command: "Font",
        //   subCommand: "FontName",
        // },
        // {
        //   text: "Noto Sans Georgian",
        //   value: "Noto Sans Georgian",
        //   command: "Font",
        //   subCommand: "FontName",
        // },
        // {
        //   text: "Source Sans Pro",
        //   value: "Source Sans Pro",
        //   command: "Font",
        //   subCommand: "FontName",
        // },
        // {
        //   text: "Work Sans",
        //   value: "Work Sans",
        //   command: "Font",
        //   subCommand: "FontName",
        // },
      ],
    };

    // useEffect(() => {
    //   const check =
    //     privacyNotice !== undefined &&
    //     privacyNotice !== "" &&
    //     !checkProvacyNotice.test(privacyNotice);

    //   if (check !== privacyNoticeCompiled) {
    //     setValue("privacyNoticeCompiled", check);
    //     trigger("privacyNoticeCompiled");
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [privacyNotice, privacyNoticeCompiled]);

    // useEffect(() => {
    //   const check =
    //     privacyPolicy !== undefined &&
    //     privacyPolicy !== "" &&
    //     privacyPolicy !== "<div></div>";

    //   if (check !== privacyPolicyCompiled) {
    //     setValue("privacyPolicyCompiled", check);
    //     trigger("privacyPolicyCompiled");
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [privacyPolicy, privacyPolicyCompiled]);

    const getPrivacyPolicyValue = useCallback(() => {
      if (!privacyPolicy || privacyPolicy === "") return "<p></p>";
      return privacyPolicy;
    }, [privacyPolicy]);

    const getPrivacyNoticeValue = useCallback(() => {
      if (!privacyNotice || privacyNotice === "") return "<p></p>";
      return privacyNotice;
    }, [privacyNotice]);

    return (
      <Stack direction="column" flex={1} gap={2}>
        <Stack flex="1">
          <Stack flex="1" direction="row">
            <Typography>
              Informativa sul trattamento dei dati personali*
            </Typography>
            <BluTooltip
              text="Questa informativa è stata precompilata. Per completarla, sostituisci i campi evidenziati in giallo con le tue informazioni personali."
              sx={{
                p: "2px",
                ml: "20px",
                backgroundColor: "#f5f5f5", // Colore di sfondo predefinito
                ".dark-mode-bludata &": {
                  backgroundColor: "#404040", // Colore di sfondo in modalità dark
                },
                "& svg": { width: "15px!important", height: "15px!important" },
              }}
            />
          </Stack>
          <If condition={disabled}>
            <Then>
              <div
                style={{
                  width: "100%",
                  border: "1px solid var(--divider-color)",
                  height: "500px",
                  overflowY: "scroll",
                  padding: "10px 20px",
                  boxSizing: "border-box",
                  backgroundColor: "white",
                }}
                dangerouslySetInnerHTML={{ __html: getPrivacyNoticeValue() }}
              />
            </Then>
            <Else>
              <BluHtmlEditor
                cssClass="privacy-html-editor"
                fontFamily={fontFamily}
                valueTemplate={getPrivacyNoticeValue()}
                change={(event: any) => {
                  setValue("privacyNotice", event?.value ?? "<p></p>");
                  trigger("privacyNotice");
                }}
              />
            </Else>
          </If>
        </Stack>
        <Stack flex="1">
          <Stack flex="1" direction="row">
            <Typography>Privacy policy*</Typography>
            <BluTooltip
              text="È importante includere la tua privacy policy in questo spazio. Ti consigliamo di utilizzare la policy del tuo sito web."
              sx={{
                p: "2px",
                ml: "20px",
                backgroundColor: "#f5f5f5", // Colore di sfondo predefinito
                ".dark-mode-bludata &": {
                  backgroundColor: "#404040", // Colore di sfondo in modalità dark
                },
                "& svg": { width: "15px!important", height: "15px!important" },
              }}
            />
          </Stack>

          <If condition={disabled}>
            <Then>
              <div
                style={{
                  width: "100%",
                  border: "1px solid var(--divider-color)",
                  height: "500px",
                  overflowY: "scroll",
                  padding: "10px 20px",
                  boxSizing: "border-box",
                  backgroundColor: "white",
                }}
                dangerouslySetInnerHTML={{ __html: getPrivacyPolicyValue() }}
              />
            </Then>
            <Else>
              <BluHtmlEditor
                cssClass="privacy-html-editor"
                fontFamily={fontFamily}
                valueTemplate={getPrivacyPolicyValue()}
                change={(event: any) => {
                  setValue("privacyPolicy", event?.value ?? "<p></p>");
                  trigger("privacyPolicy");
                }}
              />
            </Else>
          </If>
        </Stack>
      </Stack>
    );
  }
);
