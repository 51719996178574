import { Stack, Box, Typography, useTheme } from "@mui/material";
import { FC, memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { nanoid } from "nanoid";

interface UserRotaHeaderInterface {
  turni: any;
  isSingleCalendarManagement: boolean;
}

export const UserRotaHeader: FC<UserRotaHeaderInterface> = memo(
  ({ turni, isSingleCalendarManagement }) => {
    const { turniFilter } = useSelector((state: RootState) => state.rotaRoot);
    const theme = useTheme();

    const getUsers = useCallback(() => {
      if (turni) {
        const [calendar, shift] = turni;
        const union = [
          ...new Set([
            ...Object.keys(calendar ?? {}),
            ...Object.keys(shift ?? {}),
          ]),
        ];
        return union
          .map((user: string) => {
            if (calendar && user in calendar) return calendar[user];
            return shift[user];
          })
          .flat();
      }
      return [];
    }, [turni]);

    const GetShift = useMemo(() => {
      if (turniFilter === "calendar") return <></>;
      return (
        <Box
          sx={{
            height: "70px",
            boxSizing: "border-box",
            borderTop: "1px solid var(--divider-color)",
          }}
        >
          <Typography sx={{ pt: "25px", pl: "10px", color: "var(--color)" }}>
            Lavoro
          </Typography>
        </Box>
      );
    }, [turniFilter]);

    const GetCalendar = useMemo(() => {
      if (turniFilter === "shift") return <></>;
      return (
        <Box
          sx={{
            height: "69px",
            boxSizing: "border-box",

            borderTop: "1px solid var(--divider-color)",
          }}
        >
          <Typography sx={{ pt: "25px", pl: "10px", color: "var(--color)" }}>
            Appuntamenti
          </Typography>
        </Box>
      );
    }, [turniFilter]);

    return (
      <Stack
        sx={{
          width: "270px",
          position: "sticky",
          left: 0,
          backgroundColor: "var(--base-foreground-color)",
          boxSizing: "border-box",
          zIndex: 1,
          border: "1px solid var(--divider-color)",
          borderRadius: "8px 0 0 8px",
          flexDirection: "column",
        }}
        // direction="row"
      >
        <Stack sx={{ position: "relative" }}>
          <Box
            id="user-rota-header"
            sx={{
              height: "39px",
              width: "250px",
              position: "sticky",
              top: 0,
              left: 0,
              borderRadiusTopleft: "8px",
              backgroundColor:
                theme?.palette?.mode === "dark"
                  ? "var(--background-color) !important"
                  : "#f0f0f0 !important",
            }}
          />
          {getUsers().map((user: any, index: number) => (
            <Stack
              sx={{
                width: "100%",
                backgroundColor: "var(--base-foreground-color)",
                boxSizing: "border-box",
                // borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderRadius: "8px 0 0 8px",
              }}
              key={nanoid()}
              direction="row"
            >
              <Stack flex="1">
                <Box
                  sx={{
                    height:
                      turniFilter === undefined && !isSingleCalendarManagement
                        ? "140px"
                        : "70px",
                    boxSizing: "border-box",
                    borderTop: "1px solid var(--divider-color)",
                    borderRight: !isSingleCalendarManagement
                      ? "1px solid var(--divider-color)"
                      : "",

                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      margin: "auto",
                      pl: "10px",
                      color: "var(--color)",
                    }}
                  >
                    {user.username}
                  </Typography>
                </Box>
              </Stack>
              {!isSingleCalendarManagement && (
                <Stack flex="1">
                  {GetShift}
                  {GetCalendar}
                </Stack>
              )}
            </Stack>
          ))}
        </Stack>
      </Stack>
    );
  }
);
