export const servicesIconsList: {
  [x: string]: { label: string; value: string; icon?: any };
} = {
  "font-case": {
    label: "Controllo della vista",
    value: "font-case",
  },
  eye: {
    label: "Applicazione lenti a contatto",
    value: "eye",
  },
  "eye-test": {
    label: "Controllo della vista",
    value: "eye-test",
  },
  "contact-lens": {
    label: "Lenti a contatto",
    value: "contact-lens",
  },
  "people-arrows": {
    label: "Consulenza personalizzata",
    value: "people-arrows",
  },
  "low-vision": {
    label: "Ipovisione",
    value: "low-vision",
  },
  "house-return": {
    label: "Ritiro prodotto",
    value: "house-return",
  },
  screwdriver: {
    label: "Manutenzione professionale",
    value: "screwdriver",
  },
  glasses: {
    label: "Nuovo occhiale da vista",
    value: "glasses",
  },
  sunglasses: {
    label: "Nuovo occhiale da sole",
    value: "sunglasses",
  },
  "bags-shopping": {
    label: "Personal shopper",
    value: "bags-shopping",
  },
  camera: {
    label: "Foto-ottica",
    value: "camera",
  },
  gem: {
    label: "Gioielleria",
    value: "gem",
  },
  briefcase: {
    label: "Rappresentanti prodotti",
    value: "briefcase",
  },
  ear: {
    label: "Audiologia",
    value: "ear",
  },
  child: {
    label: "Applicazioni LAC o occhiale per bambini e ragazzi",
    value: "child",
  },
  "glass-cheers": {
    label: "Evento nel centro ottico",
    value: "glass-cheers",
  },
  star: {
    label: "Speciale",
    value: "star",
  },
  "traffic-light": {
    label: "Patente",
    value: "traffic-light",
  },
  watch: {
    label: "Orologio",
    value: "watch",
  },
  "concierge-bell": {
    label: "Altri servizi",
    value: "concierge-bell",
  },
  "user-md": {
    label: "Oculista",
    value: "user-md",
  },
  "gift-card": {
    label: "Card",
    value: "gift-card",
  },
  "shopping-cart": {
    label: "Ecommerce",
    value: "shopping-cart",
  },
  "user-injured": {
    label: "Infortuni",
    value: "user-injured",
  },
  "user-shield": {
    label: "Assicurazione",
    value: "user-shield",
  },
  "user-crown": {
    label: "Cliente premium",
    value: "user-crown",
  },

  "user-friends": {
    label: "Amici",
    value: "user-friends",
  },
  female: {
    label: "Femmina",
    value: "female",
  },
  male: {
    label: "Uomo",
    value: "male",
  },
  users: {
    label: "Gruppo di persone",
    value: "users",
  },
  swimmer: {
    label: "Sport acquatici",
    value: "swimmer",
  },
  skiing: {
    label: "Sport invernali",
    value: "skiing",
  },
  user: {
    label: "Persona",
    value: "user",
  },
  "hands-heart": {
    label: "Cura",
    value: "hands-heart",
  },
  "parking-circle": {
    label: "Parcheggio",
    value: "parking-circle",
  },
  "birthday-cake": {
    label: "Compleanno",
    value: "birthday-cake",
  },
  wheelchair: {
    label: "Handicap",
    value: "wheelchair",
  },
};

// ["font-case",
// 'eye',
// "eye-test",
// "contact-lens",
// "people-arrows",
// "low-vision",
// "house-return",
// 'screwdriver',
// 'glasses',
// 'sunglasses',
// "bags-shopping",
// 'camera',
// 'gem',
// 'briefcase',
// 'ear',
// 'child',
// "glass-cheers",
// 'star',
// "traffic-light",
// 'watch',
// "concierge-bell",
// "user-md",
// "gift-card",
// "shopping-cart",
// "user-injured",
// "user-shield",
// "user-crown",

// "user-friends",
// 'female',
// 'male',
// 'users',
// 'swimmer',
// 'skiing',
// 'user',
// "hands-heart",
// "parking-circle",
// "birthday-cake",
// 'wheelchair',]
