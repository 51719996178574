import { Checkbox } from "@mui/material";
import { ChangeEvent, FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store";
import { EnableAllInterface, setEnableAll } from "../../../store/form";

interface EnableHeaderInterface {
  key: keyof EnableAllInterface;
}

export const EnableHeader: FC<EnableHeaderInterface> = ({ key }) => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useSelector((state: RootState) => state.serviceForm);
  const { openForm } = useSelector((state: RootState) => state.serviceRoot);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setEnableAll({ key, value: e?.target ? e?.target?.checked : false })
      );
    },
    [dispatch, key]
  );

  const getValue = useCallback(() => {
    return (
      Object.values(params[key]).find(
        (element: any) => element.enable === false
      ) === undefined
    );
  }, [key, params]);

  return (
    <Checkbox
      checked={getValue()}
      onChange={handleChange}
      disabled={openForm === "info"}
      sx={{
        width: "30px",
        height: "30px",
        // marginLeft: "10px",
      }}
    />
  );
};
