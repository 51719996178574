import { FC } from "react";
import { BluTitle } from "@bludata/components";
import { Stack } from "@mui/material";
import { ServiceSelect } from "../../components/ServiceSelect/ServiceSelect";

export const Agenda: FC<{
  mode: "modify" | "info" | "new";
}> = ({ mode }): JSX.Element => {
  return (
    <Stack flex="1" gap={1}>
      <BluTitle
        text={"Agenda"}
        sx={{ mt: "8px!important", mb: "8px!important" }}
      />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="start"
        gap="1"
      >
        <ServiceSelect disabled={mode === "info"} />
      </Stack>
    </Stack>
  );
};
