export const defaultUserBb: any = {
  id: null,
  companyId: null,
  pointOfSaleId: null,
  externalCode: "",
  name: "",
  surname: "",
  email: "",
  username: "",
  sign: "",
  telephone: null,
  photo: "",
  color: "",
  isActive: true,
  hasAgenda: false,
  visibilityUserEnum: -1,
  userRolesEnum: 0,
  updatedAt: null,
  userId: null,
  password: "",
};
