import { FC, memo, useDeferredValue, useEffect } from "react";
import { Divider, Stack } from "@mui/material";
import { AppDispatch, useAppSelector } from "../../../../../../store";
import { useDispatch } from "react-redux";
import { setExtraHours } from "../../../../store/extra-hours";
import { setIsLoadingForm } from "../../../../store/root";
import { BluTitle } from "@bludata/components";
import { ExtraHoursDataGrid } from "./ExtraHoursDataGrid";
import { AddPeriod } from "./AddPeriod";
import { useTurniExtraPointOfSale } from "../../../../api/turni-extra-point-of-sale";
import { getPointOfSaleWhiteCache } from "../../../../lib/white-cache";
import { useWatch } from "react-hook-form";
import { bluBookingLoginData } from "../../../../../../store/slices/root";

interface ExtraHoursInterface {
  disabled: boolean;
}

export const ExtraHours: FC<ExtraHoursInterface> = memo(({ disabled }) => {
  const dispatch: AppDispatch = useDispatch();
  const { isSingleCalendarManagement } = useAppSelector(bluBookingLoginData);

  const pointOfSaleId = useWatch({ name: "pointOfSaleId" });

  const { data, isLoading } = useTurniExtraPointOfSale(
    pointOfSaleId,
    isSingleCalendarManagement
  );

  const result = useDeferredValue(data);

  useEffect(() => {
    if (result) {
      dispatch(setExtraHours({ value: result }));
    }

    dispatch(setIsLoadingForm({ key: "extraHours", value: isLoading }));
  }, [dispatch, isLoading, result]);

  return (
    <Stack flex="1" gap={1}>
      <Divider sx={{ opacity: 0, mb: "24px!important" }} />

      <BluTitle
        text={"Orari straordinari"}
        sx={{ mt: "8px!important", mb: "8px!important" }}
      />
      <AddPeriod />
      <ExtraHoursDataGrid disabled={disabled} />
    </Stack>
  );
});
