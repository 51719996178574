import axios from "axios";
import { getUsersPath } from "../lib/white-cache";

export const createUserGoogleCalendar = async (
  userId: string,
  email: string
): Promise<boolean> => {
  try {
    await axios.post(`${getUsersPath()}/GoogleCalendarUserSync`, {
      userId: userId,
      email: email,
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
