/**
 * Parses extra hours data and returns an array of objects for creating extra shift and extra calendar users.
 *
 * @param userId - The ID of the user.
 * @param extraHours - The extra hours data.
 * @param isSingleCalendarManagement - Indicates whether single calendar management is enabled.
 * @returns An array containing objects for creating extra shift users and extra calendar users.
 *
 * @remarks
 * The function iterates over the extra hours data and creates objects for each point of sale and date.
 * If multiple entries exist for the same date, the first entry with extraCalendarType = 0 is considered.
 * The created objects include the necessary properties for creating extra shift and extra calendar users.
 */
import { TurniExtraInterface } from "../interfaces/extra-turni-user";

/**
 *
 * @param userId
 * @param extraHours
 * @param isSingleCalendarManagement
 * @returns [CreateExtraShiftUsers, CreateExtraCalendarUsers]
 *
 * ? Nel caso in cui ci sia più volte lo stesso giorno viene preso il primo che ha extraCalendarType = 0
 */
export const parseExtraHours = (
  userId: string | undefined,
  extraHours: TurniExtraInterface | null,
  isSingleCalendarManagement: boolean
) => {
  let CreateExtraShiftUsers: any = [];
  let CreateExtraCalendarUsers: any = [];

  if (!extraHours) return [CreateExtraShiftUsers, CreateExtraCalendarUsers];

  for (const pointOfSaleKey in extraHours) {
    const pointOfSaleExtraHours: any = extraHours[pointOfSaleKey];

    const map = new Map();
    for (const pointOfSaleExtraHourKey in pointOfSaleExtraHours) {
      let startDate = pointOfSaleExtraHours[pointOfSaleExtraHourKey].startDate
        ?.clone()
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
      let endDate = pointOfSaleExtraHours[pointOfSaleExtraHourKey].endDate
        ?.clone()
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);

      while (!startDate?.isAfter(endDate)) {
        const key = startDate?.format("YYYY-MM-DD");

        if ((map.get(key) ?? {})?.extraCalendarType !== 0) {
          if (
            !map.has(key) ||
            pointOfSaleExtraHours[pointOfSaleExtraHourKey]
              ?.extraCalendarType === 0
          ) {
            map.set(key, {
              ...pointOfSaleExtraHours[pointOfSaleExtraHourKey],
              date: startDate,
              startDate: undefined,
              endDate: undefined,
              key: pointOfSaleExtraHourKey,
            });
          }
        }

        startDate = startDate.add(1, "day");
      }
    }

    const pointOfSaleExtraHoursArray = Array.from(map.values());

    for (const extraHour of pointOfSaleExtraHoursArray) {
      const { shift, calendar } = extraHour;
      const formattedDate = extraHour.date.format("YYYY-MM-DD");

      CreateExtraShiftUsers.push({
        userId,
        pointOfSaleId: pointOfSaleKey,
        isClosingDay: shift?.isClosed ?? false,
        startTime1:
          shift !== null && shift.startTime1 && shift.startTime1.isValid()
            ? shift.startTime1.format("HH:mm")
            : null,
        startTime2:
          shift !== null && shift.startTime2 && shift.startTime2.isValid()
            ? shift.startTime2.format("HH:mm")
            : null,
        endTime1:
          shift !== null && shift.endTime1 && shift.endTime1.isValid()
            ? shift.endTime1.format("HH:mm")
            : null,
        endTime2:
          shift !== null && shift.endTime2 && shift.endTime2.isValid()
            ? shift.endTime2.format("HH:mm")
            : null,
        startValidDate: formattedDate,
        endValidDate: formattedDate,
        extraCalendarType: extraHour.extraCalendarType,
      });
      if (!isSingleCalendarManagement)
        CreateExtraCalendarUsers.push({
          userId,
          pointOfSaleId: pointOfSaleKey,
          isClosingDay: calendar?.isClosed ?? false,
          startTime1:
            calendar !== null &&
            calendar.startTime1 &&
            calendar.startTime1.isValid()
              ? calendar.startTime1.format("HH:mm")
              : null,
          startTime2:
            calendar !== null &&
            calendar.startTime2 &&
            calendar.startTime2.isValid()
              ? calendar.startTime2.format("HH:mm")
              : null,
          endTime1:
            calendar !== null &&
            calendar.endTime1 &&
            calendar.endTime1.isValid()
              ? calendar.endTime1.format("HH:mm")
              : null,
          endTime2:
            calendar !== null &&
            calendar.endTime2 &&
            calendar.endTime2.isValid()
              ? calendar.endTime2.format("HH:mm")
              : null,
          startValidDate: formattedDate,
          endValidDate: formattedDate,
          extraCalendarType: extraHour.extraCalendarType,
        });
    }
  }

  if (isSingleCalendarManagement)
    return [CreateExtraCalendarUsers, CreateExtraShiftUsers];

  return [CreateExtraShiftUsers, CreateExtraCalendarUsers];
};
