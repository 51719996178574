import axios from "axios";
import { store } from "../../../store";

interface ManagementCompanySettingsInterface {
  useShiftPointOfSale?: boolean;
  useShiftUser?: boolean;
}

export const managementCompanySettings = async ({
  useShiftPointOfSale = true,
  useShiftUser = true,
}: ManagementCompanySettingsInterface): Promise<boolean> => {
  try {
    const {
      generaliRoot: { mode, ...companySettings },
      generaliHolidays: { holidays, DeleteHolidays, ModifyHolidays },
    } = store.getState();

    await axios.put("/api/General/Management", {
      updateHolidayManagementRequest: {
        CreateHolidays: holidays
          ?.filter((row: any) => row?.id === undefined)
          .flat(),
        DeleteHolidays: DeleteHolidays,
        ModifyHolidays: ModifyHolidays,
      },
      updateCompanySettingsRequest: {
        ...companySettings,
        useShiftPointOfSale,
        useShiftUser,
      },
    });
    return true;
  } catch (error) {
    console.error("ERROR MANAGEMENT COMPANY SETTINGS:", error);
    return false;
  }
};
// 0.3.185
