import { FC, memo, useCallback } from "react";
import { Stack, TableCell } from "@mui/material";
import { DefaultTimeComponent } from "./DefaultTimeComponent";
import { nanoid } from "nanoid";
import { Dayjs } from "dayjs";
import { AppDispatch, useAppSelector } from "../../../../../../store";
import { useDispatch } from "react-redux";
import {
  defaultHourValue,
  setDefaultHoursValue,
} from "../../../../store/default-hours";

export const DefaultRange: FC<{
  disabled: boolean;
  day: string;
  turniType: "shift" | "calendar";
}> = memo(({ disabled, day, turniType }): JSX.Element => {
  const turniData: any = useAppSelector((state) =>
    defaultHourValue(state, turniType, day)
  );
  const dispatch: AppDispatch = useDispatch();

  const setRangeHour = useCallback(
    (startTime: Dayjs | null, endTime: Dayjs | null, range: 1 | 2) => {
      if (startTime) {
        if (!startTime.isValid()) {
          startTime = null;
        }
      }

      if (endTime) {
        if (!endTime.isValid()) {
          endTime = null;
        }
      }
      const temp: any = { ...turniData };
      temp[`startTime${range}`] = startTime;
      temp[`endTime${range}`] = endTime;
      dispatch(
        setDefaultHoursValue({ defaultHoursValue: temp, day, turniType })
      );
    },
    [day, dispatch, turniData, turniType]
  );

  const getValues = useCallback(
    (range: 1 | 2) => {
      return (
        <>
          <TableCell
            id={nanoid()}
            key={nanoid()}
            colSpan={2}
            sx={{
              borderTop: 0,
              borderLeft: "1px solid rgba(224, 224, 224, 1)",
              p: 0,
            }}
            size="small"
          >
            <Stack
              direction="row"
              gap={1}
              justifyContent="center"
              justifyItems="center"
            >
              <DefaultTimeComponent
                title={`${turniType}${day}startTime${range}`}
                disabled={disabled}
                start={
                  turniData && `startTime${range}` in turniData
                    ? turniData[`startTime${range}`]
                    : null
                }
                end={
                  turniData && `endTime${range}` in turniData
                    ? turniData[`endTime${range}`]
                    : null
                }
                setValue={(startTime: Dayjs | null, endTime: Dayjs | null) =>
                  setRangeHour(startTime, endTime, range)
                }
                turniType={turniType}
                day={day}
                rangeNumber={range}
              />
            </Stack>
          </TableCell>
        </>
      );
    },
    [day, disabled, setRangeHour, turniData, turniType]
  );

  return (
    <>
      {getValues(1)}
      {getValues(2)}
    </>
  );
});
