import {
  TurniDefaultInterface,
  TurniItemRangeInterface,
} from "../interfaces/default-turni-user";

export const getCreateDefaultHours = (
  userId: string | undefined,
  defaultHours: TurniDefaultInterface | null,
  isSingleCalendarManagement: boolean
) => {
  let defaultHoursShift: any = {};
  let defaultHoursCalendar: any = {};
  if (defaultHours) {
    for (const pointOfSaleKey in defaultHours) {
      const pointOfSale: any = (defaultHours as any)[pointOfSaleKey];
      for (const day of days) {
        console.log(
          "DEFAULT POINT OF SALE",
          pointOfSale,
          pointOfSale[`shift${day}`]
        );

        if (pointOfSale[`shift${day}`]) {
          const [startTime1, endTime1, startTime2, endTime2] = getRanges(
            pointOfSale[`shift${day}`]
          );
          const key: string = `${pointOfSaleKey}${[
            startTime1,
            endTime1,
            startTime2,
            endTime2,
          ].toString()}`;

          if (!(key in defaultHoursShift)) {
            defaultHoursShift[key] = {
              userId,
              pointOfSaleId: pointOfSaleKey,
              startTime1,
              endTime1,
              startTime2,
              endTime2,
              ...defaultHoursEmptyStructure,
              [daysTranslation[day]]: true,
            };
          } else {
            defaultHoursShift[key][daysTranslation[day]] = true;
          }
        }
        if (!isSingleCalendarManagement) {
          if (pointOfSale[`calendar${day}`]) {
            const [startTime1, endTime1, startTime2, endTime2] = getRanges(
              pointOfSale[`calendar${day}`]
            );
            const key: string = `${pointOfSaleKey}${[
              startTime1,
              endTime1,
              startTime2,
              endTime2,
            ].toString()}`;

            if (!(key in defaultHoursCalendar)) {
              defaultHoursCalendar[key] = {
                userId,
                pointOfSaleId: pointOfSaleKey,
                startTime1,
                endTime1,
                startTime2,
                endTime2,
                ...defaultHoursEmptyStructure,
                [daysTranslation[day]]: true,
              };
            } else {
              defaultHoursCalendar[key][daysTranslation[day]] = true;
            }
          }
        } else defaultHoursCalendar = defaultHoursShift;
      }
    }
  }

  console.log(
    "defaultHoursShift & defaultHoursCalendar",
    defaultHoursShift,
    defaultHoursCalendar
  );
  return [
    Object.values(defaultHoursShift),
    Object.values(defaultHoursCalendar),
  ];
};

const getRanges = (turno: TurniItemRangeInterface) => {
  const startTime1 =
    turno.startTime1 && turno.startTime1.isValid()
      ? turno.startTime1.format("HH:mm")
      : null;
  const startTime2 =
    turno.startTime2 && turno.startTime2.isValid()
      ? turno.startTime2.format("HH:mm")
      : null;
  const endTime1 =
    turno.endTime1 && turno.endTime1.isValid()
      ? turno.endTime1.format("HH:mm")
      : null;
  const endTime2 =
    turno.endTime2 && turno.endTime2.isValid()
      ? turno.endTime2.format("HH:mm")
      : null;
  return [startTime1, endTime1, startTime2, endTime2];
};

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
const daysTranslation: any = {
  monday: "hasMonday",
  tuesday: "hasTuesday",
  wednesday: "hasWednesday",
  thursday: "hasThursday",
  friday: "hasFriday",
  saturday: "hasSaturday",
  sunday: "hasSunday",
};
const defaultHoursEmptyStructure = {
  hasMonday: false,
  hasTuesday: false,
  hasWednesday: false,
  hasThursday: false,
  hasFriday: false,
  hasSaturday: false,
  hasSunday: false,
};
