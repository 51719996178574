import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TurniExtraInterface,
  TurniExtraItemInterface,
} from "../interfaces/extra-turni-user";

import { RootState } from "../../../store";
import { Dayjs } from "dayjs";
import {
  checkAllExtraHours,
  checkextraHoursValue,
} from "../utils/check-extra-hours";

export interface ExtraHoursInterface {
  extra: TurniExtraInterface;
  extraErrors: {
    [pointOfSaleId: string]: {
      [key: string]: {
        pointOfSaleId: string;
        shift: {
          range1: boolean;
          range2: boolean;
        };
        calendar: {
          range1: boolean;
          range2: boolean;
        };
      };
    };
  };

  openAddPeriodPopUp: boolean;
}

const initialState: ExtraHoursInterface = {
  extra: {},
  extraErrors: {},
  openAddPeriodPopUp: false,
};

const UserExtraHoursSlice = createSlice({
  name: "UserExtraHours",
  initialState,
  reducers: {
    setExtraHours(
      state: ExtraHoursInterface,
      action: PayloadAction<{
        value: TurniExtraInterface;
        keyChanged?: string;
        pointOfSaleId?: string;
      }>
    ) {
      state.extra = action.payload.value;
      if (action.payload.pointOfSaleId && action.payload.keyChanged) {
        (state.extraErrors as any)[action.payload.pointOfSaleId][
          action.payload.keyChanged
        ]["shift"].range1 = checkextraHoursValue(
          1,
          "shift",
          action.payload.value[action.payload.pointOfSaleId][
            action.payload.keyChanged
          ]
        );
        (state.extraErrors as any)[action.payload.pointOfSaleId][
          action.payload.keyChanged
        ]["shift"].range2 = checkextraHoursValue(
          2,
          "shift",
          action.payload.value[action.payload.pointOfSaleId][
            action.payload.keyChanged
          ]
        );

        (state.extraErrors as any)[action.payload.pointOfSaleId][
          action.payload.keyChanged
        ]["calendar"].range1 = checkextraHoursValue(
          1,
          "calendar",
          action.payload.value[action.payload.pointOfSaleId][
            action.payload.keyChanged
          ]
        );
        (state.extraErrors as any)[action.payload.pointOfSaleId][
          action.payload.keyChanged
        ]["calendar"].range2 = checkextraHoursValue(
          2,
          "calendar",
          action.payload.value[action.payload.pointOfSaleId][
            action.payload.keyChanged
          ]
        );
      } else {
        for (const key in action.payload.value) {
          state.extraErrors[key] = checkAllExtraHours(
            action.payload.value[key]
          );
        }
      }
    },

    setExtraHourValue(
      state: ExtraHoursInterface,
      action: PayloadAction<{
        value: {
          date: Dayjs;
          pointOfSaleId: string;
          shift: TurniExtraItemInterface | null;
          calendar: TurniExtraItemInterface | null;
        };
        keyChanged: string;
        turniType?: "calendar" | "shift";
        pointOfSaleId: string;
      }>
    ) {
      (state.extra as any)[action.payload.pointOfSaleId][
        action.payload.keyChanged
      ] = action.payload.value;

      (state.extraErrors as any)[action.payload.pointOfSaleId][
        action.payload.keyChanged
      ]["shift"].range1 = checkextraHoursValue(
        1,
        "shift",
        action.payload.value
      );
      (state.extraErrors as any)[action.payload.pointOfSaleId][
        action.payload.keyChanged
      ]["shift"].range2 = checkextraHoursValue(
        2,
        "shift",
        action.payload.value
      );

      (state.extraErrors as any)[action.payload.pointOfSaleId][
        action.payload.keyChanged
      ]["calendar"].range1 = checkextraHoursValue(
        1,
        "calendar",
        action.payload.value
      );
      (state.extraErrors as any)[action.payload.pointOfSaleId][
        action.payload.keyChanged
      ]["calendar"].range2 = checkextraHoursValue(
        2,
        "calendar",
        action.payload.value
      );
    },
    setDeleteExtraHour(
      state: ExtraHoursInterface,
      action: PayloadAction<{
        key: string;
        idShift: string | undefined;
        idCalendar: string | undefined;
        pointOfSaleId: string;
      }>
    ) {
      const temp: any = state.extra[action.payload.pointOfSaleId];
      const tempErrors: any = state.extraErrors[action.payload.pointOfSaleId];

      delete temp[action.payload.key];
      if (action.payload.key in tempErrors) {
        delete tempErrors[action.payload.key];
        state.extraErrors[action.payload.pointOfSaleId] = tempErrors;
      }
      state.extra[action.payload.pointOfSaleId] = temp;
    },

    setOpenAddPeriodPopUp(
      state: ExtraHoursInterface,
      action: PayloadAction<boolean>
    ) {
      state.openAddPeriodPopUp = action.payload;
    },
    resetExtraHours() {
      return initialState;
    },
  },
});

export const {
  setOpenAddPeriodPopUp,
  setExtraHours,
  setExtraHourValue,
  setDeleteExtraHour,
  resetExtraHours,
} = UserExtraHoursSlice.actions;

export const extraHourValueError = (
  state: RootState,
  turniType: "calendar" | "shift",
  key: string,
  rangeNumber: 1 | 2,
  pointOfSaleId: string
): boolean =>
  key in (state.newUserExtraHours.extraErrors as any)[pointOfSaleId]
    ? (state.newUserExtraHours.extraErrors as any)[pointOfSaleId][key][
        turniType
      ][`range${rangeNumber}`]
    : false;

export const hasErrorExtraHourValue = (state: RootState): boolean => {
  return (
    Object.values(state.newUserExtraHours.extraErrors).find(
      (value: any) =>
        Object.values(value).find(
          (error: any) =>
            error.shift.range1 ||
            error.shift.range2 ||
            error.calendar.range1 ||
            error.calendar.range2
        ) !== undefined
    ) !== undefined
  );
};

export default UserExtraHoursSlice.reducer;
